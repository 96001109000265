import { Stepper } from '@contraktor-tech/ui';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resetStore } from '../../../redux/ducks/starterWizard';
import AddPersons from '../AddPersons';
import ConfirmPersons from '../ConfirmPersons';
import FileDetails from '../FileDetails';
import SendSign from '../SendSign';
import UploadArea from '../UploadArea';
import UploadLoading from '../UploadLoading';
import { Wrapper } from './styles';

const stepperItems = [
  {
    step: 0,
    label: 'Importar',
    steps: [2]
  },
  {
    step: 1,
    label: 'Cadastrar',
    steps: [3, 4]
  },
  {
    step: 2,
    label: 'Enviar',
    steps: [5, 6]
  }
];

const Wizard = () => {
  const dispatch = useDispatch();
  const { stepIndex } = useSelector((state) => state.starterWizard);

  const handleGetActiveStep = () => {
    const activeStep = stepperItems.find((step) => step
      .steps.includes(stepIndex));
    return activeStep ? activeStep.step : 0;
  };

  const currentStep = handleGetActiveStep();

  useEffect(() => {
    dispatch(resetStore());
  // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <div className="steps-container">
        <Stepper
          options={stepperItems}
          activeStep={currentStep}
        />
      </div>
      {stepIndex === 1 && <UploadArea />}
      {stepIndex === 2 && <UploadLoading />}
      {stepIndex === 3 && <FileDetails />}
      {stepIndex === 4 && <AddPersons />}
      {stepIndex === 5 && <ConfirmPersons />}
      {stepIndex === 6 && <SendSign />}
    </Wrapper>
  );
};
export default Wizard;
