import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import chairImage from '../../../assets/img/cadeira_convite.svg';
import logoHorizontalColorfull from '../../../assets/img/logo_horiz_colorfull.svg';
import { Breakpoints } from '../../../utils/constants';
import { zDepth } from '../../../utils/styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  width: 60%;
  margin-bottom: 2em;

  img {
    width: 100%;
  }

  @media (min-width: ${Breakpoints.TABLET}px) {
    width: 310px;
  }
`;

const LeftColumn = styled.div`
  background: ${(props) => props.theme.backgroundColor};
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3em;
  position: relative;
  overflow: auto;

  ${zDepth(2)}
`;

const RightColumn = styled.div`
  background: ${(props) => props.theme.primaryColor};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: 10%;
`;

const Image = styled.div`
  width: 130%;
  height: 100%;
  margin-left: -15%;
  margin-right: -15%;

  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 90%;
`;

const Main = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: ${Breakpoints.LARGEMONITOR}px) {
    width: 80%;
  }

  input {
    height: 50px;
  }
`;

const LayoutPage = ({
  children
}) => {
  const { loading } = useSelector((state) => state.recoveryPassword);
  return (
    <Wrapper>
      <LeftColumn>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>

        <LogoWrapper>
          <img src={logoHorizontalColorfull} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
        </LogoWrapper>

        <Main>
          {React.Children.toArray(children)}
        </Main>
      </LeftColumn>
      <RightColumn>
        <Image url={chairImage} />
      </RightColumn>
    </Wrapper>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutPage;
