import PropTypes from 'prop-types';
import React from 'react';

import { getInitialsFromName } from '../../../utils/parsers';
import Avatar from '../Avatar';

const SignerAvatar = ({
  signer,
  ...props
}) => (
  <Avatar
    color={signer.id}
    popup={signer.name}
    {...props}
  >
    {getInitialsFromName(signer.name)}
  </Avatar>
);

SignerAvatar.propTypes = {
  signer: PropTypes.objectOf(PropTypes.string).isRequired
};

export default SignerAvatar;
