/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useCallback
} from 'react';
import { MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import api from '../../../utils/backend';
import { checkUserIsSigner } from '../../../utils/helpers';
import Button from '../../Button';
import FilePreview from '../../FilePreview';
import SignModal from './components/SignModal';
import * as S from './styles';

const DocumentPreview = ({ doc, confirmSign = () => {} }) => {
  const history = useHistory();
  const {
    user
  } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  useEffect(() => {
    if (!doc.signatureLoading && openModal) {
      setOpenModal(false);
    }
  // eslint-disable-next-line
  }, [doc.signatureLoading]);

  const {
    signers, signature, status, preview, title, id
  } = doc.data;

  const currentSigner = signers && signers.find((signer) => signer.email === user.email);
  const canSign = checkUserIsSigner(signers, user);

  const documentSigned = status === 'signed';
  const readOnly = status !== 'canceled' && !documentSigned && signature;

  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    const handleUpdateTitle = () => api.put(`/documents/${id}`, {
      document: {
        title: documentTitle === '' ? 'Sem título' : documentTitle
      }
    });

    setTimeout(() => {
      handleUpdateTitle();
    }, 500);
  }, [documentTitle, id]);

  useEffect(() => {
    if (editTitle) document.getElementById('doc_title').focus();
    else if (documentTitle === '') setDocumentTitle('Sem título');
  }, [editTitle]); //eslint-disable-line

  const updateDocumentTitle = async (event) => {
    setDocumentTitle(event.target.value);
  };

  const handleKeyboardAction = useCallback((event) => {
    if (event.key === 'Enter') setEditTitle(false);
  }, []);

  const editTitleInput = () => (
    <input
      name="doc_title"
      id="doc_title"
      value={documentTitle}
      maxLength="120"
      onChange={(event) => updateDocumentTitle(event)}
      onBlur={() => setEditTitle(false)}
      onKeyDown={(event) => handleKeyboardAction(event)}
    />
  );

  const canEdit = status === 'draft';

  return (
    <S.Wrapper>
      <S.Header>
        <button onClick={() => history.push('/documents')} type="button" className="documentPreview-header__back-button">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0002 7.00008V9.00008H4.00016L9.50016 14.5001L8.08016 15.9201L0.160156 8.00008L8.08016 0.0800781L9.50016 1.50008L4.00016 7.00008H16.0002Z" fill="white" />
          </svg>
          Voltar
        </button>

        <span className="documentPreview-header__title">
          {
            !editTitle
              && canEdit
              && (
                <MdEdit
                  onClick={() => {
                    setEditTitle(!editTitle);
                  }}
                  className="edit-icon"
                />
              )
          }
          {
            !editTitle
              ? documentTitle.length > 30 ? `${documentTitle.slice(0, 30)}...` : documentTitle
              : editTitleInput()
          }
        </span>
      </S.Header>

      <S.Content>
        {canSign
        && readOnly && (
          <S.SignButtonContainer>
            <Button primary onClick={setOpenModal}>Assinar documento</Button>
          </S.SignButtonContainer>
        )}
        <FilePreview previewUrl={preview} />
      </S.Content>

      <SignModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={confirmSign}
        name={currentSigner?.name}
        email={currentSigner?.email}
        loading={doc.signatureLoading}
      />
    </S.Wrapper>
  );
};

export default DocumentPreview;
