/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useCallback,
  useState,
  createContext,
  useContext
} from 'react';

import { useChat } from '../../hooks/ChatProvider';
import { useSocket } from '../../hooks/useSocket';
import { formatBytes } from '../../utils/parsers';
import { useAuth } from '../AuthContext/AuthContext';

const StorageDocumentContext = createContext();

export const StorageDocumentProvider = ({ children }) => {
  const { chatUpdate } = useChat();
  const [documentChannel, setDocumentChannel] = useState(null);
  const [storageChannel, setStorageChannel] = useState(null);
  const [documentsData, setDocumentsData] = useState({
    documents: [],
    loading: false
  });

  const [dispatchesCountData, setDispatchesCountData] = useState({
    maxDispatches: 0,
    dispatches: 0
  });

  const [storageData, setStorageData] = useState({
    storageSize: 0,
    storage: 0
  });

  const { subscribeChannel } = useSocket();
  const { user, signed } = useAuth();

  const handleStorageSocket = useCallback((orgId) => {
    setStorageChannel(subscribeChannel(`storage:${orgId}`, { organization_id: orgId }));
  }, [subscribeChannel, setStorageChannel]);

  const handleDocumentSocket = useCallback((orgId) => {
    setDocumentChannel(subscribeChannel(`document:${orgId}`, { organization_id: orgId }));
  }, [subscribeChannel, setDocumentChannel]);

  const handleSocketConnections = useCallback((orgId) => {
    handleDocumentSocket(orgId);
    handleStorageSocket(orgId);
  }, [handleDocumentSocket, handleStorageSocket]);

  useEffect(() => {
    if (documentChannel && documentChannel.state !== 'joined') {
      documentChannel
        .join()
        .receive('ok', () => {})
        .receive('error', () => {});

      documentChannel
        .push('get', { organization_id: user.organizationId })
        .receive('ok', ({ documents: docs, dispatch_count, max_dispatch }) => {
          setDispatchesCountData((prevState) => ({
            ...prevState,
            maxDispatches: max_dispatch,
            dispatches: dispatch_count
          }));
          setDocumentsData((prevState) => ({
            ...prevState, loading: false, documents: docs
          }));
          chatUpdate({
            documents_sent_this_month: dispatch_count
          });
        });

      documentChannel
        .on('send_document', ({ documents: docs, dispatches }) => {
          setDocumentsData((prevState) => ({
            ...prevState, documents: docs
          }));

          setDispatchesCountData((prevState) => ({
            ...prevState,
            dispatches
          }));

          chatUpdate({
            documents_sent_this_month: dispatches
          });
        });
    }
  }, [documentChannel]); //eslint-disable-line

  useEffect(() => {
    if (storageChannel && storageChannel.state !== 'joined') {
      storageChannel
        .join()
        .receive('ok', () => {})
        .receive('error', () => {});

      storageChannel
        .push('get', { organization_id: user.organizationId })
        .receive('ok', ({ storage_size, storage_usage }) => {
          setStorageData((prevState) => ({
            ...prevState,
            storageSize: storage_size,
            storage: storage_usage
          }));
          chatUpdate({
            storage_used: formatBytes(storage_usage)
          });
        });

      storageChannel
        .on('send_storage_usage', ({ storage_usage }) => {
          setStorageData((prevState) => ({
            ...prevState,
            storage: storage_usage
          }));
          chatUpdate({
            storage_used: formatBytes(storage_usage)
          });
        });
    }
  }, [storageChannel]); //eslint-disable-line

  useEffect(() => {
    if (signed) {
      handleSocketConnections(user.organizationId);
    }
  }, [signed]); //eslint-disable-line

  return (
    <StorageDocumentContext.Provider
      value={{
        documentsData,
        dispatchesCountData,
        storageData
      }}
    >
      {children}
    </StorageDocumentContext.Provider>
  );
};
export function useStorageDocument() {
  const context = useContext(StorageDocumentContext);
  return context;
}

StorageDocumentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastName: PropTypes.string,
    organization: PropTypes.shape({
      amountToPay: PropTypes.string,
      logoUrl: PropTypes.string,
      nextChargeAt: PropTypes.string,
      state: PropTypes.string,
      planName: PropTypes.string
    }),
    subscription: PropTypes.shape({
      value: PropTypes.string,
      suspended: PropTypes.bool,
      suspensionReason: PropTypes.string,
      nextPaymentDueDate: PropTypes.string,
      planName: PropTypes.string
    }),
    organizationId: PropTypes.string,
    token: PropTypes.string
  })
};

StorageDocumentProvider.defaultProps = {
  user: null
};

export default StorageDocumentContext;
