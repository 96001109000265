/* eslint-disable import/named */
import React, {
  useState
} from 'react';

import FirstStep from './components/FirstStep';
import LastStep from './components/LastStep';
import Steps from './components/Steps';

const RegisterSteps = () => {
  const [flow, setFlow] = useState({
    step: 1,
    payload: null,
    validationError: null
  });

  return (
    <div>
      <Steps step={flow.step} />
      {flow.step === 1 ? (
        <FirstStep
          onSave={(payload) => setFlow((prev) => ({
            step: 2, payload: { ...prev.payload, ...payload }
          }))}
          validationError={flow.validationError}
          prevData={flow.payload}
        />
      ) : (
        <LastStep
          prevData={flow.payload}
          validationError={(error, payload) => setFlow({
            step: 1, payload, validationError: error
          })}
        />
      )}
    </div>

  );
};

export default RegisterSteps;
