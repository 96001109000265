/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: ${theme.starterHeight};
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    font-family: 'Nunito', sans-serif;
    background:  rgba(150, 177, 208, 0.08);
    align-items: center;
    justify-content: center;
    color: ${theme.fontColor};
    cursor: pointer;

    &:focus {
      outline: none;
    }

    > i {
      opacity: 0.25;
    }

    > h3 {
      font-weight: 500;
      margin-top: 0.5em;
    }

    > p.footer-info {
      font-family: 'Nunito', sans-serif;
      font-size: 14px;
      margin-top: 9px;
    }

    > p.error {
      font-size: 14px;
      margin-top: 1rem;
      color: ${theme.errorColor};
      font-weight: 500;
    }

    .upload-button {
      height: 48px;
    }
  
  `}
 
`;

export { Wrapper };
