import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import * as reducers from './ducks';
import logger from './middlewares/logger';

const configureStore = () => {
  const rootReducer = combineReducers(reducers);
  const rootMiddleware = process.env.REACT_APP_CUSTOM_NODE_ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

  return createStore(rootReducer, composeWithDevTools(rootMiddleware));
};

export default configureStore;
