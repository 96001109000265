import styled from 'styled-components';

import themes from '../../../../../_utils/themes';

export const Card = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  padding: 8px;
  border: 1px solid ${themes.pallete.primary};
  background-color: ${themes.pallete.primaryLight};
  border-radius: 8px;
`;

export const CardText = styled.span`
  color: ${themes.pallete.primary};
  font-size: ${themes.fonts.size.medium};
  font-family: ${themes.fonts.family.Poppins};
`;
