import { Button, Progress } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../../../ModalBase';

const SearchBlockedModal = ({
  name = '', email = '', open = false, onSubmit, onClose, loading = false
}) => (
  <Modal open={open} onClose={onClose}>
    <h2 className="modal-base__title">
      Confirmar Dados para Assinar
    </h2>
    <p className="modal-base__description">
      <strong>{name}</strong> confirme seus dados abaixo para assinar o documento

      <br />
      <br />

      <b>Seu nome: {name}</b>
      <br />
      <b>Seu e-mail: {email}</b>
    </p>

    <div className="modal-base__footer" style={loading ? { display: 'flex', justifyContent: 'center' } : {}}>
      {loading ? <Progress type="circular" /> : (
        <>
          <Button color="secondary" variant="outlined" label="Voltar" disableElevation fullWidth onClick={onClose} disabled={loading} />
          <Button label="Assinar" disableElevation fullWidth onClick={onSubmit} disabled={loading} />
        </>
      )}

    </div>
  </Modal>
);

SearchBlockedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default SearchBlockedModal;
