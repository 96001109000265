import styled from 'styled-components';

export const Info = styled.div`
  padding: 24px;
  display: table;
  
  .info-text {
    display: inline-table;
    font-family: Poppins;
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;
  }

  .info-sub-text {
    display: inline-table;
    margin-top: 0.5rem;
    font-family: Poppins;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  Loader {
    padding: 16px 32px 16px 32px;
  }

  > span {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.0313rem;
  }

  .button-text-tiny {
    font-weight: 400;
  }

  .button-text-normal {
    font-weight: 500;
  }

  .button-text-bold {
    font-weight: 600
  }

  .button-text-blue {
    color: #346AF7;
  }

  .button-text-white {
    color: #ffffff
  }
`;

export const Footer = styled.div`
  padding: 11px 24px 24px 24px;
  width: 100%;
  display: table;
  clear: both;
  position: absolute;
  bottom: 0;

  .action-button, .action-button:hover {
    width: 48.71%;
    display: flow-root;
    padding: 16px 0 16px 0;
    box-shadow: none;
  }

  .back-button, .back-button:hover {
    float: left;
    background: rgba(255, 255, 255, 0.12);
  }
  
  .next-button, .create-payment-button:hover {
    float: right;
    background: #E53935 !important;
  }

  .Mui-disabled {
    background-color: #E53935ad !important;
  }
`;
