import PropTypes from 'prop-types';
import React from 'react';

import ErrorPage from '../ErrorPage';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorPage>
          <p>Algo errado aconteceu...Não foi possível visualizar o conteúdo da página.</p>
          <p>Por favor, tente novamente mais tarde</p>
        </ErrorPage>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
