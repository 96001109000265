/* eslint-disable import/prefer-default-export */
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AlreadyExistAccount = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.15rem 1rem;

  span {
    padding: 0 !important;
  }
  a {
    padding: 0 0.15rem;
  }
`;

export const RecoveryButton = styled(Link)`
  font-weight: bold;
  font-size: 0.8rem;
  opacity: 1 !important;
  text-decoration: underline;
`;
