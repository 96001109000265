import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';

import themes from '../../_utils/themes';

export const CustomButton = styled(LoadingButton)({
  fontFamily: themes.fonts.family,
  fontSize: themes.fonts.size.small,
  fontWeight: themes.fonts.weight.normal,

  width: '100%',
  maxHeight: '40px',
  textTransform: 'capitalize',

  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: 'unset'
  },

  '&.Mui-disabled': {
    backgroundColor: `${themes.buttons.bg.disabled}!important`,
    color: `${themes.buttons.label.disabled}!important`,
    cursor: 'not-allowed!important'
  }
});
