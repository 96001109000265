import styled from 'styled-components';

import themes from '../../../../../_utils/themes';

export const TermsText = styled.div`
  width: 100%;
  text-align: center;
  color: ${themes.fonts.color.lightGrey};
  font-size: ${themes.fonts.size.small};
  font-weight: ${themes.fonts.weight.thin};
  font-family: ${themes.fonts.family.Poppins};

  a {
    color: ${themes.pallete.primary};
    cursor: pointer;
    text-decoration: underline;
    font-size: ${themes.fonts.size.small};
    font-weight: ${themes.fonts.weight.thin};
    font-family: ${themes.fonts.family.Poppins};
  }
`;
