import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal, List, Icon } from 'semantic-ui-react';

import { useMixpanel } from '../../../hooks/MixpanelProvider';
import api from '../../../utils/backend';
import Button from '../../Button';
import Input from '../../Input';

const ModalSearchPersons = ({ onAddPerson }) => {
  const { Mixpanel } = useMixpanel();
  const { persons: storePersons } = useSelector((state) => state.starterWizard);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const handleFilterPersons = useCallback(() => {
    if (query !== '') {
      return api.get('/persons', {
        params: {
          search: formatString(query.toLowerCase())
        }
      }).then(({ data: { data: persons } }) => {
        Mixpanel.track('document_signer_button_buscar-participante', { search_term: query, search_results_qty: persons.length });
        if (persons.length > 0) {
          setResults(persons);
        }
      });
    }
    return setResults([]);
  }, [query, Mixpanel]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setLoading(false);
      handleFilterPersons();
    }, 2000);

    setLoading(true);

    return () => clearTimeout(debounce);
  }, [query, handleFilterPersons]);

  const handleCloseModal = () => {
    setResults([]);
    setQuery('');
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      size="tiny"
      trigger={(
        <Button style={{ color: '#346AF7' }} link onClick={() => setOpen(true)}>
          <Icon name="search" />Buscar assinante
        </Button>
      )}
    >
      <Modal.Header>Buscar assinante</Modal.Header>
      <Modal.Content scrolling>
        <Input
          placeholder="Buscar por nome ou e-mail"
          loading={loading}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          icon="search"
          iconPosition="left"
          fluid
        />
        {query !== '' && results.length !== 0 && (
          <List divided verticalAlign="middle">
            {results.map((person) => {
              const exist = storePersons.find((p) => p.email === person.email);

              return (
                <List.Item as="a" key={person.id}>
                  <List.Content floated="right">
                    {!exist && (
                      <Button
                        size="small"
                        onClick={() => {
                          onAddPerson(person.name, person.email, person.whatsapp);
                        }}
                      >
                        Adicionar
                      </Button>
                    )}
                  </List.Content>
                  <List.Content>
                    <List.Header>
                      {person.name}
                    </List.Header>
                    <List.Description>
                      {person.email}
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        )}
        {query !== '' && results.length === 0 && !loading && (
          <p style={{ textAlign: 'center', marginTop: '1rem' }}>
            Nenhum resultado encontrado.
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCloseModal}>Concluir</Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalSearchPersons.propTypes = {
  onAddPerson: PropTypes.func.isRequired
};

export default ModalSearchPersons;
