import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ background, theme }) => css`
  
    display: flex;
    align-items: center;

    .status-circle {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      background-color: ${`${background} !important` || theme.secondaryBackgroundColor};
      margin-right: 0.5rem;
    }

    .status-title {
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
      font-size: 1rem;
    }
  `}
`;
