import PropTypes from 'prop-types';
import React from 'react';

import { AmplitudeProvider } from './AmplitudeProvider';
import { ChatProvider } from './ChatProvider';
import { MixpanelProvider } from './MixpanelProvider';
import { SocketProvider } from './useSocket';

const AppProvider = ({ children }) => (
  <MixpanelProvider>
    <SocketProvider>
      <AmplitudeProvider>
        <ChatProvider>
          {children}
        </ChatProvider>
      </AmplitudeProvider>
    </SocketProvider>
  </MixpanelProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
