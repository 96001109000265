import PropTypes from 'prop-types';
import React from 'react';

import errorIcon from '../../../../assets/img/error-icon.png';
import { useChat } from '../../../../hooks/ChatProvider';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import Button from '../Button';
import Wrapper from './styles';

const Error = ({ errorMessage, onBackStep }) => {
  const { openChat } = useChat();
  const { Mixpanel } = useMixpanel();

  return (
    <Wrapper>
      <img src={errorIcon} alt="Erro ao efetuar pagamento" className="overlay" />
      <p className="subtitle">Não conseguimos processar seu pagamento.</p>
      <p className="caption">
        Ocorreu o seguinte erro: <span className="error">{errorMessage}</span> Por
        favor, verifique suas informações:
      </p>
      <Button
        onClick={() => {
          Mixpanel.track('checkout_button_tentar-com-outros-dados');
          onBackStep();
        }}
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        Tentar com outros dados
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          Mixpanel.track('checkout_button_iniciar-suporte-via-chat');
          openChat(`Pagamento - Ocorreu o seguinte erro ${errorMessage}`);
        }}
      >
        Iniciar suporte via chat
      </Button>
    </Wrapper>
  );
};

Error.defaultProps = {
  errorMessage: ''
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  onBackStep: PropTypes.func.isRequired
};

export default Error;
