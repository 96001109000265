/* eslint-disable no-control-regex */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import { Button } from '@contraktor-tech/ui';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoPersonSharp } from 'react-icons/io5';
import { MdAccountBalance } from 'react-icons/md';

import { useMixpanel } from '../../../../../../../hooks/MixpanelProvider';
import GridContainer from '../../../../../components/GridContainer/index';
import { CustomTextField } from '../../CustomFieldStyles/CustomTextField';
import CNPJInputCustom from '../../CustomInputMasks/CNPJInputCustom';
import CPFInputCustom from '../../CustomInputMasks/CPFInputCustom';
import * as S from './styles';

const CustomerForm = ({
  updateCustomer, type, setType, setFormState, customerPayload
}) => {
  const { Mixpanel } = useMixpanel();
  const {
    register, setValue, control, clearErrors, setError, getValues
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      document_id: ''
    },
    mode: 'onChange'
  });

  const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g;

  useEffect(() => {
    setFormState(!_.isEmpty(customerPayload?.name)
      && !_.isEmpty(customerPayload?.email)
      && (cnpj.isValid(customerPayload?.document_id) || cpf.isValid(customerPayload?.document_id)));
  }, [setFormState]); //eslint-disable-line

  const switchType = (selectedType) => {
    Mixpanel.track(selectedType === 'FISICA' ? 'document_payer_button_pessoa-fisica' : 'document_payer_button_pessoa-juridica');
    setType(selectedType);
    setValue('name', null);
    setValue('email', null);
    setValue('document_id', null);
  };

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);

    const emailValid = getValues('email').trim().toLowerCase().match(emailRegex);

    if (!emailValid) setError('email', 'Insira um e-mail válido');
    else clearErrors('email');

    const values = getValues();
    updateCustomer(values);
    setFormState(!_.isEmpty(values?.name)
      && !_.isEmpty(values?.email && emailValid)
      && (cnpj.isValid(values?.document_id) || cpf.isValid(values.document_id)));
  };

  const validateRequiredField = (value, field) => {
    if (_.isEmpty(value)) setError(field, { message: 'Este campo é obrigatório' });
    else clearErrors(field);
  };

  const nameField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
        Mixpanel.track('document_payer_input_nome');
      }}
      fullWidth
      label={(
        <S.InputLabel>
          Nome { type === 'FISICA' ? 'e sobrenome' : 'da empresa' }
        </S.InputLabel>
      )}
      id="name"
      error={!!error}
      helperText={error?.message}
    />
  );

  const emailField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
        Mixpanel.track('document_payer_input_email');
      }}
      fullWidth
      label={<S.InputLabel>Email</S.InputLabel>}
      id="email"
      type="email"
      error={!!error}
      helperText={error?.message}
    />
  );

  const documentField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
        Mixpanel.track('document_payer_input_cpf-cnpj');
      }}
      fullWidth
      label={<S.InputLabel>{ type === 'FISICA' ? 'CPF' : 'CNPJ' }</S.InputLabel>}
      id="document_id"
      type="cpf"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: type === 'FISICA' ? CPFInputCustom : CNPJInputCustom
      }}
    />
  );

  return (
    <>
      <S.SelectorContainer>
        <GridContainer
          columns="2fr 2fr"
          width="auto"
          spacing="1.125"
          padding="0"
        >
          <Button
            onClick={() => switchType('FISICA')}
            label={(
              <span className="button-label">
                <IoPersonSharp className={type === 'FISICA' ? 'icon-active' : 'icon-normal'} />
                Pessoa física
              </span>
            )}
            className={
              type === 'FISICA' ? 'type-button-active' : 'type-button-normal'
            }
          />
          <Button
            onClick={() => switchType('JURIDICA')}
            label={(
              <span className="button-label">
                <MdAccountBalance className={type === 'JURIDICA' ? 'icon-active' : 'icon-normal'} />
                Pessoa jurídica
              </span>
            )}
            className={
              type === 'JURIDICA' ? 'type-button-active' : 'type-button-normal'
            }
          />
        </GridContainer>
      </S.SelectorContainer>
      <S.FormContainer>
        <GridContainer
          columns="2fr 2fr"
          width="auto"
          spacing="1.125"
          padding="0"
        >
          <Controller
            control={control}
            {...register(
              'name',
              {
                value: customerPayload.name || '',
                required: true
              }
            )}
            render={({ field, fieldState: { error } }) => nameField(field, error)}
          />
          <Controller
            control={control}
            {...register(
              'email',
              {
                value: customerPayload.email || '',
                required: true
              }
            )}
            render={({ field, fieldState: { error } }) => emailField(field, error)}
          />
        </GridContainer>
        <GridContainer
          columns="1fr"
          width="auto"
          spacing="1.125"
          padding="1.125rem 0 0 0"
        >
          <Controller
            control={control}
            {...register(
              'document_id',
              {
                value: customerPayload.document_id || '',
                required: true
              }
            )}
            render={({ field, fieldState: { error } }) => documentField(field, error)}
          />
        </GridContainer>
      </S.FormContainer>
    </>
  );
};

CustomerForm.propTypes = {
  updateCustomer: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  customerPayload: PropTypes.any.isRequired
};

export default CustomerForm;
