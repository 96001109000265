import axios from 'axios';

const getCkPayUrl = () => {
  const ckPayURL = process.env.REACT_APP_CK_PAY_API_URL;

  if (!ckPayURL) {
    throw new Error('CKPay URL not found.');
  }

  return ckPayURL;
};

const apiAdapter = axios.create({
  baseURL: getCkPayUrl(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

const ckPayPath = '/payments';
const ckAccountPath = '/accounts';

const {
  get, put, patch, post, delete: destroy
} = apiAdapter;
export {
  get, put, post, patch, destroy, ckPayPath, ckAccountPath
};
export default apiAdapter;
