/* eslint-disable react/forbid-prop-types */
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from 'react-phone-number-input/input';
import { Header, Grid, Form } from 'semantic-ui-react';

import { emailRegex } from '../../utils/parsers';
import states from '../../utils/states';
import FieldErrorMessage from '../FieldErrorMessage';
import InputWithRef from '../InputWithRef';
import { StyledForm, InputLabel, HideBlock } from './styles';

const personTypeOptions = [
  { value: 'pf', text: 'Pessoa Física' }
];

const maritalStatusOptions = [
  { value: null, text: '' },
  { value: 'married', text: 'Casado(a)' },
  { value: 'single', text: 'Solteiro(a)' },
  { value: 'divorced', text: 'Divorciado(a)' },
  { value: 'widower', text: 'Viuvo(a)' }
];

const SignerForm = ({
  register,
  errors,
  watch,
  control,
  setValue,
  setError,
  triggerValidation,
  getValues
}) => {
  const state = watch('addressState');
  const city = watch('addressCity');
  const cep = watch('addressZipCode');
  const maritalStatus = watch('maritalStatus');

  useEffect(() => {
    register('maritalStatus');
    register('addressCity');
    register('addressState');
  }, [register]);

  useEffect(() => {
    function handleCepLength() {
      const parsedCep = cep && cep.replace(/[^\d]+/g, '');
      if (parsedCep && parsedCep.length >= 8) {
        axios.get(`https://viacep.com.br/ws/${cep}/json/`)
          .then(({ data }) => {
            if (!data.erro) {
              const { logradouro, localidade, uf } = data;

              setValue('addressStreet', logradouro);
              setValue('addressCity', localidade);
              setValue('addressState', uf.toLowerCase());
            } else {
              setError('addressZipCode', { type: 'error', message: <FieldErrorMessage message="CEP informado não encontrado." /> });
            }
          })
          .catch(() => {
            setError('addressZipCode', { type: 'requestError', message: <FieldErrorMessage message="CEP informado não encontrado." /> });
          });
      }
    }

    handleCepLength();
  }, [cep, setValue, setError]);

  return (
    <StyledForm>
      <Header as="h3">Informações Gerais</Header>
      <Grid columns={2} stackable>
        <HideBlock>
          <Grid.Column mobile={16} largeScreen={16} widescreen={16}>
            <Form.Select
              onChange={async (e, { name, value }) => {
                setValue(name, value);
                await triggerValidation({ name });
              }}
              name="personType"
              defaultValue={personTypeOptions[0].value}
              label="Tipo de Pessoa"
              options={personTypeOptions}
            />
          </Grid.Column>
        </HideBlock>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="name"
            label="Nome Completo"
            required
            {...register('name', {
              required: (
                <FieldErrorMessage message="Campo Obrigatório" />
              )
            })}
          >
            {errors?.name && errors?.name.message}
          </InputWithRef>
        </Grid.Column>

        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="document">CPF</InputLabel>
          <Controller
            control={control}
            {...register('document', {
              value: getValues('document') || ''
            })}
            render={({ field, fieldState: { error } }) => <InputMask {...field} mask="999.999.999-99" error={!!error} />}
          />
          {errors?.document && errors?.document.message}
        </Grid.Column>

        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="email"
            label="E-mail"
            required
            onChange={(e) => {
              if (e.target.value) {
                e.target.value = e.target.value.toLowerCase().trim();
              }
            }}
            {...register('email', {
              required: (
                <FieldErrorMessage message="Campo Obrigatório" />
              ),
              pattern: {
                value: emailRegex,
                message: (
                  <FieldErrorMessage message="O e-mail inserido deve ser válido." />
                )
              }
            })}
          >
            {errors?.email && errors?.email.message}
          </InputWithRef>
        </Grid.Column>

        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
          <Controller
            control={control}
            {...register('whatsapp', {
              value: getValues('whatsapp') || ''
            })}
            render={({ field }) => <PhoneInput {...field} autoComplete="on" country="BR" />}
          />
        </Grid.Column>

        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="reference"
            label="Referência"
            {...register('reference')}
          >
            {errors?.reference && errors?.reference.message}
          </InputWithRef>
        </Grid.Column>

        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <Form.Select
            label="Estado Civil"
            value={maritalStatus || null}
            name="maritalStatus"
            {...register('maritalStatus')}
            options={maritalStatusOptions}
            onChange={async (e, { name, value }) => {
              setValue(name, value);
              await triggerValidation({ name });
            }}
          />
          {errors?.maritalStatus && errors?.maritalStatus.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="nationalId">RG</InputLabel>
          <Controller
            control={control}
            {...register('nationalId', {
              value: getValues('nationalId') || ''
            })}
            render={({ field, fieldState: { error } }) => <InputMask {...field} mask="99.999.999-9" error={!!error} />}
          />
          {errors?.nationalId && errors?.nationalId.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="birthDate">
            Data de Nascimento
          </InputLabel>
          <Controller
            control={control}
            {...register('birthDate', {
              value: getValues('birthDate') || ''
            })}
            render={({ field, fieldState: { error } }) => <InputMask {...field} mask="99/99/9999" error={!!error} />}
          />
          {errors?.birthDate && errors?.birthDate.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="nationality"
            label="Nacionalidade"
            {...register('nationality')}
          >
            {errors?.nationality && errors?.nationality.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="profession"
            label="Profissão"
            {...register('profession')}
          >
            {errors?.profession && errors?.profession.message}
          </InputWithRef>
        </Grid.Column>
      </Grid>

      <Header as="h3">Endereço</Header>

      <Grid columns={2} stackable>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="addressZipCode">CEP</InputLabel>
          <Controller
            control={control}
            {...register('addressZipCode', {
              value: getValues('addressZipCode') || ''
            })}
            render={({ field, fieldState: { error } }) => <InputMask {...field} mask="99999-999" error={!!error} />}
          />
          {errors?.addressZipCode && errors?.addressZipCode.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="addressStreet"
            label="Logradouro"
            {...register('addressStreet')}
          >
            {errors?.addressStreet && errors?.addressStreet.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={4} widescreen={4}>
          <InputWithRef
            type="text"
            id="addressNumber"
            label="Número"
            {...register('addressNumber')}
          >
            {errors?.addressNumber && errors?.addressNumber.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={4} widescreen={4}>
          <InputWithRef
            type="text"
            id="addressComplement"
            label="Complemento"
            {...register('addressComplement')}
          >
            {errors?.addressComplement && errors?.addressComplement.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="addressNeighborhood"
            label="Bairro"
            {...register('addressNeighborhood')}
          >
            {errors?.addressNeighborhood && errors?.addressNeighborhood.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="document">Estado</InputLabel>
          <Form.Select
            placeholder="Selecione um estado"
            fluid
            search
            value={state || ''}
            onChange={(e, { value }) => {
              setValue('addressState', value);
              setValue('addressCity', '');
            }}
            name="addressState"
            options={
            states.map((s) => ({
              key: s.sigla.toLowerCase(),
              value: s.sigla.toLowerCase(),
              text: s.nome
            }))
          }
          />
          {errors?.addressState && errors?.addressState.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputLabel htmlFor="document">Cidade</InputLabel>
          <Form.Select
            value={city || ''}
            onChange={(e, { value }) => setValue('addressCity', value)}
            placeholder={!state ? 'Selecione um estado' : 'Selecione uma cidade'}
            options={
            (state && states
              .find((s) => s.sigla.toLowerCase() === state)
              && states.find((s) => s.sigla.toLowerCase() === state)
                .cidades
                .map((c) => ({ key: c, value: c, text: c }))) || []
          }
            search
            fluid
            name="addressCity"
          />
          {errors?.addressCity && errors?.addressCity.message}
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="addressCountry"
            label="País"
            {...register('addressCountry')}
          >
            {errors?.addressCountry && errors?.addressCountry.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8} widescreen={8}>
          <InputWithRef
            type="text"
            id="contactPhone"
            label="Telefone"
            {...register('contactPhone')}
          >
            {errors?.contactPhone && errors?.contactPhone.message}
          </InputWithRef>
        </Grid.Column>
      </Grid>
    </StyledForm>
  );
};

SignerForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.object),
  register: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  triggerValidation: PropTypes.func,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired
};

SignerForm.defaultProps = {
  errors: {},
  control: {},
  triggerValidation: () => {}
};

export default SignerForm;
