import { Button as ButtonUi } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import Button from '../../../../components/Button';
import FieldErrorMessage from '../../../../components/FieldErrorMessage';
import InputWithRef from '../../../../components/InputWithRef';
import { useChat } from '../../../../hooks/ChatProvider';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import api from '../../../../utils/backend';
import { emailRegex } from '../../../../utils/parsers';
import ConfirmationModal from '../ConfirmationModal';
import * as S from './styles';

const titles = {
  create: 'Novo Usuário',
  edit: 'Editar Usário'
};

const FormModal = ({
  open, onClose, user, type, onSuccess
}) => {
  const { openChat } = useChat();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const { Mixpanel } = useMixpanel();

  const [loading, setLoading] = useState(false);
  const [emailAlertModal, setEmailAlertModal] = useState(false);

  const handleCleanEmail = () => {
    setValue('email', '');
    setEmailAlertModal(false);
  };

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true);
      if (type === 'edit') {
        await api.patch('users/settings/update_from_organization_owner', {
          user: {
            first_name: values.firstName,
            last_name: values.lastName,
            id: user.id
          }
        });
        Mixpanel.track('document_signer_button_editar-participantes');
        toast.success('Usuário atualizado com sucesso!');
      } else {
        await api.post('users/settings/create_user_from_organization', {
          user: {
            ...values,
            first_name: values.firstName,
            last_name: values.lastName
          }
        });
        toast.success('Usuário cadastrado com sucesso!');
      }
      onSuccess();
    } catch (error) {
      if (error.response.data.errors && error.response.data.errors?.email) {
        setEmailAlertModal(true);
      } else {
        toast.error('Algo saiu errado! Por favor, tente novamente ou contate o suporte.');
      }
    } finally {
      setLoading(false);
    }
  }, [type, onSuccess, user.id, Mixpanel]);

  useEffect(() => {
    if (type === 'edit' && user) {
      reset(user);
    }
  // eslint-disable-next-line
  }, [type, user]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon="close"
      >
        <Modal.Header>
          <h2>{titles[type]}</h2>
        </Modal.Header>
        <Modal.Content scrolling>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <InputWithRef
                type="text"
                id="firstName"
                label="Nome"
                required
                {...register('firstName', {
                  required: (
                    <FieldErrorMessage message="Campo Obrigatório" />
                  )
                })}
              >
                {errors?.firstName && errors?.firstName.message}
              </InputWithRef>

              <InputWithRef
                type="text"
                id="lastName"
                label="Sobrenome"
                required
                {...register('lastName', {
                  required: (
                    <FieldErrorMessage message="Campo Obrigatório" />
                  )
                })}
              >
                {errors?.lastName && errors?.lastName.message}
              </InputWithRef>

            </div>
            {type === 'create' && (
            <div className="form-row">
              <InputWithRef
                type="text"
                id="email"
                label="E-mail"
                required
                disabled={Boolean(type === 'edit')}
                onChange={(e) => {
                  if (e.target.value) {
                    e.target.value = e.target.value.toLowerCase().trim();
                  }
                }}
                {...register('email', {
                  required: (
                    <FieldErrorMessage message="Campo Obrigatório" />
                  ),
                  pattern: {
                    value: emailRegex,
                    message: (
                      <FieldErrorMessage message="O e-mail inserido deve ser válido." />
                    )
                  }
                })}
              >
                {errors?.email && errors?.email.message}
              </InputWithRef>
            </div>
            )}
          </S.Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            type="button"
            content="Cancelar"
            onClick={onClose}
          />
          <Button
            type="submit"
            primary
            content="Salvar"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          />
        </Modal.Actions>
      </Modal>

      <ConfirmationModal open={emailAlertModal} onClose={() => setEmailAlertModal(false)}>
        <h5>ERRO</h5>
        <h3>Este e-mail já está sendo usado</h3>
        <p>
          Parece que este e-mail já está vinculado a outra organização.
          Tente cadastrar com outro e-mail ou entre em contato com nosso suporte
        </p>
        <div className="actions">
          <ButtonUi label="Suporte" variant="outlined" fullWidth onClick={() => openChat()} />
          <ButtonUi label="Entendi" variant="contained" disableElevation fullWidth onClick={() => handleCleanEmail()} />
        </div>
      </ConfirmationModal>
    </>
  );
};

FormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  type: PropTypes.oneOf(['edit', 'create']).isRequired,
  onSuccess: PropTypes.func.isRequired
};

FormModal.defaultProps = {
  user: {}
};

export default FormModal;
