import { Icon } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

const ModalBase = ({
  children,
  onClose = () => {},
  open,
  hiddenCloseButton,
  ...props
}) => (
  <S.Modal open={open} onClose={onClose} {...props}>
    <div className="modal-base__wrapper">
      {!hiddenCloseButton && (
      <S.CloseButton onClick={onClose}>
        <Icon>close</Icon>
      </S.CloseButton>
      )}
      {children}
    </div>
  </S.Modal>
);

ModalBase.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hiddenCloseButton: PropTypes.bool
};

ModalBase.defaultProps = {
  hiddenCloseButton: false
};

export default ModalBase;
