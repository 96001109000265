import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

import { CustomContainer, Subtitle, Title } from '../../_shared/styles';
import themes from '../../_utils/themes';

export const Container = muiStyled(CustomContainer)({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  '&.MuiContainer-root': {
    width: '100%',
    paddingLeft: '0',
    paddingRight: '0'
  }
});

export const Wrapper = muiStyled(CustomContainer)({
  border: `2px dashed ${themes.fonts.color.darkGrey}`,
  borderRadius: '8px',
  marginBottom: '16px',
  cursor: 'pointer',

  '&.MuiContainer-root': {
    width: '100%',
    height: '180px',
    display: 'flex',
    paddingLeft: '0',
    paddingRight: '0',
    alignItems: 'center',
    backgroundColor: themes.background.lightGrey
  }
});

export const TextWrapper = styled.div`
  display: block;
  text-align: center;
`;

export const UploadTitle = styled(Title)`
  color: ${({ theme }) => theme.fonts.color.darkGrey};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  display: flex;
  align-items: center;

  .upload-icon {
    width: 20px;
    height: 20px;
  }
`;

export const UploadInfo = styled(Subtitle)`
  color: ${({ theme }) => theme.fonts.color.lightGrey};
  font-size: ${({ theme }) => theme.fonts.size.smaller};
`;

export const PreviewImage = styled.img`
  width: 274px;
  height: 154px;
  object-fit: contain;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
