import { ListItem as ListItemMUI } from '@contraktor-tech/ui';
import styled, { css } from 'styled-components';

export const DrawerHeader = styled.div`
  width: 304px;
  padding: 1rem;

  .avatar-container { 
    margin-top: 64px;
    margin-bottom: 30px;
  }
`;

export const ListItem = styled(ListItemMUI)`
  ${({ selected }) => selected && css`
    background-color: rgba(43, 181, 255, 0.08) !important;
  
    .MuiTypography-subtitle1 {
      font-family: 'Nunito', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #1FA9F0;
    }
  `}

`;
