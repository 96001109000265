import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

import { personTypeParser } from '../../../utils/parsers';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Type = ({
  signer
}) => (
  <ShortenedField clamp={1}>
    {personTypeParser(signer.personType)}
  </ShortenedField>
);

Type.propTypes = {
  signer: PropTypes.shape({
    personType: PropTypes.string
  }).isRequired
};

export default Type;
