import React from 'react';

import { ExtraLargeTitle } from '../../../../_shared/styles';
import { useAuth } from '../../../../../_legacy/containers/AuthContext/AuthContext';
import { Container, CustomSubtitle } from './styles';

export default function PageTitle() {
  const { trialIsOver } = useAuth();

  const title = trialIsOver ? 'Seu teste gratuito chegou ao fim!' : 'Planos para todas as necessidades!';
  const subtitle = trialIsOver
    ? 'O teste gratuito de 7 dias do Unlimited encerrou. Mas não se preocupe, aqui estão nossos planos para você continuar aproveitando todos os benefícios...'
    : 'Escolha o plano ideal para o seu negócio e comece agora a revolução na gestão de documentos e assinaturas.';

  return (
    <Container>
      <ExtraLargeTitle>{ title }</ExtraLargeTitle>
      <CustomSubtitle>{ subtitle }</CustomSubtitle>
    </Container>
  );
}
