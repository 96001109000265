/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Loader, Modal } from 'semantic-ui-react';

import * as S from './styles';

const DeleteInfoPopover = ({
  open,
  closeModal,
  confirmDeletion,
  deleting,
  payment
}) => {
  useEffect(() => {}, []);

  if (!open) return null;

  return (
    <Modal
      open={open}
      size="small"
      style={{
        minHeight: '188px', maxWidth: '670px', borderRadius: '8px', backgroundColor: '#0C0440'
      }}
    >
      <S.Info>
        <span className="info-text">
          {
            payment.frequency && payment.installment_count > 1
              ? 'Tem certeza que deseja cancelar essa parcela?'
              : 'Tem certeza que deseja deletar essa cobrança?'
          }
        </span>
        <br />
        <span className="info-sub-text">
          {
            payment.frequency && payment.installment_count > 1
              ? 'Ao cancelar essa parcela você vai deletar definitivamente todas as parcelas seguintes.'
              : 'Ao cancelar essa cobrança você não receberá nenhum valor.'
          }
        </span>
      </S.Info>
      <S.Footer>
        <Button
          className="action-button back-button"
          onClick={closeModal}
          label={(
            <S.ButtonText>
              <span className="button-text-normal button-text-white">Cancelar</span>
            </S.ButtonText>
          )}
        />
        <Button
          disabled={deleting}
          className="action-button next-button"
          onClick={confirmDeletion}
          label={(
            <S.ButtonText>
              {
                deleting
                  ? <div style={{ padding: '7px 32px' }}><Loader active={deleting} /></div>
                  : <span className="button-text-normal button-text-white">Sim, cancelar { payment.frequency && payment.installment_count > 1 ? 'parcelas' : 'cobrança' }</span>
              }
            </S.ButtonText>
          )}
        />
      </S.Footer>
    </Modal>
  );
};

DeleteInfoPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  payment: PropTypes.any.isRequired
};

export default DeleteInfoPopover;
