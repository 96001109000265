import styled from 'styled-components';

export const Wrapper = styled.header`
  display: none;

  @media (min-width: 320px) and (max-width: 480px) {
    top: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  padding: 1rem;

  img {
    width: 220px;
  }
`;

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  width: 30px;
  height: 100%;
  display: flex;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

export const Button = styled.button`
  display: flex;
  height: 100%;
  background-color: ${(props) => (props.background ? '#03a9f4' : 'transparent')};
  color: #FFFFFF;
  font-weight: 700;
  align-items: center;
  ${(props) => props.background && 'padding: 0 18px'};
  border-radius: 4px;
  border: 1px solid #0287c3;
  
`;
