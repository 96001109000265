import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FreemiumDocumentsHeader from '../../components/_new/_freemium/DocumentsHeader';
import DocumentsList from '../../components/DocumentsList';
import HeaderWrapper from '../../components/DocumentsList/HeaderWrapper';
import MainLayout from '../../components/MainLayout';
import { useAmplitude } from '../../hooks/AmplitudeProvider';
import { fetchCounters } from '../../redux/ducks/documents';
import api from '../../utils/backend';
import { useAuth } from '../AuthContext/AuthContext';
import { Container, Wrapper } from './styles';

const DocumentsPage = () => {
  const dispatch = useDispatch();
  const { Amplitude } = useAmplitude();
  const { trialIsOver, isFreemium } = useAuth();
  const freemiumHeader = trialIsOver || isFreemium;

  useEffect(() => {
    function getCounters() {
      api.get('/documents/counters').then((res) => {
        dispatch(fetchCounters(res.data));
      });
    }
    getCounters();
    Amplitude.logEvent('document_list_screen');
  }, [dispatch, Amplitude]);

  return (
    <>
      <MainLayout header options>
        <Container>
          <Wrapper>
            {freemiumHeader ? <FreemiumDocumentsHeader /> : <HeaderWrapper />}
            <DocumentsList />
          </Wrapper>
        </Container>
      </MainLayout>
    </>
  );
};

export default DocumentsPage;
