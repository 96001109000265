import currency from 'currency.js';
import React, { useEffect, useState } from 'react';

import { useChoosePlan } from '../../../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import { whatsappQuotaPrices } from '../../../../Plans/_components/WhatsappCard/constants/whatsappQuotaPrices';
import {
  Card, CardText, Icon, TextSubtitle, TextTitle
} from './styles';

export default function PlanInfoCard({ voucher }) {
  const { planSelected } = useChoosePlan();
  const [price, setPrice] = useState(null);
  const additionalFeatures = JSON.parse(localStorage.getItem('additionalFeatures'));

  const calculateValue = () => {
    let value = planSelected.priceFloat;

    console.log(value);

    if (voucher) {
      const voucherDiscount = planSelected.priceFloat * (voucher.discountPercent / 100);
      value -= voucherDiscount;
    }

    if (additionalFeatures) {
      const { quota } = additionalFeatures.find((af) => af.name === 'whatsapp');
      const addFeaturesValue = planSelected.frequency === 'ANNUAL'
        ? (whatsappQuotaPrices[quota] * 12)
        : whatsappQuotaPrices[quota];

      value += addFeaturesValue;
    }

    const formattedValue = currency(value, { symbol: 'R$', decimal: ',', separator: '.' }).format();
    setPrice(formattedValue);
  };

  useEffect(() => {
    calculateValue();
  }, []); //eslint-disable-line

  return (
    <Card>
      <Icon />
      <CardText>
        <TextTitle>{planSelected.name}</TextTitle>
        <TextSubtitle>{`${price} ${planSelected.suffix}`}</TextSubtitle>
      </CardText>
    </Card>
  );
}
