import { Button } from '@contraktor-tech/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import * as S from './styles';
import Vector from './Vector';

const SidebarBlockedCard = () => {
  const history = useHistory();
  const { Mixpanel } = useMixpanel();

  const onClick = () => {
    Mixpanel.track('home_button_saiba-mais');
    history.push('/plans');
  };

  return (
    <S.Container>
      <S.VectorWrapper>
        <Vector />
      </S.VectorWrapper>

      <S.Title>Não perca tempo!</S.Title>

      <S.Text>Acompanhe o status das assinaturas dos seus documentos.</S.Text>

      <Button label="Saiba mais" disableElevation fullWidth onClick={onClick} />
    </S.Container>
  );
};

export default SidebarBlockedCard;
