import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const StatusDropdown = ({
  allowNull,
  placeholder,
  value,
  onChange,
  className,
  selection,
  ...props
}) => {
  let options = [];

  if (allowNull) {
    options.push({
      value: '',
      text: placeholder || 'Sem status'
    });
  }

  const statuses = [
    { name: 'Ag.Assinatura', value: 'wait_sign', color: 'orange' },
    { name: 'Vigente', value: 'signed', color: 'green' },
    { name: 'Cancelado', value: 'canceled', color: 'red' }
  ];

  options = options.concat(statuses.map((status) => ({
    value: status.value,
    text: status.name,
    label: { circular: true, empty: true, style: { backgroundColor: status.color } }
  })));

  return (
    <Dropdown
      {...props}
      placeholder={placeholder}
      className={(selection) ? `${className} selection` : className}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

StatusDropdown.propTypes = {
  className: PropTypes.string,
  allowNull: PropTypes.bool,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  selection: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired
};

StatusDropdown.defaultProps = {
  className: null,
  placeholder: null,
  allowNull: false,
  selection: false,
  value: null
};

export default StatusDropdown;
