import { Button, Progress } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

function UpsellModal({
  onUpsell, open, onClose, loading
}) {
  const isLoading = loading ? (
    <Progress
      variant="indeterminate"
      size={30}
      type="circular"
      color="#FFF"
    />
  ) : 'Confirmar';

  return (
    <S.Container open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="modal-header">
        <p className="modal-subtitle">Melhorando seu plano</p>
        <p className="modal-title">Confirmar a compra do plano Unlimited?</p>
      </div>
      <div className="modal-footer">
        <Button onClick={onClose} variant="outlined" fullWidth label="Cancelar" />
        <Button onClick={onUpsell} fullWidth label={isLoading} />
      </div>
    </S.Container>
  );
}

UpsellModal.propTypes = {
  onUpsell: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UpsellModal;
