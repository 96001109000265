import React, { useState, useEffect } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import sendImage from '../../../assets/img/paper-plane.svg';
import { useAmplitude } from '../../../hooks/AmplitudeProvider';
import { resetStore } from '../../../redux/ducks/starterWizard';
import api from '../../../utils/backend';
import theme from '../../../utils/theme';
import Button from '../../Button';
import { Wrapper } from './styles';

const SendSign = () => {
  const dispatch = useDispatch();
  const { Amplitude } = useAmplitude();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    id, file, persons, stepIndex
  } = useSelector((state) => state.starterWizard);

  async function handleAddPersons() {
    if (stepIndex === 6 && file && persons.length > 0) {
      try {
        Amplitude.logEvent('new_doc_send_attempt');
        await api.post(`/documents/${id}/signers`, {
          signers: persons.map((p) => ({ name: p.name, email: p.email }))
        });

        await api.post(`/documents/${id}/signatures`);
        Amplitude.logEvent('new_doc_send_complete');

        setSuccess(true);

        setTimeout(() => {
          dispatch(resetStore());
        }, 3000);
      } catch (err) {
        setError(true);
        Amplitude.logEvent('new_doc_send_error');
        throw err;
      }
    }
  }

  useEffect(() => {
    handleAddPersons();
  // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      {!error && !success && (
        <>
          <Loader active inline size="large" />
          <h3>Salvando assinantes e enviando para assinatura...</h3>
        </>
      )}

      {error && (
        <>
          <AiOutlineWarning size={80} color={theme.greyBlue} />
          <h2 className="error">Oops!</h2>
          <p>Erro ao enviar para assinatura, tente novamente.</p>
          <Button link onClick={() => dispatch(resetStore())}>Enviar novo arquivo</Button>
        </>
      )}

      {success && (
      <>
        <img src={sendImage} alt="Documento enviado" />
        <h3 className="success">Documento enviado para assinatura!</h3>
        <small>Você será redirecionado para a página principal em 3 segundos.</small>
      </>
      )}
    </Wrapper>
  );
};

export default SendSign;
