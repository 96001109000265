/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { RiPencilFill } from 'react-icons/ri';

import * as S from './styles';

const ContextMenu = ({
  xAxis, yAxis, open, openEditModal, openDeleteInfoPopover
}) => {
  const editPayment = () => {
    openEditModal();
  };

  const options = [
    {
      id: 'edit',
      label: 'editar',
      Icon: RiPencilFill,
      onClick: editPayment
    },
    {
      id: 'delete',
      label: 'cancelar cobrança',
      Icon: IoMdTrash,
      onClick: openDeleteInfoPopover
    }
  ];

  useEffect(() => {}, []);

  return (
    open
      ? (
        <>
          <S.Container
            style={{ top: `${yAxis}px`, left: `${xAxis - 266}px`, zIndex: '1' }}
          >
            <S.Options>
              {options.map((o) => (
                <S.Option onClick={() => o.onClick()}>
                  <o.Icon className="option-icon" />
                  <span className="option-label">{o.label}</span>
                </S.Option>
              ))}
            </S.Options>
          </S.Container>
        </>
      )
      : <></>
  );
};

ContextMenu.propTypes = {
  xAxis: PropTypes.number.isRequired,
  yAxis: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openDeleteInfoPopover: PropTypes.func.isRequired
};

export default ContextMenu;
