/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import api from '../../../../utils/backend';
import ModalCreatePayment from '../../Payment/ModalCreatePayment/ModalCreatePayment';
import ModalDocumentId from '../../Payment/ModalDocumentId';
import ModalPaymentInfo from '../../Payment/ModalPaymentInfo/ModalPaymentInfo';
import ModalPaymentLimit from '../../Payment/ModalPaymentLimit';
import ContactOrganizationOwnerModal from '../../Payment/PaymentList/ContactOrganizationOwnerModal';
import { checkIfHasAccount, createAccount } from '../../services/api/adapters/account';

const CreatePaymentButton = ({ onCreatePayment }) => {
  const dontShowPaymentInfo = localStorage.getItem('dontShowPaymentInfo');
  const { user } = useAuth();
  const { isOwner } = user;
  const [paymentFormModalOpen, setPaymentFormModalOpen] = useState(false);
  const [documentIdModalOpen, setDocumentIdModalOpen] = useState(false);
  const [paymentInfoModalOpen, setPaymentInfoModalOpen] = useState(false);
  const [paymentLimitModalOpen, setPaymentLimitModalOpen] = useState(false);
  const [contactOwnerModalOpen, setContactOwnerModalOpen] = useState(false);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);
  const [person, setPerson] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);

  const buttonStyle = {
    background: '#346AF71f',
    boxShadow: 'none',
    width: '100%',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fonstSize: '0.875rem',
    letterSpacing: '1.25px',
    color: '#346AF7',
    marginTop: '16px'
  };

  const handlePaymentClick = async () => {
    if (!isOwner) {
      const hasAccount = (await checkIfHasAccount())?.data?.data?.hasAccount;
      if (hasAccount) {
        setPaymentInfoModalOpen(true);
        return;
      }
      const { data: { users } } = await api.get(`organizations/${user.organizationId}?load_users=true`);
      setOwnerInfo(users.find((u) => u.owner === true));
      setContactOwnerModalOpen(true);
    } else {
      setPaymentInfoModalOpen(true);
    }
  };

  const startPaymentCreation = (infoModalActive) => {
    if (!infoModalActive) setPaymentFormModalOpen(true);
    else setPaymentInfoModalOpen(true);
  };

  const checkPersonInfo = async () => {
    try {
      setLoadingAccountInfo(true);

      const hasAccount = (await checkIfHasAccount())?.data?.data?.hasAccount;
      if (hasAccount) {
        startPaymentCreation(dontShowPaymentInfo);
        return;
      }

      let owner = (await api.get('/persons', { params: { email: user.email } })).data.data[0];
      if (!owner) owner = (await api.post('/persons', { person: { email: user.email, name: `${user.firstName} ${user.lastName}`, person_type: 'pf' } })).data;
      setPerson(owner);

      const canCreateAccount = owner?.document && owner?.phone && owner?.addressZipCode;

      if (!canCreateAccount) {
        setDocumentIdModalOpen(true);
      } else {
        await createAccount({
          name: owner.name,
          email: owner.email,
          document_id: owner.document,
          mobile_phone: owner.phone,
          postal_code: owner.addressZipCode,
          address: owner.addressStreet || 'N/D',
          city: owner.city || 'N/D',
          phone: owner.contactPhone || 'N/D',
          address_number: owner.addressNumber || 'N/D',
          complement: owner.addressComplement || 'N/D',
          province: owner.addressState || 'N/D'
        });
        startPaymentCreation(dontShowPaymentInfo);
      }
      setLoadingAccountInfo(false);
    } catch (error) {
      toast.error('Erro ao criar conta no ASAAS');
      setPaymentLimitModalOpen(false);
      setLoadingAccountInfo(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Button
        style={buttonStyle}
        onClick={() => handlePaymentClick()}
        label="Criar cobrança"
      />
      <ModalDocumentId
        open={documentIdModalOpen}
        person={person}
        closeModal={() => setDocumentIdModalOpen(false)}
        openPaymentFormModal={() => {
          setDocumentIdModalOpen(false);
          setPaymentFormModalOpen(true);
        }}
      />
      <ModalCreatePayment
        open={paymentFormModalOpen}
        closeModal={() => {
          setPaymentFormModalOpen(false);
        }}
        onCreatePayment={() => onCreatePayment()}
      />
      <ModalPaymentInfo
        open={paymentInfoModalOpen}
        closeModal={() => {
          setPaymentInfoModalOpen(false);
        }}
        openPaymentFormModal={() => {
          setPaymentInfoModalOpen(false);
          setPaymentFormModalOpen(true);
        }}
      />
      <ModalPaymentLimit
        open={paymentLimitModalOpen}
        loadingAccountInfo={loadingAccountInfo}
        handleOKClick={() => {
          checkPersonInfo();
        }}
        closeModal={() => {
          setPaymentLimitModalOpen(false);
        }}
      />
      <ContactOrganizationOwnerModal
        open={contactOwnerModalOpen}
        handleOKClick={() => {
          setContactOwnerModalOpen(false);
        }}
        closeModal={() => {
          setContactOwnerModalOpen(false);
        }}
        ownerInfo={ownerInfo}
      />
    </>
  );
};

CreatePaymentButton.propTypes = {
  onCreatePayment: PropTypes.func.isRequired
};

export default CreatePaymentButton;
