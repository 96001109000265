import { Card, Divider, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const WhatsAppInfoCardContainer = muiStyled(Card)({
  backgroundColor: '#088952',
  borderRadius: '8px',
  boxShadow: 'none'
});

export const CustomTypography = muiStyled(Typography)({
  color: '#ffffff',
  fontFamily: 'Poppins'
});

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

export const StarterInfoTag = styled.div`
  width: 100%;
  color: #000000;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  background-color: #FFB95E;
  border-radius: 8px;
  padding: 8px 0;
  text-align: center;
`;

export const CustomDivider = muiStyled(Divider)({
  borderColor: '#ffffff',
  marginLeft: 0,
  marginRight: 0,
  marginBottom: '1rem'
});
