import styled from 'styled-components';

import { Subtitle } from '../../../../_shared/styles';
import themes from '../../../../_utils/themes';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CustomSubtitle = styled(Subtitle)`
  color: ${themes.fonts.color.neutralGrey};
  max-width: 738px;
  text-align: center;
`;
