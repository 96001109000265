import PropTypes from 'prop-types';
import React from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { useTheme } from 'styled-components';

import { Container, CustomSpan } from './styles';

function LabelRequired({ text }) {
  const theme = useTheme();

  return (
    <Container>
      <CustomSpan>
        {text}
      </CustomSpan>
      <FaAsterisk name="asterisk" size={12} color={theme.yellowOrange} style={{ marginLeft: 5 }} />
    </Container>
  );
}

LabelRequired.propTypes = {
  text: PropTypes.string.isRequired
};

export default LabelRequired;
