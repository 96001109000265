/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

const CPFInputCustom = React.forwardRef((props) => {
  const { onChange } = props;

  return (
    <InputMask
      {...props}
      mask="999.999.999-99"
      onValueChange={(value) => {
        onChange({
          target: {
            name: props.name,
            value
          }
        });
      }}
    />
  );
});

CPFInputCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CPFInputCustom;
