import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 25em;
    height: calc(100vh - 4.5em);
    background-color: ${theme.secondaryBackgroundColor};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${theme.breakPoints.medium} {
      display: none;
    } 
  `}

`;
