/* eslint-disable consistent-return */
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useChoosePlan } from '../../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import { useMixpanel } from '../../../../../_legacy/hooks/MixpanelProvider';
import { validateVoucher } from '../../../../../_legacy/services/api/adapters/gateway';

export default function VoucherField({
  frequency,
  onChange,
  onValidate
}) {
  const { planSelected } = useChoosePlan();
  const {
    control, clearErrors, formState: { errors }, setError
  } = useForm({
    defaultValues: {
      voucher: ''
    },
    mode: 'onChange',
    reValidateMode: 'onBlur'
  });
  const { Mixpanel } = useMixpanel();

  const [voucherMessage, setVoucherMessage] = useState(null);

  const handleVoucherError = (error) => {
    setVoucherMessage(error.response.data.message);
    setError('voucher');
  };

  const handleVoucherSuccess = (v) => {
    const { code, discountPercent } = v;

    const message = code && code.toUpperCase() === '5REAIS'
      ? 'Desconto aplicado com sucesso! A primeira mensalidade será no valor de R$5,00'
      : `Desconto de ${discountPercent}% aplicado com sucesso!`;

    setVoucherMessage(message);
  };

  const checkVoucherValidity = useCallback(async (voucherCode) => {
    console.log(voucherCode);

    if (!!voucherCode && voucherCode !== '') {
      try {
        const v = await validateVoucher({
          voucher: voucherCode,
          planName: planSelected.code,
          cycle: ['yearly', 'installment'].includes(frequency) ? 'YEARLY' : frequency.toUpperCase()
        });

        handleVoucherSuccess(v.data);
        clearErrors('voucher');

        return true;
      } catch (error) {
        handleVoucherError(error, voucherCode);

        return false;
      }
    }

    setVoucherMessage('');
    clearErrors('voucher');
  }, [planSelected.frequency, planSelected.planName]); //eslint-disable-line

  return (
    <Controller
      name="voucher"
      control={control}
      rules={{
        validate: debounce(async (value) => {
          const valid = await checkVoucherValidity(value);

          onValidate(valid);

          return valid;
        }, 500),
        onChange: (e) => {
          onChange(e.target.value);
          Mixpanel.track('checkout_input_cupom-desconto_plans_page');
        }
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          name="voucher"
          label="Cupom de desconto"
          variant="outlined"
          error={!!errors.voucher}
          helperText={voucherMessage}
        />
      )}
    />
  );
}
