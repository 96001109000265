import { Modal } from '@contraktor-tech/ui';
import styled from 'styled-components';

const ConfirmationModal = styled(Modal)`
  .MuiDialog-paper {
    max-width: 36.575rem;

    .MuiDialogContent-root {
        padding: 1.875rem;
      }
  }

  h5 {
    ${({ theme }) => ({ ...theme.contraktorUi.typography.overline })}
  
  }
  h3 {
    ${({ theme }) => ({ ...theme.contraktorUi.typography.h6 })}
    margin-bottom: 1rem;
  }
  
  p {
    ${({ theme }) => ({ ...theme.contraktorUi.typography.body1 })}
    margin-bottom: 0.25rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 1rem;

    > *:first-child {
      margin-right: 1rem;
    }

    button {
      padding: 1rem;
    }
  }
`;

export default ConfirmationModal;
