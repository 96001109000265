import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import SignatureBlockedModal from '../../../components/_new/_freemium/SignatureBlockedModal';
import Button from '../../../components/Button';
import DocumentStatusLabel from '../../../components/DocumentStatusLabel';
import FilePreview from '../../../components/FilePreview';
import SignModal from '../../../components/SignModal';
import {
  Wrapper, Header, SignButtonContainer, ShortenedField
} from './styles';

const ContainerPreview = ({ confirmSign }) => {
  const {
    document,
    confirmedAt,
    name,
    email
  } = useSelector((state) => state.documentByToken);

  const {
    title, status, preview, blockedSignature, daysLeftToSignIfFreemium
  } = document;

  const readOnly = status !== 'canceled' && status !== 'signed' && document.signature;
  const signatureBlocked = readOnly && daysLeftToSignIfFreemium === 0 && blockedSignature;

  return (
    <Wrapper>
      <Header>
        <div />
        <ShortenedField clamp={1}>
          {title || 'Sem título'}
        </ShortenedField>
        <div>
          <DocumentStatusLabel status={status} />
        </div>
      </Header>
      {!confirmedAt && readOnly && (
        <SignModal
          onSign={confirmSign}
          signer={{ name, email }}
          trigger={(
            <SignButtonContainer>
              <Button
                primary
              >
                Assinar documento
              </Button>
            </SignButtonContainer>
          )}
        />
      )}
      {preview && (
        <FilePreview previewUrl={preview} />
      )}

      <SignatureBlockedModal
        open={signatureBlocked}
      />
    </Wrapper>
  );
};

ContainerPreview.propTypes = {
  confirmSign: PropTypes.func.isRequired
};

export default ContainerPreview;
