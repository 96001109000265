import PropTypes from 'prop-types';

export const signerType = {
  addressCity: PropTypes.string,
  addressComplement: PropTypes.string,
  addressCountry: PropTypes.string,
  addressNeighborhood: PropTypes.string,
  addressNumber: PropTypes.string,
  addressState: PropTypes.string,
  addressStreet: PropTypes.string,
  addressZipCode: PropTypes.string,
  birthDate: PropTypes.string,
  citySubscription: PropTypes.string,
  contactEmail: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  deletedAt: PropTypes.string,
  document: PropTypes.string,
  email: PropTypes.string.isRequired,
  id: PropTypes.string,
  insertedAt: PropTypes.string,
  maritalStatus: PropTypes.string,
  name: PropTypes.string.isRequired,
  nationalId: PropTypes.string,
  nationality: PropTypes.string,
  personType: PropTypes.string.isRequired,
  phone: PropTypes.string,
  profession: PropTypes.string,
  publishedAt: PropTypes.string,
  reference: PropTypes.string,
  stateSubscription: PropTypes.string,
  tradeName: PropTypes.string,
  updatedAt: PropTypes.string
};

export const defaultSignerProps = {
  addressCity: '',
  addressComplement: '',
  addressCountry: '',
  addressNeighborhood: '',
  addressNumber: '',
  addressState: '',
  addressStreet: '',
  addressZipCode: '',
  birthDate: '',
  citySubscription: '',
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  deletedAt: '',
  document: '',
  email: '',
  maritalStatus: '',
  name: '',
  nationalId: '',
  nationality: '',
  personType: 'pf',
  phone: '',
  profession: '',
  reference: '',
  stateSubscription: '',
  tradeName: ''
};
