/* eslint-disable import/prefer-default-export */
import { Form as SemanticForm } from 'semantic-ui-react';
import styled from 'styled-components';

export const Form = styled(SemanticForm)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > div:first-child {
    width: 700px;
    padding: 2em;
  }

  .field {
    font-weight: 500;
  }

  .column > button {
    width: 150px;
  }


`;

export const TermsOfUse = styled.small`
  width: 100%;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  color: #333333;
  font-style: normal;
  font-weight: 350;

  > a {
    margin-left: 0.25em;
    font-size: 16px;

    &:hover {
      color: #4cc7ff;
    }
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
  margin-top: 0;
`;
