import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  padding: 24px 0 0 0;
`;

export const ButtonLabel = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
`;

export const SelectorContainer = styled.div`
  width: 100%;
  display: flow-root;
  align-items: center;
  justify-content: space-between;

  Button {
    padding: 16px 0 16px 0;
    display: flex;
    align-items: center;

    .button-label {
      display: flex;
      align-items: center;
    }

    .icon-active {
      margin-right: 12px;
      width: 19px;
      height: 19px;
      color: #346AF7;
    }
    .icon-normal {
      margin-right: 12px;
      width: 19px;
      height: 19px;
      color: #000000;
    }
  }

  .type-button-active, .type-button-active:hover {
    background-color: rgba(52, 106, 247, 0.12);
    box-shadow: none;
    color: #346AF7;
  }

  .type-button-normal, .type-button-normal:hover {
    background-color: #ffffff;
    box-shadow: none;
    opacity: 0.6;
    color: #000000;
  }
`;

export const InputLabel = styled.div`
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 400;
`;
