import React from 'react';
import { useLocation } from 'react-router-dom';

import Company from '../../../_refactor/pages/Profile/Company';
import MainLayout from '../../components/MainLayout';
import PaymentSettings from '../../components/PaymentSettings';
import ProfileSettings from '../../components/ProfileSettings';
import { useAuth } from '../AuthContext/AuthContext';
import UsersPage from '../UsersPage';
import Header from './Header';
import { Container, Wrapper } from './styles';

const UserProfilePage = () => {
  const { isOwner } = useAuth();
  const { pathname } = useLocation();

  return (
    <MainLayout header options>
      <Container>
        <Wrapper>
          <Header />
          {pathname === '/profile' && <ProfileSettings />}
          {pathname === '/company' && <Company />}
          {pathname === '/payment' && <PaymentSettings />}
          {pathname === '/users' && isOwner && <UsersPage />}
        </Wrapper>
      </Container>
    </MainLayout>
  );
};

export default UserProfilePage;
