import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAmplitude } from '../../../hooks/AmplitudeProvider';
import { updateStep, resetStore } from '../../../redux/ducks/starterWizard';
import Button from '../../Button';
import { Wrapper, ButtonGroup } from './styles';

const Footer = () => {
  const dispatch = useDispatch();
  const { Amplitude } = useAmplitude();
  const { error, step, stepIndex } = useSelector((state) => state.starterWizard);

  const handleNextStep = () => {
    const nextStepIndex = stepIndex + 1;
    const nextStep = (step === stepIndex ? step + 1 : step);
    if (nextStepIndex === 6) {
      Amplitude.logEvent('new_doc_participants_register_attempt');
    }

    dispatch(
      updateStep({ step: nextStep, stepIndex: nextStepIndex })
    );
  };

  const handleCancel = () => {
    dispatch(resetStore());
  };

  const handleStepBack = () => {
    switch (stepIndex) {
      case 4:
        dispatch(updateStep({ step, stepIndex: 3 }));
        break;

      case 5:
        dispatch(updateStep({ step, stepIndex: 4 }));
        break;

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Button className="cancel" link color="grey" type="button" onClick={handleCancel}>
        Cancelar
      </Button>
      <ButtonGroup>
        <Button link disabled={error} type="button" onClick={handleStepBack}>
          Voltar
        </Button>
        <Button onClick={handleNextStep} unpadded primary disabled={error}>
          { stepIndex === 5 ? 'Enviar' : 'Avançar' }
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};

export default Footer;
