import React from 'react';

import { Title } from '../../../../../_shared/styles';

export default function PageTitle() {
  return (
    <Title style={{ fontWeight: '400' }}>
      Comece agora a simplificar sua gestão de documentos! <span role="img" aria-label="rocket">🚀</span>
    </Title>
  );
}
