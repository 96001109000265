/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

import logoCKBrick from '../../../../assets/logo_ck_plus_brick.svg';
import * as S from './styles';

const SignerModalHeader = ({
  closeModal, mode
}) => {
  useEffect(() => {}, []);

  switch (mode) {
    case 'info':
      return (
        <>
          <S.Wrapper>
            <S.HeaderImage>
              <img src={logoCKBrick} alt="Imagem com as letras CK e o nome da plataforma Brick" />
            </S.HeaderImage>
            <S.CloseModalButton>
              <IoClose id="close-modal-button" onClick={closeModal} />
            </S.CloseModalButton>
          </S.Wrapper>
          <S.SubHeader>
            <S.SubHeaderText>
              Tenha total segurança ao receber assinaturas no seu documento
            </S.SubHeaderText>
          </S.SubHeader>
        </>
      );
    case 'create':
      return (
        <>
          <S.Wrapper>
            <S.CloseModalButton>
              <IoClose id="close-modal-button" onClick={closeModal} />
            </S.CloseModalButton>
            <S.HeaderTitle>
              <S.SubTitle>
                Solicitação de assinatura
              </S.SubTitle>
              <S.MainTitle>
                Cadastro do assinante
              </S.MainTitle>
            </S.HeaderTitle>
          </S.Wrapper>
        </>
      );
    default:
      return <></>;
  }
};

SignerModalHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired
};

export default SignerModalHeader;
