import PropTypes from 'prop-types';
import React from 'react';

import LabelRequired from '../LabelRequired';
import { Label, Wrapper } from './styles';

const NewInputWithRef = React.forwardRef(
  (
    {
      placeholder,
      id,
      label,
      type,
      name,
      className,
      required,
      showRequiredLabel,
      error,
      children,
      ...rest
    },
    ref
  ) => (
    <Wrapper className={error ? 'has-error' : ''}>
      <Label htmlFor={id}>
        {required && showRequiredLabel ? <LabelRequired text={label} /> : label}
      </Label>
      <input
        placeholder={placeholder}
        type={type}
        id={id}
        aria-label={placeholder}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        ref={ref}
        name={name}
        className={className}
        {...rest}
      />
      {children}
    </Wrapper>
  )
);

NewInputWithRef.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  error: PropTypes.bool
};

NewInputWithRef.defaultProps = {
  placeholder: '',
  id: '',
  label: '',
  name: '',
  type: '',
  className: '',
  children: undefined,
  required: false,
  showRequiredLabel: false,
  error: false
};

export default NewInputWithRef;
