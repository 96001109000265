/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button, Divider } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';

import { getNextPaymentDueDate } from '../_utils/getNextPaymentDueDate';
import themes from '../../../../_refactor/_utils/themes';
import confirmPaymentImg from '../../../assets/img/confirm_payment_img.svg';
import logoCKColorfull from '../../../assets/img/logo_letters_colorfull.svg';
import logoCKWhite from '../../../assets/img/logo_letters_white.svg';
import confirmPaymentImgWhite from '../../../assets/img/payment_confirmed_img_white.svg';
import { useAuth } from '../../../containers/AuthContext/AuthContext';
import * as S from './styles';

const ModalPaymentConfirmation = ({
  open, closeModal, planSelected, nextPaymentDate
}) => {
  const isUpgrading = (new URLSearchParams(window.location.search.split('?')[1])).get('selectedPlan');
  const history = useHistory();
  const { subscription } = useAuth();

  const [nextPaymentDueDate, setNextPaymentDueDate] = useState(nextPaymentDate);

  useEffect(() => {
    setNextPaymentDueDate(getNextPaymentDueDate(subscription.nextPaymentDueDate, planSelected.frequency));
  }, [subscription, planSelected]);

  const colorPallet = {
    Unlimited: {
      background: themes.background.primaryBlue,
      letterColor: themes.fonts.color.white,
      titleColor: themes.fonts.color.white,
      subTitleColor: themes.fonts.color.white,
      confirmImg: confirmPaymentImgWhite,
      logo: logoCKWhite
    },
    Light: {
      background: themes.background.light,
      letterColor: '#000000',
      titleColor: 'rgba(0, 0, 0, 0.87)',
      subTitleColor: 'rgba(0, 0, 0, 0.6);',
      confirmImg: confirmPaymentImg,
      logo: logoCKColorfull
    },
    Essential: {
      background: themes.background.darkBlue,
      letterColor: themes.fonts.color.white,
      titleColor: themes.fonts.color.white,
      subTitleColor: themes.fonts.color.white,
      confirmImg: confirmPaymentImgWhite,
      logo: logoCKWhite
    }
  };

  if (!open && !nextPaymentDueDate) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        size="small"
        style={{ maxWidth: '420px', borderRadius: '8px', backgroundColor: colorPallet[planSelected.planName].background }}
      >
        <S.Header>
          <div className="header-content">
            <div className="logo-container">
              <img src={colorPallet[planSelected.planName].logo} alt="Header logo" />
            </div>
            <div className="plan-info">
              <span className="title" style={{ color: colorPallet[planSelected.planName].titleColor }}>{planSelected.planName}</span>
              <span className="subtitle" style={{ color: colorPallet[planSelected.planName].subTitleColor }}>{planSelected.priceDescription}</span>
            </div>
          </div>
        </S.Header>
        <Divider style={{ width: '86.66667%', marginInline: 'auto', backgroundColor: '#E0E6ED' }} />
        <Modal.Content scrolling style={{ backgroundColor: colorPallet[planSelected.planName].background }}>
          <S.Text>
            <S.TextBody>
              <img src={colorPallet[planSelected.planName].confirmImg} alt="Imagem do modal" />
              <p style={{
                color: colorPallet[planSelected.planName].letterColor,
                fontWeight: '600',
                fontSize: '1.25rem'
              }}
              >
                { isUpgrading ? 'Pagamento realizada com sucesso!' : 'Informações atualizadas com sucesso!' }
              </p>
              {
                isUpgrading
                && (
                  <p style={{
                    color: colorPallet[planSelected.planName].letterColor,
                    fontWeight: '400',
                    fontSize: '0.75rem'
                  }}
                  >
                    Seu plano é válido até o dia {nextPaymentDueDate}
                  </p>
                )
              }
            </S.TextBody>
          </S.Text>
        </Modal.Content>
        <S.Footer>
          <S.FooterActions>
            <Button
              className="action-button create-payment-button"
              onClick={() => {
                localStorage.setItem('hasMigrated', true);
                closeModal();
                history.push('/');
              }}
              label={(
                <S.ButtonText>
                  <span className="button-text-normal button-text-white">Concluir</span>
                </S.ButtonText>
              )}
            />
          </S.FooterActions>
        </S.Footer>
      </Modal>
    </>
  );
};

ModalPaymentConfirmation.defaultProps = {
  nextPaymentDate: null
};

ModalPaymentConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  planSelected: PropTypes.any.isRequired,
  nextPaymentDate: PropTypes.string
};

export default ModalPaymentConfirmation;
