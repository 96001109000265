/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  min-height: 100px;
  border-radius: 6px;
  display: flex;
`;

export const Wrapper = styled.div`
  height: 600px;
  overflow: auto;

  > div > div > div {
    border-left: 1px solid ${({ theme }) => theme.borderGrey};
    width: 1px;
    height: 45%;
  }
`;

export const Left = styled.div`
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.ui.label {
    background: ${({ theme }) => theme.primaryColor};
  }
`;

export const Line = styled.div`
  border-left: 1px solid ${({ theme }) => theme.borderGrey};
  width: 1px;
  height: 45%;
`;

export const Right = styled.div`
  padding: 1em;
  width: calc(100% - 80px);

  > span {
    font-size: 12px;
    margin-bottom: 1em;
    color: ${({ theme }) => theme.fontColor};
  }

  > p {
    color: ${({ theme }) => theme.fontColor};
  }
`;

export const HistoryContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
`;

export const HistoryInfo = styled.div`
  width: 100%;
  text-align: center;
`;

export const HistoryImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32.2px;
`;

export const HistoryTitle = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.25px;
  color: #000000;
  margin-bottom: 12px;
`;

export const HistorySubTitle = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
  color: #000000;
  opacity: 0.6;
`;

export const HistoryButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .new-payment-button, .new-payment-button:hover {
    background: rgba(52, 106, 247, 0.12);
    box-shadow: none;
    padding: 16px 50px 16px 50px;
    border-radius: 6px;
    color: #346AF7;
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 1.25px
  }
`;
