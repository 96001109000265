import React, { useState, useEffect } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { useAmplitude } from '../../../hooks/AmplitudeProvider';
import { updateId, updateTitle, updateStep } from '../../../redux/ducks/starterWizard';
import api from '../../../utils/backend';
import theme from '../../../utils/theme';
import Button from '../../Button';
import DispatchErrorModal from '../../DispatchErrorModal';
import { Wrapper } from './styles';

const UploadLoading = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState(false);
  const [limitDispatchModal, setLimitDispatchModal] = useState(false);

  const { file, step } = useSelector((state) => state.starterWizard);
  const { Amplitude } = useAmplitude();

  useEffect(() => {
    async function handleUploadFile() {
      if (step === 2 && file) {
        const formData = new FormData();

        formData.append('file', file);

        await api.post('/files/upload', formData)
          .then(({ data: { id, title } }) => {
            dispatch(updateId(id));
            dispatch(updateTitle(title));
            Amplitude.logEvent('new_doc_import_complete');
            history.push(`/document/${id}`);
            dispatch(updateStep({ step: 1, stepIndex: 1 }));
          })
          .catch((err) => {
            if (err.response.data?.message === 'Quantidade máxima de disparos atingida.') {
              setError(true);
              setLimitDispatchModal(true);
            }

            setError(true);

            Amplitude.logEvent('new_doc_import_error');
          });
      }
    }

    handleUploadFile();
  }, [dispatch, file, step, Amplitude, history]);

  const handleResetStep = () => {
    dispatch(updateStep({ step: 1, stepIndex: 1 }));
  };

  return (
    <Wrapper>
      {!error && (
        <>
          <Loader active inline size="large" />
          <h3>Carregando arquivo..</h3>
        </>
      )}

      {error && (
        <>
          <DispatchErrorModal
            open={limitDispatchModal}
            onClose={() => setLimitDispatchModal(false)}
          />
          <AiOutlineWarning size={80} color={theme.greyBlue} />
          <h2 className="error">Oops!</h2>
          <p>Erro ao enviar o arquivo, tente novamente.</p>
          <Button link onClick={handleResetStep}>Enviar novo arquivo</Button>
        </>
      )}
    </Wrapper>
  );
};

export default UploadLoading;
