/* eslint-disable react/prop-types */
import { Button as ButtonUi } from '@contraktor-tech/ui';
import React, {
  useState, useEffect, useMemo, useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader as SemanticLoader } from 'semantic-ui-react';

import Button from '../../components/Button';
import OptionsMenu from '../../components/OptionsMenu';
import Table from '../../components/Table';
import api from '../../utils/backend';
import { useAuth } from '../AuthContext/AuthContext';
import ConfirmationModal from './components/ConfirmationModal';
import FormModal from './components/FormModal';
import * as S from './styles';

const UsersPage = () => {
  const history = useHistory();
  const {
    user, currentPlan, isUnlimitedPlan, isFreemium
  } = useAuth();

  const [formModal, setFormModal] = useState({
    open: false,
    type: 'create',
    user: {}
  });
  const [usersLimitModal, setUsersLimitModal] = useState(false);
  const [users, setUsers] = useState({
    loading: true,
    data: []
  });
  const canCreateUser = isUnlimitedPlan || Boolean(currentPlan.maxUsers > users.data.length || 0);

  const fetchOrganization = async () => {
    if (!users.loading) setUsers({ loading: true, data: [] });
    const { data } = await api.get(`organizations/${user.organizationId}?load_users=true`);
    setUsers({ loading: false, data: data.users });
  };

  const handleUserDelete = useCallback(async (userId) => {
    try {
      await api.delete(`users/settings/remove_from_organization_owner/${userId}`);
      toast.success('Usuário deletado com sucesso!');
      fetchOrganization();
    } catch (error) {
      toast.error('Algo saiu errado! Por favor, tente novamente ou contate o suporte.');
    }
    // eslint-disable-next-line
  }, []);

  const handleOpenCreateModal = () => {
    if (isFreemium) {
      history.push('/plans');
      return;
    }

    if (canCreateUser) {
      setFormModal({
        open: true,
        type: 'create',
        user: {}
      });
    } else {
      setUsersLimitModal(true);
    }
  };

  useEffect(() => {
    fetchOrganization();
    // eslint-disable-next-line
  }, [user.organizationId]);

  const columns = useMemo(() => [
    {
      key: 'firstName',
      title: 'Nome',
      Cell: ({ row }) => <>{row.firstName} {row.lastName}</>
    },
    {
      key: 'email',
      title: 'E-mail'
    },
    {
      key: 'id',
      title: '',
      Cell: ({ row }) => (
        <OptionsMenu>
          <OptionsMenu.Item onClick={() => setFormModal({
            open: true,
            type: 'edit',
            user: row
          })}
          >
            Editar
          </OptionsMenu.Item>
          {!isFreemium && (
          <OptionsMenu.Item onClick={() => handleUserDelete(row.id)} danger>
            Deletar
          </OptionsMenu.Item>
          )}
        </OptionsMenu>
      )
    }
  ], [handleUserDelete, isFreemium]);

  return (
    <>
      <S.Header>
        {!isFreemium
          && !isUnlimitedPlan
          && <p className="users-info">{users.loading ? '' : users.data.length} de {currentPlan.maxUsers} usuários utilizados</p>}
        <Button
          unpadded
          primary
          onClick={() => handleOpenCreateModal()}
        >
          Novo Usuário
        </Button>
      </S.Header>
      {
        users.loading
          ? <SemanticLoader active={users.loading} />
          : <Table columns={columns} data={users.data} />
      }

      <FormModal
        onSuccess={() => {
          fetchOrganization();
          setFormModal({
            open: false,
            type: 'create',
            user: {}
          });
        }}
        onClose={() => setFormModal({
          open: false,
          type: 'create',
          user: {}
        })}
        open={formModal.open}
        user={formModal.user}
        type={formModal.type}
      />

      <ConfirmationModal open={usersLimitModal} onClose={() => setUsersLimitModal(false)}>
        <h5>limite atingido</h5>
        <h3>Sua organização atingiu o limite de usuários</h3>
        {isUnlimitedPlan ? (
          <p>
            Com o Plano Unlimited você pode ter até 12 usuários cadastrados.
            Mas é possível melhorar o seu plano para ter mais usuários.
          </p>
        ) : (
          <p>
            Com o Plano Light você pode ter até 6 usuários cadastrados.
            Mas é possível melhorar o seu plano para ter mais usuários.
          </p>
        )}
        <div className="actions">
          <ButtonUi label="Fechar" variant="outlined" fullWidth onClick={() => setUsersLimitModal(false)} />
          <ButtonUi label="Melhorar Plano" variant="contained" disableElevation fullWidth onClick={() => history.push('/payment')} />
        </div>
      </ConfirmationModal>
    </>
  );
};

export default UsersPage;
