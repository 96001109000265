import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0.75rem;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  grid-gap: 1rem;
  height: 200px;
  width: max-content;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1);
`;

export const ProgressInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const ProgressInfoLabel = styled.p`
  font-family: Poppins;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0063rem;
  margin-left: 0.5rem;
`;

export const ProgressInfoSubtitle = styled.p`
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.0125rem;
  text-align: left;
  margin-bottom: 16px;
`;
