/* eslint-disable react/no-array-index-key */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import currency from 'currency.js';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import getPlanQuery from '../../_utils/getPlanQuery';
import Button from '../../../../_components/Button';
import { useAuth } from '../../../../../_legacy/containers/AuthContext/AuthContext';
import { useChoosePlan } from '../../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import ChangePlanModal from '../ChangePlanModal';
import { whatsappQuotaPrices } from '../WhatsappCard/constants/whatsappQuotaPrices';
import {
  CardPrice, CardTitle, StyledCard, TopContent, CashPayment,
  DiscountInfo, PlanDescription,
  BottomContent,
  Benefits,
  StyledCardContent,
  PlanTag
} from './styles';

export default function PlanCard({
  plan, frequency, whatsappActive, whatsappQuota
}) {
  const { subscription: { planName, cycle, providerId }, isFreemium, isTrial } = useAuth();
  const { setPlanSelected, plansList } = useChoosePlan();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const checkIsCurrentPlan = () => {
    const matchName = planName === plan.code;
    const matchCycle = cycle?.toLowerCase() === frequency;

    const isInstallment = !providerId?.includes('sub_');

    if (isFreemium) return matchName;
    if (isInstallment) return matchName && frequency === 'installment';

    return matchName && matchCycle;
  };

  const totalPricePerMonth = plan.price.monthly;

  const monthPriceWithDiscount = totalPricePerMonth - (
    totalPricePerMonth * plan.discountPercent[frequency]
  );
  const whatsappValue = whatsappActive ? whatsappQuotaPrices[whatsappQuota] || 0 : 0;

  const discountPercentage = `${Math.ceil(plan.discountPercent[frequency] * 100)}%`;
  const isFree = ['free'].includes(plan.name.toLowerCase());
  const hasDiscount = plan.discount[frequency];
  const isCurrent = checkIsCurrentPlan();

  const getCardPrice = () => {
    if (isFree) return 0;
    if (plan.code === 'Advanced') return monthPriceWithDiscount;
    return monthPriceWithDiscount + whatsappValue;
  };

  const monthlyFormattedPrice = currency(
    getCardPrice(),
    {
      symbol: 'R$',
      decimal: ',',
      separator: '.',
      precision: 1
    }
  )
    .format();

  const renderTag = () => {
    if (isCurrent) {
      return (
        <PlanTag>Plano Atual</PlanTag>
      );
    }

    if (plan.tag) {
      return (
        <PlanTag planStyles={plan.styles}>
          { plan.tag }
        </PlanTag>
      );
    }

    return <div style={{ height: '28.8px' }} />;
  };

  const updateToAdvanced = () => {
    window.location.href = 'https://contraktor.com.br/fale-com-vendas/';
  };

  const handleChoosePlanClick = useCallback(() => {
    setPlanSelected({
      ...plan,
      frequency
    });

    if ((isTrial || isFreemium) && plan.code !== 'Freemium') {
      if (plan.code === 'Advanced') return updateToAdvanced();

      const planQuery = getPlanQuery({
        ...plan,
        frequency
      });

      setPlanSelected(plansList.find((p) => p.param === planQuery) || plansList[1]);

      return setTimeout(() => history.push(`/subscription/payment?selectedPlan=${planQuery}`), 300);
    }

    return setModal(true);
  }, [
    setPlanSelected,
    isTrial,
    isFreemium,
    history,
    plansList,
    frequency,
    plan
  ]);

  return (
    <>
      <StyledCard planStyles={plan.styles}>
        {
          renderTag()
        }
        <StyledCardContent>
          <CardTitle planStyles={plan.styles}>
            { plan.name }
          </CardTitle>
          <TopContent>
            <CardPrice planStyles={plan.styles}>
              {
              hasDiscount && (
                <DiscountInfo planStyles={plan.styles}>
                  desconto de {discountPercentage}
                </DiscountInfo>
              )
            }
              { `${monthlyFormattedPrice}` } <span className="plan-frequency">/mês</span>
              {
              plan.paymentDetails && (
                <CashPayment planStyles={plan.styles}>
                  { plan.paymentDetails[frequency] }
                </CashPayment>
              )
            }
            </CardPrice>
            <PlanDescription planStyles={plan.styles}>{ plan.description }</PlanDescription>
          </TopContent>
          <Button
            disabled={isCurrent || (isFree && whatsappActive)}
            theme={plan.styles.buttonTheme}
            label="Escolher plano"
            onClick={handleChoosePlanClick}
          />
          <BottomContent>
            <Benefits isFeature planStyles={plan.styles}>
              {plan.features.map((feature, index) => {
                return (
                  <li key={index}>
                    <span>
                      <CheckCircleIcon />
                    </span>
                    <p>{feature}</p>
                  </li>
                );
              })}
            </Benefits>
            <Benefits isFeature={false} planStyles={plan.styles}>
              {plan.notFeatures.map((notFeature, index) => {
                return (
                  <li key={index}>
                    <span>
                      <CancelIcon />
                    </span>
                    <p>{notFeature}</p>
                  </li>
                );
              })}
            </Benefits>
          </BottomContent>
        </StyledCardContent>
      </StyledCard>
      <ChangePlanModal
        open={modal}
        onClose={() => setModal(false)}
      />
    </>
  );
}
