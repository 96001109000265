/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import _, { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader, Modal } from 'semantic-ui-react';

import { editPayment, getPayment } from '../../services/api/adapters/payment';
import ModalPaymentHeader from '../shared/components/ModalPaymentHeader';
import CustomerInfo from '../shared/forms/CustomerInfo';
import PaymentInfo from '../shared/forms/PaymentInfo';
import EditInfoPopover from './EditInfoPopover';
import ExitEditionPopover from './ExitEditionPopover';
import * as S from './styles';

const ModalEditPayment = ({
  open,
  closeModal,
  id
}) => {
  const [payment, setPayment] = useState(null);
  const [oldPayment, setOldPayment] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false);
  const [isCustomerFormValid, setIsCustomerFormValid] = useState(false);
  const [step, setStep] = useState(1);
  const [backButtonText, setBackButtonText] = useState('Cancelar');
  const [confirmedCustomer, setConfirmedCustomer] = useState(false);
  const [activeForm, setActiveForm] = useState('PAYMENT');
  const [loading, setLoading] = useState(true);
  const [infoModalOpen, setInfoModalOpen] = useState('');
  const [updatingPayment, setUpdatingPayment] = useState(false);
  const [exitPopoverOpen, setExitPopoverOpen] = useState(false);

  const deleteNullValues = (payload) => {
    Object.entries(payload).forEach(([key]) => {
      if (_.isEmpty(payload[key])) delete payload[key];
    });
  };

  const formatPayloadData = (payload) => {
    deleteNullValues(payload);
    payload.value = parseFloat(payload.value.replace(',', '.'));
    if (payload.installment_count) payload.installment_count = parseInt(payload.installment_count, 10);
  };

  const resetModal = () => {
    setStep(1);
    setActiveForm('PAYMENT');
    setBackButtonText('Cancelar');
    setConfirmedCustomer(false);
    setExitPopoverOpen(false);
    setPayment(null);
    setOldPayment(null);
    setCustomer(null);
  };

  const close = () => {
    if (!_.isEqual(oldPayment, payment)) {
      setExitPopoverOpen(true);
    } else {
      closeModal();
      resetModal();
    }
  };

  const undoConfirmCustomer = () => {
    setConfirmedCustomer(false);
  };

  const submitChanges = async (payload) => {
    try {
      formatPayloadData(payload);
      await editPayment(payload);
      closeModal();
      resetModal();
    } catch (error) {
      toast.error(error.message || 'Houve um erro ao editar a cobrança');
      close();
    }
  };

  const submit = async () => {
    const payload = {
      ...payment,
      client: { ...customer }
    };
    await submitChanges(payload);
  };

  const openInfoModal = async () => {
    setInfoModalOpen(true);
  };

  const getPaymentInfo = useCallback(async () => {
    setLoading(true);
    getPayment(id)
      .then(({ data }) => {
        setPayment(data.data);
        setOldPayment(data.data);
        setCustomer(data.data.client);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(error);
      });
  }, [id]);

  const confirmEdition = async () => {
    setUpdatingPayment(true);
    await submit();
    setUpdatingPayment(false);
    setInfoModalOpen(false);
    closeModal();
  };

  useEffect(() => {
    if (open) getPaymentInfo();
  }, [getPaymentInfo, open]);

  if (!open) return null;

  return (
    <>
      <Modal
        open={open}
        id="create-payment-modal"
        size="small"
        style={{ minHeight: '591px', maxWidth: '670px', borderRadius: '8px' }}
      >
        <ModalPaymentHeader
          mode="edit"
          step={step}
          closeModal={close}
          switchForm={setActiveForm}
          activeForm={activeForm}
        />
        {(loading && isNil(payment)) && (
        <div style={{
          height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}
        ><Loader active={loading} />
        </div>
        )}
        {(!loading && !isNil(payment)) && (
          <Modal.Content scrolling>
            { activeForm === 'PAYMENT'
              ? (
                <PaymentInfo
                  updatePayment={setPayment}
                  setFormState={setIsPaymentFormValid}
                  paymentPayload={payment}
                  mode="edit"
                />
              )
              : (
                <CustomerInfo
                  confirmedCustomer={confirmedCustomer}
                  undoConfirmation={undoConfirmCustomer}
                  updateCustomer={setCustomer}
                  customerPayload={customer}
                  setFormState={setIsCustomerFormValid}
                />
              )}
          </Modal.Content>
        )}
        <S.Footer>
          <Button
            className="action-button back-button"
            onClick={close}
            label={(
              <S.ButtonText>
                <span className="button-text-normal button-text-blue">{ backButtonText }</span>
              </S.ButtonText>
            )}
          />
          <Button
            disabled={(!isPaymentFormValid && activeForm === 'PAYMENT') || (!isCustomerFormValid && activeForm === 'CUSTOMER')}
            className="action-button next-button"
            onClick={openInfoModal}
            label={(
              <S.ButtonText>
                <span className="button-text-normal button-text-white">Alterar</span>
              </S.ButtonText>
            )}
          />
        </S.Footer>
      </Modal>
      <EditInfoPopover
        open={infoModalOpen}
        closeModal={() => {
          setInfoModalOpen(false);
          setUpdatingPayment(false);
        }}
        submit={async () => {
          confirmEdition(id);
        }}
        payment={payment}
        updating={updatingPayment}
      />
      <ExitEditionPopover
        open={exitPopoverOpen}
        closeModal={() => {
          setExitPopoverOpen(false);
        }}
        exitEdition={() => {
          setExitPopoverOpen(false);
          closeModal();
        }}
      />
    </>
  );
};

ModalEditPayment.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default ModalEditPayment;
