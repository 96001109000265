/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';

import Button from '../../Button';
import StatusDropdown from '../../StatusDropdown';
import CompletedRange from './CompletedRange';
import SentRange from './SentRange';
import { Wrapper, FilterLabel } from './styles';

const orderOptions = [
  { value: null, text: 'Últimas modificações' },
  { value: 'number', text: 'Número' },
  { value: 'title', text: 'Título' },
  { value: '-inserted_at', text: 'Últimos criados' }
];

const Filters = ({
  only,
  query,
  setQuery,
  readOnly
}) => {
  const [sentSelectedDays, setSentSelectedDays] = useState({
    from: undefined, to: undefined
  });
  const [completedSelectedDays, setCompletedSelectedDays] = useState({
    from: undefined, to: undefined
  });
  const [sentDayPickerShow, setSentDayPickerShow] = useState(false);
  const [completedDayPickerShow, setCompletedDayPickerShow] = useState(false);
  const [state, setState] = useState({
    order: null,
    due: null,
    assignee: null,
    workflow: null,
    party: null,
    status: null,
    page: undefined
  });

  useEffect(() => setState(query), [query]);

  function clearQueryFilters() {
    setSentSelectedDays({ from: undefined, to: undefined });
    setSentDayPickerShow(false);
    setCompletedSelectedDays({ from: undefined, to: undefined });
    setCompletedDayPickerShow(false);
    setQuery({
      order: null,
      due: null,
      assignee: null,
      workflow: null,
      sentInitialDate: null,
      sentEndDate: null,
      completedInitialDate: null,
      completedEndDate: null,
      party: null,
      status: null,
      page: undefined
    });
  }

  function clearState() {
    setState({
      order: null,
      due: null,
      assignee: null,
      workflow: null,
      party: null,
      status: null,
      page: undefined
    });
    clearQueryFilters();
  }

  function getFiltersLength(obj) {
    const filterObj = obj;
    if (obj.search || obj.filter) {
      delete filterObj.search;
      delete filterObj.page;
      delete filterObj.filter;
    }
    return Object.keys(filterObj).length;
  }

  const filtersCount = getFiltersLength(query);

  return (
    <Dropdown
      text={filtersCount > 0 ? `Filtros (${filtersCount})` : 'Filtrar'}
      icon="filter"
      labeled
      button
      closeOnEscape={false}
      closeOnBlur={false}
      className="icon"
    >
      <Dropdown.Menu direction="left" onClick={(e) => e.stopPropagation()}>
        <Wrapper>
          <div>
            <span>Filtros ({filtersCount})</span>
            <div>
              <Button onClick={() => clearState()} unpadded size="mini">
                Limpar
              </Button>
              <Button
                onClick={() => setQuery(state)}
                unpadded
                size="mini"
                primary
              >
                Aplicar
              </Button>
            </div>
          </div>
          <Grid>
            <Grid.Row columns={2}>
              {(!only || only.includes('order')) && (
                <Grid.Column>
                  <FilterLabel>Ordem</FilterLabel>
                  <Dropdown
                    selection
                    labeled
                    className="customDropdownFilter"
                    disabled={readOnly}
                    upward={false}
                    closeOnEscape={false}
                    icon={!readOnly && 'dropdown'}
                    placeholder="Últimas modificações"
                    options={orderOptions}
                    value={(state.order) || null}
                    onChange={(_e, data) => setState(
                      { ...state, order: data.value, page: undefined }
                    )}
                  />

                </Grid.Column>
              )}
              {(!only || only.includes('status')) && (
                <Grid.Column>
                  <FilterLabel>Status</FilterLabel>
                  <StatusDropdown
                    selection
                    className="customDropdownFilter"
                    disabled={readOnly}
                    upward={false}
                    allowNull
                    icon={readOnly ? null : 'dropdown'}
                    placeholder="Qualquer"
                    value={(state && state.status) || null}
                    onChange={(_e, data) => setState(
                      { ...state, status: data.value, page: undefined }
                    )}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row columns={2}>
              {(!only || only.includes('sentAt')) && (
                <Grid.Column>
                  <FilterLabel dateRange>Enviado em</FilterLabel>
                  <SentRange
                    query={query}
                    setFilterState={setState}
                    selectedDays={sentSelectedDays}
                    setSelectedDays={setSentSelectedDays}
                    dayPickerShow={sentDayPickerShow}
                    setDayPickerShow={setSentDayPickerShow}
                  />
                </Grid.Column>
              )}
              {(!only || only.includes('completedAt')) && (
                <Grid.Column>
                  <FilterLabel dateRange>Concluido em</FilterLabel>
                  <CompletedRange
                    query={query}
                    setFilterState={setState}
                    selectedDays={completedSelectedDays}
                    setSelectedDays={setCompletedSelectedDays}
                    dayPickerShow={completedDayPickerShow}
                    setDayPickerShow={setCompletedDayPickerShow}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Wrapper>
      </Dropdown.Menu>
    </Dropdown>
  );
};

Filters.propTypes = {
  only: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired
};

Filters.defaultProps = {
  only: [],
  readOnly: false
};

export default Filters;
