import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { deleteSigner } from '../../../redux/ducks/signers';
import api from '../../../utils/backend';
import { signerType } from '../../../utils/types/signerType';
import OptionsMenu from '../../OptionsMenu';

const Wrapper = styled.div`
  width: 2em;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;

  > * {
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 0.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Actions = ({ signer }) => {
  const dispatch = useDispatch();

  const onDelete = async () => {
    try {
      await api.delete(`/persons/${signer.id}`);

      dispatch(deleteSigner(signer));
      toast.success('Assinante Deletado com Sucesso!');
    } catch (error) {
      toast.error('Ops...Algo errado aconteceu');

      throw new Error(error);
    }
  };

  return (
    <Wrapper>
      <OptionsMenu>
        <OptionsMenu.Item onClick={onDelete} danger>
          Deletar
        </OptionsMenu.Item>
      </OptionsMenu>
    </Wrapper>
  );
};

Actions.propTypes = {
  signer: PropTypes.shape(signerType).isRequired
};

export default Actions;
