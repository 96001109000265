import styled, { css } from 'styled-components';

const GridContainer = styled.section`
  ${({
    width, columns, spacing, padding, alignItems, rows
  }) => css`
    display: grid;
    width: ${width};
    grid-template-columns: ${columns};
    grid-gap: ${spacing}rem;
    align-content: stretch;
    padding: ${padding || '0.5rem'};
    ${alignItems && `align-items: ${alignItems}`}
    ${rows && `grid-template-rows: ${rows}`}
  `}
`;

export default GridContainer;
