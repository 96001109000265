import { Form as SemanticForm } from 'semantic-ui-react';
import styled from 'styled-components';

export const Form = styled(SemanticForm)`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  > div:first-child {
    width: 800px;
  }

  .form-row {
    display: flex;
    padding: 0.65rem 0;
    margin-left:13.7px;
    > * {
      width: 100%;
    }
    
   
    .form-col {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0.65rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.col-sm {
        max-width: 30%;
      }
    }
  }
  .has-error {
    .validation-error{
      color: #CD2727;
    }
    input{
      border-color: #E53935 !important;
    }
  }
  .is-valid {
    input {
      border-color: #4CAF50 !important;
    }
    .helper-text, i {
      color: #297F2D;
    }
  }
  span.input-label {
    margin-right:6em;
    margin-bottom:5px;
    color: #000;
    font-weight: 500;
    font-family: 'Fakt Soft Pro','Helvetica Neue',Arial,Helvetica,sans-serif !important
    }
    .helper-text {
    display: flex;
    padding: 0.15rem 1rem;
    font-size: 0.85rem;
    color: #000;
  }
`;

export const Label = styled.label`
  color: #000;
  font-weight: 500;
  font-family: ${(props) => props.theme.fontFamily} !important;
  text-align: left;
`;

export const ErrorContainer = styled.div`
  border-radius: 4px;
  padding: 0.65rem 1rem;
  display: flex;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  align-items: center;
  justify-content: center;
`;
