import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { checkUserIsSigner } from '../../../utils/helpers';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
  width: 85%;
  padding-right: 0.5em;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = ({ document }) => {
  const { user, isFreemium } = useAuth();
  const waitingOwnSign = checkUserIsSigner(document.signers, user) && !isFreemium;
  return (
    <Wrapper>
      <ShortenedField clamp={1}>
        {document.title || <em>Sem título</em>}
      </ShortenedField>
      {(waitingOwnSign) && document.status !== 'canceled' && (
        <Popup
          trigger={<Icon name="attention" color="orange" size="large" />}
          content="Documento aguardando sua assinatura"
        />
      )}
    </Wrapper>
  );
};

Title.propTypes = {
  document: PropTypes.shape({
    title: PropTypes.string,
    signers: PropTypes.arrayOf(PropTypes.shape({
      confirmedAt: PropTypes.string,
      confirmedIpAddress: PropTypes.string,
      confirmedUserAgent: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      sentAt: PropTypes.string
    })),
    status: PropTypes.string
  }).isRequired
};

export default Title;
