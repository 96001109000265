import PropTypes from 'prop-types';
import React from 'react';

import { HeaderWrapper, ContentWrapper } from './styles';

const Content = ({ children }) => <ContentWrapper>{children}</ContentWrapper>;

const Header = ({ children }) => <HeaderWrapper>{children}</HeaderWrapper>;

Content.propTypes = {
  children: PropTypes.node.isRequired
};

Header.propTypes = {
  children: PropTypes.node.isRequired
};

Content.Header = Header;

export default Content;
