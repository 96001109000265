import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 92147483003;

  width: 100%;
  padding: 18px 15px;

  background-color: #fff;
  box-shadow: 0px -4px 3px -3px rgba(0,0,0,0.15);
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  & > p {
    margin-right: 30px;
  }

  @media ${(props) => props.theme.breakPoints.medium} {
    flex-direction: column;
    align-items: flex-start;

    & > p {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`;
