import { Chip, styled } from '@mui/material';

import themes from '../../../../../_utils/themes';

export const SafeEnvChip = styled(Chip)({
  backgroundColor: themes.pallete.successLight,
  padding: '8px 16px',
  width: 'fit-content'
});

export const Label = styled('span')({
  display: 'flex',
  gap: '6px',
  color: themes.fonts.color.darkGreen,
  fontSize: themes.fonts.size.small,
  fontWeight: themes.fonts.weight.bold,
  fontFamily: themes.fonts.family.Poppins
});
