import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

import themes from '../../_utils/themes';

export const CustomStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${({ active }) => (active ? themes.pallete.primary : themes.pallete.primaryGrey)};
  color: ${({ active }) => (active ? themes.pallete.primary : themes.pallete.primaryGrey)};
  background-color: ${({ active }) => (active ? themes.pallete.primaryLight : themes.pallete.white)};
`;

export const CompletedStep = styled(CustomStep)({
  backgroundColor: themes.pallete.white,
  border: `1px solid ${themes.pallete.primary}`,

  '& .CompleteIcon': {
    color: themes.pallete.primary
  }
});

export const CustomConnector = muiStyled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themes.pallete.primary
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themes.pallete.primary
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: themes.pallete.primaryGrey,
    borderTopWidth: 1,
    borderRadius: 1
  }
});

export const Label = styled.span`
  color: ${({ active }) => (active ? themes.pallete.primary : themes.pallete.primaryGrey)};
  font-weight: ${({ active }) => (active ? themes.fonts.weight.normal : themes.fonts.weight.thin)};
  font-size: ${themes.fonts.size.small};
`;
