import styled from 'styled-components';

import { resetInput, resetButton } from '../../utils/styles';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: ${(props) => ((props.openDirection === 'left') ? 'row-reverse' : 'row')};
  align-items: center;
  position: relative;

  &:after{
    transition: width 0.5s ease, border-color 0.5s ease;
    width: 0;
    border-bottom: 1px solid #d5d5d5;
    height: 1px;
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    margin: 0 10%;
  }

  &:focus,
  &.open:after {
    width: 90%;
  }
`;

export const StyledInput = styled.input`
  ${resetInput}

  order: ${(props) => ((props.openDirection === 'left') ? '1' : '2')};

  border-bottom: 1px solid #fff;
  padding-left: 0.5em;
  font-size: 1.2em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;

  width: calc(100% - 3em);

  transition: width 1s ease;

  &::placeholder {
    color: #d5d5d5 !important;
  }

  &:focus,
  &.open {
    width: calc(100% - 3em);
    min-width: 12.5em;
    max-width: 100%;
    transition: border-color 0.5s ease;

    &::placeholder {
      color: #7b7b7b !important;
    }
  }
`;

export const StyledButton = styled.button`
  ${resetButton}

  order: ${(props) => ((props.openDirection === 'left') ? '2' : '1')};

  font-size: 1.5em;
  width: 1.5em;
  color: #d5d5d5;
  z-index: 200;
  outline: 0;
  cursor: pointer;
  position: relative;
`;
