import {
  Button, Menu, MenuItem, Divider
} from '@contraktor-tech/ui';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import UserInfo from './shared/UserInfo';
import { MenuContainer } from './styles';

function ProfileMenu() {
  const { isOwner } = useAuth();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { title: 'Empresa', onClick: () => history.push('/company') },
    { title: 'Minha Conta', onClick: () => history.push('/profile') },
    { title: 'Plano e Pagamento', onClick: () => history.push('/payment') },
    { title: 'Usuários', onClick: () => history.push('/users'), onlyOwner: true }
  ];

  return (
    <>
      <Button
        variant="text"
        label={<UserInfo />}
        onClick={handleClick}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        menuListPadding="0px"
      >
        <MenuContainer>
          {menuItems.filter((tab) => (tab.onlyOwner ? isOwner : true)).map((item) => (
            <MenuItem onClick={item.onClick}>{item.title}</MenuItem>
          ))}
          <Divider />
          <MenuItem onClick={() => history.push('/logout')}>Sair</MenuItem>
        </MenuContainer>
      </Menu>
    </>
  );
}

export default ProfileMenu;
