import PropTypes from 'prop-types';
import React from 'react';
import { MdMenu as MenuIcon, MdClose as CloseIcon } from 'react-icons/md';

import logoHorizontalWhite from '../../assets/img/logo_horiz_colorfull.svg';
import SignModal from '../SignModal';
import {
  Wrapper, Container, MenuButton, Button
} from './styles';

const MobileHeader = ({
  handleMenuChange,
  open,
  readOnly,
  canSign,
  confirmSign,
  signer
}) => (
  <Wrapper>
    <Container>
      <MenuButton type="button" onClick={handleMenuChange}>
        {!open && <MenuIcon size={32} color="#000" />}
        {open && <CloseIcon size={32} color="#000" />}
      </MenuButton>
      <img
        src={logoHorizontalWhite}
        alt="Imagem com as letras CK e o nome da plataforma Contraktor"
      />
      {canSign && readOnly && (
        <SignModal
          onSign={confirmSign}
          signer={signer}
          trigger={(
            <Button type="button" background>
              ASSINAR
            </Button>
          )}
        />
      )}
    </Container>
  </Wrapper>
);

MobileHeader.propTypes = {
  handleMenuChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  confirmSign: PropTypes.func.isRequired,
  canSign: PropTypes.bool.isRequired,
  signer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }),
  readOnly: PropTypes.bool
};

MobileHeader.defaultProps = {
  readOnly: null || false,
  signer: {}
};

export default MobileHeader;
