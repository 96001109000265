import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useQueryString from '../../../hooks/useQueryString';
import Header from '../Header';

const only = [
  'page',
  'search',
  'due',
  'order',
  'status',
  'sentInitialDate',
  'sentEndDate',
  'completedInitialDate',
  'completedEndDate',
  'completedAt',
  'sentAt'
];

const HeaderWrapper = () => {
  const location = useLocation();
  const { counters } = useSelector((state) => state.documents);

  const tabs = [
    {
      name: `Documentos (${counters.waitSign})`,
      href: '/documents',
      active: location.pathname === '/documents'
    },
    {
      name: `Lixeira (${counters.trash})`,
      href: '/trash',
      active: location.pathname === '/trash'
    }
  ];

  const { query, setQuery } = useQueryString({ only, clearFalsyValues: true });

  return (
    <Header
      tabs={tabs}
      query={query}
      setQuery={setQuery}
      only={only}
    />
  );
};

export default HeaderWrapper;
