/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import { cnpj } from 'cpf-cnpj-validator';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import CustomerForm from './CustomerForm/CustomerForm';
import CustomerList from './CustomerList/CustomerList';
import * as S from './styles';

const CustomerInfo = ({
  updateCustomer, customerPayload, confirmedCustomer, undoConfirmation, setFormState
}) => {
  const [customerType, setCustomerType] = useState(null);

  const checkCustomerType = useCallback(() => {
    if (cnpj.isValid(customerPayload?.document_id)) setCustomerType('JURIDICA');
    else setCustomerType('FISICA');
  }, [setCustomerType]); // eslint-disable-line

  useEffect(() => {
    checkCustomerType();
  }, [checkCustomerType]);

  return (
    <>
      <S.Container>
        {
          !confirmedCustomer && !isNil(customerType)
            ? (
              <CustomerForm
                type={customerType}
                setType={setCustomerType}
                updateCustomer={updateCustomer}
                setFormState={setFormState}
                customerPayload={customerPayload}
              />
            )
            : <CustomerList type={customerType} customer={customerPayload} undoConfirmation={undoConfirmation} />
        }
      </S.Container>
    </>
  );
};

CustomerInfo.propTypes = {
  updateCustomer: PropTypes.func.isRequired,
  customerPayload: PropTypes.any.isRequired,
  confirmedCustomer: PropTypes.bool.isRequired,
  undoConfirmation: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired
};

export default CustomerInfo;
