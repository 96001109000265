import { Button } from '@contraktor-tech/ui';
import React from 'react';
import { MdEdit } from 'react-icons/md';

const AddSignerButton = ({ editMode, onClick }) => {
  const labelText = editMode ? 'Editar assinantes' : 'Cadastrar assinantes';
  const labelIcon = editMode ? <MdEdit className="add-icon" /> : null;

  const label = (
    <span className="button-label">
      { labelIcon }
      { labelText }
    </span>
  );

  return (
    <Button
      className="add-signer-button"
      label={label}
      onClick={onClick}
    />
  );
};

export default AddSignerButton;
