import { Form as SemanticForm } from 'semantic-ui-react';
import styled from 'styled-components';

const Form = styled(SemanticForm)`
  width: 100%;
  max-width: 438px !important;
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  .form-field label {
    font-family: 'Nunito', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(-50%) scale(0.9);
    background-color: white;
    color: rgba(0, 0, 0, 60%);
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
  .icon-password {
    margin: 18px 0 0 -36px;
    position: absolute;
    width: 0;
    height: 0;
    border: 0;
    outline: 0;
    font-size: 0;
    cursor: pointer;
  }
  input,
  .ui.dropdown {
    height: 54px;
  }

  .ui.input {
    display: flex;
  }

  .form-row {
    display: flex;
    padding: 0.6rem 0;

    .form-col {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 0.65rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.col-sm {
        max-width: 30%;
      }
      .form-field {
        position: relative;
      }
    }
  }

  .form-action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    .ui.button.primary {
      width: 440px;
      height: 48px;
      background: #0693dd !important;
      margin: 0;
      font-family: 'Nunito', sans-serif !important;
    }
    .ui.button.primary:disabled,
    button.primary:disabled {
      border-color: transparent !important;
    }
    .last-button {
      background: #ffffff;
      border: 1px solid rgba(31, 169, 240, 0.6);
      color: #0074b1;
      width: 440px;
      height: 48px;
      font-family: 'Nunito', sans-serif !important;
    }
  }

  .helper-text {
    display: flex;
    padding: 0.15rem 1rem;
    font-size: 0.85rem;
  }

  .has-error {
    .validation-error {
      color: #cd2727;
    }
    input, select, .dropdown {
      border-color: #e53935 !important;
    }
  }

  .form-check {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    label {
      margin: 0 0 0 36px;
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      color: #595959;
    }
    input {
      width: 0px;
      height: 0px;
    }
    input[type='checkbox'] {
      position: relative;
      cursor: pointer;
    }
    input[type='checkbox']:before {
      content: '';
      display: block;
      border-radius: 2px;
      border: 1px solid #a5dcf9;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 0;
      background-color: #ffffff;
    }
    input[type='checkbox']:checked:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 0;
      background: #1fa9f0;
    }
    input[type='checkbox']:checked:after {
      content: '';
      display: block;
      width: 8px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 3px;
      left: 6px;
    }

    @media (min-width: 320px) and (max-width: 380px) {
      margin-bottom: 25px;
    }
  }

  .info {
    margin-top: 15px;
    max-width: 440px;
    display: flex;
    text-align: center;

    * {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
    }

    a {
      color: #0074b1;
    }
  }
`;

export default Form;
