import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 251px;
  height: 112px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background: #ffffff;
  border-radius: 6px;
`;

export const Options = styled.div`
  width: 100%;
  display: table;
`;

export const Option = styled.div`
  display: flex;
  padding: 12px 28px 12px 24px;
  width: 100%;
  align-items: center;
  cursor: pointer;

  .option-icon {
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.6);
  }

  .option-label {
    margin-left: 23px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.15px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
  }
`;
