import PropTypes from 'prop-types';
import React from 'react';
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';

import logoHorizontalWhite from '../../assets/img/logo_horiz_colorfull.svg';
import { Logo } from './styles';

const Loader = ({ loading }) => (
  <Dimmer active={loading} inverted>
    <Logo src={logoHorizontalWhite} alt="Contraktor logo" />
    <SemanticLoader active inline="centered" />
  </Dimmer>
);

Loader.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default Loader;
