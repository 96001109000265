/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1em;

  .title {
      font-size: 1.625em;
      font-weight: bold;
      text-align: center;
      color: ${(props) => props.theme.textColor};
      padding: 0.5em 0;
  }

  .icon {
    padding: 0.4em;
  }
  
  .message {
    font-size: 1em;
    text-align: center;
    padding: 0.5em 1em;
  }

  .action-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      padding: 1em 0 0 0;

      > .button {
          margin: 0.8em;
          flex-grow: 1;
          font-size: 1.2em;
          max-width: 215px;
      }

      @media ${(props) => props.theme.breakPoints.small} {
        display: flex;
        flex-direction: column-reverse;

        > .button {
          width: 100%;
          max-width: 100%!important;
          margin: 0.8rem 0!important;
          padding: 1rem 0!important;
        }
      }
  }

  .third-button {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
