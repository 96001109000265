import PropTypes from 'prop-types';
import React from 'react';

const IconChecked = ({ color }) => (
  <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM5.6 10.5L2.1 7L3.087 6.013L5.6 8.519L10.913 3.206L11.9 4.2L5.6 10.5Z" fill={color} />
  </svg>
);

IconChecked.propTypes = {
  color: PropTypes.string.isRequired
};

export default IconChecked;
