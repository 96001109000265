import axios from 'axios';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Input, Grid, Select } from 'semantic-ui-react';

import gatewayApi from '../../_new/services/gateway/config';
import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { useChoosePlan } from '../../../containers/NewPaymentPage/contexts/ChoosePlanContext';
import { useAmplitude } from '../../../hooks/AmplitudeProvider';
import { updatePayment } from '../../../redux/ducks/signUpWizard';
import {
  validateCpfCnpj,
  removeSpecialCharacteres
} from '../../../utils/helpers';
import { cpfCnpjParser } from '../../../utils/parsers';
import states from '../../../utils/states';
import Button from '../../Button';
import FieldErrorMessage from '../../FieldErrorMessage';
import InputWithRef from '../../InputWithRef';
import LabelRequired from '../../LabelRequired';
import { Form, ErrorContainer } from './styles';

const PaymentForm = ({
  formColumns,
  onCloseForm,
  confirmButtonLabel,
  doPayment,
  onPaymentSuccess
}) => {
  const {
    subscription
  } = useAuth();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    setValue,
    setError
  } = useForm({ mode: 'onBlur' });

  const { Amplitude } = useAmplitude();
  const { currentPlanInfo } = useChoosePlan();

  const stateCity = watch('state');
  const city = watch('city');
  const cep = watch('cep');
  const cpfCnpj = watch('document');
  const parsedCep = removeSpecialCharacteres(cep);

  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState({
    error: false,
    message: 'Erro ao efetuar pagamento'
  });
  const [voucherData, setVoucherData] = useState({
    cupom: null,
    loading: false,
    success: false,
    error: false,
    message: ''
  });
  const handeApplyVoucher = useCallback(
    (el, eldata) => {
      if (!eldata || !eldata.value || eldata.value.length < 2) {
        setVoucherData({});
        return;
      }

      const voucher = eldata.value;
      setVoucherData({
        cupom: voucher,
        loading: true,
        success: false,
        error: false,
        message: ''
      });
    }, []
  );

  useEffect(() => {
    register('city');
    register('state');
  }, [register]);

  useEffect(() => {
    const isValid = validateCpfCnpj(cpfCnpj);

    if (cpfCnpj && cpfCnpj.length > 0 && !isValid) {
      setError('document', {
        type: 'notFound',
        message: (
          <FieldErrorMessage message="Documento inválido, insira um CPF ou CNPJ válido." />
        )
      });
    }
  }, [cpfCnpj, setError]);

  useEffect(() => {
    function handleCepLength() {
      if (parsedCep && parsedCep.length >= 8) {
        axios
          .get(`https://viacep.com.br/ws/${parsedCep}/json/`)
          .then(({ data }) => {
            if (!data.erro) {
              const {
                logradouro,
                localidade,
                uf,
                bairro
              } = data;

              setValue('street', logradouro);
              setValue('city', localidade);
              setValue('state', uf.toLowerCase());
              setValue('neighborhood', bairro);
            } else {
              setError('cep', {
                type: 'notFound',
                message: (
                  <FieldErrorMessage message="CEP informado não encontrado." />
                )
              });
            }
          })
          .catch(() => {
            setError('cep', {
              type: 'requestError',
              message: (
                <FieldErrorMessage message="CEP informado não encontrado." />
              )
            });
          });
      }
    }

    handleCepLength();
  }, [parsedCep, setError, setValue]);

  const toggleLoadingTimeout = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  //  subscribe reactivation
  const handleDoPayment = async () => {
    const url = subscription.planName === 'Trial'
      ? '/subscription/subscribe'
      : '/subscription/reactivate';

    try {
      await gatewayApi.post(url, {
        code: voucherData.cupom,
        plan: currentPlanInfo.name,
        additionalFeatures: JSON.parse(localStorage.getItem('additionalFeatures')) || null
      },
      {
        params: {
          frequency: currentPlanInfo.name.includes('Anual')
            ? 'YEARLY'
            : 'MONTHLY'
        }
      });
      onPaymentSuccess();
      onCloseForm();
    } catch (error) {
      if (
        error.response?.data?.message
      ) {
        setPaymentError({
          error: true,
          message: error.response.data.message
        });
      } else {
        setPaymentError({
          error: true,
          message: 'Erro ao efetuar pagamento'
        });
      }
    } finally {
      toggleLoadingTimeout();
    }
  };

  const onSubmit = (data) => {
    const {
      cardNumber,
      cardName,
      cardDueDate,
      cardCvv,
      street,
      neighborhood,
      number,
      compliment,
      document
    } = data;

    const parsedDocument = cpfCnpjParser(document);

    const cardData = {
      cardNumber: cardNumber.replace(/\s/g, ''),
      holderName: cardName,
      securityCode: cardCvv,
      expirationMonth: cardDueDate.split('/')[0],
      expirationYear: cardDueDate.split('/')[1]
    };

    setLoading(true);
    dispatch(updatePayment(data));

    const registerData = {
      holderName: cardData.holderName,
      cardNumber: cardData.cardNumber,
      expirationMonth: cardData.expirationMonth,
      expirationYear: cardData.expirationYear,
      last_4_card_number: cardData.cardNumber.replace(' ', '').slice(11, 15),
      ccv: cardData.securityCode,
      street,
      neighborhood,
      number,
      compliment,
      postCode: parsedCep,
      state: stateCity,
      city,
      document: parsedDocument
    };

    return gatewayApi
      .post('/creditCard', registerData)
      .then(async ({ data: response }) => {
        if (subscription.planName === 'Light') {
          Amplitude.logEvent('checkout_lg_update_card', response);
        } else {
          Amplitude.logEvent('checkout_update_card', response);
        }
        toast.success('Cartão atualizado com sucesso.');
        if (doPayment) {
          await handleDoPayment();
        } else {
          onCloseForm();
        }
      })
      .catch((error) => {
        // toggleLoadingTimeout();
        if (
          error.response?.data?.message
        ) {
          setPaymentError({
            error: true,
            message: error.response.data.message
          });
        } else {
          setPaymentError({
            error: true,
            message: 'Erro ao efetuar pagamento'
          });
        }
      })
      .finally(() => {
        toggleLoadingTimeout();
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Grid columns={formColumns} stackable>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            widescreen={16 / formColumns}
          >
            <LabelRequired text="Número do cartão" />
            <Controller
              control={control}
              name="cardNumber"
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <InputMask
                  {...field}
                  mask="9999 9999 9999 9999"
                  error={!!error}
                />
              )}
            />
            {errors?.cardNumber && errors?.cardNumber.message}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            widescreen={16 / formColumns}
          >
            <InputWithRef
              type="text"
              id="cardName"
              label="Nome no cartão"
              {...register('cardName', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
              required
            >
              {errors?.cardName && errors?.cardName.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={8 / formColumns}
            widescreen={8 / formColumns}
          >
            <LabelRequired text="Data de validade" />
            <Controller
              control={control}
              name="cardDueDate"
              rules={{
                required: true,
                pattern: {
                  value: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/i,
                  message: (
                    <FieldErrorMessage message="A data inserida deve ser válida" />
                  )
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <InputMask {...field} mask="99/9999" error={!!error} />
              )}
            />
            {errors?.cardDueDate && errors?.cardDueDate.message}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={8 / formColumns}
            widescreen={8 / formColumns}
          >
            <InputWithRef
              type="number"
              id="cardCvv"
              label="Código de segurança"
              {...register('cardCvv', {
                required: <FieldErrorMessage message="Campo Obrigatório" />,
                minLength: {
                  value: 3,
                  message: (
                    <FieldErrorMessage message="Insira código de segurança válido" />
                  )
                },
                maxLength: {
                  value: 3,
                  message: (
                    <FieldErrorMessage message="Insira código de segurança válido" />
                  )
                }
              })}
              required
            >
              {errors?.cardCvv && errors?.cardCvv.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            computer={16 / formColumns}
          >
            <InputWithRef
              type="text"
              id="document"
              label="CPF/CNPJ"
              {...register('document', {
                required: <FieldErrorMessage message="Campo Obrigatório" />,
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
                  message: (
                    <FieldErrorMessage message="Insira um CPF ou CNPJ válido" />
                  )
                }
              })}
              required
            >
              {errors?.document && errors?.document.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={8 / formColumns}
            widescreen={8 / formColumns}
          >
            <LabelRequired text="CEP" />
            <Controller
              control={control}
              name="cep"
              rules={{
                required: <FieldErrorMessage message="Campo Obrigatório" />
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <InputMask {...field} mask="99999-999" error={!!error} />
              )}
            />
            {errors?.cep && errors?.cep.message}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={8 / formColumns}
            widescreen={8 / formColumns}
          >
            <InputWithRef
              type="text"
              id="number"
              label="Número"
              {...register('number', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
              required
            >
              {errors?.number && errors?.number.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={8 / formColumns}
            widescreen={8 / formColumns}
          >
            <InputWithRef
              type="text"
              id="compliment"
              {...register('compliment')}
              label="Complemento"
            />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            computer={16 / formColumns}
          >
            <InputWithRef
              type="text"
              id="street"
              label="Rua"
              {...register('street', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
              required
            >
              {errors?.street && errors?.street.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            computer={16 / formColumns}
          >
            <InputWithRef
              type="text"
              id="neighborhood"
              label="Bairro"
              {...register('neighborhood', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
              required
            >
              {errors?.neighborhood && errors?.neighborhood.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            widescreen={16 / formColumns}
          >
            <LabelRequired text="Estado" />
            <Select
              placeholder="Selecione um estado"
              fluid
              search
              value={stateCity || ''}
              onChange={(e, { value }) => {
                setValue('state', value);
                setValue('city', '');
              }}
              {...register('state', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
              options={states.map((s) => ({
                key: s.sigla.toLowerCase(),
                value: s.sigla.toLowerCase(),
                text: s.nome
              }))}
            />
            {errors?.state && errors?.state.message}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            largeScreen={16 / formColumns}
            widescreen={16 / formColumns}
          >
            <LabelRequired text="Cidade" />
            <Select
              value={city || ''}
              onChange={(e, { value }) => setValue('city', value)}
              placeholder={
                !stateCity ? 'Selecione um estado' : 'Selecione uma cidade'
              }
              options={
                (stateCity
                  && states.find((s) => s.sigla.toLowerCase() === stateCity)
                  && states
                    .find((s) => s.sigla.toLowerCase() === stateCity)
                    .cidades.map((c) => ({ key: c, value: c, text: c })))
                || []
              }
              search
              fluid
              {...register('city', {
                required: <FieldErrorMessage message="Campo Obrigatório" />
              })}
            />
            {errors?.city && errors?.city.message}
          </Grid.Column>
          {subscription.planName === 'Trial' && (
            <div className="form-row">
              <div
                className={`form-col ${voucherData.error ? 'has-error' : ''} ${
                  voucherData.success ? 'is-valid' : ''
                }`}
              >
                <span className="input-label" htmlFor="cupom">
                  Cupom de desconto
                </span>
                <Input
                  id="cupom"
                  name="cupom"
                  loading={voucherData.loading}
                  icon={voucherData.success ? 'check' : ''}
                  onChange={debounce(handeApplyVoucher, 400)}
                  defaultValue={voucherData.cupom}
                />
                {!voucherData.error && !voucherData.success && (
                  <span className="helper-text">Opcional</span>
                )}
                {voucherData.error && (
                  <span className="helper-text validation-error">
                    {voucherData.message}
                  </span>
                )}
                {voucherData.success && !voucherData.error && (
                  <span className="helper-text">
                    Cupom aplicado com sucesso!
                  </span>
                )}
              </div>
            </div>
          )}
          <Grid.Column
            verticalAlign="middle"
            mobile={8}
            largeScreen={8}
            widescreen={8}
            textAlign="center"
          >
            <Button link fluid disabled={loading} onClick={onCloseForm}>
              Voltar
            </Button>
          </Grid.Column>
          <Grid.Column
            mobile={8}
            largeScreen={8}
            widescreen={8}
            textAlign="center"
          >
            <Button primary fluid loading={loading} type="submit">
              {confirmButtonLabel}
            </Button>
          </Grid.Column>
          {!loading && paymentError.error && (
            <Grid.Column
              mobile={16}
              largeScreen={16}
              widescreen={16}
              textAlign="center"
            >
              <ErrorContainer>{paymentError.message}</ErrorContainer>
            </Grid.Column>
          )}
        </Grid>
      </div>
    </Form>
  );
};

PaymentForm.defaultProps = {
  formColumns: 2,
  confirmButtonLabel: 'Salvar Informações',
  doPayment: false,
  onPaymentSuccess: () => {}
};

PaymentForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  formColumns: PropTypes.number,
  confirmButtonLabel: PropTypes.string,
  doPayment: PropTypes.bool,
  onPaymentSuccess: PropTypes.func,
  onApplyNewPrice: PropTypes.func.isRequired
};

export default PaymentForm;
