import styled from 'styled-components';

import { resetInput } from '../../../utils/styles';

const Wrapper = styled.div`
  width: 95%;
  padding: 0.25em 0;
  border-bottom: 1px solid ${(props) => props.theme.borderGrey};
  display: flex;
  align-items: center;
  height: 70px;

  > i {
    opacity: 0.5;
    margin-bottom: 0.2em;
  }
`;

const TitleLabel = styled.div`
  width: 100%;
  padding: 1rem 0 0 1.5rem;
  font-weight: 500;
`;

const CustomInput = styled.input`
  ${resetInput}

  width: 100%;
  height: 35px;
  padding: 0 0.5em;
  color: ${(props) => props.theme.fontColor};
  font-size: 18px;
  font-weight: 500;

  ::placeholder {
    color: ${(props) => props.theme.borderGrey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.borderGrey};
  }

  ::-ms-input-placeholder {
    color: ${(props) => props.theme.borderGrey};
  }
`;

export { Wrapper, CustomInput, TitleLabel };
