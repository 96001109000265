import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding-top: ${({ hideTopBanner }) => (hideTopBanner ? '68px' : '108px')};
`;
