import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { setRequested } from '../../../redux/ducks/recoveryPassword';

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorMessage = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.recoveryPassword);

  useEffect(() => () => dispatch(setRequested(false)), [dispatch]);

  return (
    <Wrapper>
      <Message negative>
        <Message.Header>
          Ops, Ocorreu um Erro!
        </Message.Header>
        <p>
          {`${error.data.message}. Por favor solicite novamente a troca de senha `}
          <Link to="/recover_password">clicando aqui</Link>
        </p>
      </Message>
    </Wrapper>
  );
};

export default ErrorMessage;
