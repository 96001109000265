import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-inline: auto;
  max-width: 496px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  height: calc(100% + 50px);
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
`;
