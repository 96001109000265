import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import Button from '../Button';
import Icon3Dots from '../Icon3Dots';
import OptionsMenuItem from './OptionsMenuItem';

const StyledMenuDropdown = styled(Dropdown)`
  background-color: ${(props) => props.theme.backgroundColor};
  line-height: ${(props) => props.theme.lineHeight};

  .item {
    padding: 1em;
    cursor: pointer;
  }

  .item:hover,
  .item:active,
  .item.focus-within {
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }
  &.ui.dropdown .menu {
    right: 0;
    left: initial;
  }
`;

const OptionsButton = styled(Button)`
  &.ui.button {
    transition: border 200ms linear, color 200ms linear;
    background: transparent;
    padding: 0.375em;
    margin: 0;
    border: 0.125em solid transparent;
    &:hover {
      border-color: ${(props) => props.theme.primaryColor};
      border-radius: 0.250em;
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;

const OptionsMenu = ({ children }) => (
  <StyledMenuDropdown
    trigger={(
      <OptionsButton type="button">
        <Icon3Dots />
      </OptionsButton>
    )}
    icon={null}
  >
    <Dropdown.Menu>
      {children}
    </Dropdown.Menu>
  </StyledMenuDropdown>
);

OptionsMenu.propTypes = {
  children: PropTypes.node.isRequired
};

OptionsMenu.Item = OptionsMenuItem;

export default OptionsMenu;
