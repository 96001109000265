import styled from 'styled-components';

import Button from '../../Button';

export const Tabs = styled.div`
  width: 100%;
  align-items: flex-end;
  padding: 0 1em;
  border-bottom: 1px solid #dedede;
  display: flex;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const Tab = styled.button`
  min-width: 11em;
  height: 2.2em;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: ${(props) => (props.active ? '#FFF' : '#F2F2F2')};
  border: 1px solid #dedede;
  border-bottom-width: 0;
  border-radius: 6px 6px 0 0;
  margin-right: 0.5em;
  padding: 0 1em;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.active ? props.theme.primaryColor : props.theme.fontColor)};

  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }

  &:focus {
    outline: none;
  }
`;

export const ActionButton = styled(Button)`
  position: absolute;
  right: 32px;
  top: 27px;
`;

export const HeaderActions = styled.div`
  width: 100%;
  height: 5em;
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;


  > div > button, > div > div {
    margin-left: 1em !important;
  }

  > div > div > i, > div > div {
    color: ${(props) => props.theme.fontColor} !important;
  }

  > div > button > i {
    color: ${(props) => props.theme.fontColor} !important;
  }
`;
