import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import useQueryString from '../../hooks/useQueryString';
import { fetchSigners as onFetchSigners } from '../../redux/ducks/signers';
import api from '../../utils/backend';
import Paginator from '../Paginator';
import Columns from './Columns';
import { TableWrapper, PaginatorWrapper } from './styles';
import TableHeader from './TableHeader';
import TableItem from './TableItem';

const columns = [];

columns.push({
  key: 'type',
  title: 'Tipo',
  width: '8%',
  component: Columns.Type,
  highlight: true
});
columns.push({
  key: 'name',
  title: 'Nome',
  width: '30%',
  component: Columns.Name,
  center: false
});
columns.push({
  key: 'email',
  title: 'Email',
  width: '12%',
  component: Columns.Email,
  center: false
});
columns.push({
  key: 'document',
  title: 'CPF/CNPJ',
  width: '10%',
  component: Columns.Document,
  center: false
});
columns.push({
  key: 'whatsapp',
  title: 'Whatsapp',
  width: '10%',
  component: Columns.Whatsapp,
  center: false
});
columns.push({
  key: 'actions',
  title: '',
  width: '1%',
  component: Columns.Actions,
  center: false
});

const SignersList = () => {
  const dispatch = useDispatch();
  const { signersList, pagination } = useSelector((state) => state.signers);
  const [loading, setLoading] = useState(true);
  const { query } = useQueryString();

  useEffect(() => {
    async function fetchSigners() {
      try {
        const { data } = await api.get('/persons', { params: query });
        dispatch(onFetchSigners(data));
      } catch (err) {
        throw new Error('Error: on fetch signers');
      } finally {
        setLoading(false);
      }
    }

    fetchSigners();
  }, [query, dispatch]);

  return (
    <>
      <TableHeader columns={columns} />
      {signersList && signersList.length === 0 && (
        <Loader active={loading} />
      )}
      <TableWrapper>
        {signersList
          && signersList.map((signer) => (
            <TableItem
              key={signer.id}
              signer={signer}
              columns={columns}
            />
          ))}
      </TableWrapper>
      <PaginatorWrapper>
        <Paginator pagination={pagination} />
      </PaginatorWrapper>
    </>
  );
};

export default SignersList;
