/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {
  useCallback, useState, useEffect
} from 'react';
import { useForm } from 'react-hook-form';

import hidePwdImg from '../../../../../assets/icons/visibilityHide.svg';
import showPwdImg from '../../../../../assets/icons/visibilityShow.svg';
import Button from '../../../../../components/Button';
import NewInputWithRef from '../../../../../components/NewInputWithRef';
import { useMixpanel } from '../../../../../hooks/MixpanelProvider';
import { SUPERADMIN_URL } from '../../../../../utils/constants';
import { emailRegex } from '../../../../../utils/parsers';
import Form from '../../../components/Form';
import { AlreadyExistAccount, RecoveryButton } from '../../styles';

const validates = {
  minLength: {
    value: 2,
    message: (
      <span className="helper-text validation-error">
        Insira no mínimo 2 letras
      </span>
    )
  },
  maxLength: {
    value: 80,
    message: (
      <span className="helper-text validation-error">
        Insira no máximo 80 letras
      </span>
    )
  },
  required: (
    <span className="helper-text validation-error">Campo Obrigatório</span>
  )
};

const RegisterSteps = ({ onSave, validationError, prevData }) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: prevData ? {
      firstName: prevData.firstName,
      lastName: prevData.lastName,
      email: prevData.email
    } : {}
  });

  const { isValid, errors } = formState;

  const { Mixpanel } = useMixpanel();

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateEmail = async (value) => {
    try {
      const data = {
        email: value,
        source: 'LPST'
      };

      const { data: { valid } } = await axios.post(`${SUPERADMIN_URL()}/utils/email_validation`, data);

      return valid;
    } catch (error) {
      // throw new Error('Erro na validação do email');
      return false;
    }
  };

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    data.email = data.email.trim().toLowerCase();

    const isValidEmail = await validateEmail(data.email);
    if (isValidEmail) {
      setLoading(false);
      onSave(data);
    } else {
      setLoading(false);
      setError('email', 'invalid');
    }
    Mixpanel.track('register_button_avancar');
  }, [onSave, setError, Mixpanel]);

  useEffect(() => {
    if (validationError) {
      const { data } = validationError.response;
      if (!data.errors) return;

      Object.entries(data.errors).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((message) => {
            if (key === 'email' && message === 'has already been taken') {
              setError('email', 'requestError');
            }
          });
        }
      });
    }
  // eslint-disable-next-line
  }, [validationError])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-col">
          <div className="form-field">
            <NewInputWithRef
              type="text"
              id="firstName"
              name="firstName"
              label="Nome"
              {...register('firstName', {
                required: validates.required,
                validate: {
                  minLength: (value) => value.replace(/\s/g, '').length >= 2,
                  maxLength: (value) => value.replace(/\s/g, '').length <= 80
                }
              })}
              required
              error={!!errors?.firstName}
              showRequiredLabel={false}
              onChange={(e) => {
                setValue('firstName', e.target.value);
                Mixpanel.track('register_input_nome', { value: e.target.value });
              }}
            >
              {errors?.firstName
                && errors?.firstName.type === 'minLength'
                && validates.minLength.message}
              {errors?.firstName
                && errors?.firstName.type === 'maxLength'
                && validates.maxLength.message}
              {errors?.firstName && errors?.firstName.message}
            </NewInputWithRef>
          </div>
        </div>
        <div className="form-col">
          <div className="form-field">
            <NewInputWithRef
              type="text"
              id="lastName"
              label="Sobrenome"
              {...register('lastName', {
                required: validates.required,
                validate: {
                  minLength: (value) => value.replace(/\s/g, '').length >= 2,
                  maxLength: (value) => value.replace(/\s/g, '').length <= 80
                }
              })}
              required
              error={!!errors?.lastName}
              showRequiredLabel={false}
              onChange={(e) => {
                setValue('lastName', e.target.value);
                Mixpanel.track('register_input_sobrenome', { value: e.target.value });
              }}
            >
              {errors?.lastName
                && errors?.lastName.type === 'minLength'
                && validates.minLength.message}
              {errors?.lastName
                && errors?.lastName.type === 'maxLength'
                && validates.maxLength.message}
              {errors?.lastName && errors?.lastName.message}
            </NewInputWithRef>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className="form-field">
            <NewInputWithRef
              type="email"
              id="email"
              label="Email"
              {...register('email', {
                required: validates.required,
                validate: (value) => {
                  return emailRegex.test(value);
                }
              })}
              error={!!errors?.email}
              showRequiredLabel={false}
              onChange={(e) => {
                setValue('email', e.target.value);
                Mixpanel.track('register_input_email', { value: e.target.value });
              }}
            >
              {errors?.email
              && errors?.email.type === 'requestError'
              && (
                <AlreadyExistAccount>
                  <span className="helper-text validation-error">
                    Email já cadastrado no sistema.
                  </span>
                  <RecoveryButton to="/recover_password">Clique aqui</RecoveryButton>
                  <span className="helper-text validation-error">
                    para redefinir sua senha
                  </span>
                </AlreadyExistAccount>
              )}
              {errors?.email && errors?.email.message}
              {errors?.email && !errors?.email.message && errors?.email.type !== 'requestError' && (
                <span className="helper-text validation-error">
                  E-mail inválido. Por favor, verifique.
                </span>
              )}
            </NewInputWithRef>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className="form-field">
            <NewInputWithRef
              id="password"
              label="Crie uma senha"
              type={isRevealPwd ? 'text' : 'password'}
              {...register('password', {
                required: validates.required,
                minLength: {
                  value: 8,
                  message: () => (
                    <span className="helper-text validation-error">
                      A senha deve possuir no mínimo 8 caracteres
                    </span>
                  )
                }
              })}
              error={!!errors?.password}
              showRequiredLabel={false}
              onChange={(e) => {
                setValue('password', e.target.value);
                Mixpanel.track('register_input_senha', { value: e.target.value });
              }}
            >
              <button
                className="icon-password"
                type="button"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              >
                <img
                  src={isRevealPwd ? showPwdImg : hidePwdImg}
                  alt="Password"
                />
              </button>
              {errors?.password && errors?.password.message()}
            </NewInputWithRef>
          </div>
        </div>
      </div>

      <div className="form-action">
        <Button primary type="submit" disabled={!isValid || loading} loading={loading}>
          Avançar
        </Button>
      </div>
    </Form>
  );
};

export default RegisterSteps;
