import { TextField } from '@mui/material';
import React from 'react';
import InputMask from 'react-input-mask';

export default function CepField({
  props,
  error,
  helperText
}) {
  return (
    <InputMask
      {...props}
      mask="99999-999"
      maskChar=" "
    >
      {() => (
        <TextField
          {...props}
          fullWidth
          label="CEP"
          variant="outlined"
          error={error}
          helperText={helperText}
        />
      )}
    </InputMask>
  );
}
