import {
  Header, IconButton, Icon, NavDrawer, Divider, List, ListItemText
} from '@contraktor-tech/ui';
import React, { useState } from 'react';
import {
  NavLink, useHistory, useLocation
} from 'react-router-dom';

import logoLettersWhite from '../../../../../../assets/img/logo_letters_white.svg';
import { useAuth } from '../../../../../../containers/AuthContext/AuthContext';
import UserAvatar from '../../../../../Avatar/UserAvatar';
import HelpOnboardingVideo from '../../../../../HelpOnboardingVideo';
import { DrawerHeader, ListItem } from './styles';

const drawerList = [
  { title: 'Documentos', path: '/documents' },
  { title: 'Pessoas', path: '/signers' },
  { component: <Divider /> },
  { title: 'Meu Perfil', path: '/profile' },
  { title: 'Sair', path: '/logout' }
];

const MobileMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const iconByDrawerState = openDrawer ? 'close' : 'menu';

  const userFullName = `${user.firstName} ${user.lastName}`;
  const isCurrentPath = (path) => location.pathname === path;

  return (
    <Header
      appBarPosition="static"
      appBarColorType="primary"
      toolbarType="regular"
      disableGutters={false}
    >
      <NavLink to="/">
        <img id="logo" src={logoLettersWhite} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
      </NavLink>
      <div>
        <HelpOnboardingVideo
          trigger={(
            <IconButton>
              <Icon className="icon-button--help-icon">help_outline</Icon>
            </IconButton>
          )}
        />
        <IconButton onClick={handleOpenDrawer}>
          <Icon className="menu-icon">
            {iconByDrawerState}
          </Icon>
        </IconButton>
      </div>
      <NavDrawer
        onClose={handleCloseDrawer}
        open={openDrawer}
        anchor="right"
        variant="temporary"
      >
        <DrawerHeader>
          <div className="avatar-container">
            <UserAvatar user={user} />
          </div>
          <div className="info-container">
            <p className="headline-6 grey">{userFullName}</p>
            <p className="body2 grey">{user.email}</p>
          </div>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          {drawerList.map((item) => item.component || (
          <ListItem
            key={item.path}
            button
            onClick={() => history.push(item.path)}
            selected={isCurrentPath(item.path)}
          >
            <ListItemText primary={item.title} />
          </ListItem>
          ))}
        </List>
      </NavDrawer>
    </Header>
  );
};

export default MobileMenu;
