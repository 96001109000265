import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FlexField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
