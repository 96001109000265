import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const PaginatorWrapper = styled.div`
  width: 100%;
  height: 19%;
  display: flex;
  align-items: flex-end;
`;
