import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1em;
  height: 100%;
  position: relative;
  background: #fff;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;
