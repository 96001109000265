// Action types

export const TOGGLE_LOADING = '@contraktor/global/TOGGLE_LOADING';
export const TOGGLE_PAYMENT_SUCCESS_MODAL = '@contraktor/global/TOGGLE_PAYMENT_SUCCESS_MODAL';

// Reducer

export const INITIAL_STATE = {
  loading: false,
  paymentSuccessModal: false
};

export default function global(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading
      };

    case TOGGLE_PAYMENT_SUCCESS_MODAL:
      return {
        ...state,
        paymentSuccessModal: action.paymentSuccessModal
      };
    default:
      return state;
  }
}

// Actions

export function toggleLoading(loading) {
  return {
    type: TOGGLE_LOADING,
    loading
  };
}

export function togglePaymentSuccessModal(paymentSuccessModal) {
  return {
    type: TOGGLE_PAYMENT_SUCCESS_MODAL,
    paymentSuccessModal
  };
}
