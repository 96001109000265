import { styled as muiStyled, Typography } from '@mui/material';
import styled from 'styled-components';

import { CustomContainer } from '../../_shared/styles';
import themes from '../../_utils/themes';

export const MainContainer = muiStyled(CustomContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '48px',
  height: 'fit-content',
  marginBottom: '50px',
  maxWidth: '100%!important'
});

export const Header = muiStyled(CustomContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '48px',
  padding: '0',
  marginTop: '50px'
});

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

export const Footer = muiStyled(Typography)(() => ({
  fontFamily: themes.fonts.family.Poppins,
  fontSize: themes.fonts.size.small,
  color: themes.fonts.color.secundaryBlue,
  fontWeight: themes.fonts.weight.thin,
  width: '100%',
  marginBottom: '50px',
  textAlign: 'center'
}));
