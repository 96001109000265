import PropTypes from 'prop-types';
import React from 'react';
import { FaCheck as FaCheckIcon } from 'react-icons/fa';

import { Wrapper, StepsContent, Step } from './styles';

const Steps = ({ step }) => (
  <Wrapper>
    <StepsContent>
      <Step active={step === 1} complete={step > 1}>
        <span>{step > 1 ? (<FaCheckIcon />) : '1'}</span>
        <p>Cadastro</p>
      </Step>
      <Step active={step === 2} complete={false}>
        <span>{step > 2 ? (<FaCheckIcon />) : '2'}</span>
        <p>Informações adicionais</p>
      </Step>
    </StepsContent>
  </Wrapper>
);

Steps.defaultProps = {
  step: 1
};

Steps.propTypes = {
  step: PropTypes.number
};

export default Steps;
