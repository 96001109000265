/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useChoosePlan } from '../../../containers/NewPaymentPage/contexts/ChoosePlanContext';
import Header from '../../Header';
import gatewayApi from '../services/gateway/config';
import PlanComponent from './shared/PlanComponent';
import UpsellModal from './shared/UpsellModal';
import * as S from './styles';

function UpgradePlanGatewayMigration() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { monthlyPlans, currentPlanInfo } = useChoosePlan();
  const location = useLocation();

  const upsellPlan = useCallback(async () => {
    setLoading(true);
    await gatewayApi.put('/subscription/upsell', { code: null }, {
      params: {
        frequency: 'MONTHLY'
      }
    })
      .then(() => {
        setOpenModal(false);
        setLoading(false);
        toast.success('Boas-vindas ao plano Unlimited! 🥳');
      })
      .catch(() => {
        setOpenModal(false);
        setLoading(false);
        toast.error('Ocorreu uma falha ao realizar a contratação do novo plano. Tente novamente.');
      });
  }, []);

  const handlePlanList = () => {
    let list = monthlyPlans;
    if (currentPlanInfo.planName === 'Unlimited') {
      list = monthlyPlans.filter((plan) => plan.planName === 'Unlimited');
    }

    return list;
  };

  const filteredList = handlePlanList();

  return (
    <>
      {location.pathname !== '/register/choose-plan' && <Header options />}
      <S.Wrapper>
        <S.Container>
          <div className="choose-plan-container">
            <h1 className="choose-plan-message">
              Confira nossos planos e descontos <span role="img" aria-label="emoji">🤑</span>
            </h1>
          </div>
          <div className="plans-container">
            <UpsellModal
              open={openModal}
              loading={loading}
              onClose={() => setOpenModal(false)}
              onUpsell={upsellPlan}
            />
            {filteredList.map((plan) => (
              <PlanComponent
                key={plan.planName}
                plan={plan}
                currentPlan={currentPlanInfo}
                onChoosePlan={() => setOpenModal(true)}
                withFooter
              />
            ))}
          </div>
        </S.Container>
      </S.Wrapper>
      <div
        style={{
          position: 'absolute',
          top: '19.5882%',
          left: '84.22222%',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          color: '#ffffff',
          fontFamily: 'Nunito',
          fontSize: '0.875rem'
        }}
        onClick={() => {
          window.location.href = '/update-payment-info';
        }}
      >
        <BsArrowRightShort style={{ color: '#ffffff', width: '20px', height: '20px' }} />
        <span>Não escolher agora</span>
      </div>
    </>
  );
}

export default UpgradePlanGatewayMigration;
