import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 76px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  .header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    width: 44.8020%;
    height: 76px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .logo-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 41.9890%;
      height: 76px;
      flex: none;
      order: 0;
      flex-grow: 0;

      img {
        width: 3.375rem;
      }
    }

    .plan-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-family: 'Nunito';

      .title {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.75rem;
        letter-spacing: 0.0156rem;
      }

      .subtitle {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.0187rem;
      }
    }
  }
`;

export const Text = styled.div`
  width: 100%;
  font-family: Nunito;
  text-align: center;
`;

export const TextTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  margin-top: 11px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TextBody = styled.div`
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.15px;
  p {
    margin-bottom: 16px;
    margin-top: 16px;
    b {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

export const ButtonText = styled.div`
  > span {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.0313rem;
  }

  .button-text-tiny {
    font-weight: 400;
  }

  .button-text-normal {
    font-weight: 500;
  }

  .button-text-bold {
    font-weight: 600
  }

  .button-text-blue {
    color: #346AF7;
  }

  .button-text-white {
    color: #ffffff
  }
`;

export const Footer = styled.div`
  padding: 0 24px 24px 24px;
  width: 100%;
  display: table;
  clear: both;

  .tax-info-button, .tax-info-button:hover {
    width: 100%;
    box-shadow: none;
    background: rgba(52, 106, 247, 0.12);
    color: #346AF7;
    cursor: unset;
  }
`;

export const FooterActions = styled.div`
  display: table;
  width: 100%;
  
  .action-button, .action-button:hover {
    width: 97.42%;
    display: flow-root;
    padding: 16px 0 16px 0;
    box-shadow: none;
  }

  .back-button, .back-button:hover {
    float: left;
    background: #ffffff;
    border: 1px solid #346AF7;
  }
  
  .create-payment-button, .create-payment-button:hover {
    float: right;
    background: #346AF7;
  }
`;
