import React from 'react';

import themes from '../../../_utils/themes';

export const plans = [
  {
    name: 'Free',
    code: 'Freemium',
    description: 'Continue enviando documentos gratuitamente',
    price: {
      monthly: 0.00,
      yearly: 0.00,
      installment: 0.00
    },
    discount: {
      monthly: false,
      yearly: false,
      installment: false
    },
    discountPercent: {
      monthly: null,
      yearly: null,
      installment: null
    },
    features: [
      '05 envios de documentos por mês',
      'Upload máximo de documentos de 5 MB',
      '15 dias para assinar documentos',
      '1 usuário',
      <><b>30 dias</b> de armazenamento após criar cada documento</>
    ],
    notFeatures: [
      'Acompanhe o status dos seus documentos',
      'Gestão básica de documentos',
      'Seu logotipo nos e-mails',
      'Envio via WhatsApp'
    ],
    styles: {
      fontFamily: themes.fonts.family.Poppins,
      fontColor: themes.fonts.color.primary,
      backgroundColor: themes.background.light,
      buttonTheme: 'primary',
      border: `1px solid ${themes.pallete.primaryLight}`,
      boxShadow: 'none'
    }
  },
  {
    name: 'Light',
    code: 'Light',
    description: 'Gestão simples de documentos por um preço acessível',
    paymentDetails: {
      monthly: 'Assinatura mensal sem fidelidade.',
      yearly: 'Pagamento à vista de R$322,8*',
      installment: 'Cobrado mensalmente por 12 meses.*'
    },
    price: {
      monthly: 33.90,
      yearly: 406.80,
      installment: 406.80
    },
    discount: {
      monthly: false,
      yearly: true,
      installment: true
    },
    discountPercent: {
      monthly: 0,
      yearly: 0.206,
      installment: 0.118
    },
    features: [
      '15 envios de documentos por mês',
      'Upload máximo de documentos de 20 MB',
      'Tempo para assinar documento ilimitado',
      '6 Usuários',
      'Acompanhe o status dos seus documentos',
      <><b>500 MB</b> de armazenamento em nuvem</>,
      'Envio via WhatsApp (consultar valores)'
    ],
    notFeatures: [],
    styles: {
      fontFamily: themes.fonts.family.Poppins,
      fontColor: themes.fonts.color.primary,
      backgroundColor: themes.background.lightBlue,
      buttonTheme: 'primary',
      border: 'none'
    }
  },
  {
    name: 'Essential',
    code: 'Essential',
    description: 'O equilíbrio perfeito entre recursos e preço',
    paymentDetails: {
      monthly: 'Assinatura mensal sem fidelidade.',
      yearly: 'Pagamento à vista de R$538,92*',
      installment: 'Cobrado mensalmente por 12 meses.*'
    },
    tag: 'Novidade! 🤩',
    price: {
      monthly: 49.90,
      yearly: 598.80,
      installment: 598.80
    },
    discount: {
      monthly: false,
      yearly: true,
      installment: true
    },
    discountPercent: {
      monthly: 0,
      yearly: 0.10,
      installment: 0.05
    },
    features: [
      '50 envios de documentos por mês',
      'Upload máximo de documentos de 20 MB',
      'Tempo para assinar documento ilimitado',
      '10 Usuários',
      'Acompanhe o status dos seus documentos',
      <><b>1 GB</b> de armazenamento em nuvem</>,
      'Gestão básica de documentos',
      'Seu logotipo nos e-mails',
      <><b>NOVIDADE!</b> 05 Envios via WhatsApp</>
    ],
    notFeatures: [],
    styles: {
      fontFamily: themes.fonts.family.Poppins,
      fontColor: themes.fonts.color.white,
      fontColorTag: themes.fonts.color.darkBlue,
      backgroundColorTag: themes.background.secoundBlue,
      backgroundColor: themes.background.darkBlue,
      buttonTheme: 'white',
      border: 'none',
      boxShadow: 'none'
    }
  },
  {
    name: 'Unlimited',
    code: 'Unlimited',
    description: 'O mais vendido com recursos essenciais de gestão e controle',
    paymentDetails: {
      monthly: 'Assinatura mensal sem fidelidade.',
      yearly: 'Pagamento à vista de R$1.618,92*',
      installment: 'Cobrado mensalmente por 12 meses.*'
    },
    tag: 'Mais vendido 💙',
    price: {
      monthly: 149.90,
      yearly: 1798.80,
      installment: 1798.80
    },
    discount: {
      monthly: false,
      yearly: true,
      installment: true
    },
    discountPercent: {
      monthly: 0,
      yearly: 0.10,
      installment: 0.05
    },
    features: [
      'Envie documentos ilimitadamente',
      'Upload máximo de documentos de 20 MB',
      'Tempo para assinar documento ilimitado',
      'Usuários ilimitados',
      'Acompanhe o status dos seus documentos',
      <><b>2 GB</b> de armazenamento em nuvem</>,
      'Gestão básica de documentos',
      'Seu logotipo nos e-mails',
      <><b>NOVIDADE!</b> 15 Envios via WhatsApp</>
    ],
    notFeatures: [],
    styles: {
      fontFamily: themes.fonts.family.Poppins,
      fontColor: themes.fonts.color.white,
      fontColorTag: themes.fonts.color.brown,
      backgroundColorTag: themes.background.primaryOrange,
      backgroundColor: themes.background.primaryBlue,
      buttonTheme: 'white',
      border: 'none',
      boxShadow: 'none'
    }
  },
  {
    name: 'Avançados',
    code: 'Advanced',
    description: 'Planos personalizados com assinatura digital e gestão completa de documentos',
    paymentDetails: {
      monthly: 'Assinatura mensal sem fidelidade.',
      yearly: 'Pagamento à vista de R$6.480,00*',
      installment: 'Cobrado mensalmente por 12 meses.*'
    },
    price: {
      monthly: 540.00,
      yearly: 6480.00,
      installment: 6480.00
    },
    discount: {
      monthly: false,
      yearly: false,
      installment: false
    },
    discountPercent: {
      monthly: 0,
      yearly: 0,
      installment: 0
    },
    features: [
      'Automação completa de documentos, prazos, tarefas e vigência',
      'Fluxo de aprovação de documentos',
      'Formulário customizável para preenchimento de documentos',
      'Assinatura híbrida, com ou sem certificado digital',
      'Ferramenta de elaboração de documentos e minutas, com biblioteca de modelos prontos',
      'E muito mais...'
    ],
    notFeatures: [],
    styles: {
      fontFamily: themes.fonts.family.Poppins,
      fontColor: themes.fonts.color.secondaryDark,
      backgroundColor: themes.background.neutralGrey,
      buttonTheme: 'secondaryDark',
      border: 'none',
      boxShadow: 'none'
    }
  }
];
