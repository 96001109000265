import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #0C0440;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
`;

export const ProgressIndicator = styled.div`
  width: 100%;
  .progress-bar {
    border: none;
    height: 4px;
  }
  .bar-container {
    background-color: #ffffff;
  }
  .bar-completed {
    background-color: #346AF7;
    width: 100%;
  }
  .progress-label {
    visibility: hidden;
  }
`;

export const CloseModalButton = styled.div`
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 29.41px;
  margin-right: 29.41px;

  #close-modal-button {
    width: 24.8px;
    height: 24.8px;
    cursor: pointer;
  }
`;

export const HeaderImage = styled.div`
  padding: 24px 0 24px 0;
`;

export const HeaderTitle = styled.div`
  font-family: Poppins;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  padding: 24px 24px 24px 24px;
`;

export const SubTitle = styled.div`
  text-transform : uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  opacity: 0.6;
  margin-bottom: 4px;
`;

export const MainTitle = styled.div`
  display: flow-root;
  font-size: 20px;
  letter-spacing: 0.25px;
`;

export const StepCounter = styled.div`
  float: right;
`;

export const SubHeader = styled.div`
  width: 100%;
  height: 56px;
  background-color: #346AF7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubHeaderText = styled.div`
  color: #ffffff;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
`;

export const SelectorContainer = styled.div`
  width: 100%;
  display: flow-root;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  
  Button {
    padding: 16px 0 16px 0;
    display: flex;
    align-items: center;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .button-label {
      display: flex;
      align-items: center;
    }

    .icon-active {
      margin-right: 12px;
      width: 19px;
      height: 19px;
      color: #ffffff;
    }
    .icon-normal {
      margin-right: 12px;
      width: 19px;
      height: 19px;
      color: #ffffff;
    }
  }

  .type-button-active, .type-button-active:hover {
    background: rgba(255, 255, 255, 0.12);
    box-shadow: none;
    color: #ffffff;
    opacity: 1;
    border-bottom: #346AF7 solid 4px;
  }

  .type-button-normal, .type-button-normal:hover {
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    opacity: 0.48;
    color: #ffffff;
  }
`;
