/* eslint-disable jsx-a11y/accessible-emoji */
import { Button, Paper } from '@contraktor-tech/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import Vector from './Vector';

const TrashBlockedCard = () => {
  const history = useHistory();

  return (
    <Paper style={{ padding: 24 }}>
      <S.Vector>
        <Vector />
      </S.Vector>
      <S.Title>
        Perdeu algum documento? 😢
      </S.Title>
      <S.Text>
        Quer continuar usando todas  as funcionalidades da nossa plataforma?
      </S.Text>

      <Button label="Saiba mais" disableElevation fullWidth onClick={() => history.push('/plans')} />
    </Paper>
  );
};

export default TrashBlockedCard;
