/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '../Avatar';
import { AvatarText } from './styles';

const UserAvatar = ({
  user,
  ...props
}) => (
  <Avatar
    color={user.id}
    popup={user.name}
    {...props}
  >
    <AvatarText>
      {`${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`}
    </AvatarText>
  </Avatar>
);

UserAvatar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default UserAvatar;
