import styled from 'styled-components';

import { plansListData } from '../../../../utils/plans';

// eslint-disable-next-line max-len
const plansTheme = (plan) => {
  if (plan === 'Trial') {
    return plansListData.find((p) => p.id === 'unlimited');
  }

  return plansListData.find((p) => p.id === plan.toLowerCase()) || plansListData[0];
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .divider {
    display: flex;

    width: 1px;
    height: 100%;

    background: rgba(0, 0, 0, 0.12);
  }

  @media ${({ theme }) => theme.breakPoints.medium} {
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin: 24px;
  width: 100%;
  max-width: 370px;

  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border-radius: 8px;

  &.info {
    min-width: 240px;
  }

  .header,
  .content {
    width: 100%;
    padding: 16px;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;

    border-bottom: 1px solid rgba(52, 106, 247, 0.3);

    .title {
      font-size: 20px;
      font-weight: 600;
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  .content {
    p {
      text-align: left;
      font-size: 12px;
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  .benefits-list {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      display: flex;
      align-items: center;

      margin-bottom: 20px;

      p {
        margin: 0;
        padding-left: 12px;

        font-size: 14px;
      }
    }
  }
`;

export const Benefits = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  margin: 16px 0 0 0;
  padding: 0;

  li {
    display: flex;
    align-items: flex-start;

    margin: 8px 0;

    span {
      display: flex;
      flex-shrink: 0;
      
      width: 14px;
      height: 14px;

      color: #346BF7;
    }

    p {
      padding-left: 13px;

      color: #346BF7;
      font-size: 14px!important;
      line-height: 16px;

      span {
        color: #5E5E62!important;
      }
      
      b {
        font-size: 16px;
      }
    }
  }
`;

export const Color = styled.span`
  display: flex;
  flex-shrink: 0;

  width: 32px;
  height: 32px;
  margin-right: 12px;

  border-radius: 8px;

  background-color: ${({ plan }) => plansTheme(plan).bgColor};

  ${({ plan }) => plan === 'Freemium' && `
    border: 2px solid rgba(52, 106, 247, 0.3);
    background-color: transparent !important;
  `}
`;

export const Status = styled.span`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 10;

  display: flex;
  align-items: center;

  padding: 2px 8px;
  height: 24px;

  font-size: 10px;
  color: #fff;

  background: ${({ active, theme }) => (active ? theme.green : theme.errorColor)};
  border-radius: 4px;
`;
