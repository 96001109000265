import Check from '@mui/icons-material/Check';
import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

import {
  CompletedStep,
  CustomConnector,
  CustomStep,
  Label
} from './styles';

export default function Steps({ steps, activeStep }) {
  const StepComponent = (props) => {
    const {
      active,
      completed,
      icon
    } = props;

    if (completed) {
      return (
        <CompletedStep>
          <Check className="CompleteIcon" />
        </CompletedStep>
      );
    }

    return <CustomStep active={active}>{icon}</CustomStep>;
  };

  return (
    <Stepper style={{ width: '100%' }} alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
      {
        steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={StepComponent}
              >
                <Label active={activeStep === index}>
                  {label}
                </Label>
              </StepLabel>
            </Step>
          );
        })
      }
    </Stepper>
  );
}
