import React from 'react';

import CustomButtonGroup from '../../../../_components/ButtonGroup';
import SavingInfo from '../../../../../_legacy/assets/img/desconto.svg';
import { Wrapper, Flag } from './styles';

export default function PaymentTypeSelector({ selectedOption, setSelectedOption, options }) {
  return (
    <Wrapper>
      <Flag>
        <img src={SavingInfo} alt="saving info" />
      </Flag>
      <CustomButtonGroup
        selectedOption={selectedOption}
        setActiveOption={setSelectedOption}
        options={options}
      />
    </Wrapper>
  );
}
