import PropTypes from 'prop-types';
import React from 'react';

import Avatar from '../Avatar';
import {
  StackList,
  StackItem,
  CollapsedItem,
  CollapsedList
} from './styles';

const AvatarStack = ({
  items,
  max,
  size
}) => {
  if (max && (max < 2)) {
    throw new Error('Max cannot be less than 2!');
  }

  if (!max || items.length <= max) {
    return (
      <StackList>
        {items.map((item) => (
          <StackItem key={item.key} size={size}>
            {React.cloneElement(item.avatar, { size, bordered: true })}
          </StackItem>
        ))}
      </StackList>
    );
  }

  const firstItems = items.slice(0, max - 1);
  const collapsedItems = items.slice(max - 1, items.length);
  return (
    <StackList>
      {firstItems.map((item) => (
        <StackItem key={item.key} size={size}>
          {React.cloneElement(item.avatar, { size, bordered: true })}
        </StackItem>
      ))}
      <StackItem size={size}>
        <Avatar
          size={size}
          bordered
          popup={(
            <CollapsedList>
              {collapsedItems.map((item) => (
                <CollapsedItem key={item.key}>
                  {item.text}
                </CollapsedItem>
              ))}
            </CollapsedList>
          )}
        >
          +{collapsedItems.length}
        </Avatar>
      </StackItem>
    </StackList>
  );
};

AvatarStack.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    avatar: PropTypes.node.isRequired,
    text: PropTypes.node.isRequired
  })).isRequired,
  max: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired
};

export default AvatarStack;
