/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Popup } from 'semantic-ui-react';

import { AvatarWrapper, DescriptionWrapper } from './styles';

const Avatar = ({
  children,
  description,
  popup,
  size,
  responsive,
  color,
  bordered,
  faded
}) => {
  let createRef = useRef(null);

  let avatar = (
    <AvatarWrapper
      innerRef={(ref) => { createRef = ref; }}
      ref={createRef}
      size={size}
      responsive={responsive}
      color={color}
      bordered={bordered}
      faded={faded}
    >
      {React.Children.toArray(children)}
    </AvatarWrapper>
  );

  if (popup) {
    avatar = (
      <Popup
        trigger={avatar}
        content={popup}
        inverted
      />
    );
  }

  if (description) {
    avatar = (
      <DescriptionWrapper>
        {avatar}
        <span>{description}</span>
      </DescriptionWrapper>
    );
  }

  return avatar;
};

Avatar.propTypes = {
  children: PropTypes.node,
  description: PropTypes.node,
  popup: PropTypes.node,
  size: PropTypes.string,
  responsive: PropTypes.bool,
  color: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  bordered: PropTypes.bool,
  faded: PropTypes.bool
};

export default Avatar;
