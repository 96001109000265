/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { Button } from '@contraktor-tech/ui';
import currency from 'currency.js';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { IoMdWallet } from 'react-icons/io';
import { IoAdd } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import { useDocument } from '../../../../hooks/_new/useDocument';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import api from '../../../../utils/backend';
import ckPayImage from '../../assets/payment_list_icon.svg';
import { checkIfHasAccount, createAccount } from '../../services/api/adapters/account';
import { listPaymentsByDocumentId, removePayment } from '../../services/api/adapters/payment';
import { SidebarFooter } from '../../Sidebar';
import ModalCreatePayment from '../ModalCreatePayment/ModalCreatePayment';
import ModalDocumentId from '../ModalDocumentId';
import ModalEditPayment from '../ModalEditPayment/ModalEditPayment';
import ModalPaymentInfo from '../ModalPaymentInfo/ModalPaymentInfo';
import ModalPaymentLimit from '../ModalPaymentLimit';
import ContactOrganizationOwnerModal from './ContactOrganizationOwnerModal';
import ContextMenu from './ContextMenu/ContextMenu';
import DeleteInfoPopover from './DeleteInfoPopover/DeleteInfoPopover';
import * as S from './styles';

const PaymentList = () => {
  const dontShowPaymentInfo = localStorage.getItem('dontShowPaymentInfo');
  const { id } = useParams();
  const { user } = useAuth();
  const { Mixpanel } = useMixpanel();
  const { isOwner } = user;
  const { documentData: { data: { status } } } = useDocument();
  const [paymentFormModalOpen, setPaymentFormModalOpen] = useState(false);
  const [documentIdModalOpen, setDocumentIdModalOpen] = useState(false);
  const [paymentInfoModalOpen, setPaymentInfoModalOpen] = useState(false);
  const [paymentLimitModalOpen, setPaymentLimitModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [contexMenuAnchorPoint, setContextMenuAnchorPoint] = useState({});
  const [showContextModal, setShowContextModal] = useState(false);
  const [contextMenuPaymentId, setContextMenuPaymentId] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [contactOwnerModalOpen, setContactOwnerModalOpen] = useState(false);
  const [deleteInfoPopover, setDeleteInfoPopover] = useState(false);
  const [deletingPayment, setDeletingPayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [person, setPerson] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);

  const paymentFrequencies = {
    WEEKLY: 'Semanal',
    BIWEEKLY: 'Quinzenal',
    MONTHLY: 'Mensalidade',
    QUARTERLY: 'Trimestral',
    SEMIANNUALLY: 'Semestral',
    YEARLY: 'Anual'
  };

  const paymentStatus = {
    PAYMENT_ON_HOLD: {
      label: 'Aguardando assinaturas',
      color: '#FFA07A'
    },
    PENDING: {
      label: 'Pendente',
      color: '#E5AA02'
    },
    CONFIRMED: {
      label: 'Confirmado',
      color: '#4CAF50'
    },
    RECEIVED: {
      label: 'Confirmado',
      color: '#4CAF50'
    },
    RECEIVED_IN_CASH: {
      label: 'Confirmado',
      color: '#4CAF50'
    },
    OVERDUE: {
      label: 'Atrasado',
      color: '#E53935'
    }
  };

  const allowedEditionStatus = ['PAYMENT_ON_HOLD', 'PENDING', 'OVERDUE'];

  const loadPayments = useCallback(() => {
    let isMounted = true;
    listPaymentsByDocumentId(id).then((res) => {
      if (isMounted) {
        setPayments(res.data.data);
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      throw new Error(error);
    });
    return () => {
      isMounted = false;
    };
  }, [id, setPayments, setLoading]);

  const contextModal = (event, payment) => {
    setContextMenuAnchorPoint({
      xAxis: event.pageX,
      yAxis: event.pageY
    });
    setContextMenuPaymentId(payment.id);
    setSelectedPayment(payment);
    setShowContextModal(true);
  };

  const handleContextModalClose = useCallback((event) => {
    if (!event.target.id.includes('context-menu-button')) setShowContextModal(false);
  }, [setShowContextModal]);

  const confirmDeletion = async (paymentId) => {
    setDeletingPayment(true);
    await removePayment(paymentId);
    setDeletingPayment(false);
    setDeleteInfoPopover(false);
    setShowContextModal(false);
    loadPayments();
  };

  const startPaymentCreation = (infoModalActive) => {
    if (infoModalActive === false) setPaymentFormModalOpen(true);
    else setPaymentInfoModalOpen(true);
  };

  const checkPersonInfo = async () => {
    try {
      setLoadingAccountInfo(true);

      const hasAccount = (await checkIfHasAccount())?.data?.data?.hasAccount;
      if (hasAccount) {
        startPaymentCreation(!dontShowPaymentInfo);
        return;
      }

      let owner = (await api.get('/persons', { params: { email: user.email } })).data.data[0];
      if (!owner) owner = (await api.post('/persons', { person: { email: user.email, name: `${user.firstName} ${user.lastName}`, person_type: 'pf' } })).data;
      setPerson(owner);

      const canCreateAccount = owner?.document && owner?.phone && owner?.addressZipCode;

      if (!canCreateAccount) {
        setDocumentIdModalOpen(true);
      } else {
        await createAccount({
          name: owner.name,
          email: owner.email,
          document_id: owner.document,
          mobile_phone: owner.phone,
          postal_code: owner.addressZipCode,
          address: owner.addressStreet || 'N/D',
          city: owner.city || 'N/D',
          phone: owner.contactPhone || 'N/D',
          address_number: owner.addressNumber || 'N/D',
          complement: owner.addressComplement || 'N/D',
          province: owner.addressState || 'N/D'
        });
        startPaymentCreation(!dontShowPaymentInfo);
      }
      setLoadingAccountInfo(false);
    } catch (error) {
      toast.error('Erro ao criar conta no ASAAS');
      setPaymentLimitModalOpen(false);
      setLoadingAccountInfo(false);
    }
  };

  const handlePaymentClick = async () => {
    if (!isOwner) {
      Mixpanel.track('document_signer_button_criar-cobrança');

      const hasAccount = (await checkIfHasAccount())?.data?.data?.hasAccount;
      if (hasAccount) {
        setPaymentInfoModalOpen(true);
        return;
      }
      const { data: { users } } = await api.get(`organizations/${user.organizationId}?load_users=true`);
      setOwnerInfo(users.find((u) => u.owner === true));
      setContactOwnerModalOpen(true);
    } else {
      setPaymentInfoModalOpen(true);
    }
  };

  const getDueDate = (payment) => {
    if (payment.status !== 'PAYMENT_ON_HOLD') return moment(payment.due_date.toString().split('T')[0], 'YYYY-MM-DD', true).format('DD MMM');
    return moment(payment.due_date, 'YYYY-MM-DD', true).format('DD MMM');
  };

  useEffect(() => {
    loadPayments();

    document.addEventListener('click', handleContextModalClose);
    return () => {
      document.removeEventListener('click', handleContextModalClose);
    };
  }, [loadPayments, handleContextModalClose]);

  return (
    <>
      <S.Container>
        {(!payments || payments.length === 0) && (
          <Loader active={loading} />
        )}
        {
          payments?.length > 0
            ? payments.map((payment) => (
              <S.PaymentCard id={payment.id}>
                <S.PaymentInfoDiv>
                  <div className="header-info-div">
                    {
                      !payment.frequency
                        ? <></>
                        : <span className="header-info">{payment.installment_order}/{payment.installment_count} | </span>
                    }
                    <span className="header-info">{ paymentFrequencies[payment.frequency] || 'Único' }</span>
                  </div>
                  <div className="payment-value-div">
                    <span className="payment-value">R${currency(payment.value).value}</span>
                  </div>
                  <div className="footer-info">
                    <span className="payment-due-date">{getDueDate(payment)}</span>
                    <span className="payment-status" style={{ color: paymentStatus[payment.status].color }}>
                      {paymentStatus[payment.status].label}
                    </span>
                  </div>
                </S.PaymentInfoDiv>
                {
                  allowedEditionStatus.includes(payment.status) && payment.can_edit
                    ? (
                      <BiDotsHorizontalRounded
                        id={`context-menu-button-${payment.id}`}
                        className="context-menu-button"
                        onClick={(event) => contextModal(event, payment)}
                      />
                    )
                    : <></>
                }
              </S.PaymentCard>
            ))
            : !loading && (
              <S.CreatePaymentsContainer>
                <div style={{ display: 'table' }}>
                  <S.CreatePaymentImage>
                    <img src={ckPayImage} alt="Imagem de uma mulher com um cartão de crédito" />
                  </S.CreatePaymentImage>
                  <S.CreatePaymentInfo>
                    <S.CreatePaymentTitle>Automatize suas cobranças</S.CreatePaymentTitle>
                    <S.CreatePaymentSubTitle>
                      Crie cobranças automáticas e economize tempo para gastar como quiser
                    </S.CreatePaymentSubTitle>
                  </S.CreatePaymentInfo>
                  <S.CreatePaymentButtonDiv>
                    <Button
                      className="new-payment-button"
                      label="Criar cobrança automática"
                      onClick={() => handlePaymentClick()}
                    />
                  </S.CreatePaymentButtonDiv>
                </div>
              </S.CreatePaymentsContainer>
            )
        }
        <ContextMenu
          paymentId={contextMenuPaymentId}
          openEditModal={() => setIsEditModalOpen(true)}
          openDeleteInfoPopover={() => setDeleteInfoPopover(true)}
          open={showContextModal}
          xAxis={contexMenuAnchorPoint.xAxis}
          yAxis={contexMenuAnchorPoint.yAxis}
        />
        {
          payments?.length > 0 && !loading
            ? (
              <Button
                style={{ zIndex: '0' }}
                label={(
                  <span className="button-label">
                    <IoAdd className="add-icon" />Criar cobrança automática
                  </span>
              )}
                onClick={() => {
                  handlePaymentClick();
                }}
              />
            )
            : <></>
        }
      </S.Container>
      <ModalDocumentId
        open={documentIdModalOpen}
        person={person}
        closeModal={() => setDocumentIdModalOpen(false)}
        openPaymentFormModal={() => {
          setDocumentIdModalOpen(false);
          setPaymentFormModalOpen(true);
        }}
      />
      <ModalCreatePayment
        open={paymentFormModalOpen}
        closeModal={() => {
          setPaymentFormModalOpen(false);
          loadPayments();
        }}
      />
      <ModalEditPayment
        open={isEditModalOpen}
        closeModal={() => {
          setIsEditModalOpen(false);
          loadPayments();
        }}
        id={contextMenuPaymentId}
      />
      <ModalPaymentInfo
        open={paymentInfoModalOpen}
        closeModal={() => {
          setPaymentInfoModalOpen(false);
          loadPayments();
        }}
        openPaymentFormModal={() => {
          checkPersonInfo();
          setPaymentInfoModalOpen(false);
        }}
      />
      <ModalPaymentLimit
        open={paymentLimitModalOpen}
        loadingAccountInfo={loadingAccountInfo}
        handleOKClick={() => {
          checkPersonInfo();
        }}
        closeModal={() => {
          setPaymentLimitModalOpen(false);
        }}
      />
      <DeleteInfoPopover
        open={deleteInfoPopover}
        confirmDeletion={() => confirmDeletion(contextMenuPaymentId)}
        deleting={deletingPayment}
        closeModal={() => {
          setDeleteInfoPopover(false);
          setDeletingPayment(false);
        }}
        payment={selectedPayment}
      />
      <ContactOrganizationOwnerModal
        open={contactOwnerModalOpen}
        handleOKClick={() => {
          setContactOwnerModalOpen(false);
        }}
        closeModal={() => {
          setContactOwnerModalOpen(false);
        }}
        ownerInfo={ownerInfo}
      />
      {status !== 'draft' && (
        <SidebarFooter>
          <Button
            style={{
              background: 'rgba(0, 0, 0, 0.08)',
              color: 'rgba(0, 0, 0, 0.6)',
              boxShadow: 'none',
              width: '100%',
              fontFamily: 'Poppins',
              fontWeight: '500',
              fonstSize: '0.875rem',
              letterSpacing: '1.25px'
            }}
            onClick={() => {
              window.open('https://asaas.com/finance', '_blank');
            }}
            label={(
              <>
                <IoMdWallet style={{ marginRight: 8, width: '19px', height: '18px' }} />
                Acessar carteira digital
              </>
            )}
            disableElevation
          />
        </SidebarFooter>
      )}
    </>
  );
};

PaymentList.propTypes = {};

export default PaymentList;
