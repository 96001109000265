import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime } from '../../../utils/localeUtils';

const CompletedAt = ({ document }) => (
  <>{formatDateTime(document.completedAt)}</>
);

CompletedAt.propTypes = {
  document: PropTypes.shape({
    completedAt: PropTypes.string
  }).isRequired
};

export default CompletedAt;
