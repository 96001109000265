/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-parens */
/* eslint-disable import/no-extraneous-dependencies */
import Chip from '@mui/material-next/Chip';
import { styled as muiStyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React from 'react';
import styled from 'styled-components';

export const WhatsappContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const WhatsappSwitchCard = styled.div`
  display: flex;
  width: fit-content;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  border: ${props => props.active ? '1px solid #006D40' : '1px solid #D6D6D6'}
`;

export const WhatsappSwitch = muiStyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginTop: '0!important',
  marginBottom: '0!important',
  marginLeft: '0!important',
  marginRight: '16px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#006D40',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const WhatsappCardLabel = styled.div`
  color: var(--Text-900, #101113);

  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
`;

export const BetaFlag = muiStyled(Chip)({
  backgroundColor: '#B5C4FF',
  color: '#0251DE',
  fontFamily: 'Poppins',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.0156rem',
  '& .MuiChip-label': {
    padding: '0 0.5rem'
  }
});

export const NewFeatureFlag = styled.span`
  position: sticky;
  z-index: 4;
  height: fit-content;

  img {
    position: absolute;
    padding-left: 15px;
    top: -45px;
  }

  @media ${({ theme }) => theme.breakPoints.small} {
    display: none;
  }  
`;
