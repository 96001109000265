// Action types

export const UPDATE_PERSONS = '@contraktor/starterWizard/UPDATE_PERSONS';
export const TOGGLE_ERROR = '@contraktor/starterWizard/TOGGLE_ERROR';
export const UPDATE_STEP = '@contraktor/starterWizard/UPDATE_STEP';
export const UPDATE_FILE = '@contraktor/starterWizard/UPDATE_FILE';
export const UPDATE_ID = '@contraktor/starterWizard/UPDATE_ID';
export const UPDATE_TITLE = '@contraktor/starterWizard/UPDATE_TITLE';
export const RESET_STORE = '@contraktor/starterWizard/RESET_STORE';

// Reducer

export const INITIAL_STATE = {
  id: null,
  title: '',
  file: null,
  step: 1,
  stepIndex: 1,
  error: false,
  persons: []
};

export default function starterWizardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_PERSONS:
      return {
        ...state,
        persons: action.payload
      };

    case TOGGLE_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload.step,
        stepIndex: action.payload.stepIndex
      };

    case UPDATE_FILE:
      return {
        ...state,
        file: action.file
      };

    case UPDATE_ID:
      return {
        ...state,
        id: action.id
      };

    case UPDATE_TITLE:
      return {
        ...state,
        title: action.title
      };

    case RESET_STORE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

// Actions

export function updatePersons(payload) {
  return {
    type: UPDATE_PERSONS,
    payload
  };
}

export function toggleError(payload) {
  return {
    type: TOGGLE_ERROR,
    payload
  };
}

export function updateStep(payload) {
  return {
    type: UPDATE_STEP,
    payload
  };
}

export function updateFile(file) {
  return {
    type: UPDATE_FILE,
    file
  };
}

export function updateId(id) {
  return {
    type: UPDATE_ID,
    id
  };
}

export function updateTitle(title) {
  return {
    type: UPDATE_TITLE,
    title
  };
}

export function resetStore() {
  return {
    type: RESET_STORE
  };
}
