import { IconButton } from '@mui/material';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import themes from '../../_utils/themes';
import Button from '../Button';
import cardImg from './_assets/free_trial_modal.svg';
import {
  CustomModal,
  ModalContainer
} from './styles';

export default function FreeTrialModal({ open, onClose }) {
  const history = useHistory();

  return (
    <CustomModal
      open={open}
      onClose={onClose}
    >
      <ModalContainer>
        <IconButton onClick={onClose} className="close-btn">
          <IoClose className="close-icon" />
        </IconButton>

        <img src={cardImg} alt="free-trial-modal" className="free-trial-icon" />

        <div className="title">
          Envie documentos em segundos! <span role="img" aria-label="">🏎️💨</span>
        </div>

        <div className="body">
          Solucione seus problemas de assinatura e gestão de documentos.
          Experimente nossa plataforma <b>GRÁTIS</b>, por 7 dias e comece a assinar
          com mais facilidade.
        </div>

        <Button
          label="Comece seu Trial agora"
          theme="primary"
          styles={{
            fontSize: themes.fonts.size.smaller
          }}
          onClick={() => history.push('/register')}
        />

        <div className="disclaimer">
          &bull; Não precisa de cartão de crédito!
        </div>
      </ModalContainer>
    </CustomModal>
  );
}
