import PropTypes from 'prop-types';
import React from 'react';

import { signerType } from '../../../utils/types/signerType';
import {
  Wrapper, HeaderRow, Description, Column
} from './styles';

const TableItem = ({ columns, signer }) => {
  const renderTableItem = columns.map((col) => (
    <Column
      key={col.key}
      width={col.width}
      center={col.center}
    >
      {col.component && (
        <col.component
          signer={signer}
        />
      )}
    </Column>
  ));

  return (
    <Wrapper>
      <HeaderRow>
        <Description>
          {renderTableItem}
        </Description>
      </HeaderRow>
    </Wrapper>
  );
};

TableItem.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf),
  signer: PropTypes.shape(signerType).isRequired
};

TableItem.defaultProps = {
  columns: []
};

export default TableItem;
