/* eslint-disable consistent-return */
import { Button } from '@contraktor-tech/ui';
import React, { useState } from 'react';

import * as S from './styles';

const localStorageKey = '@ck:cookies';

const CookiesAlert = () => {
  const [open, setOpen] = useState(() => {
    if (localStorage.getItem(localStorageKey)) return false;

    return true;
  });

  const handleClose = () => {
    localStorage.setItem(localStorageKey, true);
    setOpen(false);
  };

  if (!open) return null;

  return (
    <S.Wrapper>
      <S.Container>
        <p>
          Usamos cookies e outras tecnologias semelhantes para fornecer os
          serviços e os recursos oferecidos no nosso site, e para melhorar a
          experiência dos nossos usuários. Ao navegar por nossas páginas, você
          concorda com a nossa{' '}
          <a href="https://contraktor.com.br/politica-de-privacidade/">
            Política de Privacidade
          </a>
          .
        </p>

        <Button label="Entendi!" onClick={() => handleClose()} disableElevation size="large" />
      </S.Container>
    </S.Wrapper>
  );
};

export default CookiesAlert;
