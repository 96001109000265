import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
width: 100%;
flex-grow: 1;
display: flex;
flex-direction: column;
justify-content: center;

> label {
  margin-bottom: 2rem !important;
}
`;

export const Title = styled.h2`
color: ${(props) => props.theme.headerColor};
font-size: 1.750em;
text-align: center;
`;

export const Description = styled.p`
font-size: 1em;
text-align: center;
`;
