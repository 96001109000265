import { Modal as MuiModal, IconButton } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Modal = styled(MuiModal)`
  > .MuiDialog-root > .MuiDialog-container > .MuiDialog-paperWidthSm {
    max-width: fit-content;
  }

  .modal-base__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 20px 16px 32px 16px;
  }

  .modal-base__vector {
    display: block;

    margin: 0 auto 16px auto;
    width: 100%;
    max-width: 200px;
  }

  .modal-base__title {
    font-size: 25px;
    font-weight: 500;
    color: #000000;
  }

  .modal-base__description {
    margin: 12px 0 24px 0;

    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }

  .modal-base__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    width: 100%;

    > *:nth-child(2) {
      margin-left: 16px;
    }

    &.modal-base__footer-btn-xs-padding {
      .MuiButton-root {
        padding: 16px !important;
      }
    }
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute !important;

  right: 0;
  top: 0;
`;
