import {
  FormControl, InputLabel, MenuItem, Select,
  TextField
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import React, {
  forwardRef, useEffect,
  useState
} from 'react';
import { useForm, Controller } from 'react-hook-form';

import CepField from '../../../../../_components/CepField';
import { useMixpanel } from '../../../../../../_legacy/hooks/MixpanelProvider';
import { validateCpfCnpj } from '../../../../../../_legacy/utils/helpers';
import estados from '../../../../../../_legacy/utils/states';
import PlanInfoCard from '../PlanInfoCard';
import { FlexField, Form } from './styles';

const AddressForm = forwardRef(({
  onSubmit, defaultValues, setIsValid, voucher
}, ref) => {
  const {
    postCode,
    state,
    city,
    street,
    number,
    neighborhood,
    compliment,
    document
  } = defaultValues;

  const { Mixpanel } = useMixpanel();
  const [cep, setCep] = useState(postCode);
  const [cepLoading, setCepLoading] = useState(false);
  const [cepData, setCepData] = useState({
    state,
    city,
    street,
    neighborhood
  });
  const {
    control, handleSubmit, formState: { errors, isValid }, setError, setValue
  } = useForm({
    defaultValues: {
      postCode,
      state,
      city,
      street,
      number,
      neighborhood,
      compliment,
      document
    },
    mode: 'onChange'
  });

  const fetchCep = async (value) => {
    try {
      setCepLoading(true);
      const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
      const {
        logradouro,
        localidade,
        uf,
        bairro
      } = data;

      setCepData({
        state: uf,
        city: localidade,
        street: logradouro,
        neighborhood: bairro
      });

      setCepLoading(false);
    } catch {
      setError('postCode', {
        type: 'requestError',
        message: 'CEP informado não encontrado'
      });
      Mixpanel.track('checkout_input_cep', { state: 'error', error_message: 'CEP informado não encontrado' });
    }
  };

  useEffect(() => {
    if (cep && /^\d{5}-\d{3}$/.test(cep)) fetchCep(cep);
  }, [cep]); //eslint-disable-line

  useEffect(() => {
    if (!errors.postCode) {
      Mixpanel.track('checkout_input_cep', { state: 'success' });
      Mixpanel.track('checkout_input_estado', { state: 'success' });
      Mixpanel.track('checkout_input_cidade', { state: 'success' });
      Mixpanel.track('checkout_input_rua', { state: 'success' });
      Mixpanel.track('checkout_input_bairro', { state: 'success' });
    }
  }, [errors.postCode, Mixpanel]);

  useEffect(() => {
    Object.entries(cepData).forEach(([key, val]) => {
      setValue(key, val);
    });
  }, [cepData]); //eslint-disable-line

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  return (
    <Form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <PlanInfoCard voucher={voucher} />
      <Controller
        name="postCode"
        control={control}
        defaultValue={postCode}
        rules={{
          required: 'CEP é obrigatório',
          pattern: {
            value: /^\d{5}-\d{3}$/,
            message: 'CEP inválido'
          },
          onChange: debounce((e) => setCep(e.target.value), 500)
        }}
        render={({ field }) => (
          <CepField
            props={field}
            error={!!errors.postCode}
            helperText={errors.postCode ? errors.postCode.message : ''}
          />
        )}
      />
      <Controller
        disabled={cepLoading}
        name="state"
        control={control}
        defaultValue={cepData.state || state}
        rules={{
          required: 'Estado é obrigatório'
        }}
        render={({ field }) => (
          <FormControl error={!!errors.state}>
            <InputLabel>Estado</InputLabel>
            <Select
              {...field}
              label="Estado"
              name="state"
            >
              {estados.map((st) => (
                <MenuItem key={st.nome} value={st.sigla}>
                  {st.sigla}
                </MenuItem>
              ))}
            </Select>
            {errors.state && <p style={{ color: 'red' }}>{errors.state.message}</p>}
          </FormControl>
        )}
      />
      <Controller
        disabled={cepLoading}
        name="city"
        control={control}
        defaultValue={cepData.city || city}
        rules={{
          required: 'Cidade é obrigatória'
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Cidade"
            variant="outlined"
            error={!!errors.city}
            helperText={errors.city ? errors.city.message : ''}
          />
        )}
      />
      <FlexField>
        <Controller
          disabled={cepLoading}
          name="street"
          control={control}
          defaultValue={cepData.street || street}
          rules={{
            required: 'Rua é obrigatória'
          }}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ width: '56.25%' }}
              label="Rua"
              variant="outlined"
              error={!!errors.street}
              helperText={errors.street ? errors.street.message : ''}
            />
          )}
        />
        <Controller
          name="number"
          control={control}
          defaultValue={number}
          rules={{
            required: 'Número é obrigatório',
            onChange: debounce(() => Mixpanel.track('checkout_input_number'), 500)
          }}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ width: '43.75%' }}
              label="Número"
              variant="outlined"
              error={!!errors.number}
              helperText={errors.number ? errors.number.message : ''}
            />
          )}
        />
      </FlexField>
      <Controller
        disabled={cepLoading}
        name="neighborhood"
        control={control}
        defaultValue={cepData.neighborhood || neighborhood}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Bairro"
            variant="outlined"
          />
        )}
      />
      <Controller
        name="compliment"
        control={control}
        defaultValue={compliment}
        rules={{
          onChange: debounce(() => Mixpanel.track('checkout_input_complemento'), 500)
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="Complemento"
            variant="outlined"
            error={!!errors.compliment}
            helperText={errors.compliment ? errors.compliment.message : ''}
          />
        )}
      />
      <Controller
        name="document"
        control={control}
        defaultValue={document}
        rules={{
          required: 'Documento é obrigatório',
          validate: {
            validDocument: (value) => {
              const valid = validateCpfCnpj(value);

              if (!valid) {
                setError('document', {
                  message: 'O documento informado é inválido'
                });

                Mixpanel.track('checkout_input_cpf-cnpj', { state: 'error' });
              }

              Mixpanel.track('checkout_input_cpf-cnpj', { state: 'success' });

              return valid;
            }
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label="CPF/CNPJ"
            variant="outlined"
            error={!!errors.document}
            helperText={errors.document ? errors.document.message : ''}
          />
        )}
      />
    </Form>
  );
});

export default AddressForm;
