import styled from 'styled-components';

export const Info = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderModalContainer = styled.div`
  display: table;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spinner 1s linear infinite;
    animation-play-state: inherit;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }

  .loader-text {
    font-family: Poppins;
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;
  }
`;
