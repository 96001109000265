import {
  Header as HeaderUI
} from '@contraktor-tech/ui';
import styled from 'styled-components';

export const HeaderWrapper = styled(HeaderUI)`
  .MuiToolbar-root {
    position: relative;
    justify-content: center;
  }

  .white-header__icon--back {
    position: absolute;
    left: 10px;
    top: 9px;
    z-index: 5;

    color: #0C0440;
  }
  
  .white-header__logo-image {
    display: flex;
    
    width: 17rem;

    img {
      width: 100%;
    }
  }
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 9px;
  z-index: 5;

  display: flex;
  align-items: center;
  
  .back-button-text {
    color: #0C0440;

    @media ${(props) => props.theme.breakPoints.small} {
      display: none;
    }
  }

  > button {
    color: #0C0440;
  }
`;
