import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';

import { Label, SafeEnvChip } from './styles';

export default function SafeEnvironmentChip() {
  return (
    <SafeEnvChip
      label={
        <Label><LockOutlinedIcon />Ambiente seguro</Label>
      }
    />
  );
}
