import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Grid, Form } from 'semantic-ui-react';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import api from '../../../utils/backend';
import { checkEmailAlreadyTaken } from '../../../utils/helpers';
import { emailRegex } from '../../../utils/parsers';
import Button from '../../Button';
import FieldErrorMessage from '../../FieldErrorMessage';
import InputWithRef from '../../InputWithRef';

const validates = {
  minLength: {
    value: 2,
    message: <FieldErrorMessage message="Insira no mínimo 2 letras" />
  },
  maxLength: {
    value: 80,
    message: <FieldErrorMessage message="Insira no máximo 80 letras" />
  },
  required: <FieldErrorMessage message="Campo Obrigatório" />
};

const ProfileForm = ({ onChangePassword }) => {
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const {
    register, handleSubmit, formState: { errors }, setError
  } = useForm({
    mode: 'blur',
    defaultValues: user
  });

  const onSubmit = async (data) => {
    const { firstName, lastName, email } = data;
    setLoading(true);

    try {
      await api.patch('/users/settings', {
        user: {
          first_name: firstName.replace(/\s{2,}/g, ' ').trim(),
          last_name: lastName.trim(),
          email
        }
      });

      toast.success('Dados atualizados com sucesso.');
    } catch (error) {
      checkEmailAlreadyTaken(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid columns={1}>
        <Grid.Column mobile={16}>
          <InputWithRef
            type="text"
            id="firstName"
            label="Nome"
            {...register('firstName', {
              required: validates.required,
              validate: {
                minLength: (value) => value.replace(/\s/g, '').length >= 2,
                maxLength: (value) => value.replace(/\s/g, '').length <= 80
              }
            })}
            required
          >
            {errors?.firstName
              && errors?.firstName.type === 'minLength'
              && validates.minLength.message}
            {errors?.firstName
              && errors?.firstName.type === 'maxLength'
              && validates.maxLength.message}
            {errors?.firstName && errors?.firstName.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <InputWithRef
            type="text"
            id="lastName"
            label="Sobrenome"
            {...register('lastName', {
              required: validates.required,
              validate: {
                minLength: (value) => value.replace(/\s/g, '').length >= 2,
                maxLength: (value) => value.replace(/\s/g, '').length <= 80
              }
            })}
            required
          >
            {errors?.lastName
              && errors?.lastName.type === 'minLength'
              && validates.minLength.message}
            {errors?.lastName
              && errors?.lastName.type === 'maxLength'
              && validates.maxLength.message}
            {errors?.lastName && errors?.lastName.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column mobile={16}>
          <InputWithRef
            type="email"
            id="email"
            onChange={(e) => {
              if (e.target.value) {
                e.target.value = e.target.value.toLowerCase().trim();
              }
            }}
            label="Email"
            {...register('email', {
              required: validates.required,
              pattern: {
                value: emailRegex,
                message: (
                  <FieldErrorMessage message="O e-mail inserido deve ser válido." />
                )
              }
            })}
            required
          >
            {errors?.email && errors?.email.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column
          verticalAlign="middle"
          mobile={8}
          largeScreen={8}
          widescreen={8}
          textAlign="center"
        >
          <Button fluid basic onClick={onChangePassword}>
            Alterar Senha
          </Button>
        </Grid.Column>
        <Grid.Column
          mobile={8}
          largeScreen={8}
          widescreen={8}
          textAlign="center"
        >
          <Button primary fluid type="submit" loading={loading}>
            Salvar Informações
          </Button>
        </Grid.Column>
      </Grid>
    </Form>
  );
};

ProfileForm.propTypes = {
  onChangePassword: PropTypes.func.isRequired
};

export default ProfileForm;
