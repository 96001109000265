/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button, Select } from '@contraktor-tech/ui';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader, Modal } from 'semantic-ui-react';

import api from '../../../../utils/backend';
import GridContainer from '../../components/GridContainer';
import { createAccount } from '../../services/api/adapters/account';
import ModalCreatePayment from '../ModalCreatePayment/ModalCreatePayment';
import ModalPaymentHeader from '../shared/components/ModalPaymentHeader/ModalPaymentHeader';
import { CustomTextField } from '../shared/forms/CustomFieldStyles/CustomTextField';
import CNPJInputCustom from '../shared/forms/CustomInputMasks/CNPJInputCustom';
import CPFInputCustom from '../shared/forms/CustomInputMasks/CPFInputCustom';
import PhoneInputCustom from '../shared/forms/CustomInputMasks/PhoneInputCustom';
import ZipCodeCustom from '../shared/forms/CustomInputMasks/ZipCodeCustom';
import * as S from './styles';

const ModalDocumentId = ({
  open,
  closeModal,
  openPaymentFormModal,
  person
}) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isZipCodeValid, setIsZipCodeValid] = useState(false);
  const [isDocumentValid, setIsDocumentValid] = useState(false);
  const [personType, setPersonType] = useState('FISICA');
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const {
    setValue, setError, clearErrors, control, register, reset, getValues
  } = useForm({
    defaultValues: {
      person_type: 'FISICA',
      document_id: '',
      mobile_phone: '',
      zip_code: ''
    },
    mode: 'onChange'
  });

  const personTypeOptions = [
    {
      label: 'Pessoa Física',
      value: 'FISICA'
    },
    {
      label: 'Pessoa Jurídica',
      value: 'JURIDICA'
    }
  ];

  const phoneRegex = /(\?\d{2}\?\s)?(\d{4,5}-\d{4})/g;
  const zipCodeRegex = /^[0-9]{5}-[0-9]{3}$/;

  const handleDocumentChange = (event) => {
    const documentValid = (cnpj.isValid(getValues('document_id')) && getValues('person_type') === 'JURIDICA')
    || (cpf.isValid(getValues('document_id')) && getValues('person_type') === 'FISICA');
    setIsDocumentValid(documentValid);

    if (!documentValid) setError(event.target.name, 'Insira um número de documento válido');
    else clearErrors(event.target.name);
  };

  const handlePhoneChange = (event) => {
    const phoneValid = event.target.value?.replace(/([' ', '(', ')', '-', '_'])/g, '')?.length > 10;
    setIsPhoneValid(phoneValid);

    if (!phoneValid) setError(event.target.name, 'Insira um número válido');
    else clearErrors(event.target.name);
  };

  const handleZipCodeChange = (event) => {
    const zipCodeValid = zipCodeRegex.test(event.target.value);
    setIsZipCodeValid(zipCodeValid);

    if (zipCodeValid) setError(event.target.name, 'Insira um CEP válido');
    else clearErrors(event.target.name);
  };

  const handleChange = (event) => {
    if (event.target.name === 'person_type') setPersonType(event.target.value);
    setValue(event.target.name, event.target.value);
    Promise.all([
      handleDocumentChange({ target: { value: getValues('document_id') } }),
      handlePhoneChange({ target: { value: getValues('mobile_phone') } }),
      handleZipCodeChange({ target: { value: getValues('zip_code') } })
    ]);
  };

  const validateRequiredField = (value, field) => {
    if (isEmpty(value)) setError(field, { message: 'Este campo é obrigatório' });
    else clearErrors(field);
  };

  const resetModal = () => {
    reset();
    setIsDocumentValid(false);
    setIsPhoneValid(false);
  };

  const submitAndGoNext = async () => {
    try {
      const updatedPerson = {
        ...person,
        document: getValues('document_id'),
        phone: getValues('mobile_phone'),
        addressZipCode: getValues('zip_code')
      };
      setIsCreatingAccount(true);
      await api.put(`persons/${updatedPerson.id}`, { person: updatedPerson });
      await createAccount({
        name: updatedPerson.name,
        email: updatedPerson.email,
        document_id: updatedPerson.document,
        mobile_phone: updatedPerson.phone,
        address: updatedPerson.addressStreet || 'N/D',
        city: updatedPerson.city || 'N/D',
        phone: updatedPerson.contactPhone || 'N/D',
        address_number: updatedPerson.addressNumber || 'N/D',
        complement: updatedPerson.addressComplement || 'N/D',
        province: updatedPerson.addressState || 'N/D',
        postal_code: updatedPerson.addressZipCode
      });
      setIsCreatingAccount(false);
      resetModal();
      openPaymentFormModal();
    } catch (error) {
      toast.error(error.message);
      setIsCreatingAccount(false);
      resetModal();
    }
  };

  const documentField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
      }}
      fullWidth
      label={<S.InputLabel>{ personType === 'FISICA' ? 'CPF' : 'CNPJ' }</S.InputLabel>}
      id="document_id"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: personType === 'FISICA' ? CPFInputCustom : CNPJInputCustom
      }}
    />
  );

  const zipCodeField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
      }}
      fullWidth
      label={<S.InputLabel>CEP</S.InputLabel>}
      id="zip_code"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: ZipCodeCustom
      }}
    />
  );

  const persontTypeField = (field, error) => (
    <Select
      {...field}
      options={personTypeOptions}
      onChange={(event) => {
        handleChange(event);
        setValue('person_type', '');
      }}
      label={<S.InputLabel>Tipo de pessoa</S.InputLabel>}
      id="person_type"
      error={!!error}
      helperText={error?.message}
    />
  );

  const mobilePhoneField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
      }}
      fullWidth
      label={<S.InputLabel>Celular</S.InputLabel>}
      id="document_id"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: PhoneInputCustom
      }}
    />
  );

  useEffect(() => {}, []);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      size="small"
      style={{ maxWidth: '438px', borderRadius: '8px' }}
    >
      <ModalPaymentHeader
        mode="document-info"
        closeModal={closeModal}
      />
      <ModalCreatePayment open={openPaymentModal} closeModal={() => setOpenPaymentModal(false)} />
      <Modal.Content scrolling>
        <S.FormHeader>
          Para continuar a utilizar a Automatização de Cobrança é necessário cadastrar o CPF ou CNPJ e o número de telefone móvel.
        </S.FormHeader>
        <S.FormContainer>
          <GridContainer
            columns="1fr"
            width="auto"
            spacing="1.125"
            padding="0"
          >
            <Controller
              control={control}
              {...register(
                'person_type',
                {
                  required: true
                }
              )}
              render={({ field, fieldState: { error } }) => persontTypeField(field, error)}
            />
            <Controller
              control={control}
              {...register(
                'document_id',
                {
                  required: true,
                  value: person?.document || ''
                }
              )}
              render={({ field, fieldState: { error } }) => documentField(field, error)}
            />
            <Controller
              control={control}
              {...register(
                'mobile_phone',
                {
                  required: true,
                  regex: phoneRegex,
                  value: person?.phone || ''
                }
              )}
              render={({ field, fieldState: { error } }) => mobilePhoneField(field, error)}
            />
            <Controller
              control={control}
              {...register(
                'zip_code',
                {
                  required: true,
                  value: person?.addressZipCode || ''
                }
              )}
              render={({ field, fieldState: { error } }) => zipCodeField(field, error)}
            />
          </GridContainer>
        </S.FormContainer>
      </Modal.Content>
      <S.Footer>
        <Button
          className="action-button back-button"
          onClick={() => {
            resetModal();
            closeModal();
          }}
          label={(
            <S.ButtonText>
              <span className="button-text-normal button-text-blue">Cancelar</span>
            </S.ButtonText>
          )}
        />
        <Button
          disabled={(!isDocumentValid || !isPhoneValid || !isZipCodeValid) || isCreatingAccount}
          className="action-button next-button"
          onClick={() => submitAndGoNext()}
          label={(
            <S.ButtonText>
              {
                  isCreatingAccount
                    ? <div style={{ padding: '7px 32px' }}><Loader active={isCreatingAccount} /></div>
                    : <span className="button-text-normal button-text-white">Atualizar</span>
                }
            </S.ButtonText>
          )}
        />
      </S.Footer>
    </Modal>
  );
};

ModalDocumentId.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openPaymentFormModal: PropTypes.func.isRequired,
  person: PropTypes.any.isRequired
};

export default ModalDocumentId;
