import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useAuth } from '../../AuthContext/AuthContext';
import { Tabs, Tab } from './styles';

function Header() {
  const { isOwner } = useAuth();
  const { pathname } = useLocation();

  const tabs = useMemo(() => [
    {
      name: 'Empresa',
      href: '/company',
      active: pathname === '/company',
      onlyOwner: false
    },
    {
      name: 'Minha Conta',
      href: '/profile',
      active: pathname === '/profile',
      onlyOwner: false
    },
    {
      name: 'Plano e Pagamento',
      href: '/payment',
      active: pathname === '/payment',
      onlyOwner: false
    },
    {
      name: 'Usuários',
      href: '/users',
      active: pathname === '/users',
      onlyOwner: true
    }
  ], [pathname]);

  return (
    <Tabs>
      {tabs.filter((tab) => (tab.onlyOwner ? isOwner : true)).map((tab) => (
        <NavLink
          exact
          to={tab.href}
          key={tab.name}
        >
          <Tab key={tab.name} active={tab.active}>
            {tab.name}
          </Tab>
        </NavLink>
      ))}
    </Tabs>
  );
}

export default Header;
