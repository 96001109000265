import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import queryString from 'query-string';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useQueryString(queryOptions) {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { current: opts } = useRef(queryOptions);

  useEffect(() => {
    function parseQueryString(str) {
      const qs = queryString.parse(str);

      if (opts && opts.only) {
        return pick(qs, opts.only);
      }
      return qs;
    }
    setQuery(parseQueryString(location.search));
  }, [location.search, opts]);

  function handleSetQuery(newQuery) {
    setQuery(queryString.parse(location.search));

    let filteredNewQuery = { ...newQuery };
    if (queryOptions && queryOptions.only) {
      filteredNewQuery = pick(filteredNewQuery, queryOptions.only);
    }

    if (queryOptions && queryOptions.clearFalsyValues) {
      filteredNewQuery = mapValues(filteredNewQuery, (value) => value || undefined);
    }

    const search = queryString.stringify({
      ...query,
      ...filteredNewQuery
    });

    history.push({
      pathname: location.pathname,
      hash: location.hash,
      search
    });
  }

  return {
    setQuery: (data) => handleSetQuery(data),
    query
  };
}

export default useQueryString;
