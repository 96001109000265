import {
  Divider, Grid
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../_components/Button';
import Dropzone from '../../../_components/Dropzone';
import OutlineTextField from '../../../_components/Input';
import {
  Content, CustomContainer, Subtitle, Title
} from '../../../_shared/styles';
import { useAuth } from '../../../../_legacy/containers/AuthContext/AuthContext';
import api from '../../../../_legacy/utils/backend';
import TemplatePreview from './_components/TemplatePreview';
import { OrganizationForm } from './styles';

export default function Company() {
  const { user: { organization: { name, logoUrl }, firstName, lastName }, setAuthData } = useAuth();

  const [preview, setPreview] = useState(logoUrl);
  const [file, setFile] = useState();
  const [orgName, setOrgName] = useState(name || '');

  const updateName = async (data) => {
    try {
      const response = await api.post('/organizations', { organization: { name: data } });

      setAuthData((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          name: response?.data?.name
        }
      }));

      toast.success('Nome da empresa alterado com sucesso.');
    } catch (err) {
      toast.error('Erro ao alterar nome da empresa.');
    }
  };

  const updateLogo = async (data) => {
    const formData = new FormData();
    formData.append('file', data);

    try {
      const response = await api.post('/organizations/upload', formData);

      setPreview(response?.data?.logoUrl);
      setAuthData((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          logoUrl: response?.data?.logoUrl
        }
      }));

      toast.success('Logo alterada com sucesso');
    } catch (err) {
      toast.error('Erro ao alterar logo da empresa');
    }
  };

  const removeLogo = async () => {
    try {
      await api.post('/organizations', { organization: { logoUrl: null } });

      setAuthData((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          logoUrl: null
        }
      }));

      toast.success('Nome da empresa alterado com sucesso.');
    } catch (err) {
      toast.error('Erro ao alterar nome da empresa.');
    }
  };

  const handleImgDelete = useCallback(() => {
    setPreview(null);
    setFile(null);
  }, []);

  const handleDrop = useCallback((acceptedFiles) => {
    const f = acceptedFiles[0];
    const previewUrl = URL.createObjectURL(f);
    setFile(f);
    setPreview(previewUrl);
  }, []); // eslint-disable-line

  const handleNameChange = useCallback((event) => {
    const { value } = event.target;
    setOrgName(value);
  }, []); // eslint-disable-line

  const handleSubmit = useCallback(async (data) => {
    if (data.orgName) await updateName(data.orgName);
    if (data.file) await updateLogo(data.file);
    if (!data.file) await removeLogo();
  }, []); // eslint-disable-line

  return (
    <CustomContainer maxWidth>
      <Grid container spacing={0}>
        <Grid xs={5.9} md={5.9} sm={11.8}>
          <Content elevation={0}>
            <Title bottomGap="20px">
              Personalização de E-mails
            </Title>
            <Subtitle bottomGap="40px">
              Personalize seus e-mails e impulsione sua marca!
              Assim você pode criar uma experiência de marca profissional
              para seus assinantes na solicitação de assinatura do documento.
            </Subtitle>
            <Dropzone onDrop={handleDrop} onDelete={handleImgDelete} image={preview} />
            <OrganizationForm>
              <OutlineTextField
                id="organization-name"
                label="Nome da Empresa"
                value={orgName}
                onChange={handleNameChange}
                title="Dados da Empresa"
              />
              <Button
                onClick={() => handleSubmit({ orgName, file })}
                variant="contained"
                label="Salvar"
                theme="primary"
              />
            </OrganizationForm>
          </Content>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid xs={5.9} md={5.9} sm={11.8}>
          <Content elevation={0}>
            <Title bottomGap="20px">
              Visualização E-mail
            </Title>
            <Subtitle bottomGap="40px">
              Confira nesta visualização simulada como
              seu logotipo será exibido em seus e-mails
              de solicitação de assinatura de documentos.
            </Subtitle>
            <TemplatePreview
              logo={preview}
              userName={`${firstName} ${lastName}`}
              orgName={orgName}
            />
          </Content>
        </Grid>
      </Grid>
    </CustomContainer>
  );
}
