import queryString from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';
import ErrorMessage from './ErrorMessage';
import LayoutPage from './LayoutPage/LayoutPage';
import RecoverySuccessMessage from './RecoverySuccessMessage';
import SuccessMessage from './SuccessMessage';

const ForgotPasswordPage = () => {
  const { search } = useLocation();
  const { recoveryRequested, error } = useSelector((state) => state.recoveryPassword);
  const { token } = queryString.parse(search);

  return (
    <LayoutPage>
      {!recoveryRequested && !token && (
        <ForgotPasswordForm />
      )}

      {!recoveryRequested && !error && token && (
        <RecoveryPasswordForm token={token} />
      )}

      {recoveryRequested && !token && (
        <SuccessMessage />
      )}

      {token && error && (
        <ErrorMessage />
      )}

      {recoveryRequested && token && (
        <RecoverySuccessMessage />
      )}
      <Link to="/login">
        <Icon name="arrow left" size="large" />
      </Link>
    </LayoutPage>
  );
};

export default ForgotPasswordPage;
