import React from 'react';
import { useSelector } from 'react-redux';

import Content from '../Content';
import Footer from '../Footer';
import Header from '../Header';
import { Wrapper, Grid, Card } from './styles';

const ConfirmPersons = () => {
  const { persons } = useSelector((state) => state.starterWizard);

  return (
    <Wrapper>
      <Header />
      <Content>
        <Content.Header>
          <h4>Assinantes</h4>
        </Content.Header>
        <Grid>
          {persons.map(({ id, name, email }) => (
            <Card key={id}>
              <h5>{name}</h5>
              <p>{email}</p>
            </Card>
          ))}
        </Grid>
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default ConfirmPersons;
