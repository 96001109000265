import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  padding: 1rem;
`;

export const AvatarWrapper = styled.div`
  > div {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  > div > span {
    font-size: 3rem;
  } 
`;

export const Wrapper = styled.div`
  width: 30%;
  height: inherit;

  > :first-child {
    text-align: center;
  }

  hr {
    background: #fafafa;
    width: auto;
  }

  @media (max-width: 801px) {
    width: 100%;
  }

  @media (min-width: 600px) and (max-width: 900px) {
    width: 100%;
  }
`;
