import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import checkedImage from '../../../assets/img/green-checked.png';
import Wrapper from './styles';

const SignUpSuccessPage = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('addressValues');
      history.push('/');
    }, 5000);
  }, [history]);

  return (
    <Wrapper>
      <img src={checkedImage} alt="Assinatura realizada com sucesso" className="overlay" />
      <h3>Cadastro realizado com sucesso!</h3>
      <p>Você será redirecionado(a) em instantes</p>
    </Wrapper>
  );
};

export default SignUpSuccessPage;
