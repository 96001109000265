import currency from 'currency.js';
import moment from 'moment';
import React from 'react';

import { getNextPaymentDueDate } from '../../../_new/_utils/getNextPaymentDueDate';
import IconChecked from '../../../_new/PlansList/components/PlanCard/Icon';
import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import { useChoosePlan } from '../../../../containers/NewPaymentPage/contexts/ChoosePlanContext';
import { getDaysBetweenDates } from '../../../../utils/localeUtils';
import { plansListData } from '../../../../utils/plans';
import * as S from './styles';

// eslint-disable-next-line max-len
const planBenefits = (plan) => plansListData.find((p) => p.id === plan.toLowerCase()) || plansListData[0];

const trialText = (trialIsOver, daysRemaining) => {
  if (trialIsOver) {
    return 'Seu teste gratuito venceu. Se quiser continuar utilizando todas as nossa features e enviando documentos para assinatura, clique em mudar plano e veja as opções.';
  }

  return (
    <>
      Seu plano é um teste gratuito do plano Unlimited. <br />
      Você ainda tem {daysRemaining} dias disponíveis
    </>
  );
};

const statusName = (trialIsOver, planActivated) => {
  if (trialIsOver) return 'Bloqueado';

  if (!planActivated) return 'Inativo';

  return 'Ativo';
};

const renderText = ({
  isTrial,
  isFreemium,
  trialIsOver,
  daysRemaining,
  nextPaymentDueDate,
  insertedAt,
  currentPlanInfo,
  suspended,
  endTime,
  value
}) => {
  if (isTrial) {
    return (
      <p>{trialText(trialIsOver, daysRemaining)}</p>
    );
  }

  if (isFreemium) {
    return (
      <>
        <p>Seu plano será renovado automaticamente em { getNextPaymentDueDate(insertedAt, 'MONTHLY') }</p>
        <p>Você será cobrado R$0/mês</p>
      </>
    );
  }

  const formattedValue = currency(parseFloat(value), { decimal: ',', symbol: 'R$' }).format();

  if (!suspended) {
    return (
      <>
        <p>
          Seu plano vai renovar automáticamente em {
            getNextPaymentDueDate(nextPaymentDueDate, currentPlanInfo.frequency)
          }.
        </p>
        <p>Você será cobrado {formattedValue}</p>
      </>
    );
  }

  return (
    <>
      <p>
        Seu plano no valor de {formattedValue},
        venceu no dia {moment(endTime).format('DD/MM/YYYY')}
        e não pode ser renovado automaticamente.
        O plano encontra-se inativo devido à falta de pagamento.
        Para continuar desfrutando das funcionalidades do plano, por favor, regularize o pagamento.
      </p>
    </>
  );
};

function PlanInfo() {
  const {
    subscription, isTrial, isFreemium, planActivated, trialIsOver
  } = useAuth();

  const {
    planName, nextPaymentDueDate, suspended, endTime, insertedAt, value
  } = subscription;

  const { currentPlanInfo } = useChoosePlan();
  const daysRemaining = getDaysBetweenDates(Date.now(), nextPaymentDueDate);

  return (
    <S.Container>
      <S.Card className="info">
        <div className="header">
          <S.Status active={planActivated}>{statusName(trialIsOver, planActivated)}</S.Status>

          <S.Color plan={planName} />
          <h2 className="title">{currentPlanInfo.name}</h2>
        </div>
        <div className="content">
          {renderText({
            isTrial,
            isFreemium,
            trialIsOver,
            daysRemaining,
            nextPaymentDueDate,
            insertedAt,
            currentPlanInfo,
            suspended,
            endTime,
            value
          })}
        </div>
      </S.Card>
      <span className="divider" />
      <S.Card>
        <div className="header">
          <h2 className="title">Seu plano inclui</h2>
        </div>

        <div className="content">
          <S.Benefits>
            {planBenefits(planName).benefits().map((benefit) => (
              <li key={benefit}>
                <span>
                  <IconChecked color="#346BF7" />
                </span>
                {benefit}
              </li>
            ))}
          </S.Benefits>
        </div>
      </S.Card>
    </S.Container>
  );
}

export default PlanInfo;
