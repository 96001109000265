import styled from 'styled-components';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 1.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h4 {
    color: ${(props) => props.theme.fontColor};
    font-weight: 500;
  }

  > button {
    font-size: 14px;
    font-weight: 600;
  }
`;

export { ContentWrapper, HeaderWrapper };
