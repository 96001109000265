import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.backgroundColor};

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: ${(props) => (props.mobileSidebar ? '100%' : '0')};
    position: absolute;
    top: ${(props) => (props.mobileSidebar ? '5rem' : '-100vh')};
    transition: all 400ms;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 4rem;
  padding: 1rem;

  > .ui.label {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.blue {
      background: ${({ theme }) => theme.primaryColor} !important;

      &:hover {
        background: ${({ theme }) => theme.primaryColor} !important;
        opacity: 0.9;
      }
    }
  }
`;
