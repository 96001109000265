import { Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  padding: 1rem;
`;

export const InputLabel = styled.label`
  color: #000;
  font-weight: 500;
  font-family: ${(props) => props.theme.fontFamily} !important;
`;

export const StyledFormField = styled(Form.Field)`
  &.field {
    height: 100px;
    min-width: 8em;
    width: calc(${(props) => props.growth * 100}% - 1em);
    margin: 0 0.5em !important;
    flex-grow: ${(props) => props.growth * 4};
  }
`;

export const RequireIcon = styled(Icon)`
  color: #FBBD08;
  margin-left: 0.3em !important;
`;

export const HideBlock = styled.div`
  display: none;
`;
