/* eslint-disable react/prop-types */
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const handleStatus = (days, status) => {
  if (status === 'signed') return '-';

  if (status === 'wait_sign' && days < 1) {
    return (
      <Link to="/plans">
        Expirado - Reenviar
      </Link>
    );
  }

  return `${days} dias`;
};

const DaysLeft = ({
  document
}) => (
  <ShortenedField clamp={2}>
    {handleStatus(document.daysLeftToSignIfFreemium, document.status)}
  </ShortenedField>
);

export default DaysLeft;
