/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';

import ModalCreatePayment from '../ModalCreatePayment/ModalCreatePayment';
import ModalPaymentHeader from '../shared/components/ModalPaymentHeader/ModalPaymentHeader';
import * as S from './styles';

const ModalPaymentInfo = ({
  open,
  closeModal,
  openPaymentFormModal
}) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {}, []);

  if (!open) {
    return null;
  }

  const toggleDontShowAgain = () => {
    setDontShowAgain(!dontShowAgain);
    localStorage.setItem('dontShowPaymentInfo', dontShowAgain);
  };

  return (
    <>
      <Modal
        open={open}
        size="small"
        style={{ maxWidth: '670px', borderRadius: '8px' }}
      >
        <ModalPaymentHeader mode="info" closeModal={closeModal} />
        <Modal.Content scrolling>
          <S.Text>
            <S.TextTitle>Como a cobrança automática funciona?</S.TextTitle>
            <S.TextBody>
              <p>Quando você automatiza suas cobranças pela Contraktor, você economiza tempo e esforço para focar em outras coisas mais importantes.</p>

              <p>
                A cobrança será feita pelo nosso parceiro: O
                <a
                  href="https://www.asaas.com/?gclid=CjwKCAiA4KaRBhBdEiwAZi1zzqkABkgXt0am4yVFpm0D3qLUpiVgPNjr2v-9koTc3ZamXutgHe8fbhoCqvkQAvD_BwE"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  &nbsp;banco Assas
                </a>.
                Mas você vai poder cadastrar as informações e acompanhar todo o processo de pagamento por aqui mesmo.
              </p>

              <p>E sabe qual é o melhor? Nós só vamos cobrar você se o pagamento for efetuado, então se o seu pagador não cumprir a parte dele, você não terá nenhum custo.</p>
            </S.TextBody>
          </S.Text>
        </Modal.Content>
        <S.Footer>
          <Button
            className="tax-info-button"
            label={(
              <S.ButtonText>
                <span className="button-text-tiny button-text-blue">Valor por pagamento de </span>
                <span className="button-text-bold button-text-blue">R$3,58</span>
              </S.ButtonText>
            )}
          />
          <S.FooterActions>
            <Button
              className="action-button back-button"
              onClick={closeModal}
              label={(
                <S.ButtonText>
                  <span className="button-text-normal button-text-blue">voltar</span>
                </S.ButtonText>
              )}
            />
            <Button
              className="action-button create-payment-button"
              onClick={() => openPaymentFormModal()}
              label={(
                <S.ButtonText>
                  <span className="button-text-normal button-text-white">Criar cobrança automática</span>
                </S.ButtonText>
              )}
            />
          </S.FooterActions>
          <S.CheckboxContainer>
            <input
              id="dont-show-again"
              type="checkbox"
              onChange={toggleDontShowAgain}
              name="dontShowAgain"
            />
            <label className="checkbox-label" htmlFor="dontShowAgain">
              Não mostrar essa informação novamente
            </label>
          </S.CheckboxContainer>
        </S.Footer>
      </Modal>
      <ModalCreatePayment open={openPaymentModal} closeModal={() => setOpenPaymentModal(false)} />
    </>
  );
};

ModalPaymentInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openPaymentFormModal: PropTypes.func.isRequired
};

export default ModalPaymentInfo;
