import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../Button';
import DialogModal from '../../DialogModal';

const ModalCancelationConfirmed = ({ open, onClose }) => {
  const history = useHistory();

  const buttonDefaultStyle = {
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontSize: '16px',
    maxWidth: '48.6666667%'
  };

  const messageTextStyle = {
    fontFamily: 'Nunito',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: '#5E5E62'
  };

  const ConfirmButton = () => (
    <Button
      onClick={() => onClose()}
      style={{
        background: '#0251DE',
        color: '#ffffff',
        ...buttonDefaultStyle
      }}
    >
      Fechar
    </Button>
  );

  const Title = () => (
    <span style={{ fontSize: '2.125rem', lineHeight: '2.875rem' }}>
      Seu plano está cancelado <span role="img" aria-label="cancel emoji" style={{ fontSize: '34px' }}>❌</span>
    </span>
  );

  const Message = () => (
    <>
      <p style={{ ...messageTextStyle }}>
        Você cancelou o seu plano. Seu acesso estará limitado à
        visualização até que um novo plano seja contratado.
      </p>
    </>
  );

  return (
    <DialogModal
      open={open}
      title={<Title />}
      message={<Message />}
      confirmButton={<ConfirmButton />}
      modalSize="small"
      cancelButton={
          (
            <Button
              onClick={() => history.push('/plans')}
              style={{
                background: '#CAE6FF',
                color: '#001E2F',
                border: '0.0625rem solid #CAE6FF',
                ...buttonDefaultStyle
              }}
            >
              Ver outros planos
            </Button>
          )
        }
    />
  );
};

ModalCancelationConfirmed.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalCancelationConfirmed;
