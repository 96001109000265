import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Loader, Popup } from 'semantic-ui-react';

import { updateTitle } from '../../../redux/ducks/starterWizard';
import api from '../../../utils/backend';
import { Wrapper, CustomInput, TitleLabel } from './styles';

const Header = () => {
  const { title: documentTitle, id } = useSelector((state) => state.starterWizard);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(documentTitle);

  useEffect(() => {
    const handleUpdateTitle = async () => api.put(`/documents/${id}`, {
      document: {
        title: title === '' ? 'Sem título' : title
      }
    });

    setLoading(true);

    if (title === documentTitle) {
      setLoading(false);
    }

    const debounce = setTimeout(() => {
      handleUpdateTitle();
      setLoading(false);
      dispatch(updateTitle(title === '' ? 'Sem título' : title));
    }, 2000);

    return () => clearTimeout(debounce);
  }, [title, dispatch, documentTitle, id]);

  return (
    <>
      <TitleLabel>Título do documento</TitleLabel>
      <Wrapper>
        <Popup
          content="Aqui você pode editar o título do seu documento"
          trigger={<Icon name="info circle" />}
        />

        <CustomInput
          type="text"
          placeholder="Título do documento"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Loader
          inline
          size="small"
          active={loading}
        />
      </Wrapper>
    </>
  );
};

export default Header;
