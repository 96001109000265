import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from 'react';

import { useModal } from '../../../../../Modal';
import * as S from '../styles';

const UpSellWhatsappField = () => {
  const { openModal } = useModal();

  const controlProps = {
    checked: false,
    name: 'upsell_send_via_whatsapp',
    onClick: () => {
      openModal('upsellWhatsapp');
    }
  };

  const radioButton = <Radio {...controlProps} />;

  return (
    <S.SendToWhatsappContainer columns="1fr" width="auto">
      <FormControlLabel
        control={radioButton}
        label={(
          <S.InputLabel
            style={{
              fontWeight: 500
            }}
          >
            <span>Enviar documento via WhatsApp</span>
          </S.InputLabel>
        )}
        labelPlacement="end"
      />
    </S.SendToWhatsappContainer>
  );
};

export default UpSellWhatsappField;
