import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
  justify-content: center;

  > .ui.loader {
    position: relative !important;
  }

  Button, Button:hover {
    width: 100%;
    background-color: #ffffff;
    box-shadow: none;

    .button-label {
      font-family: Poppins;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 1.25px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;

      .add-icon {
        margin-right: 13px;
        width: 20px;
        height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
`;

export const PaymentCard = styled.div`
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;

  .context-menu-button {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
`;

export const PaymentInfoDiv = styled.div`
  padding: 11px 16px 11px 16px;
  display: table;

  .header-info-div {
    display: flex;
    align-items: center;
    width: 100%;
    
    .header-info {
      font-family: Poppins;
      font-weight: 500;
      font-size: 10px;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
    }
  }

  .payment-value-div {
    display: flex;
    align-items: center;
    width: 100%;

    .payment-value {
      font-family: Poppins;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);
      text-transform: uppercase;
    }
  }

  .footer-info {
    display: flex;
    align-items: center;
    width: 100%;
    .payment-due-date {
      font-family: Poppins;
      font-weight: 500;
      font-size: 10px;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
    }
    .payment-status {
      margin-left: 8px;
      font-family: Poppins;
      font-weight: 400;
      font-size: 10px;
      letter-spacing: 0.3px;
    }
  }
`;

export const CreatePaymentsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreatePaymentInfo = styled.div`
  width: 100%;
  text-align: center;
`;

export const CreatePaymentImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32.2px;
`;

export const CreatePaymentTitle = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.25px;
  color: #000000;
  margin-bottom: 12px;
`;

export const CreatePaymentSubTitle = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
  color: #000000;
  opacity: 0.6;
`;

export const CreatePaymentButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .new-payment-button, .new-payment-button:hover {
    background: rgba(52, 106, 247, 0.12);
    box-shadow: none;
    padding: 16px 50px 16px 50px;
    border-radius: 6px;
    color: #346AF7;
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 1.25px
  }
`;
