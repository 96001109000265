import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader as SemanticLoader } from 'semantic-ui-react';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import useQueryString from '../../hooks/useQueryString';
import { fetchDocuments } from '../../redux/ducks/documents';
import backend from '../../utils/backend';
import Paginator from '../Paginator';
import Columns from './Columns';
import { TableWrapper, PaginatorWrapper } from './styles';
import TableHeader from './TableHeader';
import TableItem from './TableItem';

const columns = [
  {
    key: 'number',
    title: 'Número',
    width: '6%',
    component: Columns.Number,
    highlight: true,
    plans: ['Trial', 'Freemium', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'title',
    title: 'Título',
    width: '22%',
    component: Columns.Title,
    center: false,
    plans: ['Trial', 'Freemium', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'status',
    title: 'Status',
    width: '20%',
    component: Columns.Status,
    center: false,
    plans: ['Trial', 'Freemium', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'shares',
    title: 'Assinantes',
    width: '15%',
    component: Columns.Shares,
    center: false,
    plans: ['Trial', 'Freemium', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'insertedAt',
    title: 'Enviado em',
    width: '18%',
    component: Columns.InsertedAt,
    center: false,
    plans: ['Trial', 'Freemium', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'finishedAt',
    title: 'Concluído em',
    width: '18%',
    component: Columns.CompletedAt,
    center: false,
    plans: ['Trial', 'Essential', 'Light', 'Unlimited']
  },
  {
    key: 'finishedAt',
    title: 'Dias disponíveis para assinatura',
    width: '15%',
    component: Columns.DaysLeft,
    center: false,
    plans: ['Freemium']
  }
];

const DocumentsList = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentPlan } = useAuth();
  const { query } = useQueryString();

  const { documentsList, pagination } = useSelector((state) => state.documents);

  const [loading, setLoading] = useState(true);

  const onFetchDocuments = useCallback(async () => {
    const formatedQuery = transform(query, (result, num, key) => {
      const res = result;
      res[snakeCase(key)] = num;
    });

    if (pathname === '/trash') {
      return backend.get('/documents', {
        params: {
          ...formatedQuery,
          filter: 'trash'
        }
      }).then((res) => {
        dispatch(fetchDocuments(res.data));
        setLoading(false);
      });
    }
    if (Object.keys(query).length > 0) {
      return backend.get('/documents', {
        params: formatedQuery
      }).then((res) => {
        dispatch(fetchDocuments(res.data));
        setLoading(false);
      });
    }
    return backend.get('/documents').then((res) => {
      dispatch(fetchDocuments(res.data));
      setLoading(false);
    });
  }, [pathname, query, dispatch]);

  useEffect(() => {
    onFetchDocuments();
  }, [onFetchDocuments]);

  const filteredColumns = columns.filter((c) => c.plans.includes(currentPlan.name));

  return (
    <>
      <TableHeader
        columns={filteredColumns}
      />
      {documentsList && documentsList.length === 0 && (
        <SemanticLoader active={loading} />
      )}
      <TableWrapper>
        {documentsList && documentsList.map((document) => (
          <TableItem
            key={document.id}
            document={document}
            columns={filteredColumns}
          />
        ))}
      </TableWrapper>
      <PaginatorWrapper>
        <Paginator
          pagination={pagination}
        />

      </PaginatorWrapper>
    </>
  );
};

export default DocumentsList;
