import { useTheme } from '@contraktor-tech/ui';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import 'phoenix_html';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import newTheme from '../_refactor/_utils/themes';
import { ModalProvider } from './components/_new/Modal';
import GlobalLoader from './components/GlobalLoader';
import { AuthProvider } from './containers/AuthContext/AuthContext';
import { ChoosePlanProvider } from './containers/NewPaymentPage/contexts/ChoosePlanContext';
import { StorageDocumentProvider } from './containers/StorageDocumentContext';
import AppProvider from './hooks';
import Routes from './routes';
import GlobalStyles from './utils/globalStyles';
import theme from './utils/theme';

moment.locale('pt-br');

function App() {
  const materialUiTheme = useTheme();
  const history = useHistory();
  const tagManagerArgs = React.useMemo(() => ({
    gtmId: process.env.REACT_APP_GTM_ID
  }), []);
  const [cacheCleaned, setCacheCleaned] = useState(false);

  history.listen((locationls) => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
      TagManager.dataLayer({
        dataLayer: {
          page: locationls.pathname
        }
      });
    }
  });

  useEffect(() => {
    const hasSubscriptionDefined = JSON.parse(localStorage.getItem('contraktor:user'))?.subscription;

    if (localStorage.getItem('cache_cleaned') !== 'true' && !hasSubscriptionDefined) {
      localStorage.clear();
      setCacheCleaned(true);
      localStorage.setItem('cache_cleaned', true);
    }

    setCacheCleaned(localStorage.getItem('cache_cleaned') === 'true');
  }, []); //eslint-disable-line

  useLayoutEffect(() => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }
  }, [tagManagerArgs]);

  return cacheCleaned && (
    <ThemeProvider theme={{ ...theme, ...newTheme, contraktorUi: materialUiTheme }}>
      <GlobalStyles />
      <GlobalLoader />
      <LastLocationProvider>
        <AppProvider>
          <ModalProvider>
            <AuthProvider>
              <ChoosePlanProvider>
                <StorageDocumentProvider>
                  <ToastContainer limit={2} autoClose={2000} />
                  <Routes />
                </StorageDocumentProvider>
              </ChoosePlanProvider>
            </AuthProvider>
          </ModalProvider>
        </AppProvider>
      </LastLocationProvider>
    </ThemeProvider>
  );
}

export default App;
