import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Header, Modal
} from 'semantic-ui-react';

import Button from '../Button';
import { StyledModal, ActionContainer } from './styles';

const SignModal = ({
  trigger,
  signer,
  onSign
}) => {
  const [open, setOpen] = useState(false);

  function handleSign() {
    setOpen(false);
    onSign();
  }

  if (!signer) {
    return <div>Loading...</div>;
  }

  return (
    <StyledModal
      trigger={trigger}
      size="mini"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(!open)}
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Confirmar Dados para Assinar</Header>
          <div>
            <b>{signer.name.split(' ')[0]}</b> confirme seus dados abaixo para assinar o documento.
          </div>

          <div style={{ wordBreak: 'break-word', overflow: 'hidden', marginTop: 10 }}>
            <b>{signer.name}</b>
            <br />
            <b>{signer.email}</b>
          </div>

          <ActionContainer>
            <Button primary onClick={() => handleSign()}>
              Assinar
            </Button>
            <Button link onClick={() => setOpen(!open)}>
              Voltar
            </Button>
          </ActionContainer>
        </Modal.Description>
      </Modal.Content>
    </StyledModal>
  );
};

SignModal.propTypes = {
  trigger: PropTypes.node.isRequired,
  signer: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  onSign: PropTypes.func.isRequired
};

export default SignModal;
