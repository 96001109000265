import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.whiteColor};
  font-family: 'Nunito', sans-serif;

  > span {
    margin-left: 0.5rem;
  }
`;
