import styled from 'styled-components';

export const InputLabel = styled.div`
  font-family: Roboto;
  color: #00000099;
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 400;
`;

export const ModalWrapper = styled.div`
  text-align: center;

  .modal-header {
    width: 100%;

    span {
      font-family: 'Nunito';
      font-size: 2.125rem;
      line-height: 2.875rem;
      font-weight: 600;
    }

    img {
      width: -webkit-fill-available;
      margin-left: 1.5625rem;
    }
  }

  .modal-content {
    margin-top: 1rem;
    font-family: Poppins;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #00000099;

    p {
      font-weight: bold;
      margin-top: 1rem;
      font-family: Poppins;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .justify-options {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 1rem;

    @media ${({ theme }) => theme.breakPoints.small} {
      grid-template-columns: 100%;
    }

    .MuiFormControlLabel-label {
      text-align: initial;
      color: #605D62;
    }
  }

  > .MuiTextField-root .MuiOutlinedInput-notchedOutline {
    border-color: #0000004D!important
  }

  .form-field {
    width: 100%;
    display: grid;

    .ui.input>input {
      width: 100%!important;
    }

    .input-label {
      font-family: Poppins;
      rgba(0,0,0,.87);
      font-size: 1rem;
      letter-spacing: 0.15px;
      font-weight: 400;
      margin: 1rem 0 0.5rem 0;
      width: -webkit-fit-content;
    }
  }

  .modal-footer {
    width: 100%;
    margin-top: 2rem;
    display: flow-root;

    > .MuiButton-contained.Mui-disabled {
      color: #ffffff!important;
      background-color: #CAE6FFAD!important;
    }

    .left-button {
      width: 48.71%;
      float: left;
      box-shadow: none;
      background-color: #CAE6FF;
      color: #000000;
      font-size: 0.875rem!important;
      text-transform: none!important;
    }

    .left-button:hover {
      width: 48.71%;
      float: left;
      box-shadow: none;
      background-color: #CAE6FF;
      color: #000000;
      font-size: 0.875rem!important;
      text-transform: none!important;
    }

    .right-button {
      width: 48.71%;
      float: right;
      box-shadow: none;
      background-color: #0251DE;
      font-size: 0.875rem!important;
      text-transform: none!important;
    }
  }

  @media ${({ theme }) => theme.breakPoints.small} {
    .modal-footer {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      flex-direction: column-reverse;
      grid-gap: 1rem;
  
      > .MuiButton-contained.Mui-disabled {
        color: #ffffff!important;
        background-color: #CAE6FFAD!important;
      }
  
      .left-button {
        width: 100%;
        box-shadow: none;
        background-color: #CAE6FF;
        color: #000000;
        font-size: 0.875rem!important;
        text-transform: none!important;
      }
  
      .left-button:hover {
        width: 100%;
        box-shadow: none;
        background-color: #CAE6FF;
        color: #000000;
        font-size: 0.875rem!important;
        text-transform: none!important;
      }
  
      .right-button {
        width: 100%;
        box-shadow: none;
        background-color: #0251DE;
        font-size: 0.875rem!important;
        text-transform: none!important;
      }
    }
  }  
`;
