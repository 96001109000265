/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Label = styled.label`
  color: #000;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily} !important;
`;
