import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import DocumentStatusLabel from '../../DocumentStatusLabel';

const Status = ({ document }) => {
  const { trialIsOver, isFreemium } = useAuth();
  const statusBlocked = trialIsOver || isFreemium;

  if (statusBlocked) {
    return (
      <Link to="/plans">
        <span>Acompanhar status do documento</span>
      </Link>
    );
  }

  return (
    <DocumentStatusLabel status={document.status} />
  );
};

Status.propTypes = {
  document: PropTypes.shape({
    status: PropTypes.string
  }).isRequired
};

export default Status;
