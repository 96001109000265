import { Button as ButtonUI } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Button = styled(ButtonUI)`
  height: 48px;
`;

export const FormContainer = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  overflow-y: auto;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  overflow-y: scroll;

  > button {
    margin-top: 1rem !important;
  }
`;

export const Container = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.4em 1.4em;

  > button {
    margin-left: 1rem;
  }
 
  > h2 {
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 1em;
  }

  > .form {
    padding-top: 1.2em;
  }

  .reactivate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reactivate-text {
    margin-bottom: 1rem;
  }

  @media ${({ theme }) => theme.breakPoints.medium} {
    flex-direction: column;

    > button {
      margin: 1rem 0; 
    }
  }
`;
