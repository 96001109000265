/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
// import NumberFormat from 'react-number-format';
import CurrencyInput from 'react-currency-input-field';

const ValueInputCustom = React.forwardRef(({ inputRef, ...rest }) => {
  const { onChange } = rest;

  return (
    <CurrencyInput
      {...rest}
      ref={inputRef}
      decimalsLimit={2}
      onValueChange={(value) => {
        onChange({
          target: {
            name: rest.name,
            value
          }
        });
      }}
      prefix="R$ "
    />
  );
});

ValueInputCustom.propTypes = {
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ValueInputCustom;
