/* eslint-disable react/prop-types */
import { Icon } from '@contraktor-tech/ui';
import React, { useRef, useEffect, useState } from 'react';

import * as S from './styles';

const Popover = ({ children, trigger, direction }) => {
  const wrapperRef = useRef(null);
  const backdropRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  useEffect(
    () => {
      const node = wrapperRef.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
        };
      }
      return null;
    },
    [wrapperRef]
  );

  return (
    <S.Wrapper open={isHovered} ref={wrapperRef}>
      <S.TriggerWrapper open={isHovered}>{trigger}</S.TriggerWrapper>
      {isHovered && (
        <S.Content direction={direction}>
          <S.CloseButton onClick={() => setIsHovered(false)}>
            <Icon>close</Icon>
          </S.CloseButton>
          {children}
        </S.Content>
      )}
      <S.Backdrop id="popoverComponent-backdrop-el" ref={backdropRef} open={isHovered} />
    </S.Wrapper>
  );
};

export default Popover;
