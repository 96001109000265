import styled from 'styled-components';

export const Text = styled.div`
  width: 100%;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  padding-left: 19px;
  padding-right: 19px;
`;

export const TextTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 24px;
  margin-top: 11px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TextBody = styled.div`
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.5px;
  p {
    margin-bottom: 24px;
  }
`;

export const ButtonText = styled.div`
  > span {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.0313rem;
  }

  .button-text-tiny {
    font-weight: 400;
  }

  .button-text-normal {
    font-weight: 500;
  }

  .button-text-bold {
    font-weight: 600
  }

  .button-text-blue {
    color: #346AF7;
  }

  .button-text-white {
    color: #ffffff
  }
`;

export const Footer = styled.div`
  padding: 11px 24px 0 24px;
  width: 100%;
  display: table;
  clear: both;

  .tax-info-button, .tax-info-button:hover {
    width: 100%;
    box-shadow: none;
    background: rgba(52, 106, 247, 0.12);
    color: #346AF7;
    cursor: unset;
  }
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0.6);
  }

  .checkbox-label {
    padding: 0 11px 0 11px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const FooterActions = styled.div`
  margin: 24px 0 32px 0;
  display: table;
  width: 100%;
  
  .action-button, .action-button:hover {
    width: 100%;
    display: flow-root;
    padding: 16px 0 16px 0;
    box-shadow: none;
  }

  .back-button, .back-button:hover {
    float: left;
    background: rgba(52, 106, 247, 0.12);
  }
  
  .create-payment-button, .create-payment-button:hover {
    float: right;
    background: #346AF7;
  }
`;
