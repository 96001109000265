import React, {
  useCallback, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../_components/Button';
import Steps from '../../../_components/Steps';
import { CustomContainer } from '../../../_shared/styles';
import Error from '../../../../_legacy/components/_new/_payment_form/Error';
import ModalPaymentConfirmation from '../../../../_legacy/components/_new/ModalPaymentConfirmation/ModalPaymentConfirmation';
import gatewayApi from '../../../../_legacy/components/_new/services/gateway/config';
import MainLayout from '../../../../_legacy/components/MainLayout';
import { useChoosePlan } from '../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import { useMixpanel } from '../../../../_legacy/hooks/MixpanelProvider';
import { subscribe } from '../../../../_legacy/services/api/adapters/gateway';
import AddressForm from './_components/AddressForm';
import InvoiceInfoCard from './_components/InvoiceInfoCard';
import PageTitle from './_components/PageTitle';
import PaymentForm from './_components/PaymentForm';
import SafeEnvironmentChip from './_components/SafeEnvironmentChip';
import TermsAndConditions from './_components/TermsAndConditions';
import { Footer, Wrapper } from './styles';

export default function Checkout() {
  const formRef = useRef(null);
  const history = useHistory();
  const { planSelected } = useChoosePlan();
  const { Mixpanel } = useMixpanel();

  const [voucherData, setVoucherData] = useState(null);
  const [paymentInProcess, setPaymentInProcess] = useState(false);
  const [newSubscription, setNewSubscription] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [valid, setValid] = useState(false);
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    cardNumber: '',
    holderName: '',
    expirationDate: '',
    ccv: '',
    voucher: '',
    postCode: '',
    state: '',
    city: '',
    street: '',
    number: '',
    neighborhood: '',
    compliment: '',
    document: ''
  });

  const steps = [
    'Pagamento',
    'Cadastro'
  ];

  const frequencies = {
    ANNUAL: 'YEARLY',
    MONTHLY: 'MONTHLY'
  };

  const handleSuccess = () => {
    setSuccessModal(true);
    setPaymentInProcess(false);
    Mixpanel.track('checkout_successful-payment', { plan_type: planSelected.planName, plan_frequency: planSelected.frequency });
  };

  const handleError = (error) => {
    toast.error(error.response.data.message);
    setErrorMessage(error.response.data.message);
    setStep(2);
    setPaymentInProcess(false);
    Mixpanel.track('checkout_error-payment');
  };

  const registerCreditCard = async (data) => {
    try {
      await gatewayApi.post('/creditCard', data);
    } catch (error) {
      switch (error.response.data.code) {
        case 'invalid_action':
          toast.warn('Você precisa atualizar os dados do cartão de crédito', {
            autoClose: 5000
          });
          history.push('/payment?updateCreditCard=true');
          break;
        default:
          break;
      }

      throw error;
    }
  };

  const finishCheckout = useCallback(async (data) => {
    try {
      setPaymentInProcess(true);
      const {
        plan,
        voucher,
        expirationDate,
        ...creditCardPayload
      } = data;

      const {
        param,
        planName,
        frequency
      } = planSelected;

      const expirationMonth = expirationDate.split('/')[0];
      const expirationYear = expirationDate.split('/')[1];

      const subscribePayload = {
        voucher,
        planName,
        frequency,
        isInstallment: param.includes('installment')
      };

      await registerCreditCard({
        ...creditCardPayload,
        expirationMonth,
        expirationYear
      });

      const { data: response } = await subscribe({
        ...subscribePayload,
        frequency: frequencies[frequency],
        voucher: voucher === '' ? null : voucher
      });

      setNewSubscription(response);
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  }, [planSelected]); //eslint-disable-line

  const emitSubmitEvent = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const previousStep = useCallback(() => {
    if (step > 0) setStep(step - 1);
    if (step === 0) history.goBack();

    Mixpanel.track('checkout_button_voltar');
  }, [step, history, Mixpanel]);

  const onSubmit = useCallback((data) => {
    const updatedFormData = {
      ...formData,
      ...data
    };

    setFormData(updatedFormData);

    const finished = step === steps.length - 1;

    if (finished) {
      finishCheckout(updatedFormData);
    } else {
      Mixpanel.track('checkout_button_avancar');
      setStep(step + 1);
    }
  }, [setFormData, formData, finishCheckout, step, steps, Mixpanel]);

  const stepsComponents = [
    <>
      <SafeEnvironmentChip />
      <PaymentForm
        ref={formRef}
        onSubmit={onSubmit}
        setIsValid={(val) => setValid(val)}
        defaultValues={formData}
        setVoucher={(data) => setVoucherData(data)}
      />
    </>,
    <>
      <InvoiceInfoCard />
      <AddressForm
        ref={formRef}
        onSubmit={onSubmit}
        setIsValid={(val) => setValid(val)}
        defaultValues={formData}
        voucher={voucherData}
      />
    </>,
    <Error errorMessage={errorMessage} onBackStep={() => setStep(0)} />
  ];

  const handleBackButtonClick = () => {
    Mixpanel.track('checkout_button_mudar-plano');
    history.goBack();
  };

  return (
    <>
      <MainLayout
        header
        headerVariant="white"
        options
        backButtonText="Mudar de plano"
        backButtonOnClick={handleBackButtonClick}
      >
        <CustomContainer>
          <Wrapper>
            <PageTitle />
            <Steps steps={steps} activeStep={step} />
            {stepsComponents[step]}
            {
              step === steps.length - 1 && (
                <TermsAndConditions />
              )
            }
            {
              step < 2 && (
                <Footer>
                  <Button
                    disabled={paymentInProcess}
                    label="Voltar"
                    onClick={previousStep}
                    theme="secondary"
                  />
                  <Button
                    disabled={!valid}
                    loading={paymentInProcess}
                    label={step === steps.length - 1 ? 'Finalizar Compra' : 'Avançar'}
                    onClick={emitSubmitEvent}
                    theme="primary"
                  />
                </Footer>
              )
            }
          </Wrapper>
        </CustomContainer>
      </MainLayout>
      <ModalPaymentConfirmation
        nextPaymentDate={newSubscription?.nextPaymentDueDate}
        open={successModal}
        planSelected={planSelected}
        closeModal={() => setSuccessModal(false)}
      />
    </>
  );
}
