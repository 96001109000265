import CardContent from '@mui/material/CardContent';
import currency from 'currency.js';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { useAuth } from '../../../../../../../_legacy/containers/AuthContext/AuthContext';
import { whatsappQuotaPrices } from '../../constants/whatsappQuotaPrices';
import {
  CardTitle,
  CustomDivider,
  CustomTypography,
  StarterInfoTag,
  WhatsAppInfoCardContainer
} from './styles';

export const WhatsappInfoCard = ({ quota }) => {
  const { isUnlimitedPlan } = useAuth();

  const getValuePerUsage = () => {
    const totalValue = whatsappQuotaPrices[quota];
    const valuePerMessage = totalValue / quota;

    return currency(valuePerMessage, { symbol: 'R$', decimal: ',' }).format();
  };

  const paidMessagesText = () => {
    const paidMessages = quota - 15;

    if (paidMessages === 0) return '';
    return ` + ${paidMessages} envios pagos`;
  };

  return (
    <WhatsAppInfoCardContainer>
      <CardContent>
        <CardTitle>
          <FaWhatsapp
            style={{
              color: '#ffffff',
              height: '32px',
              width: '32px',
              alignSelf: 'center'
            }}
          />
          <CustomTypography sx={{ fontSize: 24, fontWeight: 600 }}>
            &nbsp;{quota} Envios via WhatsApp
          </CustomTypography>
          <CustomTypography sx={{ fontSize: 16, fontWeight: 500 }}>
            &nbsp;/mês
          </CustomTypography>
        </CardTitle>
        <CustomDivider variant="middle" />
        <CustomTypography sx={{ fontSize: 36, fontWeight: 600 }} component="div">
          {currency(whatsappQuotaPrices[quota], { symbol: 'R$', decimal: ',' }).format()} <span style={{ fontSize: '18px', fontWeight: '500' }}> /mês</span>
        </CustomTypography>
        <CustomTypography sx={{ mb: 1.5 }}>
          {getValuePerUsage()} por envio
        </CustomTypography>
        {
          isUnlimitedPlan && (
            <StarterInfoTag>
              (15 envios grátis{ paidMessagesText() })
            </StarterInfoTag>
          )
        }
        <CustomTypography sx={{ fontSize: 12, fontWeight: 400 }} marginTop={1.5}>
          *Valor será incluso no valor pago no plano
        </CustomTypography>
      </CardContent>
    </WhatsAppInfoCardContainer>
  );
};

WhatsappInfoCard.propTypes = {
  quota: PropTypes.number.isRequired
};

export default WhatsappInfoCard;
