import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { checkUserIsSigner } from '../../../utils/helpers';
import Button from '../../Button';
import DocumentStatusLabel from '../../DocumentStatusLabel';
import FilePreview from '../../FilePreview';
import SignModal from '../../SignModal';
import {
  Wrapper, Header, SignButtonContainer, ShortenedField
} from './styles';

const DocumentPreview = ({ confirmSign }) => {
  const history = useHistory();
  const {
    user
  } = useAuth();

  const {
    preview, title, status, signature, signers
  } = useSelector(
    (state) => state.document
  );

  const currentSigner = signers && signers.find((signer) => signer.email === user.email);
  const canSign = checkUserIsSigner(signers, user);

  const readOnly = status === 'canceled' && status === 'signed' && !signature;

  return (
    <Wrapper>
      <Header>
        <div>
          <Button link onClick={() => history.push('/documents')}>
            <Icon name="arrow left" />
            Voltar
          </Button>
        </div>
        <ShortenedField clamp={1}>{title || 'Sem título'}</ShortenedField>
        <div>
          <DocumentStatusLabel status={status} />
        </div>
      </Header>
      {canSign && !readOnly && (
        <SignModal
          onSign={confirmSign}
          signer={currentSigner}
          trigger={(
            <SignButtonContainer>
              <Button primary>Assinar documento</Button>
            </SignButtonContainer>
          )}
        />
      )}
      {preview && (
        <>
          <FilePreview previewUrl={preview} />
        </>
      )}
    </Wrapper>
  );
};

DocumentPreview.propTypes = {
  confirmSign: PropTypes.func.isRequired
};

export default DocumentPreview;
