import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px;
  width: 100%;
  height: 48px;

  text-align: center;
  font-size: 14px;

  border-radius: 6px;
  border: 0;
  cursor: pointer;

  ${({ theme, color }) => css`
    background: ${theme[`${color}Button`].background};
    color: ${theme[`${color}Button`].color};

    &:hover {
      background: ${theme[`${color}Button`].hover.background};
      color: ${theme[`${color}Button`].hover.color};
    }

    .MuiCircularProgress-root {
      color: ${theme[`${color}Button`].color};
    }

    ${({ disabled }) => disabled && css`
      background: ${theme[`${color}Button`].disabled.background};
      color: ${theme[`${color}Button`].disabled.color};
      cursor: not-allowed;
    `}
  `}
`;
