import React from 'react';
import DotDotDot from 'react-dotdotdot';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { useStorageDocument } from '../../../containers/StorageDocumentContext';
import { formatDateTime } from '../../../utils/localeUtils';
import DocumentStatusLabel from '../../DocumentStatusLabel';
import {
  Wrapper, Card, CardsContainer, LoadingContainer
} from './styles';

function LastDocuments() {
  const { documentsData } = useStorageDocument();
  const { documents: docs, loading } = documentsData;

  return (
    <Wrapper>
      <h4>Últimos documentos enviados</h4>
      {docs && docs.length === 0 && (
        <LoadingContainer>
          <Loader active={loading} inline />
        </LoadingContainer>
      )}
      <CardsContainer>
        {docs && docs.slice(0, 5).map((doc) => (
          <Link
            to={`/document/${doc.id}`}
            className="card-link"
            key={doc.id}
          >
            <Card>
              <div>
                <DotDotDot clamp={1}>
                  {doc.title}
                </DotDotDot>
                <DocumentStatusLabel status={doc.status} />
              </div>

              <span>Enviado em {formatDateTime(doc.inserted_at, true)}</span>
            </Card>
          </Link>
        ))}
      </CardsContainer>
      {docs.length > 0 && (
        <Link to="/documents">Ver mais documentos</Link>
      )}
    </Wrapper>
  );
}

export default LastDocuments;
