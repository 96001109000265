import { Paper, Button } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Container = styled(Paper)`
  min-width: 420px;
  width: 100%;
  border-radius: 8px !important;
  display: flex;
  flex-direction: column;   
  justify-content: space-between;
  padding: 1rem;
  height: fit-content;

  @media ${({ theme }) => theme.breakPoints.medium} {
    min-width: auto;
  }

  * {
    font-family: 'Nunito', sans-serif !important;
  }

  .MuiButton-label {
    font-size: 14px;
  }

  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .container-footer {
    padding: 1rem;
    margin: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;

    @media ${({ theme }) => theme.breakPoints.medium} {
      grid-template-columns: 1fr;
    }
  }

  .description-title {
    font-weight: 700;
    color: ${({ theme }) => theme.surfaceMediumEmphasis};
    text-transform: uppercase;
    font-size: 10px;
    margin: 0;
  }

  .description-container {
    padding: 1rem;
    margin-top: 1rem;
    min-height: 230px;
  }

  .features-list {
    margin: 0.5rem 0 0 0;
    padding: 0;
    list-style: none;
  }

  .feature-list-item {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    margin: 0.5rem 0;
  }

  .feature-list-item-icon {
    width: 15px;
    display: flex;

    > svg {
      color: #b5e5ff;
    }
  }

  .feature-list-item-text {
    margin-left: 0.75rem;
    font-size: 14px;
    color: ${({ theme }) => theme.surfaceMediumEmphasis};
    font-weight: 400;
  }

  .plan-info-title {
    font-weight: 600;
    color: ${({ theme }) => theme.surfaceHighEmphasis};
    font-size: 20px;
  }

  .plan-info-price, .footer-text {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.surfaceMediumEmphasis};
  }

  .icon-container {
    margin-right: 1.5rem;
  }

  .loading-container {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .plans-details {
    width: 100%;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    
    .plans-details-title {
      font-family: Nunito,
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.0313rem;
      color: #346AF7;
    }

    .option-card {
      width: 100%;
      padding: 0.5rem 0 1.75rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2.125rem;
      height: fit-content;
  
      .option-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        width: 59.6659%;

        .option-info-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: none;
          order: 0;
          flex-grow: 0
          font-family: Nunito;
          
          .option-name {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
            letter-spacing: 0.0156rem;
            color: rgba(0, 0, 0, 0.87);
          }

          .option-discount {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1rem;
            letter-spacing: 0.0313rem;
            color: #346AF7;
          }

          .option-price-details {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            letter-spacing: 0.0187rem;
          }
        }
      }

      .option-button-light {
        width: 40.3103%;
        height: 2.625rem;
        border: 1px solid #346AF7;
        background-color: #ffffff;
        box-shadow: none;
        color: #346AF7;
        font-family: Nunito;
        font-size: 0.875rem;
        padding: 0!important;
      }

      .option-button-starter {
        width: 40.3103%;
        height: 2.625rem;
        background-color: #346AF7;
        color: #ffffff;
        font-family: Nunito;
        font-size: 0.875rem;
        padding: 0!important;
      }
    }
  }

  .current-plan-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    gap: 204px;
    width: 100%;
    flex: none;
    order: 9;
    flex-grow: 0;
    cursor: pointer;

    .current-plan-info-text {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      font-feature-settings: 'kern' off;
      color: #346AF7;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .current-plan-info-icon {
      color: #346AF7;
      width: 22px;
      height: 22px;
    }

    .current-plan-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      flex: none;
      order: 10;
      flex-grow: 0;
    }
  }

  .details-content {
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .price-details {
      display: flex;
      flex-direction: column;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      
      .plan-name {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.25px;
      }

      .price-description {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.3px;
      }
    }

    .divider {
      margin-left: 1rem; 
      height: 55px;
      mix-blend-mode: normal;
      border: 1px solid #346AF7;
    }

    .renew-dates {
      margin-left: 1rem;
      flex-direction: column;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      letter-spacing: 0.3px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const ChoosePlanButton = styled(Button)`
  width: 100%;
  padding: 14px !important;
`;
