import React from 'react';

import EmailTemplate from './EmailTemplate';

export default function TemplatePreview({
  logo,
  userName,
  orgName
}) {
  const signers = [
    {
      name: 'signer 1'
    },
    {
      name: 'signer 2'
    }
  ];

  return (
    <EmailTemplate
      logoUrl={logo}
      ownerName={userName}
      companyName={orgName}
      documentName="Contrato de Prestação de Serviços"
      signers={signers}
    />
  );
}
