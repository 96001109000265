import { Box, styled as muiStyled, Typography } from '@mui/material';
import styled from 'styled-components';

import themes from '../../../../_utils/themes';

export const ModalContent = muiStyled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '32px',
  alignItems: 'center',
  backgroundColor: themes.background.light,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '670px',
  borderRadius: '8px'
});

export const ModalTitle = muiStyled(Typography)({
  fontFamily: themes.fonts.family.Poppins,
  color: themes.fonts.color.darkBlue,
  fontSize: themes.fonts.size.big,
  fontWeight: themes.fonts.weight.bold,
  textAlign: 'center'
});

export const ModalBody = muiStyled(Typography)({
  fontFamily: themes.fonts.family.Poppins,
  color: themes.fonts.color.neutralGrey,
  fontSize: themes.fonts.size.medium,
  fontWeight: themes.fonts.weight.thin,
  textAlign: 'center'
});

export const JustifyOptions = styled.div`
    min-width: 568px;
    display: grid;
    grid-template-columns: 50% 50%;

    @media ${({ theme }) => theme.breakPoints.small} {
      min-width: 100%;
      grid-template-columns: 100%;
    }

    .MuiFormControlLabel-label {
      text-align: initial;
      color: #605D62;
    }
`;

export const Footer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  gap: 16px;
`;
