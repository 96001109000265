import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../Button';
import DialogModal from '../../DialogModal';

const ModalCancelPlan = ({ open, onClose, handleConfirm }) => {
  const history = useHistory();

  const buttonDefaultStyle = {
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontSize: '16px',
    maxWidth: '48.6666667%',
    padding: '10px 0'
  };

  const messageTextStyle = {
    fontFamily: 'Nunito',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: '#5E5E62'
  };

  const ConfirmButton = () => (
    <Button
      onClick={() => handleConfirm()}
      style={{
        background: '#0251DE',
        color: '#ffffff',
        ...buttonDefaultStyle
      }}
    >
      Confirmar cancelamento
    </Button>
  );

  const CloseButton = () => (
    <Button
      onClick={() => onClose()}
      style={{
        ...buttonDefaultStyle,
        background: '#ffffff',
        color: '#0251DE',
        width: '100%',
        maxWidth: '100%'
      }}
    >
      Fechar
    </Button>
  );

  const Title = () => (
    <span style={{ fontSize: '2.125rem', lineHeight: '2.875rem' }}>
      Tem certeza que deseja cancelar seu plano? <span role="img" aria-label="sad emoji" style={{ fontSize: '34px' }}>😢</span>
    </span>
  );

  const Message = () => (
    <>
      <p style={{ ...messageTextStyle }}>
        Antes de cancelar seu plano, gostaríamos de confirmar se você
        tem certeza de que deseja continuar com essa ação.
      </p>
      <br />
      <br />
      <p style={{ ...messageTextStyle, fontWeight: 'bold' }}>
        Lembre-se de que você perderá acesso a todos os recursos exclusivos
        e benefícios que acompanham seu plano.
      </p>
      <br />
      <br />
      <p style={{ ...messageTextStyle }}>
        Se você estiver enfrentando algum problema, entre em contato conosco antes
        de cancelar para ver se podemos ajudá-lo de alguma forma.
      </p>
    </>
  );

  return (
    <DialogModal
      open={open}
      title={<Title />}
      message={<Message />}
      confirmButton={<ConfirmButton />}
      modalSize="small"
      hasThirdButton
      thirdButton={<CloseButton />}
      cancelButton={
          (
            <Button
              onClick={() => history.push('/plans')}
              style={{
                background: '#CAE6FF',
                color: '#001E2F',
                border: '0.0625rem solid #CAE6FF',
                ...buttonDefaultStyle
              }}
            >
              Ver outros planos
            </Button>
          )
        }
    />
  );
};

ModalCancelPlan.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

export default ModalCancelPlan;
