import PropTypes from 'prop-types';
import React, { useEffect, useContext, createContext } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import { OnboardTool } from '../../components/OnboardTool';
import { useAuth } from '../AuthContext/AuthContext';

// Cria um contexto para manter o estado dos parâmetros da URL
const URLParamsContext = createContext({});

// Componente que consome o contexto e atualiza a URL
const WithURLParams = ({ children }) => {
  const { plan, cycle } = useContext(URLParamsContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Se plan e frequency estiverem definidos, adiciona à URL
    if (plan && cycle) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('plan', plan);
      searchParams.set('frequency', cycle);
      history.replace({ search: searchParams.toString() });
    }
  }, [plan, cycle]); // eslint-disable-line

  return children;
};

const PrivateRoute = ({ children }) => {
  const { signed, loading, subscription } = useAuth();

  if (loading) {
    return loading;
  }

  if (!signed) {
    return <Redirect to="/login" />;
  }

  // Adiciona o provedor de contexto com os valores do plano e da frequência
  return (
    <URLParamsContext.Provider value={{
      plan: subscription.planName,
      cycle: subscription.cycle || 'UNDEFINED'
    }}
    >
      <OnboardTool />
      <WithURLParams>{children}</WithURLParams>
    </URLParamsContext.Provider>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrivateRoute;
