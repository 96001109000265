import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useQueryString from '../../hooks/useQueryString';
import Button from '../Button';

const PaginationWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;

  > span {
    margin: 0 1em;
  }

  > button {
    height: 3.2em;
  }

  > .selection {
    height: 2.2em !important;
    min-height: 2.2em !important;
    line-height: 0.5em !important;
    z-index: 999;
  }

  > .dropdown {
    padding: 0.616667em !important;
  }
  `;

const PageLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ArrowButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Paginator = ({
  pagination: {
    pageNumber,
    totalPages
  }
}) => {
  const { query } = useQueryString();
  const maxConcurrentPages = 5;

  const pages = [];
  let left = pageNumber - Math.floor(maxConcurrentPages / 2.0);
  let right = pageNumber + Math.floor(maxConcurrentPages / 2.0);

  if ((left < 1) && (right > totalPages)) {
    left = 1;
    right = totalPages;
  } else if (left < 1) {
    right = Math.min(right + Math.abs(left) + 1, totalPages);
    left = 1;
  } else if (right > totalPages) {
    left = Math.max(left - (right - totalPages), 1);
    right = totalPages;
  }

  for (let i = left; i <= right; i += 1) {
    pages.push(i);
  }

  return (
    <PaginationWrapper>
      {(pageNumber > 1) ? (
        <>
          <PageLink to={`?${queryString.stringify({ ...query, page: 1 })}`}>
            <ArrowButton size="small" unpadded>
              {'<<'}
            </ArrowButton>
          </PageLink>

          <PageLink to={`?${queryString.stringify({ ...query, page: pageNumber - 1 })}`}>
            <ArrowButton size="small" unpadded disabled={!pageNumber > 1}>
              {'<'}
            </ArrowButton>
          </PageLink>
        </>
      ) : (
        <>
          <ArrowButton size="small" unpadded disabled>
            {'<<'}
          </ArrowButton>
          <ArrowButton size="small" unpadded disabled>
            {'<'}
          </ArrowButton>
        </>
      )}

      <span>
        Página{' '}
        <strong>
          {pageNumber} de {totalPages}
        </strong>
      </span>

      {(totalPages > 1 && pageNumber < totalPages) ? (
        <>
          <PageLink to={`?${queryString.stringify({ ...query, page: pageNumber + 1 })}`}>
            <Button size="small" unpadded>
              {'>'}
            </Button>
          </PageLink>
          <PageLink to={`?${queryString.stringify({ ...query, page: totalPages })}`}>
            <Button size="small" unpadded>
              {'>>'}
            </Button>
          </PageLink>
        </>
      ) : (
        <>
          <Button size="small" unpadded disabled>
            {'>'}
          </Button>
          <Button size="small" unpadded disabled>
            {'>>'}
          </Button>
        </>
      )}
    </PaginationWrapper>

  );
};

Paginator.propTypes = {
  pagination: PropTypes.shape({
    pageNumber: PropTypes.number,
    totalPages: PropTypes.number
  })
};

Paginator.defaultProps = {
  pagination: {
    pageNumber: 0,
    totalPages: 0
  }
};

export default Paginator;
