import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};

  .steps-container {
    width: 100%;
    max-width: 45rem;
  }
`;
