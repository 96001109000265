import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import * as S from './styles';

const contentDataByPlan = {
  Light: {
    documentLimit: 15,
    planName: 'Light'
  },
  Freemium: {
    documentLimit: 5,
    planName: 'Free'
  }
};

const modalContent = (planName) => ({
  title: 'Você atingiu o limite de envios de documentos deste mês 😢',
  description: (
    <span>
      <p>
        Ficamos muito felizes em ver você utilizando
        a ContraktorSign para agilizar os seus processos!
      </p>

      <p>
        <bold>
          É importante lembrar que o limite de {contentDataByPlan[planName]?.documentLimit} envios
          é mensal e será renovado a cada mês, ou seja, após um mês do bloqueio, você
          terá novamente {contentDataByPlan[planName]?.documentLimit} envios disponíveis no
          Plano {contentDataByPlan[planName]?.planName}! Confira a data de
          renovação na página de Planos e Preços.
        </bold>
      </p>

      <p>
        Se você necessita enviar mais documentos neste mês ou quer ter acesso a
        funcionalidades ainda melhores, como <bold>envios ilimitados e recursos extras</bold>,
        nós temos planos ideais esperando por você! Verifique os planos disponíveis
        e escolha aquele que melhor se adequa às suas necessidades.
      </p>

      <p>
        Clique em <bold>“Escolher Plano Ideal”</bold> para obter mais
        informações e encontrar a solução perfeita para continuar aproveitando
        a eficiência da assinatura digital com a ContraktorSign!
      </p>
    </span>
  )
});

function DispatchErrorModal({ open, onClose }) {
  const history = useHistory();
  const { subscription: { planName } } = useAuth();

  return (
    <S.Container
      open={open}
      onClose={onClose}
      fullWidth
    >
      <div className="modal-header">
        <p className="modal-title">{modalContent(planName).title}</p>
      </div>
      <div className="modal-content">
        <p className="content-text">{modalContent(planName).description}</p>
      </div>
      <div className="modal-footer">
        <Button className="left" onClick={onClose} fullWidth label={`Continuar no Plano ${contentDataByPlan[planName].planName}`} />
        <Button className="right" onClick={() => history.push('/plans')} fullWidth label="Escolher Plano Ideal" />
      </div>
    </S.Container>
  );
}

DispatchErrorModal.defaultProps = {
  open: false,
  onClose: () => {}
};

DispatchErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DispatchErrorModal;
