import styled from 'styled-components';

import { Breakpoints } from '../../utils/constants';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .background {
    position: fixed;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
`;

export const LogoWrapper = styled.div`
  width: 60%;
  margin-bottom: 3rem;

  img {
    width: 100%;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.3))
  }

  @media (min-width: ${Breakpoints.TABLET}px) {
    width: 360px;
  }
`;

export const Box = styled.div`
  width: 80%;

  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  border-radius: 8px;
  z-index: 1;

  @media (min-width: ${Breakpoints.TABLET}px) {
    width: 518px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  > h3 {
    font-weight: bold;
  }

  > h5 {
    margin-top: .25rem;
    font-weight: 100;
    color: #616161;
  }

  > h5 > a {
    font-size: 14px;
  }
`;
