import { Container, FormControl, Paper } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

import themes from '../_utils/themes';

export const CustomContainer = muiStyled(Container)({
  '& .MuiContainer-root': {
    justifyContent: 'center'
  }
});

export const Content = muiStyled(Paper)({
  backgroundColor: themes.background.light,
  textAlign: 'left',
  padding: '32px'
});

export const ExtraLargeTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.family.Poppins};
  font-size: ${({ theme }) => theme.fonts.size.extraLarge};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
  color: ${({ theme }) => theme.fonts.color.darkBlue};
  padding-bottom: ${({ bottomGap }) => bottomGap};
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.family.Poppins};
  font-size: ${({ theme }) => theme.fonts.size.larger};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.fonts.color.darkBlue};
  padding-bottom: ${({ bottomGap }) => bottomGap};
`;

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.family.Poppins};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.thin};
  color: ${({ theme }) => theme.fonts.color.darkGrey};
  padding-bottom: ${({ bottomGap }) => bottomGap};
`;

export const Disclaimer = styled.p`
  font-family: ${({ theme }) => theme.fonts.family.Poppins};
  font-size: ${({ theme }) => theme.fonts.size.smaller};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  color: ${({ theme }) => theme.fonts.color.lightGrey};
  letter-spacing: ${({ theme }) => theme.fonts.letterSpacing.small};
`;

export const CustomFormControl = muiStyled(FormControl)({
  gap: '16px'
});
