/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import { Content } from './styles';

const DialogModal = ({
  open,
  title,
  message,
  icon,
  modalSize,
  confirmButton,
  cancelButton,
  contentStyle,
  hasThirdButton,
  thirdButton,
  ...rest
}) => (
  <Modal open={open} size={modalSize} {...rest}>
    <Content style={contentStyle}>
      <h2 className="title">{title}</h2>
      {icon && <span className="icon">{icon}</span>}
      <p className="message">{message}</p>
      <div
        className="action-buttons"
        style={{ justifyContent: cancelButton ? 'space-around' : 'center' }}
      >
        {cancelButton && cancelButton}
        {confirmButton}
      </div>
      {
        hasThirdButton && (
          <div className="third-button">
            {thirdButton}
          </div>
        )
      }
    </Content>
  </Modal>
);

DialogModal.defaultProps = {
  icon: null,
  modalSize: 'tiny',
  contentStyle: {},
  cancelButton: null,
  thirdButton: null
};

DialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node
  ]),
  modalSize: PropTypes.string,
  contentStyle: PropTypes.object,
  confirmButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node
  ]).isRequired,
  cancelButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node
  ]),
  hasThirdButton: PropTypes.bool.isRequired,
  thirdButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.node
  ])
};

export default DialogModal;
