export const modalTexts = {
  downsell: {
    title: 'Você está contratando um plano abaixo do seu! 😢',
    body: 'Esse plano possui menos funções e armazenamento do que o seu plano atual. Quais são seus motivos para contratar um plano inferior?',
    justify: true,
    voucher: false
  },
  upsell: {
    title: 'Você está fazendo um Upgrade no seu plano! 🚀',
    body: 'Você está contratando um plano melhor do que o seu plano atual! A cobrança será feita assim que você confirmar, para poder aproveitar suas melhorias o quanto antes. 😉',
    justify: false,
    voucher: true
  },
  monthlyToAnnual: {
    title: 'Você está ganhando 10% de desconto! 🎉',
    body: 'Você está mudando o seu plano mensal para o anual. Fazendo isso você ganha 10% de desconto na contratação!',
    justify: false,
    voucher: true
  },
  yearlyToMonthly: {
    title: 'Você está perdendo o seu desconto! 😢',
    body: 'Seu plano atual é o {plano_atual} Anual e você está contratando o {plano_a_contratar} Mensal, perdendo seu desconto de 10%.',
    justify: true,
    voucher: false
  },
  trialToFreemium: {
    title: 'Você está fazendo um Upgrade no seu plano! 🚀',
    body: 'Você está contratando um plano melhor do que o seu plano atual! A cobrança será feita assim que você confirmar, para poder aproveitar suas melhorias o quanto antes. 😉',
    justify: false,
    voucher: false
  },
  downsellFree: {
    title: 'Você está contratando um plano abaixo do seu! 😢',
    body: 'Esse plano possui menos funções e armazenamento do que o seu plano atual. Quais são seus motivos para contratar um plano inferior?',
    justify: true,
    voucher: false
  }
};
