import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 6px 8px;
  border-radius: 4px;

  ${({ status }) => {
    switch (status) {
      case 'canceled':
        return 'background-color: #E53935;';
      case 'pending':
        return 'background-color: #fbb408;';
      case 'signed':
        return 'background-color: #4CAF50;';
      default:
        return 'background-color: #fbb408;';
    }
  }}

  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:before {
    content: "";
    
    width: 8px;
    height: 8px;
    margin-right: 8px;

    border-radius: 50%;
    background-color: #fff;
  }
`;
