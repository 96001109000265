import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '../AuthContext/AuthContext';

const LogoutPage = () => {
  const { user, signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  if (user) {
    return null;
  }

  return <Redirect to="/login" />;
};

export default LogoutPage;
