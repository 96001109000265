// Action types

export const SET_DOCUMENT = '@contraktor/document/SET_DOCUMENT';

// Reducer

export const INITIAL_STATE = {
  id: null,
  number: null,
  title: null,
  status: null,
  signature: null,
  signers: null,
  preview: null,
  download: null,
  insertedAt: null,
  createdAt: null,
  completedAt: null
};

export default function document(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DOCUMENT:
      return action.payload;

    default:
      return state;
  }
}

// Actions

export function setDocument(payload) {
  return {
    type: SET_DOCUMENT,
    payload
  };
}
