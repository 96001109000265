import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Checkout from '../_refactor/pages/Payment/Checkout';
import Plans from '../_refactor/pages/Plans';
import DocumentSignTokenPage from './containers/DocumentSignTokenPage';
import DocumentsPage from './containers/DocumentsPage';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import LogoutPage from './containers/LogoutPage';
import NewSignUp from './containers/NewSignUp';
import NotFoundPage from './containers/NotFoundPage';
import PrivateRoute from './containers/PrivateRoute';
import SignersPage from './containers/SignersPage';
import SignInPage from './containers/SignInPage';
import StarterDocumentPage from './containers/StarterDocumentPage';
import UserProfilePage from './containers/UserProfilePage';
// new pages
import NewDocumentPage from './pages/Document';
import HomePage from './pages/Home';

const Routes = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    const updateRoutes = (path) => {
      setPreviousPath(currentPath);
      setCurrentPath(path);
    };

    updateRoutes(location.pathname);
  }, [location]); // eslint-disable-line

  useEffect(() => {
    const isExitingDocumentPage = previousPath?.includes('/document/') && !currentPath?.includes('/document/');

    if (isExitingDocumentPage) localStorage.removeItem('draftSigners');
  }, [currentPath, previousPath]);

  useEffect(() => {
    const invalidRoutes = [null, undefined, '/logout', '/login', '/recover_password'];
    const isUnauthorized = !invalidRoutes.includes(previousPath) && currentPath === '/login';

    if (isUnauthorized) localStorage.setItem('redirectTo', previousPath);
  }, [currentPath, previousPath]);

  return (
    <Switch>
      <Route exact path={['/register', '/register/choose-plan', '/register/success']} component={NewSignUp} />

      <Route exact path="/login" component={SignInPage} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/recover_password" component={ForgotPasswordPage} />
      <Route exact path="/documents/sign" component={DocumentSignTokenPage} />

      <PrivateRoute>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/plans" component={Plans} />
          <Route exact path="/subscription/payment" component={Checkout} />

          <Route exact path={['/register/payment', '/register/payment/success']} component={Checkout} />
          <Route exact path="/update-payment-info" component={Checkout} />
          <Route exact path="/documents/:id" component={StarterDocumentPage} />
          <Route exact path="/document/:id" component={NewDocumentPage} />
          <Route exact path={['/profile', '/company', '/payment', '/users']} component={UserProfilePage} />
          <Route exact path="/payment/upgrade-plan" component={Plans} />
          <Route exact path="/payment/upgrade-plan-migration" component={Plans} />
          <Route exact path={['/documents', '/trash']} component={DocumentsPage} />
          <Route exact path="/signers" component={SignersPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
