import React from 'react';

import backgroundImage from '../../assets/img/bg_2.svg';
import logoHorizontalWhite from '../../assets/img/logo_horiz_white_2.svg';
import CookiesAlert from '../../components/_new/CookiesAlert';
import LoginForm from '../../components/LoginForm';
import {
  Wrapper, LogoWrapper, Box, BoxHeader
} from './styles';

const SignInPage = () => (
  <Wrapper>
    <img className="background" src={backgroundImage} alt="Imagem de fundo com as cores da contraktor" />
    <LogoWrapper>
      <img
        src={logoHorizontalWhite}
        alt="Imagem com as letras CK e o nome da plataforma Contraktor"
      />
    </LogoWrapper>
    <Box>
      <BoxHeader>
        <h3>Acesse os planos Free, Light, Essential e Unlimited</h3>
        <h5>Para acessar outros planos, {' '}
          <a href="https://app.contraktor.com.br" target="blank">
            clique aqui
          </a>
        </h5>
      </BoxHeader>
      <LoginForm />
    </Box>
    <CookiesAlert />
  </Wrapper>
);

export default SignInPage;
