import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useCallback } from 'react';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const MixpanelContext = createContext({});

const MixpanelProvider = ({ children }) => {
  const identifyPeople = useCallback((user, fromLogin = true) => {
    if (fromLogin) {
      mixpanel.people.set({
        plan_type: user.organization?.planName,
        $first_name: user.firstName,
        $last_name: user.lastName,
        $email: user.email,
        user_id: user.id,
        organization_id: user.organizationId,
        organization_name: user.organization?.name,
        last_login: new Date().toISOString()
      });
    } else {
      mixpanel.people.set({
        $first_name: user.name,
        $email: user.email,
        last_access: new Date().toISOString()
      });
    }
    mixpanel.identify(user.email);
    mixpanel.register('', {
      Email: user.email
    });
  }, []);

  const authTrack = useCallback((event = 'login') => {
    if (event === 'login') {
      mixpanel.time_event('Logout');
      mixpanel.track('Login');
      mixpanel.people.increment('Acessos Realizados', 1);
    } else {
      mixpanel.track('Logout');
      mixpanel.reset();
    }
  }, []);

  return (
    <MixpanelContext.Provider value={{
      Mixpanel: mixpanel, mixpanelIdentify: identifyPeople, authTrack
    }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

function useMixpanel() {
  const context = useContext(MixpanelContext);

  if (!context) {
    throw new Error('useMixpanel must be used within an MixpanelProvider');
  }

  return context;
}

MixpanelProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { MixpanelProvider, useMixpanel };
