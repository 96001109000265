import React from 'react';

const Vector = () => (
  <svg width="221" height="165" viewBox="0 0 221 165" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M221 144.711H0V144.821H221V144.711Z" fill="#E6E6E6" />
    <path d="M207.033 148.609H198.736V148.72H207.033V148.609Z" fill="#E6E6E6" />
    <path d="M168.738 151.641H164.897V151.752H168.738V151.641Z" fill="#E6E6E6" />
    <path d="M132.299 150.117H123.742V150.227H132.299V150.117Z" fill="#E6E6E6" />
    <path d="M54.8615 150.059H35.7715V150.17H54.8615V150.059Z" fill="#E6E6E6" />
    <path d="M31.8369 150.059H29.0391V150.17H31.8369V150.059Z" fill="#E6E6E6" />
    <path d="M125.076 147.442H83.6699V147.553H125.076V147.442Z" fill="#E6E6E6" />
    <path d="M104.754 124.998H19.4081C18.7395 124.996 18.0987 124.73 17.6263 124.257C17.154 123.784 16.8887 123.142 16.8887 122.474V2.50172C16.8945 1.83696 17.1624 1.20135 17.6341 0.732932C18.1058 0.264518 18.7433 0.00113876 19.4081 0H104.754C105.423 0 106.065 0.265902 106.538 0.73921C107.012 1.21252 107.278 1.85446 107.278 2.52382V122.474C107.278 123.143 107.012 123.785 106.538 124.258C106.065 124.732 105.423 124.998 104.754 124.998ZM19.4081 0.0884003C18.7688 0.0895712 18.1561 0.344347 17.7045 0.796805C17.2528 1.24926 16.9992 1.86243 16.9992 2.50172V122.474C16.9992 123.113 17.2528 123.726 17.7045 124.179C18.1561 124.631 18.7688 124.886 19.4081 124.887H104.754C105.394 124.886 106.007 124.631 106.459 124.179C106.911 123.727 107.166 123.113 107.167 122.474V2.50172C107.166 1.86203 106.911 1.24887 106.459 0.796535C106.007 0.344203 105.394 0.0895687 104.754 0.0884003H19.4081Z" fill="#E6E6E6" />
    <path d="M200.362 124.998H115.012C114.343 124.996 113.702 124.73 113.229 124.257C112.756 123.784 112.489 123.143 112.488 122.474V2.50172C112.495 1.83656 112.764 1.20095 113.237 0.732656C113.709 0.264364 114.347 0.00112634 115.012 0H200.362C201.026 0.00230039 201.663 0.266194 202.133 0.734484C202.604 1.20277 202.871 1.83773 202.877 2.50172V122.474C202.877 123.142 202.613 123.782 202.141 124.255C201.67 124.728 201.03 124.995 200.362 124.998ZM115.012 0.0884003C114.372 0.0895687 113.759 0.344203 113.307 0.796535C112.855 1.24887 112.6 1.86203 112.599 2.50172V122.474C112.6 123.113 112.855 123.727 113.307 124.179C113.759 124.631 114.372 124.886 115.012 124.887H200.362C201.002 124.886 201.615 124.631 202.067 124.179C202.52 123.727 202.774 123.113 202.776 122.474V2.50172C202.774 1.86203 202.52 1.24887 202.067 0.796535C201.615 0.344203 201.002 0.0895687 200.362 0.0884003H115.012Z" fill="#E6E6E6" />
    <path d="M115.344 8.25684H25.9492V71.9623H115.344V8.25684Z" fill="#FAFAFA" />
    <path d="M116.998 8.25684H26.7236V71.9623H116.998V8.25684Z" fill="#F5F5F5" />
    <path d="M114.682 69.3586V10.8555L29.0397 10.8555V69.3586H114.682Z" fill="#FAFAFA" />
    <path d="M44.6338 69.3586L58.2209 10.8555H76.0335L62.4464 69.3586H44.6338Z" fill="white" />
    <path d="M65.3271 69.3586L78.9186 10.8555H86.963L73.3715 69.3586H65.3271Z" fill="white" />
    <path d="M114.682 69.3586V10.8555H114.386V69.3586H114.682Z" fill="#FAFAFA" />
    <path d="M24.2119 8.15039V33.3974H30.8905C30.8905 33.3974 42.8732 25.5696 45.618 9.46755C45.6845 9.08191 45.6657 8.68637 45.5629 8.30877C45.4601 7.93118 45.2759 7.58069 45.0231 7.28196C44.7703 6.98324 44.4551 6.74353 44.0997 6.57972C43.7443 6.4159 43.3573 6.33195 42.966 6.33377H26.0285C25.5467 6.33377 25.0847 6.52516 24.744 6.86584C24.4033 7.20653 24.2119 7.66859 24.2119 8.15039Z" fill="#E0E0E0" />
    <path d="M24.2119 79.7545V35.4352H30.8905C30.8905 35.4352 44.4467 61.5353 46.0158 79.7545H24.2119Z" fill="#E0E0E0" />
    <path d="M30.6975 33.3975H24.4565C24.0879 33.3975 23.7891 33.6963 23.7891 34.0649V34.7677C23.7891 35.1363 24.0879 35.4351 24.4565 35.4351H30.6975C31.0661 35.4351 31.3649 35.1363 31.3649 34.7677V34.0649C31.3649 33.6963 31.0661 33.3975 30.6975 33.3975Z" fill="#F5F5F5" />
    <path d="M118.897 8.15037V33.3974H112.205C112.205 33.3974 100.205 25.5696 97.4776 9.46753C97.4119 9.082 97.4313 8.68676 97.5343 8.30949C97.6374 7.93223 97.8217 7.58206 98.0744 7.28352C98.327 6.98498 98.6419 6.74529 98.9969 6.58122C99.3519 6.41716 99.7385 6.3327 100.13 6.33375H117.085C117.565 6.33608 118.026 6.52836 118.365 6.86867C118.705 7.20897 118.896 7.66969 118.897 8.15037Z" fill="#E0E0E0" />
    <path d="M118.897 79.7545V35.4352H112.205C112.205 35.4352 98.6536 61.5353 97.0801 79.7545H118.897Z" fill="#E0E0E0" />
    <path d="M112.4 35.4308H118.641C119.01 35.4308 119.309 35.132 119.309 34.7634V34.0606C119.309 33.692 119.01 33.3932 118.641 33.3932H112.4C112.032 33.3932 111.733 33.692 111.733 34.0606V34.7634C111.733 35.132 112.032 35.4308 112.4 35.4308Z" fill="#F5F5F5" />
    <path d="M116.879 87.8077H102.191V144.711H116.879V87.8077Z" fill="#FAFAFA" />
    <path d="M112.958 144.711H116.879V138.138H108.848L112.958 144.711Z" fill="#F4F8FF" />
    <path d="M46.6095 87.8077H31.9219V144.711H46.6095V87.8077Z" fill="#E0E0E0" />
    <path d="M116.878 87.8077H43.1875V142.448H116.878V87.8077Z" fill="#FAFAFA" />
    <path d="M65.3271 78.1456C65.3271 83.4496 70.4367 87.8077 76.6865 87.8077H82.3265C88.5719 87.8077 93.6814 83.4585 93.6814 78.1456H65.3271Z" fill="#EBEBEB" />
    <path d="M62.0571 66.0039H72.6651C72.6651 66.0039 70.822 70.8659 70.822 73.7301C70.822 76.5942 78.8929 87.8078 67.3611 87.8078C55.8294 87.8078 63.9003 76.5898 63.9003 73.7301C63.9003 70.8703 62.0571 66.0039 62.0571 66.0039Z" fill="#E0E0E0" />
    <path d="M113.837 91.1361H46.2285V104.913H113.837V91.1361Z" fill="#EBEBEB" />
    <path d="M47.108 144.711H43.1875V138.138H51.2231L47.108 144.711Z" fill="#FAFAFA" />
    <path d="M77.9561 98.0223C77.9561 98.4332 78.0779 98.8349 78.3062 99.1765C78.5344 99.5181 78.8589 99.7844 79.2385 99.9416C79.6181 100.099 80.0358 100.14 80.4387 100.06C80.8417 99.9797 81.2119 99.7818 81.5024 99.4913C81.7929 99.2008 81.9908 98.8306 82.0709 98.4276C82.1511 98.0246 82.11 97.6069 81.9527 97.2274C81.7955 96.8478 81.5292 96.5233 81.1876 96.295C80.846 96.0668 80.4443 95.9449 80.0335 95.9449C79.4825 95.9449 78.9541 96.1638 78.5645 96.5534C78.1749 96.943 77.9561 97.4714 77.9561 98.0223Z" fill="#FAFAFA" />
    <path d="M113.837 108.242H46.2285V122.019H113.837V108.242Z" fill="#EBEBEB" />
    <path d="M77.9561 115.128C77.9561 115.539 78.0779 115.94 78.3062 116.282C78.5344 116.624 78.8589 116.89 79.2385 117.047C79.6181 117.204 80.0358 117.245 80.4387 117.165C80.8417 117.085 81.2119 116.887 81.5024 116.597C81.7929 116.306 81.9908 115.936 82.0709 115.533C82.1511 115.13 82.11 114.712 81.9527 114.333C81.7955 113.953 81.5292 113.629 81.1876 113.401C80.846 113.172 80.4443 113.05 80.0335 113.05C79.4825 113.05 78.9541 113.269 78.5645 113.659C78.1749 114.048 77.9561 114.577 77.9561 115.128Z" fill="#FAFAFA" />
    <path d="M113.837 125.342H46.2285V139.119H113.837V125.342Z" fill="#EBEBEB" />
    <path d="M80.0335 134.311C81.1808 134.311 82.1109 133.38 82.1109 132.233C82.1109 131.086 81.1808 130.156 80.0335 130.156C78.8861 130.156 77.9561 131.086 77.9561 132.233C77.9561 133.38 78.8861 134.311 80.0335 134.311Z" fill="#FAFAFA" />
    <path d="M163.487 7.48755H130.678V30.8428H163.487V7.48755Z" fill="#E0E0E0" />
    <path d="M166.166 7.48755H132.543V30.8428H166.166V7.48755Z" fill="#F5F5F5" />
    <path d="M164.022 28.699V9.6311L134.687 9.6311V28.699L164.022 28.699Z" fill="white" />
    <path d="M158.859 23.143V15.1826L139.849 15.1826V23.143H158.859Z" fill="#F5F5F5" />
    <path d="M190.104 19.1653H170.135V40.1072H190.104V19.1653Z" fill="#E0E0E0" />
    <path d="M192.791 19.1653H172.004V40.1072H192.791V19.1653Z" fill="#F5F5F5" />
    <path d="M190.648 37.9634V21.3088H174.149V37.9634H190.648Z" fill="white" />
    <path d="M179.31 29.6361C179.31 28.9007 179.601 28.1953 180.121 27.6749C180.641 27.1545 181.346 26.8615 182.081 26.8604H182.709C183.444 26.8615 184.149 27.1545 184.668 27.6749C185.188 28.1953 185.48 28.9007 185.48 29.6361C185.48 30.3711 185.188 31.076 184.668 31.5957C184.148 32.1155 183.444 32.4075 182.709 32.4075H182.103C181.737 32.4104 181.374 32.3408 181.036 32.2029C180.697 32.0649 180.389 31.8612 180.129 31.6036C179.869 31.346 179.663 31.0395 179.523 30.7018C179.382 30.3641 179.31 30.0019 179.31 29.6361Z" fill="#F5F5F5" />
    <path d="M221 47.2277H168.269H158.682V59.4136H149.095V71.5995H139.508V83.7855H129.921V95.9714H120.334V108.153H110.747V120.339H101.16V132.525H91.5732V144.711H101.16H221V47.2277Z" fill="#FAFAFA" />
    <path d="M145.232 132.525H90.5879V136.18H145.232V132.525Z" fill="#EBEBEB" />
    <path d="M154.819 120.339H100.175V123.994H154.819V120.339Z" fill="#EBEBEB" />
    <path d="M164.406 108.153H109.762V111.808H164.406V108.153Z" fill="#EBEBEB" />
    <path d="M173.993 95.9672H119.349V99.6225H173.993V95.9672Z" fill="#EBEBEB" />
    <path d="M183.581 83.7855H128.937V87.4409H183.581V83.7855Z" fill="#EBEBEB" />
    <path d="M193.167 71.5995H138.522V75.2548H193.167V71.5995Z" fill="#EBEBEB" />
    <path d="M202.754 59.4136H148.109V63.0689H202.754V59.4136Z" fill="#EBEBEB" />
    <path d="M212.341 47.2277H157.696V50.883H212.341V47.2277Z" fill="#EBEBEB" />
    <path d="M211.413 47.2277V59.4136H201.826V71.5995H192.239V83.7855H182.652V95.9714H173.065V108.153H163.478V120.339H153.891V132.525H144.304V144.711H153.891H221V59.4136V47.2277H211.413Z" fill="white" />
    <path d="M110.499 164.672C157.83 164.672 196.199 162.431 196.199 159.668C196.199 156.905 157.83 154.665 110.499 154.665C63.1687 154.665 24.7998 156.905 24.7998 159.668C24.7998 162.431 63.1687 164.672 110.499 164.672Z" fill="#F5F5F5" />
    <path d="M161.396 47.2938C162.015 49.3668 165.206 53.2476 165.458 56.0719C165.71 58.8963 160.269 63.4136 160.269 63.4136C160.269 63.4136 156.202 49.8176 158.748 48.2309C161.294 46.6441 161.396 47.2938 161.396 47.2938Z" fill="#263238" />
    <path d="M150.222 61.9816C149.258 62.7861 148.312 63.4623 147.327 64.1563C146.341 64.8502 145.324 65.4823 144.29 66.0746C143.252 66.6888 142.182 67.2479 141.086 67.7498C140.529 68.0017 139.976 68.2492 139.402 68.4658C139.119 68.5807 138.831 68.6912 138.54 68.7885L137.656 69.0979C136.9 69.3092 136.109 69.3663 135.331 69.2658C134.68 69.1897 134.036 69.0656 133.404 68.8945C132.225 68.5684 131.071 68.1577 129.952 67.6658C127.778 66.7168 125.681 65.6011 123.68 64.3287L124.506 62.7905C125.549 63.2325 126.619 63.6745 127.68 64.059C128.741 64.4436 129.81 64.8502 130.88 65.1817C131.923 65.5235 132.985 65.801 134.062 66.0127C134.557 66.1094 135.057 66.1699 135.561 66.1939C135.936 66.223 136.313 66.184 136.674 66.079C137.683 65.6932 138.669 65.2504 139.627 64.753C140.118 64.4966 140.604 64.2359 141.081 63.953C141.559 63.6701 142.045 63.3961 142.518 63.1043C143.468 62.5165 144.396 61.8933 145.316 61.2524C146.235 60.6115 147.15 59.9264 147.968 59.2766L150.222 61.9816Z" fill="#E58A7B" />
    <path d="M152.555 57.2744C151.458 56.417 146.923 59.2236 145.549 60.3949L146.552 66.4636C146.552 66.4636 153.072 63.732 153.461 61.8668C153.872 59.9176 153.372 57.9286 152.555 57.2744Z" fill="#346AF7" />
    <path opacity="0.2" d="M152.555 57.2744C151.458 56.417 146.923 59.2236 145.549 60.3949L146.552 66.4636C146.552 66.4636 153.072 63.732 153.461 61.8668C153.872 59.9176 153.372 57.9286 152.555 57.2744Z" fill="black" />
    <path d="M167.809 75.6394L154.045 78.3223C146.933 59.0644 148.48 58.4323 148.48 58.4323C149.48 57.8827 150.514 57.3956 151.574 56.9737L151.76 56.903C152.644 56.5803 152.821 56.5317 153.996 56.187C156.021 55.7052 157.908 55.303 160.246 54.9715L160.688 54.9096C161.13 54.8565 161.532 54.8123 161.939 54.7726C162.213 54.7416 162.487 54.7239 162.748 54.7063L163.632 54.6576C165.143 54.5825 166.226 54.6046 166.226 54.6046C165.806 66.6314 166.487 70.0834 167.809 75.6394Z" fill="#346AF7" />
    <path opacity="0.2" d="M167.809 75.6394L154.045 78.3223C146.933 59.0644 148.48 58.4323 148.48 58.4323C149.48 57.8827 150.514 57.3956 151.574 56.9737L151.76 56.903C152.644 56.5803 152.821 56.5317 153.996 56.187C156.021 55.7052 157.908 55.303 160.246 54.9715L160.688 54.9096C161.13 54.8565 161.532 54.8123 161.939 54.7726C162.213 54.7416 162.487 54.7239 162.748 54.7063L163.632 54.6576C165.143 54.5825 166.226 54.6046 166.226 54.6046C165.806 66.6314 166.487 70.0834 167.809 75.6394Z" fill="black" />
    <path d="M157.028 48.6774C156.144 50.1405 159.348 50.8167 161.245 49.3183C162.761 48.1161 162.403 45.3403 161.103 44.695C159.804 44.0497 157.028 48.6774 157.028 48.6774Z" fill="#346AF7" />
    <path opacity="0.4" d="M157.028 48.6774C156.144 50.1405 159.348 50.8167 161.245 49.3183C162.761 48.1161 162.403 45.3403 161.103 44.695C159.804 44.0497 157.028 48.6774 157.028 48.6774Z" fill="black" />
    <path d="M170.426 155.35L166.982 155.606L165.723 147.681L169.166 147.425L170.426 155.35Z" fill="#E58A7B" />
    <path d="M194.78 144.273L191.859 146.112L186.656 139.942L189.578 138.099L194.78 144.273Z" fill="#E58A7B" />
    <path d="M165.723 147.681L166.421 152.083L169.816 151.513L169.17 147.429L165.723 147.681Z" fill="#CF6F64" />
    <path d="M189.582 138.103L186.656 139.942L188.813 142.496L191.801 140.733L189.582 138.103Z" fill="#CF6F64" />
    <path d="M154.258 78.3223C154.258 78.3223 154.209 105.647 156.441 115.937C158.766 126.642 165.604 150.996 165.604 150.996L170.563 150.355C170.563 150.355 166.687 125.753 165.034 115.114C163.235 103.521 164.34 76.3599 164.34 76.3599L154.258 78.3223Z" fill="#346AF7" />
    <path opacity="0.7" d="M154.258 78.3223C154.258 78.3223 154.209 105.647 156.441 115.937C158.766 126.642 165.604 150.996 165.604 150.996L170.563 150.355C170.563 150.355 166.687 125.753 165.034 115.114C163.235 103.521 164.34 76.3599 164.34 76.3599L154.258 78.3223Z" fill="black" />
    <path opacity="0.5" d="M164.03 88.4706L161.599 86.0485C161.691 96.546 162.279 101.731 164.299 107.379C163.981 101.408 163.954 94.3448 164.03 88.4706Z" fill="black" />
    <path d="M157.678 77.6151C157.678 77.6151 165.09 100.197 168.768 113.205C172.648 126.929 187.526 142.107 187.526 142.107L191.796 139.641C191.796 139.641 181.63 125.232 177.89 112.427C170.288 86.433 173.413 80.9257 167.804 75.6394L157.678 77.6151Z" fill="#346AF7" />
    <path opacity="0.7" d="M157.678 77.6151C157.678 77.6151 165.09 100.197 168.768 113.205C172.648 126.929 187.526 142.107 187.526 142.107L191.796 139.641C191.796 139.641 181.63 125.232 177.89 112.427C170.288 86.433 173.413 80.9257 167.804 75.6394L157.678 77.6151Z" fill="black" />
    <path d="M151.854 40.7037C153.825 41.3623 155.615 46.2553 155.69 48.1824C155.765 50.1095 149.98 52.7659 149.98 52.7659C149.98 52.7659 147.429 46.5779 148.141 42.5999C148.45 40.8717 151.854 40.7037 151.854 40.7037Z" fill="#263238" />
    <path d="M124.798 62.9541L122.46 60.4082L121.695 63.5994C121.695 63.5994 123.357 65.0669 124.701 64.563L124.798 62.9541Z" fill="#E58A7B" />
    <path d="M120.09 59.6788L118.455 60.9783L121.695 63.5994L122.46 60.4081L120.09 59.6788Z" fill="#E58A7B" />
    <path d="M190.942 145.453L193.674 142.7C193.721 142.652 193.784 142.624 193.85 142.619C193.917 142.614 193.983 142.633 194.036 142.673L196.525 144.521C196.587 144.569 196.637 144.63 196.673 144.699C196.71 144.768 196.731 144.845 196.736 144.923C196.74 145.001 196.728 145.079 196.7 145.152C196.672 145.226 196.629 145.292 196.574 145.347C195.601 146.289 195.088 146.695 193.886 147.906C193.148 148.653 192.145 149.816 191.124 150.846C190.103 151.875 189.029 150.916 189.356 150.404C190.845 148.074 190.58 147.204 190.682 146.019C190.697 145.805 190.79 145.604 190.942 145.453Z" fill="#263238" />
    <path d="M166.753 155.111H170.634C170.702 155.111 170.768 155.135 170.821 155.178C170.873 155.222 170.909 155.283 170.921 155.35L171.531 158.417C171.545 158.493 171.542 158.57 171.522 158.644C171.502 158.718 171.466 158.787 171.417 158.846C171.368 158.904 171.306 158.952 171.236 158.984C171.167 159.016 171.091 159.032 171.014 159.031C169.657 159.009 169.003 158.93 167.297 158.93C166.249 158.93 164.083 159.036 162.634 159.036C161.184 159.036 161.029 157.604 161.626 157.476C164.278 156.892 165.317 156.092 166.187 155.323C166.345 155.188 166.545 155.113 166.753 155.111Z" fill="#263238" />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.27">
      <path d="M166.673 61.5751L164.552 62.8392L167 72.0461C166.466 69.257 166.704 66.194 166.673 61.5751Z" fill="#346AF7" />
    </g>
    <path d="M154.615 47.2011C156.122 48.4608 158.31 50.2951 160.887 48.1514C163.097 46.3038 164.644 41.4551 160.679 41.2783C158.606 41.1855 154.615 47.2011 154.615 47.2011Z" fill="#263238" />
    <path d="M158.258 48.063C158.258 50.1492 158.58 53.9106 160.247 54.9803C160.247 54.9803 160.096 57.1284 156.357 57.8533C152.251 58.6533 153.997 56.1958 153.997 56.1958C156.136 55.2234 155.765 53.5703 155.057 52.0852L158.258 48.063Z" fill="#E58A7B" />
    <path d="M156.954 49.7029L155.062 52.0853C155.228 52.4296 155.364 52.7874 155.469 53.1549C156.3 52.8632 157.295 51.6963 157.211 50.7814C157.192 50.4091 157.106 50.0435 156.954 49.7029Z" fill="#CF6F64" />
    <path d="M158.456 43.2718C158.898 46.6708 159.168 48.0985 157.806 50.1538C155.76 53.2478 151.49 53.0489 150.036 49.8532C148.71 46.9758 148.215 41.8972 151.238 39.9303C151.903 39.4902 152.669 39.2276 153.464 39.1674C154.259 39.1072 155.056 39.2514 155.78 39.5863C156.503 39.9213 157.129 40.4358 157.597 41.0809C158.066 41.726 158.361 42.4802 158.456 43.2718Z" fill="#E58A7B" />
    <path d="M152.551 37.318C150.253 38.949 154.863 39.4308 154.867 41.7955C154.867 42.7723 156.472 45.8707 158.753 45.6939L160.963 41.769C163.288 38.012 157.793 33.592 152.551 37.318Z" fill="#263238" />
    <path d="M152.55 37.3179C148.506 37.1058 146.742 40.3235 147.206 43.2142C150.028 42.5108 152.946 42.2793 155.843 42.5291C161.386 43.0949 152.55 37.3179 152.55 37.3179Z" fill="#263238" />
    <path d="M159.56 46.2022C159.616 46.862 159.408 47.517 158.981 48.0232C158.411 48.6906 157.655 48.2973 157.39 47.5061C157.164 46.79 157.191 45.5834 157.96 45.2033C158.13 45.1247 158.317 45.0896 158.504 45.1013C158.69 45.113 158.871 45.171 159.03 45.2701C159.189 45.3693 159.321 45.5064 159.413 45.6691C159.506 45.8318 159.556 46.015 159.56 46.2022Z" fill="#E58A7B" />
    <path d="M153.545 45.2033C153.598 45.4773 153.497 45.7248 153.32 45.7602C153.143 45.7956 152.953 45.6011 152.9 45.3182C152.847 45.0353 152.948 44.7922 153.125 44.7569C153.302 44.7215 153.492 44.9292 153.545 45.2033Z" fill="#263238" />
    <path d="M150.456 45.8045C150.509 46.0786 150.408 46.3305 150.231 46.3659C150.054 46.4012 149.864 46.2067 149.811 45.9239C149.758 45.641 149.86 45.3979 150.036 45.3669C150.213 45.336 150.403 45.5261 150.456 45.8045Z" fill="#263238" />
    <path d="M150.121 45.3714L149.427 45.314C149.427 45.314 149.864 45.7604 150.121 45.3714Z" fill="#263238" />
    <path d="M151.367 46.1182C151.243 46.7877 151.029 47.4375 150.73 48.0497C150.889 48.1236 151.062 48.1619 151.237 48.1619C151.411 48.1619 151.584 48.1236 151.743 48.0497L151.367 46.1182Z" fill="#DF5753" />
    <path d="M152.896 48.9337C152.877 48.934 152.859 48.9275 152.844 48.9153C152.83 48.9031 152.82 48.8862 152.817 48.8674C152.815 48.857 152.815 48.8462 152.817 48.8358C152.82 48.8255 152.824 48.8157 152.83 48.807C152.836 48.7984 152.844 48.791 152.853 48.7855C152.862 48.7799 152.872 48.7762 152.883 48.7746C153.225 48.7152 153.547 48.5762 153.825 48.3688C154.103 48.1615 154.329 47.8917 154.483 47.5812C154.487 47.5713 154.493 47.5624 154.501 47.5548C154.508 47.5472 154.517 47.5412 154.527 47.5371C154.537 47.533 154.547 47.5309 154.558 47.5309C154.569 47.5309 154.579 47.5329 154.589 47.537C154.599 47.5411 154.608 47.547 154.616 47.5546C154.623 47.5621 154.629 47.571 154.633 47.5809C154.637 47.5907 154.639 47.6013 154.639 47.612C154.639 47.6226 154.637 47.6332 154.633 47.6431C154.471 47.9805 154.231 48.274 153.931 48.4986C153.632 48.7233 153.283 48.8725 152.914 48.9337H152.896Z" fill="#263238" />
    <path d="M154.257 44.0278C154.217 44.0282 154.177 44.0141 154.146 43.988C153.998 43.8466 153.818 43.7425 153.621 43.6841C153.424 43.6258 153.217 43.6148 153.015 43.6521C152.97 43.6632 152.923 43.6562 152.884 43.6326C152.844 43.6089 152.816 43.5706 152.805 43.5261C152.794 43.4815 152.801 43.4344 152.824 43.395C152.848 43.3557 152.886 43.3273 152.931 43.3161C153.186 43.266 153.449 43.2771 153.699 43.3486C153.948 43.42 154.178 43.5498 154.367 43.7272C154.398 43.7578 154.415 43.7991 154.415 43.8421C154.415 43.8851 154.398 43.9264 154.367 43.957C154.355 43.976 154.339 43.9921 154.32 44.0043C154.301 44.0165 154.279 44.0245 154.257 44.0278Z" fill="#263238" />
    <path d="M148.745 44.642C148.724 44.6466 148.703 44.6466 148.683 44.642C148.663 44.6338 148.644 44.6216 148.629 44.6062C148.614 44.5908 148.601 44.5725 148.593 44.5523C148.585 44.5322 148.581 44.5105 148.581 44.4888C148.581 44.467 148.586 44.4454 148.595 44.4254C148.689 44.1837 148.839 43.9681 149.034 43.7966C149.228 43.6251 149.461 43.5027 149.713 43.4398C149.734 43.4353 149.756 43.4353 149.777 43.4396C149.799 43.444 149.819 43.4526 149.837 43.465C149.854 43.4774 149.869 43.4934 149.881 43.5118C149.892 43.5303 149.9 43.5509 149.903 43.5724C149.907 43.5933 149.908 43.6149 149.904 43.6359C149.899 43.657 149.891 43.6769 149.879 43.6947C149.867 43.7124 149.852 43.7276 149.834 43.7392C149.816 43.7509 149.796 43.7588 149.775 43.7624C149.576 43.8166 149.393 43.9163 149.239 44.0537C149.086 44.191 148.966 44.3622 148.891 44.5536C148.876 44.58 148.855 44.6022 148.83 44.6177C148.804 44.6333 148.775 44.6417 148.745 44.642Z" fill="#263238" />
    <path d="M189.917 147.058C189.841 147.056 189.767 147.035 189.701 146.998C189.635 146.962 189.578 146.909 189.537 146.846C189.506 146.808 189.484 146.763 189.476 146.715C189.467 146.667 189.472 146.617 189.488 146.572C189.643 146.156 190.863 145.891 191 145.86C191.016 145.857 191.032 145.859 191.047 145.865C191.062 145.871 191.075 145.882 191.084 145.895C191.093 145.908 191.098 145.924 191.098 145.939C191.098 145.955 191.093 145.971 191.084 145.984C190.845 146.377 190.377 147.036 189.939 147.058H189.917ZM190.841 146.063C190.359 146.187 189.723 146.408 189.643 146.629C189.635 146.649 189.634 146.671 189.638 146.693C189.643 146.714 189.654 146.733 189.67 146.748C189.697 146.794 189.736 146.831 189.782 146.857C189.829 146.883 189.882 146.896 189.935 146.894C190.169 146.881 190.501 146.576 190.841 146.063Z" fill="#346AF7" />
    <path d="M190.643 146.063C190.201 146.063 189.649 145.953 189.485 145.714C189.449 145.66 189.432 145.595 189.438 145.53C189.443 145.464 189.471 145.403 189.516 145.356C189.555 145.307 189.604 145.266 189.659 145.237C189.715 145.208 189.776 145.191 189.839 145.188H189.874C190.369 145.188 191.05 145.855 191.076 145.882C191.087 145.892 191.094 145.905 191.098 145.919C191.102 145.933 191.102 145.947 191.098 145.961C191.094 145.975 191.087 145.988 191.077 145.998C191.067 146.008 191.054 146.015 191.041 146.019C190.911 146.05 190.777 146.065 190.643 146.063ZM189.874 145.352H189.847C189.808 145.353 189.769 145.363 189.734 145.381C189.699 145.4 189.668 145.426 189.644 145.458C189.582 145.542 189.6 145.59 189.622 145.621C189.755 145.824 190.431 145.939 190.846 145.891C190.581 145.621 190.243 145.433 189.874 145.352Z" fill="#346AF7" />
    <path d="M165.484 155.717C165.187 155.743 164.889 155.668 164.64 155.504C164.581 155.458 164.535 155.398 164.505 155.329C164.476 155.261 164.464 155.185 164.472 155.111C164.472 155.067 164.483 155.023 164.505 154.984C164.526 154.945 164.558 154.913 164.596 154.89C165.002 154.651 166.426 155.359 166.585 155.438C166.6 155.446 166.612 155.458 166.62 155.473C166.628 155.488 166.631 155.505 166.629 155.522C166.627 155.539 166.62 155.554 166.609 155.567C166.598 155.58 166.583 155.589 166.567 155.593C166.211 155.667 165.848 155.709 165.484 155.717ZM164.83 155.001C164.778 154.997 164.726 155.008 164.68 155.031C164.665 155.039 164.653 155.052 164.645 155.067C164.637 155.082 164.634 155.099 164.636 155.115C164.631 155.165 164.639 155.215 164.658 155.262C164.677 155.308 164.708 155.349 164.746 155.381C164.967 155.571 165.537 155.602 166.289 155.478C165.832 155.239 165.339 155.077 164.83 155.001Z" fill="#346AF7" />
    <path d="M166.55 155.584H166.519C166.077 155.394 165.224 154.643 165.285 154.258C165.285 154.17 165.365 154.055 165.586 154.033H165.652C166.421 154.033 166.62 155.438 166.629 155.5C166.631 155.515 166.629 155.53 166.624 155.544C166.618 155.558 166.61 155.57 166.598 155.58C166.583 155.586 166.566 155.587 166.55 155.584ZM165.666 154.187H165.617C165.475 154.187 165.467 154.254 165.467 154.271C165.431 154.506 166.015 155.093 166.457 155.354C166.368 155.009 166.143 154.196 165.648 154.196L165.666 154.187Z" fill="#346AF7" />
    <path d="M153.211 44.7701L152.517 44.7083C152.517 44.7083 152.954 45.1591 153.211 44.7701Z" fill="#263238" />
    <path d="M156.245 60.7926C148.85 62.4413 151.135 57.9683 151.577 56.9826L151.763 56.9119C152.647 56.5892 152.824 56.5406 154 56.1958C156.051 55.642 158.14 55.2358 160.25 54.9803L160.692 54.9185C161.134 54.861 161.536 54.8168 161.942 54.7815C162.216 54.7505 162.49 54.7328 162.751 54.7151L163.635 54.6621C163.786 55.3516 164.152 59.0379 156.245 60.7926Z" fill="#E58A7B" />
    <path d="M167.854 74.7909L168.716 75.9047C168.782 75.9887 168.689 76.108 168.53 76.139L154.094 78.9501C153.971 78.9766 153.856 78.9501 153.834 78.8573L153.467 77.6506C153.467 77.5666 153.538 77.4738 153.67 77.4473L167.611 74.7334C167.653 74.721 167.697 74.7198 167.74 74.7299C167.783 74.74 167.822 74.761 167.854 74.7909Z" fill="#26395A" />
    <path d="M166.285 76.7135L166.661 76.6428C166.736 76.6428 166.785 76.5809 166.771 76.5323L166.285 74.9676C166.285 74.919 166.197 74.8969 166.122 74.9102L165.75 74.9809C165.675 74.9809 165.627 75.0472 165.64 75.0914L166.126 76.6605C166.139 76.7047 166.214 76.7312 166.285 76.7135Z" fill="#346AF7" />
    <path d="M157.829 81.929L154.951 80.8594L155.393 83.5954C155.393 83.5954 157.479 84.3468 158.545 83.3832L157.829 81.929Z" fill="#E58A7B" />
    <path d="M152.21 81.5843L151.525 83.3788L155.393 83.5954L154.951 80.8594L152.21 81.5843Z" fill="#E58A7B" />
    <path d="M155.168 78.8793L155.544 78.8086C155.615 78.8086 155.668 78.7467 155.65 78.6981L155.164 77.1334C155.164 77.0848 155.08 77.0627 155.005 77.0759L154.629 77.1466C154.554 77.1466 154.505 77.2129 154.523 77.2571L155.009 78.8263C155.023 78.8705 155.093 78.897 155.168 78.8793Z" fill="#346AF7" />
    <path d="M160.728 77.7919L161.099 77.7212C161.175 77.7212 161.223 77.6593 161.21 77.6107L160.724 76.046C160.724 75.9974 160.635 75.9753 160.56 75.9885L160.189 76.0593C160.114 76.0593 160.065 76.1256 160.078 76.1698L160.565 77.7389C160.582 77.7644 160.608 77.7835 160.637 77.7931C160.667 77.8027 160.699 77.8022 160.728 77.7919Z" fill="#346AF7" />
    <path d="M159.154 53.2257C159.467 53.1063 159.861 54.2113 159.764 54.3528C159.538 54.6754 155.41 55.8025 155.224 55.1705C155.213 54.8279 155.265 54.4861 155.379 54.1627C155.498 53.9505 156.413 54.3395 159.154 53.2257Z" fill="#346AF7" />
    <path d="M158.456 53.2521C158.615 52.8853 159.07 52.2974 159.468 52.6333C159.866 52.9693 160.034 53.6941 159.773 53.8135C159.512 53.9328 158.182 53.8665 158.456 53.2521Z" fill="#346AF7" />
    <path d="M159.3 53.4421C159.574 53.1504 160.184 52.7482 160.463 53.199C160.741 53.6499 160.64 54.388 160.357 54.4101C160.074 54.4322 158.836 53.9239 159.3 53.4421Z" fill="#346AF7" />
    <path d="M159.45 53.3759C159.428 53.1409 159.474 52.9045 159.583 52.6951C159.692 52.4857 159.859 52.3123 160.064 52.1958C160.568 51.8687 161.059 51.6565 161.253 51.3118C161.337 51.635 161.353 51.972 161.302 52.3018C161.2 52.7748 159.631 53.1372 159.737 53.5483L159.45 53.3759Z" fill="#346AF7" />
    <path d="M159.383 53.3538C159.237 53.1683 159.147 52.9444 159.125 52.7091C159.103 52.4739 159.15 52.2372 159.26 52.0278C159.498 51.4798 159.799 51.0378 159.781 50.6488C160.027 50.8749 160.224 51.1486 160.36 51.4532C160.528 51.8952 159.41 53.0577 159.724 53.3494L159.383 53.3538Z" fill="#346AF7" />
    <path d="M190.125 139.699C190.116 139.698 190.107 139.696 190.1 139.691C190.092 139.686 190.086 139.68 190.081 139.672C189.979 139.531 179.875 125.113 176.171 112.445C173.754 104.319 171.896 96.0372 170.611 87.6575C169.846 82.9369 169.382 80.086 167.862 77.7478C167.851 77.7371 167.846 77.7229 167.846 77.7081C167.846 77.6932 167.851 77.679 167.862 77.6683C167.872 77.6593 167.885 77.6543 167.899 77.6543C167.913 77.6543 167.926 77.6593 167.937 77.6683C169.475 80.0286 169.939 82.8883 170.704 87.6221C171.995 95.9898 173.856 104.26 176.273 112.374C179.972 125.024 190.068 139.425 190.169 139.57C190.178 139.581 190.182 139.594 190.182 139.608C190.182 139.622 190.178 139.635 190.169 139.646C190.169 139.658 190.164 139.67 190.156 139.679C190.148 139.689 190.137 139.696 190.125 139.699Z" fill="#346AF7" />
    <path opacity="0.4" d="M190.125 139.699C190.116 139.698 190.107 139.696 190.1 139.691C190.092 139.686 190.086 139.68 190.081 139.672C189.979 139.531 179.875 125.113 176.171 112.445C173.754 104.319 171.896 96.0372 170.611 87.6575C169.846 82.9369 169.382 80.086 167.862 77.7478C167.851 77.7371 167.846 77.7229 167.846 77.7081C167.846 77.6932 167.851 77.679 167.862 77.6683C167.872 77.6593 167.885 77.6543 167.899 77.6543C167.913 77.6543 167.926 77.6593 167.937 77.6683C169.475 80.0286 169.939 82.8883 170.704 87.6221C171.995 95.9898 173.856 104.26 176.273 112.374C179.972 125.024 190.068 139.425 190.169 139.57C190.178 139.581 190.182 139.594 190.182 139.608C190.182 139.622 190.178 139.635 190.169 139.646C190.169 139.658 190.164 139.67 190.156 139.679C190.148 139.689 190.137 139.696 190.125 139.699Z" fill="black" />
    <path d="M171.451 150.337L164.697 151.213L164.255 149.33L171.287 147.84L171.451 150.337Z" fill="#346AF7" />
    <path d="M192.154 139.491L187.173 142.368L185.493 140.932L190.943 137.245L192.154 139.491Z" fill="#346AF7" />
    <path d="M168.776 59.2855C169.015 60.5143 169.152 61.6723 169.285 62.8701C169.417 64.068 169.466 65.2525 169.501 66.4503C169.537 67.6576 169.51 68.8658 169.422 70.0703C169.373 70.6759 169.32 71.2814 169.236 71.8914C169.201 72.1919 169.161 72.4969 169.104 72.8019L168.94 73.7389C168.759 74.5027 168.427 75.2225 167.963 75.8561C167.583 76.3891 167.163 76.893 166.708 77.3633C165.855 78.2395 164.939 79.052 163.967 79.7943C162.088 81.2415 160.099 82.5404 158.018 83.6795L157.072 82.2121C157.956 81.5093 158.84 80.7844 159.724 80.0463C160.608 79.3081 161.448 78.5611 162.257 77.7832C163.056 77.0345 163.81 76.2389 164.516 75.4008C164.839 75.013 165.135 74.6024 165.4 74.1721C165.608 73.8586 165.758 73.5095 165.842 73.1422C165.985 72.0692 166.069 70.9891 166.093 69.9068C166.093 69.3543 166.093 68.8018 166.093 68.2493C166.093 67.6968 166.071 67.1354 166.045 66.5785C165.992 65.4647 165.89 64.3685 165.771 63.237C165.651 62.1055 165.497 60.9872 165.329 59.9397L168.776 59.2855Z" fill="#E58A7B" />
    <path d="M168.242 55.5284C170.129 57.0578 169.678 61.6015 169.678 61.6015L163.313 63.6966C163.313 63.6966 163.605 58.4589 163.923 56.576C164.295 54.335 166.381 54.0212 168.242 55.5284Z" fill="#346AF7" />
    <path opacity="0.2" d="M168.242 55.5284C170.129 57.0578 169.678 61.6015 169.678 61.6015L163.313 63.6966C163.313 63.6966 163.605 58.4589 163.923 56.576C164.295 54.335 166.381 54.0212 168.242 55.5284Z" fill="black" />
    <path d="M84.1614 74.4905C83.9145 73.4188 83.3115 72.4625 82.4509 71.7777C81.5903 71.0929 80.523 70.7201 79.4232 70.7202H44.0632C42.7737 70.7202 41.537 71.2325 40.6252 72.1443C39.7134 73.0561 39.2012 74.2927 39.2012 75.5822V154.179C39.2012 155.468 39.7134 156.705 40.6252 157.617C41.537 158.528 42.7737 159.041 44.0632 159.041H156.331C157.621 159.041 158.857 158.528 159.769 157.617C160.681 156.705 161.193 155.468 161.193 154.179V88.1571C161.193 86.8676 160.681 85.631 159.769 84.7192C158.857 83.8074 157.621 83.2951 156.331 83.2951H95.2247C92.6565 83.2955 90.1641 82.425 88.1546 80.8258C86.1452 79.2266 84.7374 76.9932 84.1614 74.4905Z" fill="#346AF7" />
    <path opacity="0.6" d="M84.1614 74.4905C83.9145 73.4188 83.3115 72.4625 82.4509 71.7777C81.5903 71.0929 80.523 70.7201 79.4232 70.7202H44.0632C42.7737 70.7202 41.537 71.2325 40.6252 72.1443C39.7134 73.0561 39.2012 74.2927 39.2012 75.5822V154.179C39.2012 155.468 39.7134 156.705 40.6252 157.617C41.537 158.528 42.7737 159.041 44.0632 159.041H156.331C157.621 159.041 158.857 158.528 159.769 157.617C160.681 156.705 161.193 155.468 161.193 154.179V88.1571C161.193 86.8676 160.681 85.631 159.769 84.7192C158.857 83.8074 157.621 83.2951 156.331 83.2951H95.2247C92.6565 83.2955 90.1641 82.425 88.1546 80.8258C86.1452 79.2266 84.7374 76.9932 84.1614 74.4905Z" fill="black" />
    <path opacity="0.22" d="M71.6787 50.0125L74.8302 60.2182C75.2325 61.5171 76.1339 62.6033 77.3364 63.2381C78.5389 63.8729 79.9441 64.0045 81.2436 63.604L91.445 60.4525L71.6787 50.0125Z" fill="#346AF7" />
    <path d="M71.6791 50.0125L35.2141 61.279C34.5704 61.4768 33.972 61.7995 33.4531 62.2288C32.9342 62.658 32.5051 63.1854 32.1902 63.7806C31.8753 64.3759 31.6809 65.0274 31.6181 65.6979C31.5552 66.3683 31.6252 67.0446 31.824 67.688L52.6421 135.084C52.8408 135.728 53.1644 136.326 53.5942 136.844C54.0241 137.363 54.5519 137.791 55.1475 138.106C55.743 138.42 56.3947 138.614 57.0653 138.677C57.7358 138.739 58.4122 138.669 59.0556 138.47L105.726 124.052C106.37 123.854 106.968 123.531 107.486 123.101C108.004 122.672 108.433 122.144 108.747 121.549C109.062 120.954 109.256 120.302 109.319 119.632C109.381 118.962 109.311 118.286 109.112 117.643L91.4321 60.4525L71.6791 50.0125Z" fill="#346AF7" />
    <path opacity="0.8" d="M71.6791 50.0125L35.2141 61.279C34.5704 61.4768 33.972 61.7995 33.4531 62.2288C32.9342 62.658 32.5051 63.1854 32.1902 63.7806C31.8753 64.3759 31.6809 65.0274 31.6181 65.6979C31.5552 66.3683 31.6252 67.0446 31.824 67.688L52.6421 135.084C52.8408 135.728 53.1644 136.326 53.5942 136.844C54.0241 137.363 54.5519 137.791 55.1475 138.106C55.743 138.42 56.3947 138.614 57.0653 138.677C57.7358 138.739 58.4122 138.669 59.0556 138.47L105.726 124.052C106.37 123.854 106.968 123.531 107.486 123.101C108.004 122.672 108.433 122.144 108.747 121.549C109.062 120.954 109.256 120.302 109.319 119.632C109.381 118.962 109.311 118.286 109.112 117.643L91.4321 60.4525L71.6791 50.0125Z" fill="white" />
    <g opacity="0.28">
      <path opacity="0.28" d="M91.3 121.108L60.9611 130.478C60.3938 130.653 59.7805 130.595 59.2554 130.319C58.7302 130.042 58.336 129.569 58.1589 129.002C57.9861 128.435 58.0449 127.822 58.3223 127.298C58.5997 126.774 59.0733 126.38 59.6396 126.204L89.9785 116.829C90.5464 116.655 91.1602 116.713 91.6854 116.991C92.2107 117.268 92.6045 117.743 92.7807 118.31C92.9535 118.877 92.8947 119.49 92.6173 120.014C92.3399 120.539 91.8663 120.932 91.3 121.108Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M58.6475 66.1146L42.6029 71.0694C42.0355 71.2425 41.4228 71.1844 40.8979 70.908C40.3731 70.6315 39.9788 70.1589 39.8006 69.5931C39.6277 69.0252 39.6864 68.4119 39.9637 67.887C40.2411 67.3621 40.7147 66.9681 41.2813 66.7908L57.3259 61.836C57.8933 61.6605 58.5071 61.7175 59.0326 61.9943C59.558 62.2711 59.9521 62.7451 60.1282 63.3123C60.3011 63.8803 60.2424 64.4935 59.9651 65.0184C59.6877 65.5434 59.2141 65.9374 58.6475 66.1146Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M71.7418 71.3697L45.2218 79.56C44.6556 79.73 44.0452 79.6699 43.5231 79.3927C43.0009 79.1155 42.6091 78.6435 42.4328 78.0793C42.2587 77.5125 42.3163 76.8998 42.593 76.3754C42.8697 75.851 43.3429 75.4576 43.9091 75.2814L70.4291 67.0912C70.9965 66.9169 71.6098 66.9744 72.1349 67.251C72.66 67.5276 73.0542 68.001 73.2314 68.5674C73.4026 69.1366 73.3422 69.7503 73.0633 70.2751C72.7843 70.7999 72.3094 71.1933 71.7418 71.3697Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M86.5474 76.0947L47.8459 88.0287C47.2791 88.2028 46.6664 88.1452 46.142 87.8685C45.6176 87.5918 45.2242 87.1186 45.0481 86.5524C44.875 85.985 44.933 85.3723 45.2095 84.8474C45.486 84.3226 45.9585 83.9282 46.5243 83.7501L85.2259 71.8161C85.7927 71.6432 86.4048 71.7013 86.9289 71.9779C87.453 72.2544 87.8465 72.7269 88.0237 73.2924C88.198 73.8598 88.1405 74.4731 87.8639 74.9982C87.5872 75.5233 87.1139 75.9175 86.5474 76.0947Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M89.1678 84.5813L66.67 91.5339C66.1026 91.7067 65.49 91.6479 64.9658 91.3705C64.4416 91.0931 64.0484 90.6195 63.8721 90.0532C63.6992 89.4864 63.7574 88.8743 64.0339 88.3502C64.3104 87.8261 64.783 87.4325 65.3484 87.2554L87.8462 80.3027C88.4142 80.131 89.0269 80.1902 89.5515 80.4674C90.0761 80.7446 90.4703 81.2175 90.6485 81.7834C90.8212 82.3508 90.7625 82.9635 90.4851 83.4877C90.2076 84.0119 89.7341 84.405 89.1678 84.5813Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M56.3571 94.7207L50.4741 96.5373C49.9067 96.7116 49.2934 96.6541 48.7683 96.3774C48.2432 96.1008 47.849 95.6275 47.6718 95.061C47.4977 94.4931 47.5558 93.8793 47.8333 93.354C48.1109 92.8288 48.5852 92.435 49.1525 92.2587L55.0355 90.4421C55.6029 90.2691 56.2157 90.3271 56.7405 90.6036C57.2653 90.88 57.6597 91.3526 57.8378 91.9184C58.0095 92.4863 57.9503 93.0991 57.6731 93.6237C57.3959 94.1483 56.9231 94.5424 56.3571 94.7207Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M91.7955 93.072L53.0939 105.028C52.5266 105.201 51.9139 105.142 51.3897 104.865C50.8655 104.587 50.4724 104.114 50.2961 103.547C50.122 102.981 50.1795 102.368 50.4562 101.843C50.733 101.319 51.2062 100.926 51.7724 100.75L90.4739 88.7934C91.0407 88.6205 91.6528 88.6786 92.1769 88.9552C92.701 89.2317 93.0946 89.7042 93.2717 90.2697C93.446 90.8371 93.3885 91.4504 93.1119 91.9755C92.8353 92.5006 92.3619 92.8948 91.7955 93.072Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M79.4804 106.173L55.7141 113.515C55.1473 113.688 54.5351 113.629 54.011 113.353C53.4869 113.076 53.0934 112.604 52.9162 112.038C52.7432 111.471 52.8012 110.858 53.0777 110.333C53.3542 109.809 53.8267 109.414 54.3925 109.236L78.1589 101.894C78.7268 101.722 79.3401 101.78 79.865 102.058C80.3899 102.335 80.7839 102.809 80.9611 103.375C81.1351 103.943 81.0769 104.556 80.7993 105.08C80.5217 105.605 80.0474 105.998 79.4804 106.173Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M94.4152 101.563L88.0725 103.521C87.5045 103.694 86.8913 103.635 86.3663 103.358C85.8414 103.08 85.4474 102.607 85.2702 102.04C85.0975 101.473 85.1562 100.86 85.4336 100.336C85.7111 99.8117 86.1846 99.4186 86.7509 99.2423L93.0936 97.2842C93.6604 97.1101 94.2731 97.1677 94.7975 97.4444C95.3219 97.7211 95.7153 98.1944 95.8915 98.7605C96.0657 99.3279 96.0082 99.9412 95.7316 100.466C95.455 100.991 94.9817 101.386 94.4152 101.563Z" fill="#346AF7" />
    </g>
    <g opacity="0.28">
      <path opacity="0.28" d="M97.0358 110.058L58.3431 121.992C57.7751 122.166 57.1613 122.108 56.6361 121.831C56.1109 121.553 55.717 121.079 55.5408 120.511C55.3669 119.944 55.4251 119.331 55.7027 118.806C55.9802 118.282 56.4545 117.889 57.0215 117.714L95.7186 105.757C96.2866 105.586 96.8993 105.645 97.4239 105.922C97.9485 106.199 98.3427 106.672 98.5209 107.238C98.6097 107.521 98.6417 107.818 98.615 108.113C98.5883 108.408 98.5035 108.695 98.3654 108.957C98.2274 109.219 98.0388 109.452 97.8106 109.641C97.5824 109.829 97.3191 109.971 97.0358 110.058Z" fill="#346AF7" />
    </g>
    <path d="M97.8674 24.6681L109.439 50.4544C109.739 51.1275 109.761 51.8922 109.499 52.5813C109.238 53.2704 108.714 53.8279 108.042 54.1319L58.9537 76.1656C58.2807 76.4672 57.5156 76.4895 56.8261 76.2277C56.1367 75.9658 55.5793 75.4412 55.2762 74.7688L43.7003 48.9826C43.3973 48.3091 43.3742 47.5429 43.6361 46.8524C43.8981 46.1619 44.4235 45.6038 45.097 45.3007L94.1855 23.267C94.8598 22.9667 95.6256 22.9455 96.3155 23.208C97.0054 23.4706 97.5633 23.9955 97.8674 24.6681Z" fill="#346AF7" />
    <path opacity="0.3" d="M97.8674 24.6681L109.439 50.4544C109.739 51.1275 109.761 51.8922 109.499 52.5813C109.238 53.2704 108.714 53.8279 108.042 54.1319L58.9537 76.1656C58.2807 76.4672 57.5156 76.4895 56.8261 76.2277C56.1367 75.9658 55.5793 75.4412 55.2762 74.7688L43.7003 48.9826C43.3973 48.3091 43.3742 47.5429 43.6361 46.8524C43.8981 46.1619 44.4235 45.6038 45.097 45.3007L94.1855 23.267C94.8598 22.9667 95.6256 22.9455 96.3155 23.208C97.0054 23.4706 97.5633 23.9955 97.8674 24.6681Z" fill="black" />
    <path d="M90.1303 23.3939L88.5615 24.0977L88.7424 24.5009L90.3112 23.7972L90.1303 23.3939Z" fill="#346AF7" />
    <path d="M86.3655 25.0811L81.4736 27.2755L81.6545 27.6788L86.5464 25.4844L86.3655 25.0811Z" fill="#346AF7" />
    <path d="M43.6561 51.5812L43.2529 51.7622L44.5927 54.746L44.9959 54.565L43.6561 51.5812Z" fill="#346AF7" />
    <path opacity="0.6" d="M96.2242 23.1742L83.5653 47.9704C82.3907 50.2687 80.467 52.0973 78.1122 53.1542C75.7575 54.211 73.1126 54.4327 70.6147 53.7827L43.6748 46.7593C43.9512 46.1129 44.4586 45.5928 45.098 45.3007L94.1866 23.267C94.8296 22.9795 95.5577 22.9463 96.2242 23.1742Z" fill="#346AF7" />
    <path d="M113.626 54.9098L75.5339 57.2612C74.861 57.3012 74.2025 57.4735 73.5962 57.7683C72.99 58.0631 72.4478 58.4745 72.0007 58.9791C71.5536 59.4836 71.2104 60.0714 70.9907 60.7088C70.7711 61.3461 70.6793 62.0205 70.7206 62.6934L75.0654 133.095C75.106 133.769 75.279 134.427 75.5745 135.034C75.8701 135.64 76.2824 136.182 76.7879 136.629C77.2933 137.076 77.882 137.418 78.5201 137.637C79.1582 137.856 79.8332 137.947 80.5064 137.904L129.259 134.894C129.931 134.854 130.589 134.681 131.194 134.386C131.799 134.091 132.341 133.679 132.786 133.174C133.232 132.67 133.574 132.082 133.793 131.445C134.011 130.808 134.102 130.134 134.059 129.462L130.391 69.7168L113.626 54.9098Z" fill="#346AF7" />
    <path opacity="0.8" d="M113.626 54.9098L75.5339 57.2612C74.861 57.3012 74.2025 57.4735 73.5962 57.7683C72.99 58.0631 72.4478 58.4745 72.0007 58.9791C71.5536 59.4836 71.2104 60.0714 70.9907 60.7088C70.7711 61.3461 70.6793 62.0205 70.7206 62.6934L75.0654 133.095C75.106 133.769 75.279 134.427 75.5745 135.034C75.8701 135.64 76.2824 136.182 76.7879 136.629C77.2933 137.076 77.882 137.418 78.5201 137.637C79.1582 137.856 79.8332 137.947 80.5064 137.904L129.259 134.894C129.931 134.854 130.589 134.681 131.194 134.386C131.799 134.091 132.341 133.679 132.786 133.174C133.232 132.67 133.574 132.082 133.793 131.445C134.011 130.808 134.102 130.134 134.059 129.462L130.391 69.7168L113.626 54.9098Z" fill="white" />
    <g opacity="0.22">
      <path opacity="0.22" d="M115.927 128.622L84.2312 130.58C83.6391 130.614 83.0576 130.412 82.6137 130.019C82.1699 129.626 81.8997 129.073 81.8621 128.481C81.8281 127.889 82.0292 127.307 82.4215 126.863C82.8138 126.419 83.3657 126.147 83.9572 126.107L115.653 124.153C116.245 124.118 116.827 124.319 117.272 124.712C117.717 125.104 117.988 125.657 118.027 126.249C118.062 126.841 117.86 127.424 117.467 127.869C117.073 128.314 116.52 128.585 115.927 128.622Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M97.1696 67.4847L80.4046 68.5101C79.8127 68.5441 79.2314 68.343 78.7869 67.9507C78.3425 67.5584 78.0708 67.0065 78.031 66.415C77.9971 65.8225 78.1987 65.2405 78.592 64.7959C78.9852 64.3514 79.5382 64.0802 80.1305 64.0415L96.8956 63.0072C97.4873 62.9721 98.0688 63.1729 98.5128 63.5655C98.9568 63.9582 99.2272 64.5108 99.2647 65.1023C99.3023 65.6959 99.1027 66.2802 98.7099 66.7269C98.3171 67.1735 97.7632 67.4461 97.1696 67.4847Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M108.653 75.6792L80.9527 77.3854C80.3602 77.4217 79.7776 77.2215 79.3326 76.8287C78.8876 76.4359 78.6166 75.8826 78.5791 75.2903C78.544 74.6974 78.7452 74.1148 79.1387 73.67C79.5322 73.2251 80.0859 72.9543 80.6786 72.9167L108.374 71.2106C108.967 71.1743 109.549 71.3745 109.994 71.7673C110.439 72.1601 110.71 72.7134 110.748 73.3057C110.783 73.8978 110.582 74.4798 110.19 74.9245C109.797 75.3693 109.245 75.6406 108.653 75.6792Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M121.925 83.7636L81.4996 86.2565C80.9055 86.2928 80.3213 86.0923 79.8748 85.6988C79.4283 85.3053 79.1559 84.7509 79.1172 84.157C79.0821 83.5653 79.2829 82.9838 79.6755 82.5398C80.0682 82.0957 80.6208 81.8254 81.2123 81.7878L121.642 79.2905C122.234 79.2567 122.816 79.4584 123.26 79.8518C123.703 80.2451 123.974 80.7981 124.011 81.39C124.046 81.9807 123.847 82.5615 123.456 83.0059C123.066 83.4504 122.515 83.7227 121.925 83.7636Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M122.474 92.6303L98.9725 94.0801C98.3803 94.1152 97.7983 93.9146 97.3536 93.522C96.9089 93.1295 96.6376 92.5769 96.5989 91.985C96.5626 91.3925 96.7628 90.8099 97.1556 90.3649C97.5484 89.9199 98.1017 89.6489 98.694 89.6114L122.195 88.1617C122.788 88.1254 123.37 88.3256 123.815 88.7183C124.26 89.1111 124.531 89.6644 124.569 90.2568C124.604 90.8489 124.403 91.4309 124.011 91.8756C123.618 92.3203 123.066 92.5916 122.474 92.6303Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M88.1932 94.7428L82.0449 95.1229C81.4532 95.158 80.8718 94.9573 80.4277 94.5646C79.9837 94.172 79.7134 93.6194 79.6758 93.0279C79.6407 92.4357 79.8413 91.8537 80.2339 91.409C80.6264 90.9643 81.179 90.693 81.7709 90.6543L87.9191 90.2742C88.5117 90.2403 89.0937 90.4419 89.5382 90.8352C89.9828 91.2284 90.254 91.7814 90.2927 92.3737C90.3265 92.9658 90.1248 93.5473 89.7314 93.9912C89.3381 94.435 88.7851 94.7053 88.1932 94.7428Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M123.024 101.496L82.594 103.989C82.0019 104.024 81.4199 103.824 80.9752 103.431C80.5305 103.039 80.2591 102.486 80.2205 101.894C80.1866 101.302 80.3882 100.72 80.7814 100.275C81.1747 99.8306 81.7277 99.5594 82.32 99.5207L122.745 97.0279C123.338 96.9928 123.921 97.194 124.366 97.5875C124.81 97.9809 125.081 98.5346 125.119 99.1274C125.154 99.719 124.953 100.301 124.561 100.745C124.168 101.189 123.615 101.459 123.024 101.496Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M107.967 111.327L83.1399 112.86C82.5473 112.894 81.9654 112.693 81.5208 112.299C81.0762 111.906 80.805 111.353 80.7664 110.761C80.7325 110.169 80.9343 109.587 81.3276 109.143C81.721 108.7 82.274 108.429 82.8659 108.392L107.693 106.858C108.285 106.824 108.867 107.026 109.31 107.419C109.754 107.813 110.025 108.366 110.062 108.958C110.098 109.55 109.898 110.132 109.505 110.576C109.112 111.02 108.559 111.29 107.967 111.327Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M123.566 110.368L116.936 110.774C116.344 110.809 115.763 110.609 115.319 110.216C114.875 109.823 114.604 109.271 114.567 108.679C114.53 108.087 114.731 107.504 115.123 107.059C115.516 106.614 116.069 106.343 116.662 106.306L123.292 105.899C123.884 105.863 124.467 106.063 124.912 106.456C125.357 106.848 125.628 107.402 125.665 107.994C125.699 108.587 125.498 109.169 125.104 109.613C124.711 110.058 124.158 110.329 123.566 110.368Z" fill="#346AF7" />
    </g>
    <g opacity="0.22">
      <path opacity="0.22" d="M124.113 119.234L83.688 121.727C83.0959 121.762 82.5139 121.561 82.0692 121.169C81.6245 120.776 81.3532 120.224 81.3145 119.632C81.2794 119.04 81.48 118.458 81.8725 118.013C82.2651 117.568 82.8177 117.297 83.4096 117.258L123.839 114.765C124.431 114.73 125.012 114.931 125.456 115.324C125.901 115.716 126.171 116.269 126.208 116.86C126.245 117.453 126.045 118.036 125.652 118.481C125.259 118.926 124.706 119.197 124.113 119.234Z" fill="#346AF7" />
    </g>
    <path opacity="0.22" d="M113.625 54.9098L114.284 65.5708C114.325 66.243 114.498 66.9005 114.793 67.5058C115.088 68.111 115.5 68.6522 116.004 69.0984C116.509 69.5445 117.096 69.887 117.733 70.1061C118.37 70.3252 119.044 70.4167 119.716 70.3754L130.39 69.7168L113.625 54.9098Z" fill="#346AF7" />
    <path opacity="0.5" d="M101.151 81.505C101.144 80.9941 101.035 80.4897 100.831 80.0217C100.626 79.5536 100.329 79.1314 99.9582 78.7799C99.5873 78.4284 99.1498 78.1549 98.6715 77.9753C98.1931 77.7958 97.6836 77.714 97.1731 77.7347H61.7689C59.086 77.7347 56.4649 79.9138 55.961 82.5967L41.9894 154.179C41.4678 156.862 43.2182 159.041 45.9011 159.041H158.169C160.852 159.041 163.451 156.866 163.977 154.179L175.5 95.1716C176.021 92.4843 174.271 90.3096 171.588 90.3096H110.499C105.195 90.3096 101.332 86.6587 101.151 81.505Z" fill="#346AF7" />
  </svg>

);

export default Vector;
