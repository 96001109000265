/* eslint-disable max-len */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from '../../ModalBase';
import Vector from './Vector';

const SearchBlockedModal = ({ open, onClose }) => {
  const history = useHistory();

  return (
    <Modal open={open} onClose={onClose}>
      <span className="modal-base__vector" style={{ maxWidth: 300 }}>
        <Vector />
      </span>
      <h2 className="modal-base__title">
        Quer pesquisar documentos por nome?
      </h2>
      <p className="modal-base__description">
        Contratando nossos planos você pode pesquisar seus documentos por nome para achá-los mais rapidamente.
      </p>

      <div className="modal-base__footer">
        <Button label="Adquirir plano" disableElevation fullWidth onClick={() => history.push('/plans')} />
      </div>
    </Modal>
  );
};

SearchBlockedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SearchBlockedModal;
