import { TextField } from '@contraktor-tech/ui';
import { withStyles } from '@material-ui/core';

export const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.26)!important'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(0, 0, 0, 0.26)!important'
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'black!important'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black!important',
        borderWidth: '1px!important'
      }
    }
  }
})(TextField);
