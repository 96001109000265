import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import Button from '../../../../components/Button';
import {
  Wrapper, Card, CardsList, CardHeader, Footer
} from './styles';

const getColor = (status) => {
  switch (status) {
    case 'ASSINADO':
      return 'green';

    case 'PENDENTE':
      return 'yellow';

    default:
      return 'grey';
  }
};

const Persons = () => {
  const { document } = useSelector((state) => state.documentByToken);
  const { signers } = document;

  function downloadFile(url) {
    window.open(url);
  }

  return (
    <Wrapper>
      <CardsList>
        {document && signers && signers.map((signer) => (
          <Card key={signer.id}>
            <CardHeader>
              <FaUser size={14} />
              <h4>{signer.name}</h4>
              {signer.confirmedAt ? (
                <Label size="medium" color={getColor('ASSINADO')}>
                  Assinado
                </Label>
              ) : (
                <Label size="medium" color={getColor('PENDENTE')}>
                  Pendente
                </Label>
              )}
            </CardHeader>
            <span>{signer.email}</span>
            {signer.confirmedAt && (
              <>
                <span>IP: {signer.confirmedIpAddress}</span>
                <span>Assinado em: {signer.confirmedAt}</span>
              </>
            )}
          </Card>
        ))}
      </CardsList>
      <Footer>
        {document.status === 'signed' && (
          <Button
            primary
            onClick={() => downloadFile(document.download)}
          >
            Baixar documento
          </Button>
        )}
      </Footer>
    </Wrapper>
  );
};

export default Persons;
