import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Whatsapp = ({ signer }) => (
  <Wrapper>
    <ShortenedField clamp={1}>
      {signer.whatsapp}
    </ShortenedField>
  </Wrapper>
);

Whatsapp.propTypes = {
  signer: PropTypes.shape({
    whatsapp: PropTypes.string
  })
};

Whatsapp.defaultProps = {
  signer: { whatsapp: '' }
};

export default Whatsapp;
