/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0.2em 0;
`;

export const CustomSpan = styled.span`
  color: #000;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily} !important;
`;
