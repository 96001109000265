import styled from 'styled-components';

export const Info = styled.div`
  padding: 24px;
  
  .info-text {
    font-family: Poppins;
    font-weigth: 500;
    color: #ffffff;
    font-size: 20px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  Loader {
    padding: 16px 32px 16px 32px;
  }

  > span {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.0313rem;
  }

  .button-text-tiny {
    font-weight: 400;
  }

  .button-text-normal {
    font-weight: 500;
  }

  .button-text-bold {
    font-weight: 600
  }

  .button-text-blue {
    color: #346AF7;
  }

  .button-text-white {
    color: #ffffff
  }
`;

export const Footer = styled.div`
  padding: 11px 24px 24px 24px;
  width: 100%;
  display: table;
  clear: both;
  position: absolute;
  bottom: 0;

  .action-button, .action-button:hover {
    width: 48.71%;
    display: flow-root;
    padding: 16px 0 16px 0;
    box-shadow: none;
  }

  .back-button, .back-button:hover {
    float: left;
    background: rgba(255, 255, 255, 0.12);
  }
  
  .next-button, .create-payment-button:hover {
    float: right;
    background: #346AF7;
  }

  .Mui-disabled {
    background-color: #346AF7ad !important;
  }
`;
