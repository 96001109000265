import { TextField } from '@mui/material';
import React from 'react';

import { CustomFormControl, Title } from '../../_shared/styles';

export default function OutlineTextField({
  value, title, label, id, onChange
}) {
  return (
    <CustomFormControl fullWidth>
      { title && <Title>{title}</Title> }
      <TextField
        id={id}
        label={label}
        variant="outlined"
        value={value}
        onChange={onChange}
      />
    </CustomFormControl>
  );
}
