import { Button, ButtonGroup, styled } from '@mui/material';

import themes from '../../_utils/themes';

export const StyledButtonGroup = styled(ButtonGroup)({
  height: '40px'
});

export const CustomButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? themes.pallete.secondaryLight : themes.pallete.white,
  color: themes.buttons.label.secondary,
  textTransform: 'capitalize',
  border: `1px solid ${themes.buttons.border.secondaryLightGrey}`,
  width: '165.67px',
  borderRadius: '40px',
  whiteSpace: 'nowrap',
  fontSize: themes.fonts.size.small,
  fontFamily: themes.fonts.family.Poppins,
  fontWeight: themes.fonts.weight.normal
}));
