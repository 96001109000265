import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal, Button } from 'semantic-ui-react';

import { StyledModal } from './styles';

const onboarding = 'https://www.youtube.com/watch?v=-g9Yx2Xu81E&ab_channel=Contraktor';
const externalLink = 'https://help-starter.contraktor.com.br/pt-BR/'; // Link externo genérico

const HelpOnboardingVideo = ({ trigger }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const redirectToExternalLink = () => {
    window.open(externalLink, '_blank'); // Abre o link em uma nova aba
  };

  return (
    <StyledModal
      trigger={trigger}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Content>
        <Modal.Description>
          <ReactPlayer url={onboarding} width="100%" height="31.25rem" playing controls />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="centered">
        <div>
          <p style={{ marginBottom: '1rem' }}>Precisa de suporte? Visite nossa Central de Ajuda para encontrar soluções.</p>
          <Button
            color="blue"
            onClick={redirectToExternalLink}
            style={{ backgroundColor: '#0b3dbe' }}
          >
            Central de ajuda
          </Button>
        </div>
      </Modal.Actions>
    </StyledModal>
  );
};

HelpOnboardingVideo.propTypes = {
  trigger: PropTypes.node.isRequired
};

export default HelpOnboardingVideo;
