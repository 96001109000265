import { Modal } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Container = styled(Modal)`
  .modal-header {
    display: flex;
    flex-direction: column;
  }

  .modal-subtitle {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.surfaceMediumEmphasis};
  }

  .modal-title {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.surfaceHighEmphasis};
  }

  .modal-content {
    font-weight: 400;
    font-size: 1rem;
    color: #000;
    margin: 1rem 0;
  }

  .content-text {

  }

  .modal-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 1rem;

    > button {
      height: 48px;
    }
  }
`;
