/* eslint-disable react/forbid-prop-types */
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import dialCodes from './data/dialCodes.json';

const CountryCodeSelector = ({ onSelect, defaultValue }) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(defaultValue);
  const countries = dialCodes;

  useEffect(() => {
    const formattedOptions = countries.map((country) => ({
      dialCode: country.dial_code,
      name: country.code
    }));
    setOptions(formattedOptions);
  }, []); //eslint-disable-line

  const handleChange = (event) => {
    const { value } = event.target;
    setSelected(value);
    onSelect(countries.find((c) => c.dial_code === value));
  };

  return (
    <Select
      labelId="country-dial-code"
      id="country-dial-code"
      value={selected || defaultValue}
      onChange={handleChange}
      label={selected.name}
    >
      {
        options.map((o) => <MenuItem value={o.dialCode}>{o.name}</MenuItem>)
      }
    </Select>
  );
};

CountryCodeSelector.defaultProps = {
  defaultValue: null
};

CountryCodeSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.any
};

export default CountryCodeSelector;
