import DotDotDot from 'react-dotdotdot';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.theme.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 2.5em;
  background: ${({ theme }) => theme.greyBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em 0 1em;
`;
export const SignButtonContainer = styled.div`
  position: absolute;
  bottom: 1em;
  z-index: 100;

  > button { 
    width: 18.75rem;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

export const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

export const CircularLabel = styled(Label)`
  margin-right: 0.5em !important;
  background-color: ${(props) => (`${props.background} !important` || props.theme.secondaryBackgroundColor)}
`;
