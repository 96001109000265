/* eslint-disable camelcase */
import { Crisp } from 'crisp-sdk-web';
import PropTypes from 'prop-types';
import React, {
  useEffect, createContext, useContext, useCallback,
  useState
} from 'react';

const ChatContext = createContext({});

const ChatProvider = ({ children }) => {
  const [chatUserData, setChatUserData] = useState(null);
  const initialize = () => {
    try {
      Crisp.configure(process.env.CRISP_WEBSITE_ID);
      Crisp.chat.hide();
    } catch (err) {
      console.error('Error @ tracker.initializeCrisp', err); // eslint-disable-line no-console
    }
  };

  const getSubscriptionName = (planName, cycle, providerId) => {
    if (['Trial', 'Freemium'].includes(planName)) return planName;

    const normalizedCycles = {
      MONTHLY: 'mensal',
      YEARLY: 'anual'
    };

    const normalizedPaymentType = !providerId?.includes('sub_') ? ' parcelado' : '';

    return `${planName} ${normalizedCycles[cycle]}${normalizedPaymentType}`;
  };

  const handleIdentifyUser = useCallback((data) => {
    try {
      const {
        user_email,
        user_name,
        organization_logo,
        token,
        ...rest
      } = data;

      Crisp.setTokenId(token);

      Crisp.user.setEmail(user_email);
      Crisp.user.setNickname(user_name);

      if (organization_logo) Crisp.user.setAvatar(organization_logo);

      Crisp.session.setData(rest);
      setChatUserData(rest);
    } catch (error) {
      console.error('[CRISP ERROR]', error);
    }
  }, [setChatUserData]);

  const handleUpdateUser = useCallback((data) => {
    try {
      Crisp.session.setData({
        ...chatUserData,
        ...data
      });

      setChatUserData({
        ...chatUserData,
        ...data
      });
    } catch (error) {
      console.error('[CRISP ERROR]', error);
    }
  }, [chatUserData]);

  const handleOpenChat = useCallback((customMsg) => {
    Crisp.chat.open();

    if (customMsg) Crisp.message.send(customMsg);
  }, []);

  const handleSessionLogout = useCallback(() => {
    Crisp.setTokenId();
    Crisp.session.reset();
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ChatContext.Provider value={{
      chatIdentify: handleIdentifyUser,
      openChat: handleOpenChat,
      finishChatSession: handleSessionLogout,
      getChatSubscriptionName: getSubscriptionName,
      chatUpdate: handleUpdateUser
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function useChat() {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ChatProvider, useChat };
