import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding: 1rem;

  .users-info {
    padding: 0.5rem 1.5rem;
    font-weight: 700;
  }
`;
