import { Icon, IconButton } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDocument } from '../../../hooks/_new/useDocument';
// import CreatePaymentButton from '../components/CreatePaymentButton';
import CreatePaymentButton from '../components/CreatePaymentButton/CreatePaymentButton';
import SendToSignButton from '../components/SendToSignButton';
import { checkIfHasAccount } from '../services/api/adapters/account';
import * as S from './styles';

export const SidebarContent = S.DrawerContent;
export const SidebarFooter = S.DrawerFooter;

const Sidebar = ({
  items,
  activeItem,
  onClick,
  setIsSending
}) => {
  const [signers, setSigners] = useState([]);
  const [hasAccount, setHasAccount] = useState(false);
  const history = useHistory();
  const { documentData: { data } } = useDocument();
  const status = data?.status;
  const Component = activeItem.component;

  const onChangeSigners = (signersList) => {
    setSigners(signersList);
  };

  useEffect(() => {
    async function checkCkPayAccount() {
      const accountExists = (await checkIfHasAccount())?.data?.data.hasAccount;
      setHasAccount(accountExists);
    }

    checkCkPayAccount();
  }, []);

  return (
    <>
      <S.Wrapper>
        <S.Nav>
          {items.map((i) => (
            <IconButton key={i.id} onClick={() => onClick(i)} color={i.id === activeItem.id ? 'primary' : 'default'}>
              <Icon>{i.icon}</Icon>
              {i.isNewFeature && <S.Badge />}
            </IconButton>
          ))}
        </S.Nav>

        <S.Drawer>
          <S.DrawerHeader>
            <p className="drawer-header__legend">{activeItem.legend}</p>
            <h2 className="drawer-header__title">{activeItem.title}</h2>
          </S.DrawerHeader>
          <Component onChangeSigners={onChangeSigners} />
          {
            status === 'draft' && activeItem.id !== 'historic' && signers?.length > 0
            && (
              <SidebarFooter>
                <SendToSignButton onFinish={() => history.push('/documents')} setIsSending={setIsSending} />
                { activeItem.id !== 'finance' && hasAccount && <CreatePaymentButton onCreatePayment={() => onClick(items.find((i) => i.id === 'finance'))} /> }
              </SidebarFooter>
            )
          }
        </S.Drawer>
      </S.Wrapper>
    </>
  );
};

Sidebar.propTypes = {
  legend: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired
  })).isRequired,
  activeItem: PropTypes.PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
    footer: PropTypes.node
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  setIsSending: PropTypes.func.isRequired
};

export default Sidebar;
