import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 24px;
  height: ${(props) => (props.fullHeight ? '100%' : 'unset')};

  .add-signer-button, .add-signer-button:hover {
    width: 100%;
    background-color: #ffffff;
    box-shadow: none;
    .button-label {
      font-family: Poppins;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 1.25px;
      color: #ffffff;
      display: flex;
      align-items: center;

      .add-icon {
        margin-right: 5px;
        width: 18px;
        height: 18px;
        color: #ffffff;
      }
    }
`;

export const RequestSignaturesContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RequestSignatureInfo = styled.div`
  width: 100%;
  text-align: center;
`;

export const RequestSignatureImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32.2px;
`;

export const RequestSignatureTitle = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.25px;
  color: #000000;
  margin-bottom: 12px;
`;

export const RequestSignatureSubTitle = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  margin-bottom: 24px;
  color: #000000;
  opacity: 0.6;
`;

export const RequestSignatureButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .new-signer-button, .new-signer-button:hover {
    background: rgba(52, 106, 247, 0.12);
    box-shadow: none;
    padding: 16px 50px 16px 50px;
    border-radius: 6px;
    color: #346AF7;
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 1.25px
  }
`;
