// Action types

export const UPDATE_USER = '@contraktor/signUpWizard/UPDATE_USER';
export const UPDATE_PAYMENT = '@contraktor/signUpWizard/UPDATE_PAYMENT';
export const UPDATE_PLAN = '@contraktor/signUpWizard/UPDATE_PLAN';
export const UPDATE_VOUCHER = '@contraktor/signUpWizard/UPDATE_VOUCHER';
export const TOGGLE_ERROR = '@contraktor/signUpWizard/TOGGLE_ERROR';
export const UPDATE_STEP = '@contraktor/signUpWizard/UPDATE_STEP';
export const UPDATE_FILE = '@contraktor/signUpWizard/UPDATE_FILE';
export const RESET_STATE = '@contraktor/signUpWizard/RESET_STATE';

// Reducer

export const INITIAL_STATE = {
  step: 1,
  stepIndex: 1,
  error: false,
  user: null,
  payment: null,
  plan: null,
  voucher: null,
  newPrice: 150
};

export default function signUpWizardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };

    case UPDATE_PAYMENT:
      return {
        ...state,
        payment: action.payload
      };

    case UPDATE_PLAN:
      return {
        ...state,
        plan: action.payload
      };

    case UPDATE_VOUCHER:
      return {
        ...state,
        voucher: action.payload.voucher,
        newPrice: action.payload.newPrice
      };

    case TOGGLE_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload.step,
        stepIndex: action.payload.stepIndex
      };

    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// Actions

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload
  };
}

export function updatePayment(payload) {
  return {
    type: UPDATE_PAYMENT,
    payload
  };
}

export function updatePlan(payload) {
  return {
    type: UPDATE_PLAN,
    payload
  };
}

export function updateVoucher(payload) {
  return {
    type: UPDATE_VOUCHER,
    payload
  };
}

export function toggleError(payload) {
  return {
    type: TOGGLE_ERROR,
    payload
  };
}

export function updateStep(payload) {
  return {
    type: UPDATE_STEP,
    payload
  };
}

export function resetState() {
  return {
    type: RESET_STATE
  };
}
