/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.theme.starterWidth};
  min-height: ${(props) => props.theme.starterHeight};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.fontColor};
  box-shadow: 0 0 16px rgba(152, 152, 152, 0.2);
  background-color: ${(props) => props.theme.starterBackground};

  > h3 {
    font-weight: 500;
    margin: 1.5em 0 1em 0;
  }

  > img {
    width: 130px;
  }
`;

const Grid = styled.div`
  padding: 0 1.4em 1em 1.4em;
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
`;

const Card = styled.div`
  border-radius: 6px;
  padding: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};

  > h5, p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  > h5 {
    font-weight: 500;
  }

  > p {
    margin-top: 0.5em;
  }
`;

export { Wrapper, Grid, Card };
