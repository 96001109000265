// Action types

export const FETCH_DOCUMENTS = '@contraktor/documents/FETCH_DOCUMENTS';
export const REMOVE_DOCUMENT = '@contraktor/documents/REMOVE_DOCUMENT';
export const FETCH_COUNTERS = '@contraktor/documents/FETCH_COUNTERS';
export const UPDATE_COUNTER = '@contraktor/documents/UPDATE_COUNTER';
// Reducer

export const INITIAL_STATE = {
  pagination: {},
  documentsList: [],
  counters: {
    trash: '...',
    waitSign: '...'
  }
};

export default function documents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        pagination: action.payload.pagination,
        documentsList: action.payload.data
      };
    case FETCH_COUNTERS:
      return {
        ...state,
        counters: action.payload
      };
    case REMOVE_DOCUMENT:
      return {
        ...state,
        documentsList: state.documentsList
          .filter((doc) => doc.id !== action.payload)
      };
    case UPDATE_COUNTER:
      return {
        ...state,
        counters: action.payload
      };
    default:
      return state;
  }
}

// Actions

export function fetchDocuments(payload) {
  return {
    type: FETCH_DOCUMENTS,
    payload
  };
}

export function fetchCounters(payload) {
  return {
    type: FETCH_COUNTERS,
    payload
  };
}

export function removeDocumentFromList(payload) {
  return {
    type: REMOVE_DOCUMENT,
    payload
  };
}

export function updateCounter(payload) {
  return {
    type: UPDATE_COUNTER,
    payload
  };
}
