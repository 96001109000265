/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import SearchInput from '../../SearchInput';
import Filters from '../Filters';
import {
  Tabs, Tab, ActionButton, HeaderActions
} from './styles';

const Header = ({
  tabs,
  only,
  query,
  setQuery
}) => (
  <>
    <Tabs>
      {tabs.map((tab) => (
        <NavLink
          exact
          to={tab.href}
          key={tab.name}
        >
          <Tab key={tab.name} active={tab.active}>
            {tab.name}
          </Tab>
        </NavLink>
      ))}
      <Link to="/">
        <ActionButton
          unpadded
          primary
        >
          Novo Documento
        </ActionButton>
      </Link>
    </Tabs>
    <HeaderActions>
      <SearchInput
        onSearch={(value) => setQuery({ search: value, page: undefined })}
      />
      <div>
        <Filters
          only={only}
          query={query}
          setQuery={setQuery}
        />
      </div>
    </HeaderActions>
  </>
);

Header.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  only: PropTypes.arrayOf(PropTypes.string),
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  setQuery: PropTypes.func.isRequired
};

Header.defaultProps = {
  tabs: [],
  only: []
};

export default Header;
