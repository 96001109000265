import { whatsappQuotaPrices } from '../../_refactor/pages/Plans/_components/WhatsappCard/constants/whatsappQuotaPrices';

export const getPlanPrice = (
  whatsappActive,
  whatsappQuota,
  price,
  id,
  format,
  isPaidPlan
) => {
  if ((whatsappActive && whatsappQuota && isPaidPlan)) {
    const isAnnual = ['yearly', 'installment'].includes(format);
    const starterDefaultDiscount = 7.50;
    const quotaPrice = id === 'unlimited'
      ? whatsappQuotaPrices[parseInt(whatsappQuota, 10)] - starterDefaultDiscount
      : whatsappQuotaPrices[parseInt(whatsappQuota, 10)];

    const additionalValue = isAnnual ? quotaPrice * 12 : quotaPrice;

    return price + additionalValue;
  }

  return price;
};
