/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAmplitude } from '../../../../hooks/AmplitudeProvider';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import api from '../../../../utils/backend';

const SendToSignButton = ({ onFinish, setIsSending }) => {
  const { Amplitude } = useAmplitude();
  const { Mixpanel } = useMixpanel();
  const { id } = useParams();

  const buttonEnabled = {
    background: '#346AF7',
    boxShadow: 'none',
    width: '100%',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fonstSize: '0.875rem',
    letterSpacing: '1.25px'
  };

  const sendToSign = async () => {
    try {
      Amplitude.logEvent('new_doc_send_attempt');
      Mixpanel.track('document_signer_button_solicitar-assinatura');

      setIsSending(true);

      await api.post(`/documents/${id}/signers`, {
        signers: JSON.parse(localStorage.getItem('draftSigners')).map((p) => ({
          name: p.name,
          email: p.email,
          whatsapp: p.whatsapp,
          sendViaWhatsapp: p.sendViaWhatsapp,
          signatureType: p.signatureType
        }))
      });

      await api.post(`/documents/${id}/signatures`);

      Mixpanel.track('document_signer_button_solicitar-assinatura-response', { state: 'success' });
      Amplitude.logEvent('new_doc_send_complete');

      localStorage.removeItem('draftSigners');
      onFinish();

      setIsSending(false);
    } catch (error) {
      console.log(error.response);
      Amplitude.logEvent('new_doc_send_error');
      const errorTrackingMsg = error?.response?.data || 'Erro ao enviar para assinatura';
      Mixpanel.track('document_signer_button_solicitar-assinatura-response', { state: 'error', error_data: errorTrackingMsg });
      toast.error(error?.response?.data?.message || 'Ocorreu um erro ao solicitar as assinaturas. Tente novamente.');
      setIsSending(false);
      throw error;
    }
  };

  return (
    <Button
      onClick={sendToSign}
      style={buttonEnabled}
      label="Solicitar assinatura"
    />
  );
};

SendToSignButton.propTypes = {
  setIsSending: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired
};

export default SendToSignButton;
