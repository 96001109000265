import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { createSigner, updateSigner } from '../../redux/ducks/signers';
import api from '../../utils/backend';
import { convertToDefaultFormatAndMerge } from '../../utils/localeUtils';
import { signerType, defaultSignerProps } from '../../utils/types/signerType';
import Button from '../Button';
import SignerForm from './SignerForm';

const SignerModalForm = ({ signer, trigger }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  moment.defaultFormat = 'DD/MM/YYYY';

  const formattedBirthdate = signer.birthDate && moment(signer.birthDate).format('L');
  const signerData = { ...signer, birthDate: formattedBirthdate };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    setValue,
    setError,
    watch,
    triggerValidation,
    getValues
  } = useForm({ mode: 'onBlur', defaultValues: signerData });

  const onUpdateSigner = useCallback(async (data) => {
    try {
      const values = await convertToDefaultFormatAndMerge(data, data.birthDate);

      const response = await api.put(`persons/${signer.id}`, { person: values });

      dispatch(updateSigner(response.data));
      setOpen(false);
      reset({
        ...response.data,
        birthDate: response.data.birthDate && moment(response.data.birthDate)
          .format('L')
      });
      toast.success('Assinante Editado com Sucesso');
    } catch (err) {
      toast.error('Ops! Algo deu errado. Verifique os dados inseridos.');
    }
  }, [reset, signer.id, dispatch]);

  const onSubmit = useCallback(async (data) => {
    try {
      const values = await convertToDefaultFormatAndMerge(data, data.birthDate);

      const response = await api.post('/persons', { person: values });

      dispatch(createSigner(response.data));
      setOpen(false);
      toast.success('Assinante Criado com Sucesso!');
    } catch (err) {
      toast.error('Ops. Algo errado ocorreu');
    }
  }, [dispatch]);

  return (
    <Modal
      trigger={trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeIcon="close"
    >
      <Modal.Header>
        {!signer.id ? 'Novo Assinante' : 'Editar Assinante'}
      </Modal.Header>
      <Modal.Content scrolling>
        <SignerForm
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          setError={setError}
          getValues={getValues}
          triggerValidation={triggerValidation}
          control={control}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          content="Cancelar"
          onClick={() => setOpen(false)}
        />
        <Button
          type="submit"
          primary
          content={signer.id ? 'Salvar' : 'Registrar'}
          onClick={handleSubmit(signer.id ? onUpdateSigner : onSubmit)}
        />
      </Modal.Actions>
    </Modal>
  );
};

SignerModalForm.propTypes = {
  signer: PropTypes.shape(signerType),
  trigger: PropTypes.node.isRequired
};

SignerModalForm.defaultProps = {
  signer: defaultSignerProps
};

export default SignerModalForm;
