/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 1.8rem 0;
  width: 100%;
`;

export const StepsContent = styled.ul`
  display: flex; 
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 0;
  width: 100%;

  list-style: none;
`;

const stepSize = 38;
export const Step = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1.2em;

  &:before, &:after{
    content: "";
    position: absolute;
    top: ${stepSize / 2}px;
    height: 1px;
    background-color: #e3e3e3;
  }
  &:before{
    left: 0;
    right: calc(50% + ${stepSize / 2}px);
  }
  &:after{
    left: calc(50% + ${stepSize / 2}px);
    right: 0;
  }
  &:first-child {
    pedding-left: 0;
    &:before{display:none;}
  }
  &:last-child {
    &:after{display:none;}
  }

  & span, p{
    transition: background 800ms;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;

    color: ${({ active, complete, theme }) =>
    complete
      ? '#afafaf'
      : active
        ? theme.primaryColor
        : '#afafaf'};
  }
  & span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${stepSize}px;
    height: ${stepSize}px;
    margin-bottom: 5px;
    border-radius: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ active, theme }) =>
    active
      ? theme.primaryColor
      : '#e3e3e3'};
        
    svg {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;
