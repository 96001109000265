/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import LabelRequired from '../LabelRequired';
import { Label } from './styles';

const InputWithRef = React.forwardRef(
  ({
    placeholder,
    id,
    label,
    type,
    className,
    required,
    showRequiredLabel,
    error,
    children,
    ...rest
  }, ref) => (
    <Label htmlFor={id} className={error ? 'has-error' : ''}>
      {required && showRequiredLabel ? (
        <LabelRequired text={label} />
      ) : label}
      <input
        placeholder={placeholder}
        type={type}
        id={id}
        aria-label={placeholder}
        onClick={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
        className={className}
        ref={ref}
        {...rest}
      />
      {children}
    </Label>
  )
);

InputWithRef.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  error: PropTypes.bool
};

InputWithRef.defaultProps = {
  placeholder: '',
  id: '',
  label: '',
  name: '',
  type: '',
  className: '',
  children: undefined,
  required: false,
  showRequiredLabel: true,
  error: false
};

export default InputWithRef;
