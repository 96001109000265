import React from 'react';

// const yearlyDiscount = 0.10; // 0.10 = 10%

const prices = {
  freemium: {
    monthly: {
      month: 0,
      year: null
    },
    installment: {
      month: 0,
      year: null
    },
    yearly: {
      month: 0,
      year: null
    }
  },
  light: {
    monthly: {
      month: 33.90,
      year: 406.80
    },
    installment: {
      month: 29.90,
      year: 358.80
    },
    yearly: {
      month: 26.90,
      year: 322.80
    }
  },
  unlimited: {
    monthly: {
      month: 149.90,
      year: 1798.80
    },
    installment: {
      month: 142.41,
      year: 1708.92
    },
    yearly: {
      month: 134.90,
      year: 1618.80
    }
  },
  advanced: {
    monthly: {
      month: 600.00,
      year: 7200.00
    },
    installment: {
      month: 600.00,
      year: 7200.00
    },
    yearly: {
      month: 540.00,
      year: 6480.00
    }
  }
};

const calcPrices = (planName) => prices[planName];

export const plansListData = [
  {
    id: 'freemium',
    name: 'Free',
    prices: calcPrices('freemium'),
    description: 'Continue enviando documentos gratuitamente',
    ctaText: 'Escolher plano',
    benefits: () => [
      <p>Envie até 5 documentos por mês</p>,
      <p>Upload máximo de documentos de <b>5mb</b></p>,
      <p>15 dias para assinar documentos</p>,
      <p>1 Usuário</p>,
      <p><b>30 dias</b> de armazenamento após criar cada documento</p>
    ],
    bgColor: '#fff',
    textColor: '#0251DE',
    borderColor: 'rgba(52, 106, 247, 0.12)',
    bgButtonColor: '#0251DE',
    textButtonColor: '#fff'
  },
  {
    id: 'light',
    name: 'Light',
    prices: calcPrices('light'),
    description: 'Gestão simples de documentos por um preço acessível',
    ctaText: 'Escolher plano',
    frequencies: {
      monthly: 'MONTHLY',
      yearly: 'YEARLY',
      installment: 'YEARLY'
    },
    benefits: () => [
      <p>Envie até 15 documentos por mês</p>,
      <p>Upload máximo de documentos de <b>20mb</b></p>,
      <p>Tempo para assinar documento ilimitado</p>,
      <p>6 Usuários</p>,
      <p>Acompanhe o status dos seus documentos</p>,
      <p><b>500 MB</b> de armazenamento em nuvem</p>
    ],
    bgColor: '#DBE1FF',
    textColor: '#0251DE',
    borderColor: '#DBE1FF',
    bgButtonColor: '#0251DE',
    textButtonColor: '#fff'
  },
  {
    id: 'essential',
    name: 'Essential',
    prices: calcPrices('essential'),
    description: 'Gestão simples de documentos por um preço acessível',
    ctaText: 'Escolher plano',
    frequencies: {
      monthly: 'MONTHLY',
      yearly: 'YEARLY',
      installment: 'YEARLY'
    },
    benefits: () => [
      <p>Envie até 50 documentos por mês</p>,
      <p>Upload máximo de documentos de <b>20mb</b></p>,
      <p>Tempo para assinar documento ilimitado</p>,
      <p>10 Usuários</p>,
      <p>Acompanhe o status dos seus documentos</p>,
      <p><b>1 GB</b> de armazenamento em nuvem</p>
    ],
    bgColor: '#DBE1FF',
    textColor: '#0251DE',
    borderColor: '#DBE1FF',
    bgButtonColor: '#0251DE',
    textButtonColor: '#fff'
  },
  {
    id: 'unlimited',
    name: 'Unlimited',
    prices: calcPrices('unlimited'),
    description: 'O plano mais vendido com recursos essenciais de gestão e controle',
    ctaText: 'Escolher plano',
    frequencies: {
      monthly: 'MONTHLY',
      yearly: 'YEARLY',
      installment: 'YEARLY'
    },
    benefits: () => [
      <p>Envie documentos ilimitadamente</p>,
      <p>Upload máximo de documentos de <b>20mb</b></p>,
      <p>Tempo para assinar documento ilimitado</p>,
      <p>12 Usuários</p>,
      <p>Acompanhe o status dos seus documentos</p>,
      <p><b>2GB</b> de armazenamento em nuvem</p>,
      <p>Gestão básica de documentos</p>,
      <p>Seu logotipo nos e-mails</p>,
      <p><b>Novidade!</b> 15 envios de solicitação de assinatura por Whatsapp por mês</p>
    ],
    bgColor: '#0251DE',
    textColor: '#fff',
    borderColor: '#0251DE',
    bgButtonColor: '#fff',
    textButtonColor: '#0251DE'
  },
  {
    id: 'advanced',
    name: 'Avançados',
    prices: calcPrices('advanced'),
    legend: 'A partir de',
    description: 'Planos personalizados com assinatura digital e gestão completa de documentos',
    ctaText: 'Falar com especialista',
    benefits: () => [
      <p>Automação completa de documentos, prazos, tarefas e vigência</p>,
      <p>Fluxo de aprovação de documentos</p>,
      <p>Formulário customizável para preenchimento de documentos</p>,
      <p>Assinatura híbrida, com ou sem certificado digital</p>,
      <p>Ferramenta de elaboração de documentos e minutas, com biblioteca de modelos prontos</p>,
      <p>E muito mais...</p>
    ],
    bgColor: '#0C0440',
    textColor: '#fff',
    borderColor: '#0C0440',
    bgButtonColor: '#fff',
    textButtonColor: '#0251DE'
  }
];
