import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

const statusList = {
  canceled: 'Cancelado',
  pending: 'Pendente',
  signed: 'Assinado'
};

const SigneeStatusLabel = ({ status }) => (
  <S.Container status={status}>
    {statusList[status]}
  </S.Container>
);

SigneeStatusLabel.propTypes = {
  status: PropTypes.oneOf(['canceled', 'pending', 'signed']).isRequired
};

export default SigneeStatusLabel;
