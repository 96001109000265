/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

import * as S from './styles';

const ExitEditionPopover = ({
  open,
  closeModal,
  exitEdition
}) => {
  useEffect(() => {}, []);

  if (!open) return null;

  return (
    <Modal
      open={open}
      size="small"
      style={{
        minHeight: '188px', maxWidth: '670px', borderRadius: '8px', backgroundColor: '#0C0440'
      }}
    >
      <S.Info>
        <span className="info-text">Ao sair da página, qualquer alteração feita será perdida. Tem certeza que deseja sair?</span>
      </S.Info>
      <S.Footer>
        <Button
          className="action-button back-button"
          onClick={closeModal}
          label={(
            <S.ButtonText>
              <span className="button-text-normal button-text-white">Cancelar</span>
            </S.ButtonText>
          )}
        />
        <Button
          className="action-button next-button"
          onClick={exitEdition}
          label={(
            <S.ButtonText>
              <span className="button-text-normal button-text-white">Sair</span>
            </S.ButtonText>
          )}
        />
      </S.Footer>
    </Modal>
  );
};

ExitEditionPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  exitEdition: PropTypes.func.isRequired
};

export default ExitEditionPopover;
