import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import { setRequested } from '../../../redux/ducks/recoveryPassword';
import { Wrapper } from './styles';

const ResetPasswordSuccessMessage = () => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
      dispatch(setRequested(false));
    }, 2000);
  }, [dispatch]);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <Wrapper>
      <Message success>
        <Message.Header>
          Nova senha cadastrada com sucesso!
        </Message.Header>
        <p>
          Aguarde, você será redicionado para a tela de autenticação...
        </p>
        <p>
          Caso não seja redirecionado automaticamente, <Link to="/signin">clique aqui</Link>.
        </p>
      </Message>
    </Wrapper>
  );
};

export default ResetPasswordSuccessMessage;
