import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 212px;
  display: flex;
  flex-direction: column;
  padding: 1em 0 1em 1em;
  overflow: hidden;

  > .card-link {
    text-align: left;
  }

  > h4 {
    font-weight: 500;
    margin-bottom: 1em;
  }

  > a {
    text-align: center;
    font-weight: 500;
  }
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  height: 120px;
  margin-bottom: 1em;
  border-radius: 6px;
  padding: 0.6em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0.8;
  transition: 300ms opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: 300ms opacity;
  }

  > div {
    > div {
      font-weight: 500;
      margin-bottom: 0.5em;
      color: #000;
    }
  }

  > span {
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.fontColor};
  }
`;

const CardsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 1em;
`;

const StyledLabel = styled(Label)`
  background: ${({ background, theme }) => (`${background} !important` || theme.secondaryBackgroundColor)};
  color: #FFF !important;
`;

export {
  Wrapper, Card, StyledLabel, CardsContainer, LoadingContainer
};
