/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import Cookies from 'js-cookie';
import React, {
  useCallback, useState, useEffect, useRef
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Select } from 'semantic-ui-react';

import Button from '../../../../../components/Button';
import { useMixpanel } from '../../../../../hooks/MixpanelProvider';
import TagManagerTracker from '../../../../../hooks/useTagManager';
import { RECAPTCHA_KEY } from '../../../../../utils/constants';
import { useAuth } from '../../../../AuthContext/AuthContext';
import Form from '../../../components/Form';

const operationSegmentList = [
  {
    key: 'segmento_varejista',
    value: 'segmento_varejista',
    text: 'Segmento varejista'
  },
  {
    key: 'industria',
    value: 'industria',
    text: 'Indústria'
  },
  {
    key: 'saude',
    value: 'saude',
    text: 'Saúde'
  },
  {
    key: 'juridico',
    value: 'juridico',
    text: 'Jurídico'
  },
  {
    key: 'marketing_e_publicidade',
    value: 'marketing_e_publicidade',
    text: 'Marketing e Publicidade'
  },
  {
    key: 'educacao',
    value: 'educacao',
    text: 'Educação'
  },
  {
    key: 'instituicoes_publicas ',
    value: 'instituicoes_publicas ',
    text: 'Instituições públicas '
  },
  {
    key: 'imobiliario',
    value: 'imobiliario',
    text: 'Imobiliário'
  },
  {
    key: 'rh_e_recrutamento',
    value: 'rh_e_recrutamento',
    text: 'RH e recrutamento'
  },
  {
    key: 'consultoria',
    value: 'consultoria',
    text: 'Consultoria'
  },
  {
    key: 'contabilidade ',
    value: 'contabilidade ',
    text: 'Contabilidade '
  },
  {
    key: 'financeiro_bancário',
    value: 'financeiro_bancário',
    text: 'Financeiro / Bancário'
  },
  {
    key: 'tecnologia_e_software',
    value: 'tecnologia_e_software',
    text: 'Tecnologia e Software'
  },
  {
    key: 'outro_nao_listado',
    value: 'outro_nao_listado',
    text: 'Outro não listado'
  }
];

const employeesNumberList = [
  {
    key: 'autonomo',
    value: 'autonomo',
    text: 'Autônomo'
  },
  {
    key: '1_10',
    value: '1_10',
    text: '1 a 10'
  },
  {
    key: '11_50',
    value: '11_50',
    text: '11 a 50'
  },
  {
    key: '51_100',
    value: '51_100',
    text: '51 a 100'
  },
  {
    key: '100_mais',
    value: '100_mais',
    text: 'Mais de 100'
  }
];

const documentsSentInMonthList = [
  {
    key: 'somente_um_documento',
    value: 'somente_um_documento',
    text: 'Somente um documento'
  },
  {
    key: 'de_1_a_15_documentos',
    value: 'de_1_a_15_documentos',
    text: 'De 1 a 15 documentos'
  },
  {
    key: 'de_16_a_40_documentos',
    value: 'de_16_a_40_documentos',
    text: 'De 16 a 40 documentos'
  },
  {
    key: 'de_41_a_80_documentos',
    value: 'de_41_a_80_documentos',
    text: 'De 41 a 80 documentos'
  },
  {
    key: 'mais_de_80_documentos',
    value: 'mais_de_80_documentos',
    text: 'Mais de 80 documentos'
  }
];

const RegisterSteps = ({ prevData, validationError }) => {
  const captchaRef = useRef(null);
  const gtm_campaign_url = Cookies.get('__gtm_campaign_url');
  const gtm_referrer = Cookies.get('__gtm_referrer');
  const history = useHistory();
  const { signUp } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState,
    control,
    setValue,
    watch,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: prevData && prevData.phone ? {
      phone: prevData.phone,
      employeesNumber: '',
      operationSegment: '',
      documentsSentInMonth: ''
    } : {}
  });

  const { Mixpanel } = useMixpanel();

  const { isValid, errors } = formState;
  const employeesNumber = watch('employeesNumber');

  const [loading, setLoading] = useState(false);
  const [grtoken, setgrtoken] = useState(null);

  const handleReCaptchaVerify = useCallback(async () => {
    const token = captchaRef.current.getValue();
    setgrtoken(token);
  }, []);

  const onSubmit = useCallback(async (data) => {
    if (!data.employeesNumber) {
      setError('employeesNumber');
      return;
    }
    if (!grtoken) return;

    setLoading(true);

    try {
      await signUp({
        ...prevData,
        ...data,
        gtm_campaign_url,
        gtm_referrer,
        grtoken
      });
      TagManagerTracker({ event: 'trial_subscription' });
      captchaRef.current.reset();
      history.push('/register/success');
    } catch (error) {
      if (!error || !error.response) return;

      if (error.response.status === 422) {
        validationError(error, { ...prevData, ...data });
      } else {
        toast.error('Algo saiu errado... Por favor, tente novamente!');
      }
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line
  }, [setLoading, prevData, grtoken, gtm_campaign_url, gtm_referrer, captchaRef]);

  useEffect(() => {
    register('employeesNumber', {
      required: true
    });
  }, [register]);

  useEffect(() => {
    if (employeesNumber) {
      clearErrors('employeesNumber');
    }
    // eslint-disable-next-line
  }, [employeesNumber]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-col">
          <div className={`form-field ${errors?.employeesNumber ? 'has-error' : ''}`}>
            <label className="input-label" htmlFor="employeesNumber" style={{ zIndex: 5 }}>
              Número de Funcionários
            </label>
            <Select
              id="employeesNumber"
              name="employeesNumber"
              placeholder="Número de Funcionários"
              fluid
              options={employeesNumberList}
              onChange={(_e, { value }) => {
                setValue('employeesNumber', value);
                Mixpanel.track('register_input_numero-funcionarios', { value });
              }}
              value={employeesNumber || ''}
            />
            {errors?.employeesNumber && <span className="helper-text validation-error">Campo Obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className={`form-field ${errors?.operationSegment ? 'has-error' : ''}`}>
            <label className="input-label" htmlFor="operationSegment" style={{ zIndex: 5 }}>
              Segmento de atuação
            </label>
            <Controller
              id="operationSegment"
              name="operationSegment"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Segmento de atuação"
                  fluid
                  options={operationSegmentList}
                  onChange={(_event, { value }) => {
                    field.onChange(value);
                    Mixpanel.track('register_input_segmento-de-atuaçao', { value });
                  }}
                  value={field.value}
                />
              )}
            />
            {errors?.operationSegment && <span className="helper-text validation-error">Campo Obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className={`form-field ${errors?.documentsSentInMonth ? 'has-error' : ''}`}>
            <label className="input-label" htmlFor="documentsSentInMonth" style={{ zIndex: 5 }}>
              Quantos documentos deseja enviar por mês? *
            </label>
            <Controller
              id="documentsSentInMonth"
              name="documentsSentInMonth"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Quantos documentos deseja enviar por mês? *"
                  fluid
                  options={documentsSentInMonthList}
                  onChange={(_event, { value }) => {
                    field.onChange(value);
                    Mixpanel.track('register_input_quantos-documentos-deseja-enviar-por-mes', { value });
                  }}
                  value={field.value}
                />
              )}
            />
            {errors?.documentsSentInMonth && <span className="helper-text validation-error">Campo Obrigatório</span>}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className={`form-field ${errors?.phone ? 'has-error' : ''}`}>
            <label className="input-label" htmlFor="phone">
              Telefone ou celular
            </label>
            <Controller
              id="phone"
              name="phone"
              control={control}
              rules={{
                required: true,
                validate: {
                  isValidPhone: (value) => value.replace(/_/g, '').length <= 4
                    || value.replace(/_/g, '').length > 13
                },
                onChange: () => Mixpanel.track('register_input_numero-telefone-ou-celular')
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => <InputMask {...field} mask="(99) 99999-9999" error={!!error} />}
            />
            {errors?.phone && (
              <span className="helper-text validation-error">
                Telefone inválido. Por favor, verifique.
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="form-col">
          <div className={`form-field ${errors?.phone ? 'has-error' : ''}`}>
            <ReCAPTCHA
              sitekey={RECAPTCHA_KEY}
              ref={captchaRef}
              onChange={() => {
                handleReCaptchaVerify();
              }}
            />
          </div>
        </div>
      </div>

      <div className="form-check">
        <input
          id="optIn"
          type="checkbox"
          name="optIn"
          {...register('optIn')}
          onChange={(e) => {
            register('optIn').onChange(e);
            Mixpanel.track('register_select-button_aceito-receber-noticias-e-promocoes-da-contraktor', { checked: e.target.checked });
          }}
        />
        <label htmlFor="optIn">
          Aceito receber notícias e promoções da Contraktor
        </label>
      </div>

      <div className="form-action">
        <Button
          primary
          type="submit"
          loading={loading}
          disabled={!isValid || !grtoken}
          onClick={() => Mixpanel.track('register_button_experimentar-gratis')}
        >
          Experimentar Grátis
        </Button>
      </div>
      <div className="info">
        <p>
          Ao clicar no botão “Experimentar Grátis” ou “Contratar Agora”. você
          concorda com os{' '}
          <a href="http://qa-starter.contraktor.com.br/termo_starter.pdf">
            Termos e Condições
          </a>{' '}
          e{' '}
          <a href="https://contraktor.com.br/politica-de-privacidade/?_ga=2.210563543.311639605.1613075325-967341359.1610480817#como-e-quando-a-contraktor-coleta-seus-dados-pessoais">
            Política de Privacidade
          </a>
          .
        </p>
      </div>
    </Form>
  );
};

export default RegisterSteps;
