/* eslint-disable react/forbid-prop-types */
import { Button, Checkbox } from '@contraktor-tech/ui';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';

import downsellTitle from '../../../assets/img/change-plan-downsell-title.svg';
import migrateAnnualTitle from '../../../assets/img/change-plan-migrate-annual-title.svg';
import migrateMonthlyTitle from '../../../assets/img/change-plan-migrate-monthly-title.svg';
import upsellTitle from '../../../assets/img/change-plan-upsell-title.svg';
import { CustomTextField } from '../../../components/_new/Payment/shared/forms/CustomFieldStyles/CustomTextField';
import * as S from './styles';

const ReasonsOptions = ({
  onChange,
  optionSelected
}) => {
  const options = [
    {
      id: 1,
      value: 'Achei o preço alto'
    },
    {
      id: 2,
      value: 'Muitas funcionalidades'
    },
    {
      id: 3,
      value: 'Não tenho demanda de documentos'
    },
    {
      id: 4,
      value: 'Tive dificuldades no uso'
    },
    {
      id: 5,
      value: 'Outro'
    }
  ];

  return (
    <div className="justify-options">
      {options.map((o) => (
        <Checkbox
          value={o.value}
          label={o.value}
          checked={optionSelected?.id === o.id}
          onChange={() => onChange(o)}
        />
      ))}
    </div>
  );
};

const ChangePlanModal = ({
  format,
  subscription,
  plan,
  selectedPlan,
  onSubmit,
  onCancel,
  onSetVoucher,
  voucherData,
  setClientSuspensionReason
}) => {
  const [optionSelected, setOptionSelected] = useState(null);
  const [textJustification, setTextJustification] = useState(null);
  const plansLevels = {
    trial: 1,
    free: 0,
    freemium: 0,
    light: 2,
    unlimited: 3,
    advanced: 4
  };

  const isAnnual = (['yearly', 'installment'].includes(format)) && (plan?.name.toLowerCase() !== 'free');
  const freePlans = ['freemium', 'trial'];
  const oldLevel = plansLevels[subscription?.planName.toLowerCase()];
  const newLevel = plansLevels[plan?.name.toLowerCase()];

  const wasFreePlan = freePlans.includes(subscription?.planName.toLowerCase());
  const isMigratingBetweenFree = oldLevel === 1 && newLevel === 0;
  const isMonthly = (format === 'monthly') && ((plan?.name.toLowerCase() !== 'freemium'));
  const wasMonthly = subscription?.cycle === 'MONTHLY';
  const wasAnnual = subscription?.cycle === 'YEARLY';
  const isUpselling = oldLevel < newLevel;
  const isDownselling = oldLevel > newLevel;

  const headerText = () => {
    if ((wasMonthly && isAnnual) || (wasFreePlan && isAnnual)) return <img src={migrateAnnualTitle} alt="Modal Title" />;
    if (wasAnnual && isMonthly) return <img src={migrateMonthlyTitle} alt="Modal Title" />;
    if (isDownselling) return <img src={downsellTitle} alt="Modal Title" />;
    if (isUpselling) return <img src={upsellTitle} alt="Modal Title" />;
    return null;
  };

  const bodyText = () => {
    if ((wasMonthly && isAnnual) || (wasFreePlan && isAnnual)) return 'Você está mudando o seu plano mensal para o anual. Fazendo isso você ganha mais desconto na contratação!';
    if (wasAnnual && isMonthly) return `Seu plano atual é o ${subscription.planName} Anual e você está contrantando o ${plan.name} Mensal, perdendo seu desconto de 10%.`;
    if (isDownselling) return 'Esse plano possui menos funções e armazenamento do que o seu plano atual.';
    if (isUpselling) return 'Você está contratando um plano melhor do que o seu plano atual! A cobrança será feita assim que você confirmar, para poder aproveitar suas melhorias o quanto antes. 😉';
    return null;
  };

  const justificationQuestion = () => {
    if (wasAnnual && isMonthly) return 'Assinale o motivo para que você esteja optando por contratar um plano inferior:*';
    return 'Quais são seus motivos para contratar um plano inferior?';
  };

  const textFieldLabel = () => 'Deixe aqui suas dúvidas, sugestões ou críticas (opcional)';

  const hasJustification = (
    wasAnnual
    && isMonthly
    && !isMigratingBetweenFree
  ) || isDownselling;
  const showDiscountField = !wasFreePlan
    && (isUpselling || (wasMonthly && isAnnual))
    && newLevel > 1;

  const discountField = (
    <div className="form-field">
      <label className="input-label" htmlFor="cupom">
        Cupom de desconto (Opcional)
      </label>
      <Input
        id="cupom"
        name="cupom"
        loading={voucherData.loading}
        icon={voucherData.success ? 'check' : ''}
        onChange={debounce(onSetVoucher, 400)}
        defaultValue={voucherData.cupom}
      />
      {voucherData.error && (
        // IMPLEMENTAR CLASSES
        <span className="helper-text validation-error">
          {voucherData.message}
        </span>
      )}
      {voucherData.success && !voucherData.error && (
        <span className="helper-text">{voucherData.message}</span>
      )}
    </div>
  );

  useEffect(() => {
    if (optionSelected) {
      const emptyVals = [undefined, null, ''];
      const hasTextJustification = !emptyVals.includes(textJustification);
      const reason = !hasTextJustification ? optionSelected.value : `${optionSelected.value} + ${textJustification}`;

      setClientSuspensionReason(reason);
    }
  }, [optionSelected, textJustification, setClientSuspensionReason]);

  return (
    <S.ModalWrapper style={{ padding: '-8px' }}>
      <div id="modalHeader" className="modal-header">
        {headerText()}
      </div>
      <div className="modal-content">
        {bodyText()}
        {
          hasJustification && <p>{justificationQuestion()}</p>
        }
      </div>
      {
        hasJustification && (
          <>
            <ReasonsOptions
              onChange={(option) => setOptionSelected(option)}
              optionSelected={optionSelected}
            />
            <div style={{ marginTop: '24px' }}>
              <CustomTextField
                variant="outlined"
                fullWidth
                onChange={(event) => setTextJustification(event.target.value)}
                placeholder={textFieldLabel()}
              />
            </div>
          </>
        )
      }
      {
        showDiscountField && discountField
      }
      <div className="modal-footer">
        <Button
          className="left-button"
          label="Voltar"
          onClick={() => onCancel()}
        />
        <Button
          disabled={
            voucherData?.loading
            || selectedPlan?.loading
            || (hasJustification && (!optionSelected))
          }
          className="right-button"
          label="Confirmar contratação"
          onClick={async () => onSubmit()}
        />
      </div>
    </S.ModalWrapper>
  );
};

ReasonsOptions.defaultProps = {
  optionSelected: null
};

ReasonsOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  optionSelected: PropTypes.any
};

ChangePlanModal.defaultProps = {
  format: null,
  subscription: null,
  plan: null,
  selectedPlan: null
};

ChangePlanModal.propTypes = {
  format: PropTypes.string,
  subscription: PropTypes.any,
  plan: PropTypes.any,
  selectedPlan: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSetVoucher: PropTypes.func.isRequired,
  voucherData: PropTypes.any.isRequired,
  setClientSuspensionReason: PropTypes.any.isRequired
};

export default ChangePlanModal;
