// Action types

export const FETCH_SIGNERS = '@contraktor/signers/FETCH_SIGNERS';
export const DELETE_SIGNER = '@contraktor/signers/DELETE_SIGNER';
export const CREATE_SIGNER = '@contraktor/signers/CREATE_SIGNER';
export const UPDATE_SIGNER = '@contraktor/signers/UPDATE_SIGNER';

// Reducer

export const INITIAL_STATE = {
  pagination: {},
  signersList: []
};

export default function signers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SIGNERS:
      return {
        ...state,
        pagination: action.payload.pagination,
        signersList: action.payload.data
      };
    case CREATE_SIGNER:
      return {
        ...state,
        signersList: [...state.signersList, action.payload]
      };
    case UPDATE_SIGNER:
      return {
        ...state,
        signersList: state.signersList
          .map((signer) => (signer.id === action.payload.id ? action.payload : signer))
      };
    case DELETE_SIGNER:
      return {
        ...state,
        signersList: state.signersList.filter(
          (signer) => signer.id !== action.payload.id
        )
      };

    default:
      return state;
  }
}

// Actions

export function createSigner(payload) {
  return {
    type: CREATE_SIGNER,
    payload
  };
}

export function deleteSigner(payload) {
  return {
    type: DELETE_SIGNER,
    payload
  };
}

export function fetchSigners(payload) {
  return {
    type: FETCH_SIGNERS,
    payload
  };
}

export function updateSigner(payload) {
  return {
    type: UPDATE_SIGNER,
    payload
  };
}
