/* eslint-disable max-len */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoaderModal from '../../components/_new/components/LoaderModal';
import DocumentPreview from '../../components/_new/DocumentPreview';
import PaymentList from '../../components/_new/Payment/PaymentList';
import { checkIfHasAccount } from '../../components/_new/services/api/adapters/account';
import Sidebar from '../../components/_new/Sidebar';
import GlobalLoader from '../../components/GlobalLoader';
import Header from '../../components/Header';
import { useAuth } from '../../containers/AuthContext/AuthContext';
import History from '../../containers/DocumentSignTokenPage/Sidebar/History/History';
import { useDocument } from '../../hooks/_new/useDocument';
import { SUPERADMIN_URL } from '../../utils/constants';
import SignerList from './components/SignersList';
import * as S from './styles';
// import LoaderModal from '../../components/_new/components/LoaderModal';

const navItems = [
  {
    id: 'signers',
    icon: 'person',
    component: SignerList,
    legend: 'Assinaturas',
    title: 'Status das assinaturas',
    plans: ['Trial', 'Freemium', 'Light', 'Essential', 'Unlimited'],
    isNewFeature: false
  },
  {
    id: 'finance',
    icon: 'attach_money',
    component: PaymentList,
    legend: 'Cobrança automática',
    title: 'Status das cobranças',
    plans: ['Trial', 'Light', 'Essential', 'Unlimited'],
    isNewFeature: true
  },
  {
    id: 'historic',
    icon: 'history',
    component: History,
    legend: 'Histórico',
    title: 'Histórico do documento',
    plans: ['Trial', 'Light', 'Essential', 'Unlimited'],
    isNewFeature: false
  }
];

const DocumentPage = () => {
  const {
    currentPlan
  } = useAuth();

  const { documentData, sendSignature } = useDocument();

  const [activeItem, setActiveItem] = useState(navItems[0]);
  const [isSendingToSign, setIsSendingToSign] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [forcedSignature, setForcedSignature] = useState(false);

  useEffect(() => {
    async function getMenuItems(plan) {
      const hasAccount = (await checkIfHasAccount())?.data?.data.hasAccount;

      const items = navItems.filter((i) => {
        if (i.id === 'finance') {
          return i.plans.includes(plan.name) && hasAccount;
        }
        return i.plans.includes(plan.name);
      });

      setMenuItems(items);
    }

    getMenuItems(currentPlan);
  }, [currentPlan]);

  async function forceSignatureIfNotSigned(document) {
    const { id, signers, status } = document;
    const hasNonCompleteSigner = signers.some((s) => !s.confirmedAt);

    if (status !== 'signed' && !hasNonCompleteSigner) {
      await axios.post(`${SUPERADMIN_URL()}/basic/force_signature`, { id });
      setForcedSignature(true);
    }
  }

  useEffect(() => {
    async function forceSignature() {
      if (documentData?.data?.signers?.length > 0) await forceSignatureIfNotSigned(documentData?.data);
    }
    if (!forcedSignature) forceSignature();
  // eslint-disable-next-line
  }, [documentData]);

  const confirmSign = async () => {
    try {
      await sendSignature();
      toast.success('Documento assinado com sucesso.');
    } catch {
      toast.error('Erro ao assinar documento.');
    }
  };

  return (
    <>
      <Prompt
        when={documentData?.data?.status === 'draft' && !isSendingToSign}
        message="Ao sair da página, qualquer alteração feita será perdida. Tem certeza que deseja sair?"
      />
      <S.Wrapper>
        <S.Header>
          <Header options hideBanner />
        </S.Header>

        <GlobalLoader />

        <S.Container hideTopBanner>
          {documentData.loading ? <p>carregando...</p> : (
            <>
              <DocumentPreview doc={documentData} confirmSign={confirmSign} />
              <Sidebar
                items={menuItems}
                activeItem={activeItem}
                onClick={(item) => setActiveItem(item)}
                setIsSending={setIsSendingToSign}
              />
            </>
          )}
        </S.Container>
      </S.Wrapper>
      <LoaderModal
        open={isSendingToSign}
        loadingMessage="Enviando documento para assinatura..."
      />
    </>
  );
};

export default DocumentPage;
