import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { setRequested } from '../../redux/ducks/recoveryPassword';
import api from '../../utils/backend';
import { emailRegex } from '../../utils/parsers';
import Button from '../Button';
import FieldErrorMessage from '../FieldErrorMessage';
import InputWithRef from '../InputWithRef';

const StyledForm = styled(Form)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > label {
    margin-bottom: 2rem !important;
  }
`;

const Title = styled.h2`
  color: ${(props) => props.theme.headerColor};
  font-size: 1.750em;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1em;
  text-align: center;
`;

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data) => {
    await api.post('/auth/request_new_password', {
      email: data.email
    }).then(() => {
      dispatch(setRequested(true));
    }).catch((error) => {
      setError('email', { type: 'notFound', message: <FieldErrorMessage message={error.response.data.message} /> });
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Title>
        Recuperar Senha
      </Title>
      <Description>
        Insira o seu email e enviaremos as instruções para redefinir a sua senha:
      </Description>

      <InputWithRef
        type="email"
        id="email"
        required
        onChange={(e) => {
          if (e.target.value) {
            e.target.value = e.target.value.toLowerCase().trim();
          }
        }}
        label="Email"
        {...register('email', {
          required: (<FieldErrorMessage message="Campo Obrigatório" />),
          pattern: {
            value: emailRegex,
            message: (
              <FieldErrorMessage message="O e-mail inserido deve ser válido." />
            )
          }
        })}
      >
        {errors?.email && errors?.email.message}
      </InputWithRef>

      <Button
        type="submit"
        primary
        fluid
      >
        Recuperar Senha
      </Button>
    </StyledForm>
  );
};

export default ForgotPasswordForm;
