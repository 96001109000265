import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader, Icon } from 'semantic-ui-react';

import { StyledForm, StyledInput, StyledButton } from './styles';

const SearchInput = ({
  openDirection,
  placeholder,
  loading,
  onChange,
  onSearch
}) => {
  let inputElem = null;
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [hasFocus, setHasFocus] = useState(false);

  function getSearchFromQueryString(query) {
    const qs = queryString.parse(query);
    return qs.search || '';
  }

  useEffect(() => {
    setValue(getSearchFromQueryString(location.search));
  }, [location.search]);

  function handleInputRef(elem) {
    inputElem = elem;
  }

  function handleMouseEnter() {
    if (!inputElem) {
      return;
    }

    setHasFocus(true);
    inputElem.focus();
  }

  function handleSearchButtonClick() {
    if (!inputElem) {
      return;
    }

    setHasFocus(true);
    inputElem.focus();
  }

  function handleOnBlur() {
    setHasFocus(false);
  }

  function handleChange(e) {
    setValue(e.target.value);

    return typeof onChange === 'function' && onChange(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const searchQuery = value.trim();

    if (onSearch) {
      onSearch(searchQuery);
      return;
    }

    const qs = queryString.parse(location.search);
    qs.search = searchQuery;
    qs.page = 1;
    history.push({
      ...location,
      search: queryString.stringify(qs)
    });
  }

  return (
    <StyledForm
      onSubmit={handleSubmit}
      openDirection={openDirection}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleOnBlur}
      className={value || hasFocus ? 'open' : ''}
    >
      <StyledInput
        innerRef={handleInputRef}
        type="search"
        name="search"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        openDirection={openDirection}
        className={value || hasFocus ? 'open' : ''}
        onBlur={handleOnBlur}
      />
      <StyledButton
        type="button"
        tabIndex="-1"
        onClick={handleSearchButtonClick}
        openDirection={openDirection}
      >
        {loading && <Loader active size="mini" />}

        {!loading && <Icon name="search" />}
      </StyledButton>
    </StyledForm>
  );
};

SearchInput.propTypes = {
  openDirection: PropTypes.oneOf(['left', 'right']),
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool
};

SearchInput.defaultProps = {
  openDirection: 'right',
  placeholder: 'Buscar...',
  loading: false,
  onSearch: () => {},
  onChange: () => {}
};

export default SearchInput;
