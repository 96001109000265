/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Loader, Modal } from 'semantic-ui-react';

import * as S from './styles';

const EditInfoPopover = ({
  open,
  closeModal,
  submit,
  updating,
  payment
}) => {
  useEffect(() => {}, []);

  if (!open) return null;

  return (
    <Modal
      open={open}
      size="small"
      style={{
        minHeight: '188px', maxWidth: '670px', borderRadius: '8px', backgroundColor: '#0C0440'
      }}
    >
      <S.Info>
        <span className="info-text">
          {
            payment.frequency && payment.installment_count > 1
              ? 'Ao editar uma parcela você vai alterar todas as parcelas seguintes. Deseja continuar?'
              : 'Você deseja concluir a edição dessa cobrança?'
          }
        </span>
      </S.Info>
      <S.Footer>
        <Button
          className="action-button back-button"
          onClick={closeModal}
          label={(
            <S.ButtonText>
              <span className="button-text-normal button-text-white">Cancelar</span>
            </S.ButtonText>
          )}
        />
        <Button
          disabled={updating}
          className="action-button next-button"
          onClick={submit}
          label={(
            <S.ButtonText>
              {
                updating
                  ? <div style={{ padding: '7px 32px' }}><Loader active={updating} /></div>
                  : (
                    <span className="button-text-normal button-text-white">
                      {
                        payment.frequency && payment.installment_count > 1
                          ? 'Editar todas as parcelas'
                          : 'Concluir'
                      }
                    </span>
                  )
              }
            </S.ButtonText>
          )}
        />
      </S.Footer>
    </Modal>
  );
};

EditInfoPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  payment: PropTypes.any.isRequired
};

export default EditInfoPopover;
