import { Form as SemanticForm } from 'semantic-ui-react';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderGrey};
`;

export const Form = styled(SemanticForm)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1rem;

  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    > label {
      width: 50%;
      padding: 1rem;
    }
  }
`;
