import React from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';

import { resetStore, updateStep } from '../../../redux/ducks/starterWizard';
import theme from '../../../utils/theme';
import Button from '../../Button';
import { Wrapper } from './styles';

const FileDetails = () => {
  const dispatch = useDispatch();

  const { file, step, stepIndex } = useSelector((state) => state.starterWizard);

  const handleNextStep = () => {
    dispatch(updateStep({ step, stepIndex: stepIndex + 1 }));
  };

  const handleResetStep = () => {
    dispatch(resetStore());
  };

  return (
    <Wrapper>
      <AiOutlineFile size={90} color={theme.greyBlue} />
      <div>
        <h3>{file.name}</h3>
        <Button primary fluid onClick={handleResetStep}>Enviar outro arquivo</Button>
        <Button link fluid onClick={handleNextStep}>Voltar</Button>
      </div>
    </Wrapper>
  );
};

export default FileDetails;
