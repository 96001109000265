import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledModal = styled(Modal)`
  > .content {
    text-align: center;
  }

  .centered {
    text-align: center!important;
    display: flex;
    justify-content: center;
  }
`;
