/* eslint-disable react/forbid-prop-types */
import { Avatar } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IoMdTrash } from 'react-icons/io';

import * as S from './styles';

const CustomerList = ({ undoConfirmation, customer, type }) => {
  useEffect(() => {}, []);

  return (
    <>
      <S.Container>
        <S.CustomerCard>
          <Avatar letters={customer?.name} />
          <S.CustomerData>
            <S.CustomerName>{customer?.name}</S.CustomerName>
            <S.CustomerDocument>
              <span style={{ fontWeight: '600', fontSize: '12px' }}>{type === 'FISICA' ? 'CPF: ' : 'CNPJ: '}</span>{customer?.document_id}
            </S.CustomerDocument>
          </S.CustomerData>
          <S.DeleteCustomerIcon onClick={undoConfirmation}>
            <IoMdTrash className="trash-icon" />
          </S.DeleteCustomerIcon>
        </S.CustomerCard>
      </S.Container>
    </>
  );
};

CustomerList.propTypes = {
  undoConfirmation: PropTypes.func.isRequired,
  customer: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired
};

export default CustomerList;
