import React from 'react';
import { Link } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';

const NotFoundPage = () => (
  <ErrorPage>
    <p>Não encontramos a página que você está tentando acessar e sentimos por isso,</p>
    <p>Mas você pode tentar novamente ou <Link to="/">voltar à página inicial</Link>.</p>
  </ErrorPage>
);

export default NotFoundPage;
