import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import MainLayout from '../../components/MainLayout';
import { DocumentPreview, SidebarDocument } from '../../components/Starter';
import { setDocument } from '../../redux/ducks/document';
import { toggleLoading } from '../../redux/ducks/global';
import api from '../../utils/backend';

const StarterDocumentPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [mobileSideBar, setMobileSideBar] = useState(false);

  useEffect(() => {
    dispatch(toggleLoading(true));
    async function fetchDocument() {
      return api.get(`/documents/${id}`)
        .then(({ data }) => {
          dispatch(setDocument(data));
          dispatch(toggleLoading(false));
        });
    }

    fetchDocument();
  }, [id, dispatch]);

  async function confirmSign() {
    dispatch(toggleLoading(true));
    await api.post(`/documents/${id}/signatures/confirm`)
      .then((response) => {
        dispatch(toggleLoading(false));
        dispatch(setDocument(response.data));
        toast.success('Documento assinado com sucesso.');
      }).catch(() => {
        toast.error('Erro ao assinar documento.');
      });
  }

  return (
    <MainLayout header options={!isMobile}>
      <DocumentPreview confirmSign={() => confirmSign()} />

      <SidebarDocument
        confirmSign={() => confirmSign()}
        mobileSideBar={mobileSideBar}
        setMobileSideBar={() => setMobileSideBar(!mobileSideBar)}
      />
    </MainLayout>
  );
};

export default StarterDocumentPage;
