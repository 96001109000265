/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { getPaymentLimit } from '../../services/api/adapters/payment';
import ModalPaymentHeader from '../shared/components/ModalPaymentHeader/ModalPaymentHeader';
import * as S from './styles';

const ModalPaymentLimit = ({
  open,
  closeModal,
  handleOKClick,
  loadingAccountInfo
}) => {
  const [paymentLimitInfo, setPaymentLimitInfo] = useState(null);

  const fetchData = useCallback(async () => {
    const limitInfo = (await getPaymentLimit()).data.data;

    setPaymentLimitInfo(limitInfo);
  }, []);

  useEffect(() => {
    if (open) fetchData();
  }, [open, fetchData]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        size="small"
        style={{ maxWidth: '670px', borderRadius: '8px' }}
      >
        <ModalPaymentHeader mode="payment-limit-info" closeModal={closeModal} />
        <Modal.Content scrolling>
          <S.Text>
            <S.TextTitle>CONTRATO ASSINADO, DINHEIRO NA CONTA.</S.TextTitle>
            <S.TextBody>
              <p>Olá! Estamos disponibilizando acesso gratuito ao <b>CK Pay</b>: a nossa nova funcionalidade de cobrança!</p>

              <p>Com essa ferramenta, você pode processar os pagamentos referentes aos seus contratos de forma automatizada. Otimize a emissão, gestão e recebimento de valores relacionados aos seus documentos com o CK Pay!</p>

              <p>Sua organização poderá utilizar a funcionalidade <b>gratuitamente por tempo limitado</b>. Todas as cobranças geradas neste período serão estornadas.</p>

              <p>Abaixo você encontra o limite de cobranças disponíveis para a sua organização. Teste agora mesmo!</p>
            </S.TextBody>
          </S.Text>
        </Modal.Content>
        <S.Footer>
          <Button
            className="tax-info-button"
            label={(
              <S.ButtonText>
                <span className="button-text-tiny button-text-blue">Cobranças disponíveis: </span>
                <span className="button-text-bold button-text-blue">{paymentLimitInfo?.paymentsRemaining || '10/10'}</span>
              </S.ButtonText>
            )}
          />
          <S.FooterActions>
            <Button
              disabled={loadingAccountInfo}
              className="action-button create-payment-button"
              onClick={() => {
                handleOKClick();
                closeModal();
              }}
              label={(
                <S.ButtonText>
                  <span className="button-text-normal button-text-white">OK</span>
                </S.ButtonText>
              )}
            />
          </S.FooterActions>
        </S.Footer>
      </Modal>
    </>
  );
};

ModalPaymentLimit.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOKClick: PropTypes.func.isRequired,
  loadingAccountInfo: PropTypes.bool.isRequired
};

export default ModalPaymentLimit;
