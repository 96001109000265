import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  padding: 3px 0 0 0;
`;

export const ButtonLabel = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
`;

export const InputLabel = styled.div`
  display: flex;

  span {
    font-family: Roboto;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    letter-spacing: 0.15px;
    font-weight: 400;
  }
`;

export const AddPersonContainer = styled.div`
  width: 100%;
  padding: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SendToWhatsappContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TooltipText = styled.div`
  span, ul li {
    font-size: 12px;
  }
`;
