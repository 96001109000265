/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader, Modal } from 'semantic-ui-react';

import { useDocument } from '../../../../hooks/_new/useDocument';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import { createPayment, confirmPayments } from '../../services/api/adapters/payment';
import ModalPaymentHeader from '../shared/components/ModalPaymentHeader';
import CustomerInfo from '../shared/forms/CustomerInfo';
import PaymentInfo from '../shared/forms/PaymentInfo';
import * as S from './styles';

const ModalCreatePayment = ({
  open,
  closeModal,
  onCreatePayment
}) => {
  const { Mixpanel } = useMixpanel();
  const { documentData } = useDocument();
  const { id } = useParams();
  const [payment, setPayment] = useState({
    value: '',
    due_date: '',
    type: '',
    category: '',
    frequency: '',
    installment_count: '',
    description: ''
  });
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    document_id: ''
  });
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false);
  const [isCustomerFormValid, setIsCustomerFormValid] = useState(false);
  const [step, setStep] = useState(1);
  const [backButtonText, setBackButtonText] = useState('Cancelar');
  const [nextButtonText, setNextButtonText] = useState('Avançar');
  const [confirmedCustomer, setConfirmedCustomer] = useState(false);
  const [isCreatingPayment, setIsCreatingPayment] = useState(false);

  const deleteNullValues = (payload) => {
    Object.entries(payload).forEach(([key]) => {
      if (_.isEmpty(payload[key])) delete payload[key];
    });
  };

  const formatPayloadData = (payload) => {
    deleteNullValues(payload);
    payload.value = parseFloat(payload.value.replace(',', '.'));
    if (payload.installment_count) payload.installment_count = parseInt(payload.installment_count, 10);
  };

  const resetModal = () => {
    setStep(1);
    setBackButtonText('Cancelar');
    setNextButtonText('Avançar');
    setConfirmedCustomer(false);
    setPayment({
      value: '',
      due_date: '',
      type: '',
      category: '',
      frequency: '',
      installment_count: '',
      reference_id: id,
      description: ''
    });
    setCustomer({
      name: '',
      email: '',
      document_id: ''
    });
  };

  const close = () => {
    closeModal();
    resetModal();
  };

  const submitPayment = async (payload) => {
    try {
      const documentStatus = documentData?.data?.status;
      formatPayloadData(payload);
      setIsCreatingPayment(true);
      await createPayment(payload);
      if (documentStatus === 'signed') confirmPayments({ document_id: documentData.data.id }).then(() => closeModal());
      setIsCreatingPayment(false);
      if (onCreatePayment) onCreatePayment();
      close();
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a cobrança');
      close();
    }
  };

  const confirmCustomer = () => {
    setConfirmedCustomer(true);
    setNextButtonText('Criar cobrança automática');
  };

  const undoConfirmCustomer = () => {
    setConfirmedCustomer(false);
    setNextButtonText('Confirmar pagador');
  };

  const back = () => {
    if (step > 1) {
      Mixpanel.track('document_payer_button_voltar');
      setStep(1);
      setBackButtonText('Cancelar');
      setNextButtonText('Avançar');
    } else {
      Mixpanel.track('document_billing_button_cancelar');
      close();
    }
  };

  const next = async () => {
    if (step < 2) {
      Mixpanel.track('document_billing_button_avançar');
      setStep(2);
      setBackButtonText('Voltar');
      setNextButtonText('Confirmar pagador');
    } else if (step === 2 && !confirmedCustomer) {
      Mixpanel.track('document_payer_button_confirmar-pagador');
      confirmCustomer();
    } else {
      Mixpanel.track('document_payer_review_button_confirmar-pagador');
      const payload = {
        ...payment,
        reference_id: id,
        client: { ...customer }
      };
      await submitPayment(payload);
    }
  };

  useEffect(() => {
    if (open) {
      Mixpanel.track('document_billing_button_criar-cobrança');
    }
  // eslint-disable-next-line
  }, [open]);

  useEffect(() => {}, []);

  return open
    ? (
      <Modal
        open={open}
        id="create-payment-modal"
        size="small"
        style={{ minHeight: '639px', maxWidth: '670px', borderRadius: '8px' }}
      >
        <ModalPaymentHeader
          mode="create"
          step={step}
          closeModal={close}
        />
        <Modal.Content scrolling>
          { step === 1
            ? (
              <PaymentInfo
                updatePayment={setPayment}
                setFormState={setIsPaymentFormValid}
                paymentPayload={payment}
                mode="create"
              />
            )
            : (
              <CustomerInfo
                confirmedCustomer={confirmedCustomer}
                undoConfirmation={undoConfirmCustomer}
                updateCustomer={setCustomer}
                customerPayload={customer}
                setFormState={setIsCustomerFormValid}
              />
            )}
        </Modal.Content>
        <S.Footer>
          <Button
            className="action-button back-button"
            onClick={back}
            label={(
              <S.ButtonText>
                <span className="button-text-normal button-text-blue">{ backButtonText }</span>
              </S.ButtonText>
            )}
          />
          <Button
            disabled={
              (!isPaymentFormValid && step === 1) || (!isCustomerFormValid && step === 2) || isCreatingPayment
            }
            className="action-button next-button"
            onClick={next}
            label={(
              <S.ButtonText>
                {
                  isCreatingPayment
                    ? <div style={{ padding: '7px 32px' }}><Loader active={isCreatingPayment} /></div>
                    : <span className="button-text-normal button-text-white">{ nextButtonText }</span>
                }
              </S.ButtonText>
            )}
          />
        </S.Footer>
      </Modal>
    ) : <></>;
};

ModalCreatePayment.defaultProps = {
  onCreatePayment: null
};

ModalCreatePayment.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onCreatePayment: PropTypes.func
};

export default ModalCreatePayment;
