import {
  post, get, put, destroy, ckPayPath
} from '../config';

export const listPaymentsByDocumentId = async (id) => get(`${ckPayPath}?reference_id=${id}`);

export const getPayment = async (id) => get(`${ckPayPath}/${id}`);

export const createPayment = async (data) => post(`${ckPayPath}`, data);

export const editPayment = (data) => put(`${ckPayPath}`, data);

export const removePayment = async (id) => destroy(`${ckPayPath}/${id}`);

export const getPaymentLimit = async () => get(`${ckPayPath}/checkPaymentLimit`);

export const getPaymentPermission = async () => get(`${ckPayPath}/checkPaymentPermission`);

export const confirmPayments = async (data) => post(`${ckPayPath}/confirm`, data);
