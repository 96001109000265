import { Modal, styled } from '@mui/material';

import themes from '../../_utils/themes';

export const CustomModal = styled(Modal)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
});

export const ModalContainer = styled('div')({
  margin: '16px',
  height: 'fit-content',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '16px',
  padding: '16px',
  backgroundColor: themes.background.lightGrey,
  boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
  letterSpacing: '0.1px',
  color: themes.fonts.color.primary,
  fontFamily: themes.fonts.family.Poppins,

  '.close-btn': {
    alignSelf: 'flex-end',

    '.close-icon': {
      height: '24px',
      width: '24px',
      fill: '#CAC4D0'
    }
  },

  '.free-trial-icon': {
    alignSelf: 'center'
  },

  '.title': {
    fontWeight: themes.fonts.weight.bolder,
    fontSize: themes.fonts.size.smaller
  },

  '.body': {
    fontSize: themes.fonts.size.medium,
    fontWeight: themes.fonts.weight.thin
  },

  '.disclaimer': {
    fontWeight: themes.fonts.weight.normal,
    fontSize: themes.fonts.size.min,
    letterSpacing: '0.5px'
  }
});
