/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';

import MainLayout from '../../../_legacy/components/MainLayout';
import PageTitle from './_components/PageTitle';
import PaymentTypeSelector from './_components/PaymentTypeSelector';
import PlansList from './_components/PlansList';
import WhatsappCard from './_components/WhatsappCard';
import {
  Body, Header, MainContainer, Wrapper, Footer
} from './styles';

export default function Plans() {
  const options = [
    {
      label: 'anual à vista',
      value: 'yearly'
    },
    {
      label: 'anual parcelado',
      value: 'installment'
    },
    {
      label: 'mensal',
      value: 'monthly'
    }
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [whatsappActive, setWhatsappActive] = useState(false);
  const [whatsappQuota, setWhatsappQuota] = useState(undefined);

  const handleWhatsappInactive = () => {
    setWhatsappActive(false);
    setWhatsappQuota(undefined);
    localStorage.removeItem('additionalFeatures');
  };

  const handleWhatsappActive = () => {
    setWhatsappActive(true);
  };

  const handleWhatsappToggle = useCallback((active) => {
    if (!active && whatsappActive) return handleWhatsappInactive();
    if (active && !whatsappActive) return handleWhatsappActive();
  }, [
    whatsappActive
  ]);

  const handleQuotaChange = useCallback((quota) => {
    if (quota) {
      setWhatsappQuota(quota);
      localStorage.setItem('additionalFeatures', JSON.stringify([{
        name: 'whatsapp',
        quota: parseInt(quota, 10)
      }]));
    }
  }, [setWhatsappQuota]);

  return (
    <MainLayout header options>
      <MainContainer>
        <Header>
          <PageTitle />
          <PaymentTypeSelector
            selectedOption={selectedOption}
            setSelectedOption={(value) => setSelectedOption(value)}
            options={options}
          />
        </Header>
        <Wrapper>
          <WhatsappCard
            active={whatsappActive}
            onChange={(event) => handleWhatsappToggle(event.target.checked)}
            onSelectQuota={(value) => handleQuotaChange(value)}
            selectedQuota={whatsappQuota}
          />
        </Wrapper>
        <Body>
          <PlansList
            frequency={selectedOption}
            whatsappActive={whatsappActive}
            whatsappQuota={whatsappQuota}
          />
        </Body>
        <Footer>
          *Os valores dos nossos planos anuais parcelados são divididos em pagamentos mensais.
          Ao aderir a um plano anual parcelado, você se compromete a pagar pelos serviços ao
          longo de 12 meses.
        </Footer>
      </MainContainer>
    </MainLayout>
  );
}
