import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.aside`
  display: flex;

  // width: ${({ open }) => (open ? '437px' : '200px')};
  width: 437px;
  height: 100%;
  transition: width 0.8s;

  background-color: blue;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 72px;
  height: 100%;
  padding: 16px;

  background-color: #fff;
  border-left: 2px solid #E0E6ED;

  & > * {
    margin-bottom: 8px !important;
  }
`;

export const Drawer = styled.div`
  display: flex;
  flex-direction: column;

  width: 365px;
  height: 100%;

  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 80px;
  padding: 16px 24px;

  border-bottom: 2px solid #E0E6ED;

  .drawer-header__legend {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.6);
  }

  .drawer-header__title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
    
    color: rgba(0, 0, 0, 0.87);
  }
`;

export const DrawerFooter = styled.div`
  display: table;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 16px 24px;

  border-top: 2px solid #E0E6ED;
`;

export const DrawerContent = styled.div`
  overflow-y: auto;

  width: 100%;
  height: ${({ hasFooter }) => (hasFooter ? 'calc(100% - 180px)' : 'calc(100% - 80px)')};
  min-height: 80px;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  10% {
    transform: scale(1.1);
    filter: alpha(opacity=1);
    opacity: 1;
  }
  20% {
    transform: scale(0.9);
    filter: alpha(opacity=1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
`;

const outerRipple = keyframes`
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  80% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
  }
  100% {
    transform: scale(3.5);
    filter: alpha(opacity=0);
    opacity: 0;
  }
`;

const innerRipple = keyframes`
  0% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  30% {
    transform: scale(1);
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.5);
    filter: alpha(opacity=0);
    opacity: 0;
  }
`;

export const Badge = styled.div`
  width: 25px;
  height: 25px;
  background-color: #346AF732;
  border-radius: 50%;
  position: absolute;
  animation: ${pulse} 2000ms linear infinite;
  -webkit-animation: ${pulse} 2000ms linear infinite;
  -moz-animation: ${pulse} 2000ms linear infinite;
  &::after, &::before {
    display: inline-block;
    margin: auto;
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #346AF732;
    left: 0;
  }
  &::after {
    z-index: -100;
    -webkit-animation: ${outerRipple} 2000ms linear infinite;
    -moz-animation: ${outerRipple} 2000ms linear infinite;
    animation: ${outerRipple} 2000ms linear infinite;
  }
  &::before {
    z-index: -200;
    -webkit-animation: ${innerRipple} 2000ms linear infinite;
    -moz-animation: ${innerRipple} 2000ms linear infinite;
    animation: ${innerRipple} 2000ms linear infinite;
  }
`;
