import { useCallback } from 'react';

const useWootric = () => {
  const wootricToken = `${process.env.REACT_APP_WOOTRIC_TOKEN}`;

  const setup = async (user = { email: '' }) => {
    const setupScript = document.createElement('script');
    setupScript.type = 'text/javascript';
    setupScript.id = 'wootric-settings';
    setupScript.async = true;
    setupScript.innerHTML = `
      wootric_no_surveyed_cookie = true;
      wootric_survey_immediately = ${process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production'};
      window.wootricSettings = {
        email: "${user.email}",
        created_at: "${Math.floor(Date.now() / 1000)}",
        account_token: "${wootricToken}"
      };
    `;
    if (document.getElementById('wootric-settings') == null) {
      document.body.appendChild(setupScript);
    }

    // Beacon
    const beacon = document.createElement('script');
    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.async = true;

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
    // eslint-disable-next-line func-names
    beacon.onload = function () {
      window.wootric('run');
    };
    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon);
    }
  };

  const runWootric = useCallback(() => {
    if (window && window.wootric) window.wootric('run');
  }, []);

  return {
    initWootric: (user) => setup(user),
    runWootric
  };
};

export { useWootric };
