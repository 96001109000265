import React, { useState } from 'react';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import { getFullNameFromUser } from '../../utils/parsers';
import UserAvatar from '../Avatar/UserAvatar';
import ChangePasswordForm from './ChangePasswordForm';
import ProfileForm from './ProfileForm';
import { AvatarWrapper, Wrapper, Container } from './styles';

const ProfileSettings = () => {
  const [openPasswordForm, setOpenPasswordForm] = useState(false);
  const { user } = useAuth();
  const fullName = getFullNameFromUser(user);

  return (
    <Container>
      <Wrapper>
        <AvatarWrapper>
          <UserAvatar
            user={user}
          />
          <h2>{fullName}</h2>
        </AvatarWrapper>
        <hr />
        {!openPasswordForm ? (
          <ProfileForm
            onChangePassword={() => setOpenPasswordForm(!openPasswordForm)}
          />
        ) : (
          <ChangePasswordForm
            onCloseForm={() => setOpenPasswordForm(!openPasswordForm)}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default ProfileSettings;
