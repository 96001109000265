const moment = require('moment');

const unitToAdd = (frequency) => {
  const unitByCycle = {
    WEEKLY: 'weeks',
    BIWEEKLY: 'weeks',
    MONTHLY: 'months',
    QUARTERLY: 'months',
    SEMIANNUALLY: 'months',
    YEARLY: 'years'
  };

  return unitByCycle[frequency];
};
const amountToAdd = (frequency) => {
  const amountByCycle = {
    WEEKLY: 1,
    BIWEEKLY: 2,
    MONTHLY: 1,
    QUARTERLY: 4,
    SEMIANNUALLY: 2,
    YEARLY: 1
  };

  return amountByCycle[frequency];
};

export function getNextPaymentDueDate(nextPaymentDueDate, frequency) {
  const isToday = moment().format('DD/MM/YYYY') === moment(nextPaymentDueDate).format('DD/MM/YYYY');
  const isLessThanToday = moment().isAfter(moment(nextPaymentDueDate));

  if (isToday || isLessThanToday) return moment(nextPaymentDueDate).add(amountToAdd(frequency), unitToAdd(frequency)).format('DD/MM/YYYY');
  return moment(nextPaymentDueDate).format('DD/MM/YYYY');
}
