export default {
  primaryColor: '#346BF7',
  primaryBackgroundColor: '#EBEDF7',
  backgroundColor: '#ffffff',
  secondaryBackgroundColor: '#fafafb',
  thirdBackgroundColor: '#efefef',
  errorColor: '#dd413c',
  errorSecondaryColor: '#fce9e8',
  tooltipBackgrounColor: '#E0E6ED',
  secondaryDark: '#0D0440',
  whiteColor: '#fff',

  greyBlue: '#E0E6ED',
  darkGrey: '#3D3D3D',
  green: '#4CAF50',
  yellowOrange: '#FBBD08',

  defaultAvatarBackground: '#767676',
  defaultAvatarColor: '#C2C2C2',
  avatarColors: [
    '#DB2828',
    '#F2711C',
    '#FBBD08',
    '#B5CC18',
    '#21BA45',
    '#00B5AD',
    '#2185D0',
    '#6435C9',
    '#A333C8',
    '#E03997',
    '#A5673F'
  ],
  avatarSizes: {
    xSmall: '1em',
    small: '1.625em',
    medium: '2.2em',
    large: '2.875em',
    xLarge: '3.750em'
  },

  // Typography
  fontSize: '16px',
  fontFamily: "'Fakt Soft Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif",
  lineHeight: '1.3em',
  fontColor: '#777777',
  textColor: '#333333',

  headerLineHeight: '1.2857em',
  headerColor: '#262626',

  mutedTextColor: '#B5B5B5',
  mutedTextColorHover: '#777777',

  linkColor: '#346BF7',
  surfaceHighEmphasis: 'rgb(0, 0, 0, 0.87)',
  surfaceMediumEmphasis: 'rgb(0, 0, 0, 0.60)',
  linkColorHover: '#4cc7ff',

  borderGrey: '#cccccc',

  starterDropAreaWidth: '90%',
  starterWidth: '750px',
  starterHeight: '80%',
  starterBackground: '#FCFCFC',
  defaultLabelColor: '#FFFFFFBD',

  buttonFont: '\'Fakt Soft Pro\', \'Helvetica Neue\', Arial, Helvetica, sans-serif',
  primaryButton: {
    background: '#346BF7',
    color: '#ffffff',
    border: '#346BF7',
    hover: {
      background: '#2959d2',
      color: '#ffffff',
      border: '#2959d2'
    },
    active: {
      background: '#2959d2',
      color: '#ffffff',
      border: '#2959d2'
    },
    disabled: {
      background: '#d2d5d9',
      color: '#ffffff',
      border: '#d2d5d9'
    }
  },

  secondaryButton: {
    background: 'rgba(52, 106, 247, 0.12)',
    color: '#346BF7',
    border: 'rgba(52, 106, 247, 0.12)',
    hover: {
      background: 'rgba(52, 106, 247, 0.14)',
      color: '#346BF7',
      border: 'rgba(52, 106, 247, 0.14)'
    },
    active: {
      background: 'rgba(52, 106, 247, 0.14)',
      color: '#346BF7',
      border: 'rgba(52, 106, 247, 0.14)'
    },
    disabled: {
      background: 'rgba(52, 106, 247, 0.08)',
      color: '#767676',
      border: 'rgba(52, 106, 247, 0.08)'
    }
  },

  greyButton: {
    background: '#e8e8e8',
    color: '#B5B5B5',
    border: '#e8e8e8',
    hover: {
      background: '#B5B5B5',
      color: '#ffffff',
      border: '#e8e8e8'
    },
    active: {
      background: '#B5B5B5',
      color: '#ffffff',
      border: '#B5B5B5'
    },
    disabled: {
      background: '#B5B5B5',
      color: '#ffffff',
      border: '#B5B5B5'
    }
  },

  lightGreyButton: {
    background: '#ffffff',
    color: '#B5B5B5',
    border: '#e8e8e8',
    hover: {
      background: '#e8e8e8',
      color: '#B5B5B5',
      border: '#e8e8e8'
    },
    active: {
      background: '#B5B5B5',
      color: '#ffffff',
      border: '#B5B5B5'
    },
    disabled: {
      background: '#B5B5B5',
      color: '#ffffff',
      border: '#B5B5B5'
    }
  },
  breakPoints: {
    small: '(max-width: 767.98px)',
    medium: '(max-width: 991.98px)',
    large: '(max-width: 1199.98px)',
    extraLarge: '(min-width: 1200px)'
  }
};
