import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  background-color: #EFEFEF;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.04), 0 3px 6px rgba(0,0,0,0.06);
  padding: 1.5em;
  flex-grow: 0;
  z-index: 1;
  height: 3.5em;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

export const Column = styled.div`
  width: ${(props) => props.width || 'auto'};
  flex-grow: 1;
  flex-shrink: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  line-height: 1.5;
  font-weight: bold;
`;
