/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/html-has-lang */
import React from 'react';

// Define o componente
const EmailTemplate = ({
  logoUrl, ownerName, companyName, documentName, signers
}) => {
  return (
    <html>
      <head>
        <title>Email Template</title>
        <style type="text/css">
          {`
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
            body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
            table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
            img { -ms-interpolation-mode: bicubic; }
            img { border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none; }
            table { border-collapse: collapse !important; }
            body { height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important; }
            a[x-apple-data-detectors] {
              color: inherit !important;
              text-decoration: none !important;
              font-size: inherit !important;
              font-family: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
            }
            @media screen and (max-width: 600px) {
              h1 { font-size: 34px !important; line-height: 46px !important; }
              h2 { font-size: 28px !important; line-height: 41px !important; }
              p, td { font-size: 22px !important; line-height: 32px !important; }
              .img-max { width: 100% !important; max-width: 100% !important; height: auto !important; }
              .max-width { max-width: 100% !important; }
              .mobile-wrapper { width: 85% !important; max-width: 85% !important; }
              .mobile-padding { padding-left: 5% !important; padding-right: 5% !important; }
            }
            div[style*="margin: 16px 0;"] { margin: 0 !important; }
          `}
        </style>
      </head>
      <body style={{
        margin: 0,
        padding: 0,
        backgroundColor: '#fafafa',
        border: '1px solid #F2F0F4',
        borderRadius: '8px'
      }}
      >
        <div style={{
          display: 'none', fontSize: '1px', color: '#fefefe', lineHeight: '1px', fontFamily: 'Open Sans, Helvetica, Arial, sans-serif', maxHeight: '0px', maxWidth: '0px', opacity: '0', overflow: 'hidden'
        }}
        >
          Contraktor
        </div>
        <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ fontFamily: "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif", background: '#f1f1f1' }}>
          <tr>
            <td align="center" valign="top" width="100%" style={{ padding: '0' }} className="mobile-padding">
              <table align="center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                <tr>
                  <td align="center" valign="top" style={{ background: '#ffffff', padding: '15px 0', borderBottom: '1px solid #E0E6ED' }}>
                    <img
                      src={logoUrl || 'https://contraktor.com.br/staticmkt/logos/contraktor_marca_color-2x.png'}
                      border="0"
                      style={{
                        display: 'block',
                        width: '316px',
                        objectFit: 'cover'
                      }}
                      alt="Logo"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top" style={{ background: '#ffffff', padding: '25px 0' }}>
                    <img src="https://static.contraktor.com.br/public/icons/1.png" border="0" style={{ display: 'block', height: '150px' }} alt="Icon" />
                  </td>
                </tr>
                <tr>
                  <td align="center" style={{ background: '#ffffff', padding: '5px 0', height: '30px' }}>
                    <h2>
                      <h2 style={{ color: '#346BF7', fontWeight: 'bold' }}>{ownerName}</h2>
                      da
                      <h2 style={{ color: '#346BF7', fontWeight: 'bold' }}>{companyName}</h2>
                      enviou um documento para você assinar na
                      <h2 style={{ color: '#346BF7', fontWeight: 'bold' }}>Contraktorsign</h2>
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td align="center" style={{ background: '#ffffff', padding: '20px 50px', height: '30px' }}>
                    <font color="#757575">{ownerName} está usando a Contraktorsign e solicita sua assinatura para o documento <b>{documentName}</b>.</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style={{ background: '#ffffff', padding: '10px 50px', height: '30px' }}>
                    <b>Assinantes do documento:</b>
                    <ul>
                      {signers && signers.map((signer, index) => (
                        <li key={index}>{signer.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </body>
    </html>
  );
};

// Exporta o componente para ser usado em outras partes do aplicativo
export default EmailTemplate;
