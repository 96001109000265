/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import { Loader, Modal } from 'semantic-ui-react';

import { useMixpanel } from '../../../../../hooks/MixpanelProvider';
import SignerModalHeader from '../shared/SignerModalHeader/SignerModalHeader';
import AddSignerForm from './AddSIgnerForm';
import * as S from './styles';

const AddSignerModal = ({
  open,
  closeModal,
  addedSigners,
  setSigners,
  defaultSignatureType
}) => {
  const { Mixpanel } = useMixpanel();

  const [loading, setLoading] = useState(false);
  const [updatedSigners, setUpdatedSigners] = useState([]);
  const [hasInvalidPerson, setHasInvalidPerson] = useState(false);

  const resetModal = () => {
    setLoading(false);
  };

  const close = () => {
    Mixpanel.track('document_signer_button_cancelar');
    closeModal();
    resetModal();
  };

  const submit = async () => {
    Mixpanel.track('document_signer_button_concluir');
    setSigners(updatedSigners);
    close();
  };

  if (!open) return null;

  return (
    <>
      <Modal
        open={open}
        id="create-payment-modal"
        size="small"
        style={{ minHeight: '302px', maxWidth: '670px', borderRadius: '8px' }}
      >
        <SignerModalHeader
          mode="create"
          closeModal={() => close()}
        />
        {(loading) && (
        <div style={{
          height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}
        ><Loader active={loading} />
        </div>
        )}
        {(!loading) && (
          <Modal.Content scrolling>
            <AddSignerForm
              defaultSignatureType={defaultSignatureType}
              setHasInvalidPerson={setHasInvalidPerson}
              addedSigners={addedSigners}
              setSigners={setUpdatedSigners}
              open={open}
            />
          </Modal.Content>
        )}
        <S.Footer>
          <Button
            className="action-button back-button"
            onClick={() => close()}
            label={(
              <S.ButtonText>
                <span className="button-text-normal button-text-blue">Cancelar</span>
              </S.ButtonText>
            )}
          />
          <Button
            disabled={hasInvalidPerson}
            className="action-button next-button"
            onClick={() => submit()}
            label={(
              <S.ButtonText>
                <span className="button-text-normal button-text-white">Concluir</span>
              </S.ButtonText>
            )}
          />
        </S.Footer>
      </Modal>
    </>
  );
};

AddSignerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addedSigners: PropTypes.array.isRequired,
  setSigners: PropTypes.func.isRequired,
  defaultSignatureType: PropTypes.string.isRequired
};

export default AddSignerModal;
