/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import AvatarStack from '../../Avatar/AvatarStack';
import SignerAvatar from '../../Avatar/SignerAvatar';

const Shares = ({ document }) => {
  const avatars = [];

  if (document.signers.length === 0) {
    return null;
  }

  document.signers.map((signer) => avatars.push({
    key: signer.id,
    text: signer.name,
    avatar: <SignerAvatar signer={signer} />
  }));

  return (
    <AvatarStack
      items={avatars}
      max={3}
      size="medium"
    />
  );
};

Shares.propTypes = {
  document: PropTypes.shape({
    signers: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default Shares;
