import { MobileStepper } from '@mui/material';
import React, { useCallback, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { plans } from '../../_constants/plans';
import PlanCard from '../PlanCard';
import { PlansGrid } from './styles';

export default function PlansList({ frequency, whatsappActive, whatsappQuota }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = useCallback((step) => {
    setActiveStep(step);
  }, [setActiveStep]);

  return (
    <PlansGrid>
      <div className="grid-container">
        {
          plans.map((p) => {
            return (
              <PlanCard
                plan={p}
                frequency={frequency}
                whatsappActive={whatsappActive}
                whatsappQuota={whatsappQuota}
              />
            );
          })
        }
      </div>

      <div className="stepper-mobile">
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          springConfig={{
            transform: 'translate(100%, 0px)'
          }}
          enableMouseEvents
        >
          {
          plans.map((p) => {
            return (
              <PlanCard
                plan={p}
                frequency={frequency}
              />
            );
          })
        }
        </SwipeableViews>
        <MobileStepper
          steps={plans.length}
          position="static"
          activeStep={activeStep}
        />
      </div>
    </PlansGrid>
  );
}
