/* eslint-disable jsx-a11y/accessible-emoji */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../../ModalBase';
import Vector from './Vector';

const SignatureBlockedModal = ({ open }) => {
  const handleSendEmail = (e) => {
    // window.location.href = 'mailto:teste@ck.com';
    e.preventDefault();
  };

  return (
    <Modal open={open} onClose={() => {}} hiddenCloseButton>
      <span className="modal-base__vector">
        <Vector />
      </span>
      <h2 className="modal-base__title">
        Infelizmente o documento não está mais disponível para assinatura
      </h2>
      <p className="modal-base__description">
        Você pode entrar em contato com remetente para que ele
        reenvie o documento para você assinar.
      </p>

      <div className="modal-base__footer modal-base__footer-btn-xs-padding">
        <Button label="Entrar em contato" disableElevation fullWidth onClick={handleSendEmail} />
      </div>
    </Modal>
  );
};

SignatureBlockedModal.propTypes = {
  open: PropTypes.bool.isRequired
};

export default SignatureBlockedModal;
