import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  ${({ open }) => (open ? 'z-index: 1900;' : '')};
  
  display: flex;
  flex-direction: column;
`;

export const Backdrop = styled.span`
  position: fixed;
  inset: 0;
  z-index: 1901;

  background: rgba(0, 0, 0, 0.45);

  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const TriggerWrapper = styled.div`
  position: relative;
  ${({ open }) => (open ? 'z-index: 1902;' : '')};
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  z-index: 7903;
  ${({ direction }) => (direction === 'left' ? `
  left: calc(100% + 8px);
  ` : `
  right: calc(100% + 8px);
  `)}
`;

export const CloseButton = styled.button`
  position: absolute;
  z-index: 7910;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;

  width: 40px;
  padding: 5px !important;

  background: transparent;
  border: 0;
  opacity: 0.75;
  cursor: pointer;
`;
