import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CustomerCard = styled.div`
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: initial;
  border-radius: 4px;
`;

export const CustomerData = styled.div`
  display: table;
  width: 100%;
  font-family: Poppins;
  margin-left: 12px;
`;

export const CustomerName = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
`;

export const CustomerDocument = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`;

export const DeleteCustomerIcon = styled.div`
  cursor: pointer;

  .trash-icon {
    width: 24px;
    height: 24px;
  }
`;
