import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  font-size: inherit;
`;

const BackgroundPath = styled.path`
  fill: #E0E6ED;
  fill-opacity: 0.2;
`;

const IconAlert = (props) => (
  <IconSvg
    viewBox="0 0 293 275"
    height="1em"
    {...props}
  >
    <BackgroundPath d="M92.55 77.63a14.11 14.11 0 0 0 24.52 13.09l19.78-25.31.66.31 29.97-38.35a47.33 47.33 0 0 1 77 54.94l-18.72 23.95a14.99 14.99 0 0 0 23.27 18.9 26.6 26.6 0 0 1-2.7 4.2L140.88 264.34a26.45 26.45 0 0 1-42.86-30.94l22.21-28.44a14.99 14.99 0 0 0-23.62-18.46l-29.18 37.35a35.6 35.6 0 0 1-50.3-49.68l75.42-96.53zm28.92-38.37L94.84 73.35c-.8 1-1.42 2.1-1.9 3.22l-44.87 57.44a26.45 26.45 0 1 1-41.7-32.57l70.65-90.42a26.45 26.45 0 0 1 44.45 28.24zm62.99 169.76l71.79-91.9a20.31 20.31 0 0 1 32.01 25.02l-71.79 91.89a20.31 20.31 0 1 1-32.01-25.01z" />
    <g>
      <path d="M240.47 200.12L159.44 59.96a14.8 14.8 0 0 0-12.94-7.46 14.8 14.8 0 0 0-12.94 7.46L52.53 200.12a14.75 14.75 0 0 0 0 14.92 14.8 14.8 0 0 0 12.94 7.46h162.06a14.8 14.8 0 0 0 12.95-7.46 14.75 14.75 0 0 0 0-14.92zm-8.13 10.23a5.46 5.46 0 0 1-4.8 2.78H65.46a5.46 5.46 0 0 1-4.81-2.78 5.44 5.44 0 0 1 0-5.54l81.03-140.16a5.46 5.46 0 0 1 4.8-2.78c.97 0 3.37.28 4.82 2.78l81.03 140.16a5.44 5.44 0 0 1 0 5.54z" />
      <path d="M147 92.5c-8 0-14.5 6.41-14.5 14.3v46.4c0 7.89 6.5 14.3 14.5 14.3s14.5-6.41 14.5-14.3v-46.4c0-7.89-6.5-14.3-14.5-14.3zm5 60.7a4.96 4.96 0 0 1-5 4.93c-2.75 0-5-2.21-5-4.93v-46.4a4.96 4.96 0 0 1 5-4.92c2.75 0 5 2.2 5 4.92zm-5.5 23.3c-7.72 0-14 6.28-14 14s6.28 14 14 14 14-6.28 14-14-6.28-14-14-14zm0 18.67a4.67 4.67 0 1 1 .01-9.35 4.67 4.67 0 0 1-.01 9.35z" />
    </g>
  </IconSvg>
);

export default IconAlert;
