/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import SearchInput from '../../SearchInput';
import SignerModalForm from '../../SignerModalForm';
import {
  Tabs, Tab, ActionButton, HeaderActions
} from './styles';

const Header = ({ tabs, setQuery }) => (
  <>
    <Tabs>
      {tabs.map((tab) => (
        <NavLink exact to={tab.href} key={tab.name}>
          <Tab key={tab.name} active={tab.active}>
            {tab.name}
          </Tab>
        </NavLink>
      ))}
      <SignerModalForm
        trigger={(
          <ActionButton unpadded primary>
            Novo Assinante
          </ActionButton>
          )}
      />
    </Tabs>
    <HeaderActions>
      <SearchInput
        onSearch={(value) => setQuery({ search: value, page: undefined })}
      />
    </HeaderActions>
  </>
);

Header.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  setQuery: PropTypes.func.isRequired
};

Header.defaultProps = {
  tabs: []
};

export default Header;
