/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
import { Button } from '@contraktor-tech/ui';
import ProgressBar from '@ramonak/react-progress-bar';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { MdAccountBalance } from 'react-icons/md';

import logoCKAsaas from '../../../../../../assets/img/logo_ck_plus_asaas.svg';
import GridContainer from '../../../../components/GridContainer/index';
import * as S from './styles';

const ModalPaymentHeader = ({
  closeModal, mode, step, switchForm, activeForm
}) => {
  useEffect(() => {}, []);

  switch (mode) {
    case 'info':
      return (
        <>
          <S.Wrapper>
            <S.HeaderImage>
              <img src={logoCKAsaas} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
            </S.HeaderImage>
            <S.CloseModalButton>
              <IoClose id="close-modal-button" onClick={closeModal} />
            </S.CloseModalButton>
          </S.Wrapper>
          <S.SubHeader>
            <S.SubHeaderText>
              Simplifique o recebimento de cobranças e automatize seu negócio
            </S.SubHeaderText>
          </S.SubHeader>
        </>
      );
    case 'create':
      return (
        <>
          <S.Wrapper>
            <S.CloseModalButton>
              <IoClose id="close-modal-button" onClick={closeModal} />
            </S.CloseModalButton>
            <S.HeaderTitle>
              <S.SubTitle>
                Automatize suas cobranças
              </S.SubTitle>
              <S.MainTitle>
                {
                  step === 1 ? 'Cadastro de cobrança automática' : 'Cadastro do pagador'
                }
                <S.StepCounter>
                  {step}/2
                </S.StepCounter>
              </S.MainTitle>
            </S.HeaderTitle>
          </S.Wrapper>
          <S.ProgressIndicator>
            <ProgressBar
              completed={100 * (step / 2)}
              maxCompleted={100}
              className="progress-bar"
              barContainerClassName="bar-container"
              completedClassName="bar-completed"
              labelClassName="progress-label"
            />
          </S.ProgressIndicator>
        </>
      );
    case 'edit':
      return (
        <>
          <S.Wrapper style={{ display: 'table' }}>
            <S.CloseModalButton>
              <IoClose id="close-modal-button" onClick={closeModal} />
            </S.CloseModalButton>
            <S.HeaderTitle style={{ padding: '32px 24px 32px 24px' }}>
              <S.SubTitle>
                Edição de cobrança
              </S.SubTitle>
              <S.MainTitle>
                Edição da parcela
              </S.MainTitle>
            </S.HeaderTitle>
            <S.SelectorContainer>
              <GridContainer
                columns="2fr 2fr"
                width="auto"
                spacing="1.125"
                padding="0"
              >
                <Button
                  onClick={() => switchForm('PAYMENT')}
                  label={(
                    <span className="button-label">
                      <BsCurrencyDollar className={activeForm === 'PAYMENT' ? 'icon-active' : 'icon-normal'} />
                      Cobrança
                    </span>
                  )}
                  className={
                    activeForm === 'PAYMENT' ? 'type-button-active' : 'type-button-normal'
                  }
                />
                <Button
                  onClick={() => switchForm('CUSTOMER')}
                  label={(
                    <span className="button-label">
                      <MdAccountBalance className={activeForm === 'CUSTOMER' ? 'icon-active' : 'icon-normal'} />
                      Pagador
                    </span>
                  )}
                  className={
                    activeForm === 'CUSTOMER' ? 'type-button-active' : 'type-button-normal'
                  }
                />
              </GridContainer>
            </S.SelectorContainer>
          </S.Wrapper>
        </>
      );
    case 'document-info':
      return (
        <>
          <S.Wrapper style={{ display: 'table' }}>
            <S.HeaderTitle style={{ padding: '23.5px 24px 23.5px 24px' }}>
              <S.SubTitle style={{ marginBottom: '4px', fontWeight: '500', opacity: '1' }}>
                Automatize suas cobranças
              </S.SubTitle>
              <S.MainTitle>
                Atualize seu cadastro
              </S.MainTitle>
            </S.HeaderTitle>
          </S.Wrapper>
        </>
      );
    case 'payment-limit-info':
      return (
        <S.Wrapper>
          <S.HeaderImage>
            <img src={logoCKAsaas} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
          </S.HeaderImage>
        </S.Wrapper>
      );
    default:
      return <></>;
  }
};

ModalPaymentHeader.defaultProps = {
  step: null,
  switchForm: null,
  activeForm: null
};

ModalPaymentHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  step: PropTypes.number,
  switchForm: PropTypes.func,
  activeForm: PropTypes.string
};

export default ModalPaymentHeader;
