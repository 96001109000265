import emailValidator from 'email-validator';
import React, { useState, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import {
  updatePersons,
  toggleError
} from '../../../redux/ducks/starterWizard';
import Button from '../../Button';
import Input from '../../Input';
import Content from '../Content';
import Footer from '../Footer';
import Header from '../Header';
import ModalSearchPersons from '../ModalSearchPersons';
import {
  Wrapper, StyledForm, FormGroup, AddPersonWrapper
} from './styles';

const initialPersons = [{
  id: uuid(), name: '', email: '', focused: null
}];

const AddPersons = () => {
  const dispatch = useDispatch();
  const { persons: storePersons } = useSelector((state) => state.starterWizard);

  const [persons, setPersons] = useState(storePersons.length > 0 ? storePersons : initialPersons);
  const [popUp, setPopup] = useState(false);

  const verifyEmail = (value) => {
    const email = ((value === '' || !emailValidator.validate(value)) && 'É necessário inserir um e-mail válido.')
      || (persons.find((p) => p.email === value) && 'E-mail inserido já existe nesta lista de assinantes.')
      || false;

    return email;
  };

  const verifyName = (value) => {
    const name = (value === '' && 'É necessário inserir o nome do assinante.') || false;

    return name;
  };

  useEffect(() => {
    function updateStore() {
      dispatch(updatePersons(persons));
      const error = persons.find(
        (p) => (p && !p.name)
          || (p && !p.email)
          || (p.focused && p.focused.name)
          || (p.focused && p.focused.email)
      );

      dispatch(toggleError(!!error));
    }

    updateStore();
  }, [persons, dispatch]);

  const handleNameChange = (person, value) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        name: value,
        focused: { ...person.focused, name: verifyName(value) }
      }
      : p));

    setPersons(updatedPersons);
  };

  const handleEmailChange = (person, value) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        email: value,
        focused: { ...person.focused, email: verifyEmail(value) }
      }
      : p));

    setPersons(updatedPersons);
  };

  const handleAddPerson = (e) => {
    e.preventDefault();

    setPersons([
      ...persons.map((p) => ({
        ...p,
        focused: {
          ...p.focused,
          email: !emailValidator.validate(p.email) && 'E-mail inválido'
        }
      })),
      {
        id: uuid(), name: '', email: '', focused: null
      }
    ]);
  };

  const handleRemovePerson = (id) => {
    let updatedPersons = persons.filter((p) => p.id !== id);

    updatedPersons = updatedPersons.map((p) => {
      const email = ((p.email === '' || !emailValidator.validate(p.email)) && 'É necessário inserir um e-mail válido.')
        || (updatedPersons.filter((u) => u.email === p.email).length > 1 && 'E-mail inserido já existe nesta lista de assinantes.')
        || false;
      const name = (p.name === '' && 'É necessário inserir o nome do assinante.') || false;
      return {
        ...p,
        focused: {
          email,
          name
        }
      };
    });

    setPersons(updatedPersons);
  };

  const handleAddFromModal = (name, email) => {
    const empty = persons.find((p) => p.name === '' || p.email === '');

    if (empty) {
      setPersons([
        ...persons.map((p) => {
          if (p.id === empty.id) {
            return {
              ...p, name, email, focused: null
            };
          }

          return p;
        })
      ]);
    } else {
      setPersons([
        ...persons,
        {
          id: uuid(), name, email, focused: null
        }
      ]);
    }
  };

  // const to check if persons array has a
  // person with null input fields and then, disable the add button
  const hasPersonNull = persons
    .find((p) => (p && !p.name)
      || (p && !p.email)
      || (p.focused && p.focused.name)
      || (p.focused && p.focused.email));

  return (
    <Wrapper>
      <Header />
      <Content>
        <Content.Header>
          <h4>Dados dos assinantes</h4>
          <Button link>
            <ModalSearchPersons onAddPerson={handleAddFromModal} />
          </Button>
        </Content.Header>
        <StyledForm>
          {persons.map((person) => {
            const {
              id, name, email, focused
            } = person;

            return (
              <div key={id}>
                <FormGroup warning={focused}>
                  <Input
                    type="text"
                    name={id}
                    placeholder="Nome completo"
                    value={name}
                    onChange={(e) => handleNameChange(person, e.target.value)}
                    focus={(focused && focused.name && true) || false}
                    required
                  />
                  <Input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => handleEmailChange(person, e.target.value.toLowerCase().trim())}
                    focus={(focused && focused.email && true) || false}
                    required
                  />
                  {persons.length !== 1 && (
                    <Button link onClick={() => handleRemovePerson(id)}>
                      <Icon name="trash alternate outline" />
                    </Button>
                  )}
                </FormGroup>
                {focused && <small>{focused.name || focused.email}</small>}
              </div>
            );
          })}
          <Popup
            content="Existem assinantes com o campo de nome/e-mail nulos ou repetidos, para adicionar mais assinantes é necessário preenchê-los."
            open={popUp}
            trigger={(
              <AddPersonWrapper>
                <Button
                  link
                  onClick={!hasPersonNull && handleAddPerson}
                  off={hasPersonNull && true}
                  onMouseEnter={() => hasPersonNull && setPopup(true)}
                  onMouseLeave={() => hasPersonNull && setPopup(false)}
                >
                  <FaUser size={14} /> Adicionar assinante
                </Button>
              </AddPersonWrapper>
              )}
          />
        </StyledForm>
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default AddPersons;
