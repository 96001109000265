/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

const Cell = ({ row, col }) => {
  if (col.Cell) {
    return <>{col.Cell({ row })}</>;
  }

  return row[col.key] || '';
};

const TableRow = ({ columns, item }) => (
  <tr>
    {columns.map((col, index) => (
      <td key={`row-col-${index}-${new Date().getTime()}`}>
        <Cell row={item} col={col} />
      </td>
    ))}
  </tr>
);

const Table = ({ columns, data }) => (
  <S.Wrapper>
    <S.Table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col.key} width={col.width || 'auto'}>
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && Array.isArray(data) && data.map((item, index) => <TableRow columns={columns} item={item} key={`row-${index}-${new Date().getTime()}`} />)}
      </tbody>
    </S.Table>
  </S.Wrapper>
);

Cell.propTypes = {
  row: PropTypes.any.isRequired,
  col: PropTypes.any.isRequired
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.any
  ).isRequired,
  data: PropTypes.array.isRequired
};

TableRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.any
  ).isRequired,
  item: PropTypes.object.isRequired
};

export default Table;
