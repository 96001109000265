import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { setDocumentByToken } from '../../redux/ducks/documentByToken';
import { toggleLoading } from '../../redux/ducks/global';
import api from '../../utils/backend';
import { SUPERADMIN_URL } from '../../utils/constants';
import ContainerPreview from './ContainerPreview';
import Sidebar from './Sidebar';
import { CustomLayout } from './styles';

const DocumentSignTokenPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const [mobileSideBar, setMobileSideBar] = useState(false);
  const [error, setError] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    dispatch(toggleLoading(true));
    async function fetchDocument() {
      try {
        const { data } = await api.get(`/signers/${token}`);

        if (data.document.status === 'canceled') {
          throw new Error();
        }
        dispatch(setDocumentByToken(data));
      } catch {
        setError(true);
      } finally {
        dispatch(toggleLoading(false));
      }
    }
    fetchDocument();
  }, [dispatch, token]);

  async function forceSignatureIfNotSigned(document) {
    const { id, signers, status } = document;
    const hasNonCompleteSigner = signers.some((s) => !s.confirmedAt);
    const documentId = id || token;

    if (status !== 'signed' && !hasNonCompleteSigner) await axios.post(`${SUPERADMIN_URL()}/basic/force_signature`, { id: documentId });
  }

  async function confirmSign() {
    dispatch(toggleLoading(true));
    await api.post(`/signers/confirm/${token}`).then(async (response) => {
      dispatch(toggleLoading(false));
      dispatch(setDocumentByToken(response.data));
      await forceSignatureIfNotSigned(response.data?.document);
      toast.success('Documento assinado com sucesso.');
    }).catch(() => {
      toast.error('Erro ao assinar documento.');
    });
  }

  if (error) {
    return (
      <ErrorPage>
        Este link de acesso não é mais válido.<br />
        Para mais informações, entre em contato com o editor do documento
        ou com o nosso suporte técnico.
      </ErrorPage>
    );
  }

  return (
    <CustomLayout header={!isMobile} hideBanner>
      <ContainerPreview confirmSign={() => confirmSign()} />
      <Sidebar
        confirmSign={() => confirmSign()}
        mobileSideBar={mobileSideBar}
        setMobileSideBar={() => setMobileSideBar(!mobileSideBar)}
        isMobile={isMobile}
      />
    </CustomLayout>
  );
};

export default DocumentSignTokenPage;
