/* eslint-disable import/named */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';

import gatewayApi from '../../../../../../../_legacy/components/_new/services/gateway/config';
import { useAuth } from '../../../../../../../_legacy/containers/AuthContext/AuthContext';
import { whatsappQuotaPrices } from '../../constants/whatsappQuotaPrices';
import {
  ContractButton,
  WhatsappQuotaContainer,
  WhatsappQuotaSelectTitle,
  WhatsappSelect,
  WhatsappSelectContainer
} from './styles';

export const WhatsappQuotaSelect = ({ onSelect }) => {
  const [loading, setLoading] = useState(false);
  const [selectedQuota, setSelectedQuota] = useState(undefined);
  const [touched, setTouched] = useState(false);
  const { history } = useHistory();
  const { subscription: { planName } } = useAuth();

  const signWhatsappFeature = async () => {
    setLoading(true);

    return gatewayApi.post('/organizationFeature', {
      quota: selectedQuota,
      name: 'whatsapp'
    })
      .catch(() => {
        toast.error('Erro ao assinar whatsapp');
      })
      .then(() => {
        toast.success('Whatsapp assinado com sucesso');
        history.push('/');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formattedQuotaPrices = (
    Object
      .keys(whatsappQuotaPrices)
      .map((key) => ({ label: key, value: key }))
  );

  const showError = touched && !selectedQuota;

  useEffect(() => {
    onSelect(selectedQuota);
  }, [selectedQuota]); // eslint-disable-line

  return (
    <WhatsappQuotaContainer>
      <WhatsappQuotaSelectTitle>
        ***Selecione a quantidade de envios por WhatsApp por mês:
      </WhatsappQuotaSelectTitle>
      <WhatsappSelectContainer>
        <FormControl className="form-control" error={touched && !selectedQuota}>
          <InputLabel id="whatsapp-quota-select-label">Quantidade de Envios</InputLabel>
          <WhatsappSelect
            labelId="whatsapp-quota-select-label"
            id="whatsapp-quota-select"
            value={selectedQuota}
            label="Quantidade de Envios"
            onChange={(event) => setSelectedQuota(event.target.value)}
            onBlur={() => setTouched(true)}
            fullWidth
          >
            {
              formattedQuotaPrices.map((fqp) => <MenuItem value={fqp.value}>{`${fqp.label} envios`}</MenuItem>)
            }
          </WhatsappSelect>
          {showError && <FormHelperText>Selecione a quantidade de envios</FormHelperText>}
        </FormControl>
        <ContractButton
          variant="contained"
          disabled={!selectedQuota || loading || ['Trial', 'Freemium'].includes(planName)}
          onClick={signWhatsappFeature}
        >
          Contratar agora!
        </ContractButton>
      </WhatsappSelectContainer>
    </WhatsappQuotaContainer>
  );
};

WhatsappQuotaSelect.propTypes = {
  onSelect: PropTypes.func.isRequired
};
