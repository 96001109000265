import React from 'react';
import {
  MdLock as MdLockIcon,
  MdKeyboardArrowLeft as MdArrowLeft,
  MdDone
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

import logoHorizontalColorfull from '../../assets/img/logo_horiz_colorfull.svg';
import CookiesAlert from '../../components/_new/CookiesAlert';
import UpgradePlanGatewayMigration from '../../components/_new/UpgradePlanGatewayMigration';
// import SelectPlanPage from '../NewPaymentPage/components/SelectPlanPage';
import RegisterSteps from './RegisterSteps';
import {
  Container, Header, Content
} from './styles';
import SignUpSuccessPage from './Success';

const NewSignUp = () => {
  const location = useLocation();

  const renderPage = () => {
    if (location.pathname === '/register/success') {
      return (
        <Container>
          <Content>
            <SignUpSuccessPage />
          </Content>
        </Container>
      );
    }

    if (location.pathname === '/register/choose-plan') {
      return (
        <UpgradePlanGatewayMigration />
      );
    }

    return (
      <Container>
        <Content>
          <div className="info-title">
            <h2 className="page-title">Faça o seu cadastro e use gratuitamente</h2>
          </div>
          <div className="info-card">
            <MdDone color="4CAF50" size="20" />
            <p>Não é necessário cartão de crédito</p>
          </div>
          <RegisterSteps />
          <div className="secure-text">
            <MdLockIcon />
            <p>Ambiente seguro</p>
          </div>
        </Content>
        <CookiesAlert />
      </Container>
    );
  };

  return (
    <>
      <Header>
        <Link to="/" className="back-button">
          <MdArrowLeft color="black" size="25" />
        </Link>
        <img src={logoHorizontalColorfull} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
      </Header>
      {renderPage()}
    </>
  );
};

export default NewSignUp;
