import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

import OutlineTextField from '../../../../_components/Input';
import { JustifyOptions } from './styles';

export default function ReasonsOptions({
  onChange,
  optionSelected,
  onReasonChange,
  reason
}) {
  const options = [
    {
      id: 1,
      value: 'Achei o preço alto'
    },
    {
      id: 2,
      value: 'Muitas funcionalidades'
    },
    {
      id: 3,
      value: 'Não tenho demanda de documentos'
    },
    {
      id: 4,
      value: 'Tive dificuldades no uso'
    },
    {
      id: 5,
      value: 'Outro'
    }
  ];

  return (
    <>
      <JustifyOptions>
        <FormGroup>
          {options.slice(0, Math.ceil(options.length / 2)).map((o) => (
            <FormControlLabel
              key={o.id}
              control={(
                <Checkbox
                  key={o.id}
                  value={o.value}
                  checked={optionSelected?.id === o.id}
                  onChange={() => onChange(o)}
                />
              )}
              label={o.value}
            />
          ))}
        </FormGroup>
        <FormGroup>
          {options.slice(Math.ceil(options.length / 2), options.length).map((o) => (
            <FormControlLabel
              key={o.id}
              control={(
                <Checkbox
                  key={o.id}
                  value={o.value}
                  checked={optionSelected?.id === o.id}
                  onChange={() => onChange(o)}
                />
              )}
              label={o.value}
            />
          ))}
        </FormGroup>
      </JustifyOptions>
      <OutlineTextField
        value={reason}
        onChange={(e) => {
          onReasonChange(e.target.value);
        }}
        label="Deixe aqui suas dúvidas, sugestões ou críticas (opcional)"
      />
    </>
  );
}
