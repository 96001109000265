import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
`;

const Icon3Dots = (props) => (
  <IconSvg
    viewBox="0 0 32 32"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx="4" cy="16" r="3" />
    <circle cx="16" cy="16" r="3" />
    <circle cx="28" cy="16" r="3" />
  </IconSvg>
);

export default Icon3Dots;
