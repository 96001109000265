/* eslint-disable import/prefer-default-export */
export const PDFJS_VIEWER_URL = 'https://static.contraktor.com.br/public/pdf.js/web/viewer.html';
export const RECAPTCHA_KEY = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? '6LftIlQiAAAAAExn4JxM5ObW5Dyo7pf9B2x2tBAw' : '6Lc2P1QiAAAAAJy26l2K5VStSpcI6iCLMnihUjYB';
export const RECAPTCHA_UPLOAD_SITE_KEY = '6LeonxUmAAAAAD1gYoD0zmiTLWtyQxdhKyFzwA1G';
export const PRODUCT_FRUIT_CODE = process.env.REACT_APP_PRODUCT_FRUITS_CODE;
export const SUPERADMIN_URL = () => {
  const url = window.location.href;

  if (url.includes('http://localhost')) return 'https://api-superadmin.contraktor.com.br';
  return 'https://api-superadmin.contraktor.com.br';
};
export const GATEWAY_URL = () => {
  const gatewayURL = `${process.env.REACT_APP_GATEWAY_API_URL}/api`;

  if (!gatewayURL) {
    throw new Error('Gateway URL not found.');
  }

  return gatewayURL;
};

export const Breakpoints = {
  MOBILE: 420,
  TABLET: 768,
  SMALLMONITOR: 992,
  LARGEMONITOR: 1200,
  WIDEMONITOR: 1440
};

export const Sizes = {
  XSMALL: 'x-small',
  SMALL: 'small',
  MOBILE: 'mobile',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'x-large',
  RESPONSIVE: 'responsive'
};
