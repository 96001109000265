import React from 'react';

import { DocumentProvider } from '../../contexts/DocumentContenxt';
import DocumentPage from './DocumentPage';

const DocumentWrapper = () => (
  <DocumentProvider>
    <DocumentPage />
  </DocumentProvider>
);

export default DocumentWrapper;
