import React from 'react';

const Vector = () => (
  <svg width="241" height="180" viewBox="0 0 241 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M240.182 158.181H0V158.301H240.182V158.181Z" fill="#EBEBEB" />
    <path d="M113.847 136.757H21.0935C20.3669 136.756 19.6705 136.466 19.1571 135.952C18.6438 135.438 18.3555 134.741 18.3555 134.014V3.62926C18.3618 2.9068 18.6529 2.21602 19.1656 1.70695C19.6782 1.19788 20.3711 0.911638 21.0935 0.9104H113.847C114.574 0.9104 115.272 1.19938 115.786 1.71377C116.301 2.22816 116.59 2.92582 116.59 3.65327V134.014C116.59 134.742 116.301 135.439 115.786 135.954C115.272 136.468 114.574 136.757 113.847 136.757ZM21.0935 1.00647C20.3988 1.00775 19.7329 1.28463 19.2421 1.77636C18.7512 2.26809 18.4756 2.93448 18.4756 3.62926V134.014C18.4756 134.709 18.7512 135.375 19.2421 135.867C19.7329 136.359 20.3988 136.636 21.0935 136.637H113.847C114.542 136.636 115.208 136.359 115.7 135.867C116.192 135.376 116.468 134.709 116.47 134.014V3.62926C116.468 2.93404 116.192 2.26766 115.7 1.77607C115.208 1.28448 114.542 1.00774 113.847 1.00647H21.0935Z" fill="#EBEBEB" />
    <path d="M217.753 136.757H124.995C124.268 136.756 123.571 136.466 123.057 135.952C122.543 135.438 122.253 134.741 122.252 134.014V3.62926C122.26 2.90636 122.552 2.21558 123.065 1.70665C123.579 1.19771 124.272 0.911624 124.995 0.9104H217.753C218.475 0.9129 219.166 1.1997 219.678 1.70863C220.189 2.21757 220.48 2.90763 220.486 3.62926V134.014C220.486 134.74 220.199 135.436 219.686 135.95C219.174 136.464 218.479 136.755 217.753 136.757ZM124.995 1.00647C124.3 1.00774 123.633 1.28448 123.142 1.77607C122.65 2.26766 122.373 2.93404 122.372 3.62926V134.014C122.373 134.709 122.65 135.376 123.142 135.867C123.633 136.359 124.3 136.636 124.995 136.637H217.753C218.448 136.636 219.115 136.359 219.606 135.867C220.098 135.376 220.374 134.709 220.376 134.014V3.62926C220.374 2.93404 220.098 2.26766 219.606 1.77607C219.115 1.28448 218.448 1.00774 217.753 1.00647H124.995Z" fill="#EBEBEB" />
    <path d="M41.1531 165.91H25.2002V166.03H41.1531V165.91Z" fill="#EBEBEB" />
    <path d="M96.2787 165.435H70.291V165.555H96.2787V165.435Z" fill="#EBEBEB" />
    <path d="M50.8135 161.453H41.6338V161.573H50.8135V161.453Z" fill="#EBEBEB" />
    <path d="M216.115 162.26H209.016V162.38H216.115V162.26Z" fill="#EBEBEB" />
    <path d="M204.736 162.26H188.048V162.38H204.736V162.26Z" fill="#EBEBEB" />
    <path d="M150.249 164.287H128.416V164.407H150.249V164.287Z" fill="#EBEBEB" />
    <path d="M174.626 56.109H185.074L183.839 30.9716H174.626V56.109Z" fill="#F5F5F5" />
    <path d="M200.239 58.5492L203.194 67.7626H200.239L182.115 58.5492H200.239Z" fill="#F5F5F5" />
    <path d="M203.194 58.5492V67.7626L185.074 58.5492H203.194Z" fill="#E6E6E6" />
    <path d="M153.293 58.5492L156.247 67.7626H153.293L135.174 58.5492H153.293Z" fill="#F5F5F5" />
    <path d="M156.247 58.5492V67.7626L138.128 58.5492H156.247Z" fill="#E6E6E6" />
    <path d="M208.483 56.157H196.791V58.9239H208.483V56.157Z" fill="#E6E6E6" />
    <path d="M130.865 58.9286L196.785 58.9286V56.1617L130.865 56.1617V58.9286Z" fill="#F5F5F5" />
    <path d="M162.151 56.1089H181.702L184.479 25.6443H164.923L162.151 56.1089Z" fill="#E6E6E6" />
    <path d="M160.989 56.1089H180.545L183.317 25.6443H163.761L160.989 56.1089Z" fill="#F0F0F0" />
    <path d="M178.662 52.7368L181.193 29.0165H165.644L163.107 52.7368H178.662Z" fill="white" />
    <path d="M36.1755 94.0432L103.282 94.0432L103.282 16.306L36.1755 16.306L36.1755 94.0432Z" fill="#E6E6E6" />
    <path d="M33.6304 94.0432L102.092 94.0432L102.092 16.306L33.6304 16.306L33.6304 94.0432Z" fill="#F0F0F0" />
    <path d="M36.1755 98.7797L103.282 98.7797V94.0433L36.1755 94.0433V98.7797Z" fill="#E6E6E6" />
    <path d="M29.3843 98.7797L97.8457 98.7797V94.0433L29.3843 94.0433V98.7797Z" fill="#F0F0F0" />
    <path d="M98.5225 90.4741V19.8751L37.1945 19.8751L37.1945 90.4741H98.5225Z" fill="#FAFAFA" />
    <path d="M73.0388 90.4741L67.3225 19.8751H57.4414L63.1529 90.4741H73.0388Z" fill="white" />
    <path d="M80.1492 90.4741L74.4376 19.8751H64.5518L70.2633 90.4741H80.1492Z" fill="white" />
    <path d="M96.8705 59.092C96.9431 59.092 97.0127 59.0632 97.0641 59.0118C97.1154 58.9605 97.1443 58.8908 97.1443 58.8182V22.1953C97.1443 22.1227 97.1154 22.0531 97.0641 22.0017C97.0127 21.9504 96.9431 21.9215 96.8705 21.9215C96.7983 21.9227 96.7293 21.952 96.6782 22.0031C96.6272 22.0542 96.5979 22.1231 96.5967 22.1953V58.8182C96.5979 58.8904 96.6272 58.9594 96.6782 59.0105C96.7293 59.0615 96.7983 59.0908 96.8705 59.092Z" fill="#F0F0F0" />
    <path d="M61.0298 90.4741L55.3135 19.8751H51.4609L57.1773 90.4741H61.0298Z" fill="white" />
    <path d="M37.6504 90.4741L37.6504 19.8751H37.1989L37.1989 90.4741H37.6504Z" fill="#E6E6E6" />
    <path opacity="0.5" d="M32.7852 26.3409H99.8919L100.219 21.2587H33.1118L32.7852 26.3409Z" fill="#E0E0E0" />
    <path opacity="0.5" d="M32.7852 34.6992H99.8919L100.219 29.6169H33.1118L32.7852 34.6992Z" fill="#E0E0E0" />
    <path opacity="0.5" d="M32.7852 43.0527H99.8919L100.219 37.9705H33.1118L32.7852 43.0527Z" fill="#E0E0E0" />
    <path opacity="0.5" d="M32.7852 51.4062H99.8919L100.219 46.324H33.1118L32.7852 51.4062Z" fill="#E0E0E0" />
    <path opacity="0.5" d="M32.7852 59.7596H99.8919L100.219 54.6774H33.1118L32.7852 59.7596Z" fill="#E0E0E0" />
    <path opacity="0.5" d="M32.7852 68.1132H99.8919L100.219 63.031H33.1118L32.7852 68.1132Z" fill="#E0E0E0" />
    <path d="M196.387 154.67H210.337V92.4965H196.387V154.67Z" fill="#F0F0F0" />
    <path d="M138.873 158.181H207.661V154.675H138.873V158.181Z" fill="#F0F0F0" />
    <path d="M196.392 92.5012H136.197V154.675H196.392V92.5012Z" fill="#F5F5F5" />
    <path d="M190.906 115.059H141.679V129.902H190.906V115.059Z" fill="#F0F0F0" />
    <path d="M190.906 133.212H141.679V148.055H190.906V133.212Z" fill="#F0F0F0" />
    <path d="M160.581 117.043H171.999C172.649 117.043 173.176 116.516 173.176 115.866V115.732C173.176 115.082 172.649 114.555 171.999 114.555H160.581C159.931 114.555 159.404 115.082 159.404 115.732V115.866C159.404 116.516 159.931 117.043 160.581 117.043Z" fill="#F5F5F5" />
    <path d="M190.906 96.9062H141.679V111.749H190.906V96.9062Z" fill="#F0F0F0" />
    <path d="M160.581 98.8901H171.999C172.649 98.8901 173.176 98.3632 173.176 97.7132V97.5787C173.176 96.9288 172.649 96.4019 171.999 96.4019H160.581C159.931 96.4019 159.404 96.9288 159.404 97.5787V97.7132C159.404 98.3632 159.931 98.8901 160.581 98.8901Z" fill="#F5F5F5" />
    <path d="M160.581 135.196H171.999C172.649 135.196 173.176 134.669 173.176 134.019V133.885C173.176 133.235 172.649 132.708 171.999 132.708H160.581C159.931 132.708 159.404 133.235 159.404 133.885V134.019C159.404 134.669 159.931 135.196 160.581 135.196Z" fill="#F5F5F5" />
    <path d="M134.333 92.5012L196.387 92.5012V90.1811L134.333 90.1811V92.5012Z" fill="#F0F0F0" />
    <path d="M212.292 90.1763H196.392V92.4964H212.292V90.1763Z" fill="#E6E6E6" />
    <path d="M91.5285 121.674H84.1309V158.181H91.5285V121.674Z" fill="#F0F0F0" />
    <path d="M37.5644 158.181H84.1309V121.674H37.5644L37.5644 158.181Z" fill="#F5F5F5" />
    <path d="M37.4058 153.186H69.6094V143.16H37.4058V153.186Z" fill="#FAFAFA" />
    <path d="M37.4058 139.927H69.6094V129.902H37.4058V139.927Z" fill="#FAFAFA" />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
      <path d="M91.5288 124.076H37.4062V125.363H91.5288V124.076Z" fill="#E6E6E6" />
    </g>
    <path d="M92.3931 120.896H84.1309V124.618H92.3931V120.896Z" fill="#F0F0F0" />
    <path d="M36.7095 124.618H84.1357V120.896H36.7095V124.618Z" fill="#F5F5F5" />
    <path d="M120.092 179.875C171.53 179.875 213.229 177.44 213.229 174.437C213.229 171.434 171.53 168.999 120.092 168.999C68.6532 168.999 26.9541 171.434 26.9541 174.437C26.9541 177.44 68.6532 179.875 120.092 179.875Z" fill="#F5F5F5" />
    <path d="M175.597 80.8092C175.727 79.7796 175.634 78.734 175.323 77.7436C175.013 76.7533 174.493 75.8413 173.799 75.0697C173.105 74.2981 172.253 73.6851 171.301 73.2721C170.349 72.8592 169.319 72.6561 168.281 72.6767H198.237C199.275 72.6554 200.305 72.858 201.258 73.2706C202.211 73.6832 203.063 74.2962 203.758 75.0679C204.453 75.8395 204.973 76.7517 205.283 77.7424C205.594 78.7332 205.687 79.7792 205.557 80.8092H175.597Z" fill="#407BFF" />
    <path opacity="0.2" d="M175.597 80.8092C175.727 79.7796 175.634 78.734 175.323 77.7436C175.013 76.7533 174.493 75.8413 173.799 75.0697C173.105 74.2981 172.253 73.6851 171.301 73.2721C170.349 72.8592 169.319 72.6561 168.281 72.6767H198.237C199.275 72.6554 200.305 72.858 201.258 73.2706C202.211 73.6832 203.063 74.2962 203.758 75.0679C204.453 75.8395 204.973 76.7517 205.283 77.7424C205.594 78.7332 205.687 79.7792 205.557 80.8092H175.597Z" fill="black" />
    <path d="M168.281 72.6766H198.236C196.015 72.7234 193.886 73.5728 192.242 75.0678C190.599 76.5628 189.552 78.6023 189.296 80.8092L186.702 106.787C186.446 108.994 185.4 111.033 183.756 112.527C182.113 114.021 179.984 114.869 177.763 114.915H164.063C163.025 114.939 161.993 114.739 161.04 114.328C160.086 113.917 159.232 113.304 158.538 112.532C157.843 111.76 157.323 110.847 157.014 109.856C156.705 108.864 156.614 107.817 156.747 106.787L159.336 80.8092C159.593 78.6019 160.641 76.5623 162.285 75.0674C163.929 73.5724 166.059 72.7232 168.281 72.6766Z" fill="#407BFF" />
    <path d="M170.448 106.787C170.314 107.817 170.405 108.864 170.714 109.856C171.023 110.847 171.543 111.76 172.238 112.532C172.933 113.304 173.786 113.917 174.74 114.328C175.694 114.739 176.725 114.94 177.763 114.915H147.803C146.765 114.94 145.734 114.739 144.78 114.328C143.826 113.917 142.973 113.304 142.278 112.532C141.583 111.76 141.063 110.847 140.754 109.856C140.445 108.864 140.354 107.817 140.487 106.787H170.448Z" fill="#407BFF" />
    <path opacity="0.2" d="M170.448 106.787C170.314 107.817 170.405 108.864 170.714 109.856C171.023 110.847 171.543 111.76 172.238 112.532C172.933 113.304 173.786 113.917 174.74 114.328C175.694 114.739 176.725 114.94 177.763 114.915H147.803C146.765 114.94 145.734 114.739 144.78 114.328C143.826 113.917 142.973 113.304 142.278 112.532C141.583 111.76 141.063 110.847 140.754 109.856C140.445 108.864 140.354 107.817 140.487 106.787H170.448Z" fill="black" />
    <path d="M163.27 89.9362H183.541C183.715 89.9316 183.882 89.8646 184.011 89.7473C184.14 89.63 184.222 89.4702 184.243 89.2973V89.23C184.252 89.1491 184.244 89.0671 184.22 88.9894C184.195 88.9117 184.154 88.8403 184.099 88.7798C184.045 88.7193 183.978 88.6711 183.903 88.6386C183.829 88.6061 183.748 88.5899 183.666 88.5911H163.4C163.226 88.5958 163.06 88.663 162.932 88.7804C162.804 88.8978 162.723 89.0575 162.703 89.23V89.2973C162.694 89.3774 162.701 89.4586 162.725 89.5356C162.75 89.6125 162.79 89.6835 162.843 89.7439C162.897 89.8042 162.963 89.8526 163.036 89.8857C163.11 89.9188 163.189 89.936 163.27 89.9362Z" fill="white" />
    <path d="M162.843 94.1298H183.119C183.293 94.1252 183.46 94.0582 183.589 93.9409C183.718 93.8236 183.8 93.6638 183.82 93.4909V93.4236C183.83 93.3427 183.822 93.2607 183.798 93.183C183.773 93.1054 183.732 93.0339 183.677 92.9734C183.623 92.9129 183.556 92.8647 183.481 92.8322C183.406 92.7997 183.326 92.7835 183.244 92.7847H162.982C162.808 92.7893 162.641 92.8563 162.513 92.9736C162.384 93.0909 162.302 93.2507 162.281 93.4236V93.4909C162.271 93.5707 162.278 93.6518 162.301 93.7288C162.325 93.8058 162.364 93.8769 162.418 93.9373C162.471 93.9978 162.536 94.0462 162.61 94.0794C162.683 94.1126 162.762 94.1297 162.843 94.1298Z" fill="white" />
    <path d="M162.43 98.3232H182.706C182.88 98.3189 183.046 98.2525 183.175 98.1362C183.303 98.0198 183.386 97.8613 183.408 97.6892V97.6171C183.418 97.5365 183.411 97.4545 183.387 97.3768C183.363 97.2992 183.322 97.2276 183.268 97.167C183.214 97.1064 183.147 97.0582 183.073 97.0256C182.998 96.993 182.917 96.9769 182.836 96.9782H162.565C162.391 96.9828 162.224 97.0498 162.095 97.1671C161.966 97.2844 161.884 97.4442 161.863 97.6171V97.6892C161.854 97.769 161.861 97.85 161.886 97.9268C161.91 98.0035 161.95 98.0742 162.004 98.1342C162.057 98.1942 162.123 98.2421 162.197 98.2746C162.27 98.3072 162.35 98.3238 162.43 98.3232Z" fill="white" />
    <path d="M166.687 102.517H182.275C182.448 102.513 182.615 102.447 182.744 102.331C182.873 102.214 182.955 102.055 182.976 101.883V101.811C182.986 101.73 182.979 101.648 182.955 101.571C182.931 101.494 182.89 101.422 182.836 101.362C182.782 101.302 182.715 101.254 182.641 101.222C182.566 101.19 182.486 101.175 182.404 101.177H166.836C166.662 101.18 166.496 101.246 166.367 101.362C166.238 101.479 166.155 101.638 166.135 101.811V101.883C166.124 101.962 166.131 102.042 166.154 102.118C166.177 102.194 166.216 102.264 166.268 102.324C166.32 102.384 166.385 102.432 166.457 102.465C166.529 102.499 166.607 102.516 166.687 102.517Z" fill="white" />
    <path d="M164.106 81.5489H184.382C184.556 81.5444 184.723 81.4774 184.852 81.36C184.98 81.2427 185.063 81.083 185.083 80.9101V80.838C185.093 80.7577 185.085 80.6763 185.061 80.5992C185.036 80.5221 184.996 80.4512 184.942 80.3911C184.887 80.3311 184.821 80.2833 184.747 80.251C184.673 80.2187 184.593 80.2027 184.512 80.2039H164.24C164.067 80.2083 163.901 80.2747 163.772 80.391C163.644 80.5073 163.561 80.6659 163.539 80.838V80.9101C163.53 80.9902 163.537 81.0714 163.561 81.1484C163.585 81.2253 163.626 81.2963 163.679 81.3567C163.733 81.417 163.798 81.4654 163.872 81.4985C163.946 81.5316 164.025 81.5488 164.106 81.5489Z" fill="white" />
    <path d="M163.689 85.7425H183.979C184.153 85.7378 184.319 85.6706 184.447 85.5532C184.575 85.4358 184.656 85.2762 184.676 85.1036V85.0364C184.685 84.9558 184.678 84.8742 184.653 84.7969C184.629 84.7196 184.588 84.6484 184.534 84.5879C184.48 84.5275 184.414 84.4793 184.34 84.4464C184.265 84.4136 184.185 84.3969 184.104 84.3975H163.804C163.63 84.402 163.463 84.4691 163.334 84.5864C163.206 84.7037 163.123 84.8634 163.103 85.0364V85.1036C163.093 85.1854 163.101 85.2682 163.126 85.3466C163.151 85.4249 163.193 85.4969 163.249 85.5576C163.305 85.6182 163.373 85.6662 163.449 85.6981C163.525 85.73 163.606 85.7451 163.689 85.7425Z" fill="white" />
    <path d="M101.324 73.5125L99.4935 91.8432C99.4445 92.1889 99.4715 92.5411 99.5725 92.8753C99.6735 93.2095 99.8462 93.5176 100.078 93.7783C100.311 94.0389 100.597 94.2458 100.918 94.3845C101.238 94.5231 101.585 94.5903 101.934 94.5813H143.658C144.399 94.5633 145.108 94.2791 145.656 93.7806C146.204 93.2821 146.554 92.6028 146.641 91.8672L148.471 73.5365C148.507 73.1953 148.473 72.8504 148.37 72.523C148.218 72.0231 147.907 71.5866 147.483 71.2797C147.06 70.9728 146.549 70.8123 146.026 70.8225H104.307C103.748 70.8355 103.202 71.0001 102.729 71.2988C102.257 71.5974 101.874 72.0189 101.622 72.5182C101.463 72.8289 101.362 73.1658 101.324 73.5125Z" fill="#407BFF" />
    <path opacity="0.3" d="M101.621 72.4845L120.989 86.9819C121.74 87.5055 122.634 87.7862 123.55 87.7862C124.465 87.7862 125.359 87.5055 126.11 86.9819L148.37 72.4845C148.218 71.9846 147.906 71.5481 147.483 71.2412C147.06 70.9343 146.548 70.7738 146.026 70.784H104.306C103.747 70.7975 103.201 70.9628 102.728 71.2624C102.255 71.5619 101.873 71.9843 101.621 72.4845Z" fill="black" />
    <path d="M110.782 75.1938H133.215C134.201 75.1717 135.146 74.7939 135.876 74.1302C136.606 73.4664 137.072 72.5613 137.187 71.5815L140.43 39.1281C140.486 38.6709 140.443 38.2071 140.304 37.7679C140.165 37.3287 139.934 36.9243 139.626 36.582C139.318 36.2397 138.94 35.9674 138.517 35.7835C138.095 35.5996 137.638 35.5083 137.178 35.5158H114.745C113.759 35.538 112.813 35.9157 112.083 36.5795C111.353 37.2432 110.888 38.1483 110.772 39.1281L107.535 71.5815C107.477 72.0386 107.519 72.5026 107.657 72.9422C107.795 73.3817 108.025 73.7865 108.333 74.1291C108.641 74.4717 109.019 74.7441 109.442 74.9278C109.864 75.1115 110.321 75.2022 110.782 75.1938Z" fill="#407BFF" />
    <path opacity="0.5" d="M110.782 75.1938H133.215C134.201 75.1717 135.146 74.7939 135.876 74.1302C136.606 73.4664 137.072 72.5613 137.187 71.5815L140.43 39.1281C140.486 38.6709 140.443 38.2071 140.304 37.7679C140.165 37.3287 139.934 36.9243 139.626 36.582C139.318 36.2397 138.94 35.9674 138.517 35.7835C138.095 35.5996 137.638 35.5083 137.178 35.5158H114.745C113.759 35.538 112.813 35.9157 112.083 36.5795C111.353 37.2432 110.888 38.1483 110.772 39.1281L107.535 71.5815C107.477 72.0386 107.519 72.5026 107.657 72.9422C107.795 73.3817 108.025 73.7865 108.333 74.1291C108.641 74.4717 109.019 74.7441 109.442 74.9278C109.864 75.1115 110.321 75.2022 110.782 75.1938Z" fill="white" />
    <path d="M114.624 52.9722H133.814C134.036 52.9667 134.247 52.8815 134.411 52.7324C134.574 52.5832 134.678 52.3801 134.703 52.1604C134.716 52.0576 134.707 51.9531 134.676 51.8542C134.645 51.7553 134.593 51.6641 134.524 51.5871C134.455 51.51 134.37 51.4488 134.275 51.4077C134.179 51.3665 134.077 51.3463 133.973 51.3486H114.806C114.585 51.3527 114.373 51.4367 114.209 51.585C114.045 51.7333 113.94 51.9359 113.913 52.1556C113.9 52.257 113.909 52.36 113.939 52.4578C113.968 52.5556 114.018 52.6461 114.086 52.7232C114.153 52.8003 114.235 52.8623 114.328 52.9052C114.421 52.9481 114.522 52.971 114.624 52.9722Z" fill="white" />
    <path d="M114.12 58.0304H133.31C133.531 58.0249 133.743 57.9397 133.906 57.7905C134.07 57.6414 134.174 57.4382 134.199 57.2185C134.211 57.1161 134.202 57.0122 134.17 56.9139C134.139 56.8155 134.087 56.725 134.018 56.6485C133.949 56.572 133.864 56.5112 133.769 56.4704C133.674 56.4295 133.572 56.4094 133.469 56.4115H114.254C114.034 56.4159 113.822 56.5 113.659 56.6484C113.495 56.7968 113.391 56.9993 113.366 57.2185C113.353 57.3233 113.363 57.4295 113.396 57.5298C113.428 57.6302 113.482 57.7222 113.554 57.7995C113.626 57.8768 113.714 57.9375 113.812 57.9774C113.909 58.0172 114.014 58.0353 114.12 58.0304Z" fill="white" />
    <path d="M132.883 61.465H113.698C113.249 61.465 112.886 61.8274 112.886 62.2744C112.886 62.7214 113.249 63.0838 113.698 63.0838H132.883C133.332 63.0838 133.695 62.7214 133.695 62.2744C133.695 61.8274 133.332 61.465 132.883 61.465Z" fill="white" />
    <path d="M117.637 68.1613H132.297C132.519 68.157 132.732 68.0723 132.896 67.923C133.061 67.7738 133.165 67.57 133.191 67.3495C133.203 67.2468 133.193 67.1427 133.162 67.0441C133.131 66.9455 133.079 66.8548 133.01 66.7779C132.941 66.701 132.856 66.6398 132.761 66.5984C132.667 66.5569 132.564 66.5362 132.461 66.5377H117.8C117.578 66.542 117.365 66.6267 117.201 66.7759C117.037 66.9252 116.932 67.129 116.907 67.3495C116.894 67.4521 116.904 67.5563 116.935 67.6548C116.966 67.7534 117.018 67.8442 117.087 67.9211C117.157 67.9979 117.241 68.0592 117.336 68.1006C117.431 68.142 117.533 68.1627 117.637 68.1613Z" fill="white" />
    <path d="M115.633 42.8557H134.848C135.069 42.8503 135.281 42.7651 135.444 42.6159C135.607 42.4668 135.711 42.2636 135.736 42.0439C135.746 41.9413 135.732 41.8379 135.698 41.7407C135.664 41.6436 135.609 41.5549 135.538 41.4807C135.466 41.4065 135.38 41.3485 135.284 41.3106C135.188 41.2726 135.085 41.2557 134.982 41.2609H115.797C115.576 41.2661 115.364 41.3504 115.2 41.4985C115.036 41.6466 114.931 41.8487 114.903 42.0679C114.894 42.1687 114.907 42.2702 114.94 42.3659C114.973 42.4616 115.025 42.5493 115.094 42.6235C115.163 42.6977 115.246 42.7567 115.339 42.7967C115.432 42.8367 115.532 42.8568 115.633 42.8557Z" fill="white" />
    <path d="M115.129 47.914H134.343C134.564 47.9085 134.776 47.8234 134.939 47.6742C135.103 47.525 135.207 47.3219 135.232 47.1022C135.244 46.9998 135.235 46.8959 135.203 46.7975C135.172 46.6992 135.12 46.6087 135.051 46.5322C134.982 46.4557 134.897 46.3949 134.802 46.354C134.707 46.3131 134.605 46.2931 134.502 46.2952H115.287C115.066 46.2994 114.854 46.3834 114.69 46.5317C114.526 46.6799 114.42 46.8826 114.394 47.1022C114.381 47.2053 114.391 47.3098 114.423 47.4087C114.454 47.5077 114.507 47.5987 114.576 47.6756C114.646 47.7526 114.731 47.8137 114.827 47.8548C114.922 47.896 115.025 47.9161 115.129 47.914Z" fill="white" />
    <path d="M128.349 126.727L129.564 114.564C129.607 114.206 129.573 113.842 129.464 113.498C129.355 113.153 129.174 112.836 128.932 112.568C128.691 112.3 128.394 112.086 128.063 111.941C127.732 111.797 127.374 111.725 127.013 111.73H101.554C100.78 111.748 100.039 112.044 99.4658 112.565C98.8931 113.086 98.5275 113.796 98.4363 114.564L97.221 126.727C97.1769 127.086 97.2104 127.45 97.319 127.795C97.4277 128.139 97.609 128.457 97.8508 128.725C98.0925 128.994 98.3891 129.208 98.7205 129.352C99.0519 129.496 99.4103 129.567 99.7717 129.561H125.231C126.005 129.545 126.747 129.248 127.32 128.728C127.893 128.207 128.258 127.496 128.349 126.727Z" fill="#407BFF" />
    <path d="M113.116 123.331C113.226 123.331 113.335 123.299 113.428 123.24L127.358 114.958C127.491 114.885 127.592 114.764 127.639 114.619C127.686 114.475 127.677 114.318 127.613 114.18C127.576 114.116 127.527 114.061 127.467 114.017C127.408 113.974 127.341 113.943 127.269 113.926C127.198 113.909 127.124 113.908 127.051 113.921C126.979 113.934 126.91 113.962 126.849 114.002L113.26 122.096L101.284 114.002C101.154 113.925 100.998 113.9 100.85 113.933C100.702 113.966 100.572 114.054 100.487 114.18C100.44 114.237 100.405 114.303 100.385 114.373C100.365 114.444 100.359 114.518 100.369 114.591C100.378 114.664 100.402 114.734 100.44 114.797C100.478 114.86 100.528 114.915 100.588 114.958L112.842 123.24C112.921 123.297 113.017 123.329 113.116 123.331Z" fill="white" />
    <path d="M192.66 50.9883L194.207 35.4965C194.262 35.0393 194.219 34.5755 194.081 34.1363C193.942 33.6971 193.711 33.2927 193.403 32.9504C193.094 32.6081 192.716 32.3359 192.294 32.1519C191.872 31.968 191.415 31.8767 190.955 31.8842H158.521C157.535 31.9084 156.591 32.2868 155.861 32.9502C155.132 33.6135 154.665 34.5175 154.548 35.4965L153.001 50.9931C152.946 51.4503 152.988 51.9141 153.127 52.3533C153.266 52.7925 153.497 53.1969 153.805 53.5392C154.113 53.8815 154.491 54.1538 154.914 54.3377C155.336 54.5216 155.793 54.6129 156.253 54.6054H188.687C189.674 54.5811 190.619 54.202 191.348 53.5376C192.078 52.8733 192.544 51.9682 192.66 50.9883Z" fill="#407BFF" />
    <path opacity="0.5" d="M192.66 50.9883L194.207 35.4965C194.262 35.0393 194.219 34.5755 194.081 34.1363C193.942 33.6971 193.711 33.2927 193.403 32.9504C193.094 32.6081 192.716 32.3359 192.294 32.1519C191.872 31.968 191.415 31.8767 190.955 31.8842H158.521C157.535 31.9084 156.591 32.2868 155.861 32.9502C155.132 33.6135 154.665 34.5175 154.548 35.4965L153.001 50.9931C152.946 51.4503 152.988 51.9141 153.127 52.3533C153.266 52.7925 153.497 53.1969 153.805 53.5392C154.113 53.8815 154.491 54.1538 154.914 54.3377C155.336 54.5216 155.793 54.6129 156.253 54.6054H188.687C189.674 54.5811 190.619 54.202 191.348 53.5376C192.078 52.8733 192.544 51.9682 192.66 50.9883Z" fill="black" />
    <path d="M173.248 46.6601C173.389 46.6593 173.527 46.6193 173.647 46.5449L191.372 35.9769C191.541 35.8831 191.668 35.729 191.728 35.5454C191.787 35.3618 191.775 35.1624 191.694 34.9873C191.647 34.9083 191.584 34.8395 191.51 34.7852C191.436 34.7308 191.351 34.692 191.262 34.6709C191.172 34.6499 191.079 34.647 190.988 34.6626C190.898 34.6782 190.811 34.7118 190.733 34.7615L173.411 45.1038L158.15 34.776C157.985 34.6761 157.788 34.6434 157.6 34.6845C157.411 34.7256 157.245 34.8374 157.137 34.9969C157.077 35.0692 157.032 35.153 157.006 35.2432C156.98 35.3334 156.972 35.428 156.984 35.5211C156.996 35.6143 157.026 35.7041 157.074 35.785C157.122 35.8659 157.186 35.9361 157.262 35.9913L172.873 46.5593C172.986 46.6295 173.116 46.6646 173.248 46.6601Z" fill="white" />
    <path d="M70.2284 50.6712C69.0179 52.7992 67.7977 54.9945 66.6257 57.1801C65.4536 59.3658 64.3151 61.561 63.3063 63.7611C63.0662 64.3039 62.826 64.8563 62.5906 65.3991L62.4273 65.8074L62.3408 66.0092V66.062V66.0092C62.379 65.8141 62.379 65.6134 62.3408 65.4183C62.2534 65.0447 62.05 64.7084 61.7596 64.4576C61.6235 64.3336 61.4583 64.246 61.2792 64.203C61.2168 64.203 61.236 64.203 61.2792 64.203C61.4503 64.2463 61.6263 64.2673 61.8028 64.2655C62.3344 64.2645 62.8654 64.2308 63.3928 64.1646C65.6841 63.8764 68.1724 63.324 70.5983 62.8148L71.5974 65.3895C69.2828 66.6901 66.8307 67.7293 64.2863 68.4879C63.5777 68.6972 62.8531 68.8482 62.1199 68.9394C61.6643 68.9967 61.2043 69.0112 60.746 68.9826C60.4308 68.9562 60.1187 68.8999 59.8141 68.8145C59.3329 68.6805 58.887 68.4428 58.5075 68.118C57.9733 67.6569 57.6067 67.032 57.4651 66.3406C57.3674 65.8335 57.3772 65.3114 57.494 64.8083L57.5612 64.5441L57.5996 64.4144V64.3567L57.6765 64.1118L57.835 63.6314C58.0416 62.9733 58.2818 62.3632 58.5075 61.734C59.4682 59.2649 60.5491 56.9063 61.7067 54.5958C62.8644 52.2852 64.1086 50.0515 65.4536 47.8082L70.2284 50.6712Z" fill="#FFC3BD" />
    <path d="M84.4276 34.2619C84.3508 34.5502 84.4276 34.824 84.6342 34.872C84.8408 34.92 85.0329 34.7231 85.1146 34.4349C85.1962 34.1467 85.1146 33.8777 84.908 33.8296C84.7015 33.7816 84.4997 33.9737 84.4276 34.2619Z" fill="#263238" />
    <path d="M84.4183 34.8145C84.5397 35.7808 84.7918 36.7261 85.1677 37.6246C84.9355 37.716 84.686 37.7553 84.4369 37.7395C84.1879 37.7237 83.9454 37.6533 83.7266 37.5333L84.4183 34.8145Z" fill="#ED847E" />
    <path d="M83.7799 32.3983C83.7598 32.3887 83.7419 32.3754 83.727 32.3589C83.7122 32.3424 83.7008 32.3231 83.6934 32.3022C83.6848 32.2811 83.6805 32.2584 83.6807 32.2356C83.6809 32.2128 83.6855 32.1903 83.6945 32.1693C83.7034 32.1483 83.7164 32.1293 83.7327 32.1134C83.749 32.0974 83.7683 32.0849 83.7895 32.0764C84.0454 31.9697 84.3231 31.9253 84.5996 31.947C84.876 31.9687 85.1434 32.0559 85.3795 32.2013C85.4166 32.2291 85.4413 32.2702 85.4485 32.316C85.4557 32.3618 85.4447 32.4085 85.4179 32.4463C85.391 32.483 85.3507 32.5077 85.3057 32.5149C85.2608 32.5221 85.2148 32.5112 85.1777 32.4847C84.9893 32.3727 84.777 32.3069 84.5583 32.2927C84.3395 32.2785 84.1205 32.3163 83.9192 32.4031C83.8969 32.412 83.873 32.4161 83.849 32.4153C83.825 32.4145 83.8015 32.4087 83.7799 32.3983Z" fill="#263238" />
    <path d="M75.5752 35.8136C74.821 38.5517 75.1477 42.4042 72.9092 44.2007C72.9092 44.2007 73.1109 46.934 78.1115 46.934C83.6165 46.934 81.2819 44.2007 81.2819 44.2007C78.3997 43.485 78.9329 41.2561 79.8696 39.1617L75.5752 35.8136Z" fill="#FFC3BD" />
    <path d="M72.7121 44.806C72.3134 44.2776 71.8763 43.6772 72.7842 43.4898C73.7785 43.2784 79.2258 43.0671 81.104 43.4562C82.9823 43.8453 82.4539 44.8973 82.4539 44.8973L72.7121 44.806Z" fill="#263238" />
    <path d="M56.7497 169.062C56.8982 169.115 57.0558 169.138 57.2133 169.13C57.3708 169.121 57.5251 169.082 57.6672 169.014C57.7218 168.964 57.7595 168.898 57.775 168.826C57.7905 168.753 57.7831 168.678 57.7537 168.61C57.7375 168.553 57.7089 168.5 57.6698 168.455C57.6308 168.41 57.5823 168.375 57.5279 168.351C57.0476 168.115 55.8899 168.581 55.8418 168.6C55.8263 168.607 55.8128 168.618 55.8027 168.631C55.7926 168.645 55.7862 168.661 55.7842 168.677C55.7838 168.695 55.7886 168.712 55.7981 168.726C55.8075 168.741 55.8211 168.752 55.837 168.759C56.1297 168.892 56.4357 168.993 56.7497 169.062ZM57.3502 168.476L57.4511 168.509C57.4833 168.522 57.5121 168.542 57.5354 168.568C57.5587 168.594 57.5758 168.625 57.5856 168.658C57.6336 168.817 57.5856 168.865 57.5567 168.879C57.355 169.047 56.62 168.879 56.1157 168.687C56.5062 168.525 56.928 168.453 57.3502 168.476Z" fill="#407BFF" />
    <path d="M55.857 168.764H55.9002C56.3133 168.644 57.2068 168.01 57.2164 167.592C57.2171 167.526 57.1956 167.463 57.1555 167.411C57.1154 167.36 57.0589 167.324 56.9954 167.308C56.9318 167.286 56.8643 167.278 56.7973 167.284C56.7303 167.29 56.6652 167.309 56.6063 167.342C56.126 167.592 55.8041 168.61 55.7897 168.653C55.7816 168.666 55.7773 168.681 55.7773 168.697C55.7773 168.712 55.7816 168.727 55.7897 168.74C55.7959 168.751 55.8062 168.76 55.8187 168.765C55.8311 168.769 55.8448 168.769 55.857 168.764ZM56.8994 167.462H56.9378C57.0435 167.496 57.0435 167.548 57.0435 167.572C57.0435 167.822 56.4478 168.317 56.0155 168.533C56.1312 168.128 56.3654 167.767 56.688 167.496C56.752 167.459 56.8272 167.447 56.8994 167.462Z" fill="#407BFF" />
    <path d="M83.256 170.868C83.7363 170.868 84.1879 170.801 84.3416 170.589C84.3811 170.534 84.4023 170.467 84.4023 170.399C84.4023 170.331 84.3811 170.265 84.3416 170.21C84.3124 170.157 84.2726 170.111 84.2246 170.075C84.1766 170.038 84.1216 170.012 84.063 169.998C83.4481 169.83 82.1415 170.565 82.1079 170.599C82.0924 170.607 82.0799 170.619 82.0721 170.635C82.0643 170.651 82.0617 170.668 82.0647 170.685C82.0665 170.703 82.074 170.72 82.0862 170.733C82.0983 170.746 82.1144 170.754 82.1319 170.757C82.5025 170.828 82.8787 170.865 83.256 170.868ZM83.842 170.152C83.8978 170.144 83.9544 170.144 84.0101 170.152C84.0457 170.161 84.0793 170.176 84.109 170.197C84.1387 170.219 84.1638 170.246 84.1831 170.277C84.2407 170.383 84.2215 170.435 84.1831 170.469C84.0101 170.704 83.083 170.709 82.3961 170.613C82.844 170.368 83.3349 170.211 83.842 170.152Z" fill="#407BFF" />
    <path d="M82.1418 170.767H82.1754C82.6222 170.565 83.5012 169.772 83.434 169.355C83.434 169.259 83.3475 169.134 83.1073 169.11C83.0213 169.1 82.9344 169.109 82.8518 169.134C82.7692 169.16 82.6927 169.202 82.627 169.259C82.1466 169.643 82.0601 170.637 82.0553 170.7C82.0535 170.714 82.0556 170.729 82.0615 170.743C82.0675 170.756 82.0769 170.768 82.089 170.776C82.1071 170.778 82.1254 170.775 82.1418 170.767ZM83.0401 169.273H83.1025C83.2563 169.273 83.2707 169.355 83.2707 169.374C83.3139 169.624 82.7134 170.248 82.2619 170.522C82.3015 170.102 82.4692 169.705 82.7423 169.383C82.8274 169.313 82.9343 169.274 83.0449 169.273H83.0401Z" fill="#407BFF" />
    <path d="M78.0967 170.676H81.7811L82.1461 162.154H78.4666L78.0967 170.676Z" fill="#FFC3BD" />
    <path d="M51.749 167.789L55.3469 168.567L58.9641 160.626L55.3614 159.848L51.749 167.789Z" fill="#FFC3BD" />
    <path d="M55.693 168.206L51.6531 167.332C51.5818 167.315 51.5065 167.323 51.4406 167.355C51.3747 167.388 51.3224 167.443 51.2928 167.51L49.8902 170.555C49.8569 170.63 49.8407 170.711 49.843 170.792C49.8452 170.874 49.8658 170.954 49.9032 171.026C49.9406 171.099 49.9938 171.162 50.0589 171.211C50.124 171.26 50.1993 171.294 50.2793 171.31C51.7204 171.588 52.3977 171.655 54.175 172.04C55.2703 172.275 57.7393 172.876 59.2477 173.202C60.756 173.529 61.2796 172.083 60.6888 171.814C58.0516 170.604 56.9467 169.542 56.231 168.552C56.1011 168.374 55.9092 168.251 55.693 168.206Z" fill="#263238" />
    <path d="M81.7574 170.253H77.7368C77.6634 170.252 77.5919 170.276 77.5342 170.322C77.4766 170.367 77.4362 170.431 77.4197 170.503L76.6944 173.774C76.6761 173.853 76.6761 173.935 76.6943 174.014C76.7124 174.093 76.7484 174.166 76.7993 174.229C76.8502 174.292 76.9148 174.343 76.9882 174.377C77.0616 174.411 77.1418 174.429 77.2228 174.427C78.6639 174.403 80.7679 174.322 82.5932 174.322C84.7212 174.322 86.5658 174.437 89.0637 174.437C90.5769 174.437 90.9852 172.909 90.3607 172.77C87.4785 172.141 85.1296 172.073 82.6413 170.536C82.3782 170.363 82.0724 170.264 81.7574 170.253Z" fill="#263238" />
    <path d="M66.8754 45.0318C63.4264 45.8244 61.332 52.1027 61.332 52.1027L67.2501 58.7125C68.6047 56.6056 69.8521 54.4316 70.9873 52.1988C73.0145 47.8947 70.4013 44.2248 66.8754 45.0318Z" fill="#407BFF" />
    <path opacity="0.3" d="M68.418 50.9306C67.4573 52.2948 66.5734 54.2931 67.5678 58.1744C68.346 56.9255 69.1098 55.6477 69.7679 54.49C69.9456 51.3004 69.3355 49.8305 68.418 50.9306Z" fill="black" />
    <path d="M88.3864 47.4576C89.2703 50.8201 90.1541 58.9143 87.7091 77.0384H68.0142C67.8797 74.0169 69.7771 59.2649 66.8613 45.0318C69.0379 44.6126 71.2394 44.335 73.4519 44.2007C76.2405 44.0397 79.0361 44.0397 81.8246 44.2007C83.0851 44.3219 84.3376 44.5143 85.5763 44.7772C86.2487 44.9159 86.8687 45.2407 87.3655 45.7146C87.8624 46.1885 88.2161 46.7925 88.3864 47.4576Z" fill="#407BFF" />
    <path opacity="0.3" d="M88.5211 47.986C85.2691 45.0078 82.8673 44.8637 82.214 48.1349C80.8449 54.9897 88.9102 62.3729 89.1456 62.5843C89.5635 54.836 89.0736 50.4215 88.5211 47.986Z" fill="black" />
    <path d="M86.4643 46.2999C87.5211 47.472 88.5154 48.6633 89.529 49.8546C90.5426 51.0459 91.5225 52.2564 92.488 53.4862C93.4535 54.7159 94.4095 55.9504 95.3702 57.2186C96.3309 58.4867 97.234 59.7693 98.1275 61.1335L96.5519 60.1104C97.3445 60.3121 98.1419 60.5283 98.9537 60.6964C99.7655 60.8645 100.601 61.0375 101.437 61.1768C102.273 61.3161 103.123 61.4602 103.978 61.5899C104.833 61.7196 105.703 61.8301 106.524 61.955L106.697 64.7123C105.736 64.9284 104.857 65.0485 103.93 65.1926C103.003 65.3367 102.086 65.3559 101.154 65.3896C99.2699 65.4481 97.3845 65.342 95.5191 65.0725L94.6449 64.9428L93.9675 64.0493C93.0789 62.8773 92.1037 61.7052 91.143 60.5283L88.1888 57.0168L82.2227 49.9843L86.4643 46.2999Z" fill="#FFC3BD" />
    <path d="M118.352 38.3499C115.967 37.8054 113.465 38.1366 111.304 39.2829C109.143 40.4293 107.465 42.3145 106.578 44.5946C104.854 49.2397 107.328 53.9857 112.102 55.1914C114.488 55.7359 116.989 55.4047 119.15 54.2584C121.312 53.112 122.989 51.2268 123.876 48.9467C124.303 47.8934 124.495 46.7601 124.441 45.6251C124.386 44.4901 124.086 43.3804 123.561 42.3728C123.036 41.3651 122.298 40.4836 121.398 39.789C120.499 39.0944 119.46 38.6034 118.352 38.3499ZM112.588 53.8464C111.655 53.6326 110.781 53.219 110.024 52.6342C109.267 52.0494 108.646 51.3073 108.204 50.4592C107.762 49.6111 107.509 48.6772 107.463 47.7219C107.417 46.7666 107.579 45.8127 107.938 44.9261C108.687 43.0095 110.1 41.4258 111.919 40.4631C113.737 39.5004 115.841 39.2227 117.848 39.6805C118.779 39.8949 119.654 40.3089 120.41 40.8939C121.166 41.479 121.786 42.2211 122.228 43.0691C122.669 43.9171 122.922 44.8509 122.968 45.8059C123.013 46.7609 122.851 47.7145 122.493 48.6008C121.743 50.5138 120.332 52.0947 118.517 53.057C116.701 54.0194 114.601 54.2994 112.597 53.8464H112.588Z" fill="#263238" />
    <path opacity="0.3" d="M112.818 53.27C111.965 53.0739 111.164 52.6951 110.472 52.1597C109.779 51.6242 109.211 50.9449 108.806 50.1686C108.402 49.3923 108.17 48.5375 108.128 47.6631C108.086 46.7887 108.234 45.9155 108.562 45.1039C109.249 43.3484 110.543 41.8979 112.209 41.0163C113.876 40.1347 115.803 39.8806 117.641 40.3002C118.494 40.4959 119.295 40.8744 119.989 41.4095C120.682 41.9446 121.251 42.6237 121.656 43.3999C122.062 44.1761 122.294 45.031 122.337 45.9057C122.38 46.7804 122.233 47.654 121.906 48.4664C121.219 50.2238 119.923 51.6758 118.255 52.5576C116.587 53.4394 114.657 53.6922 112.818 53.27Z" fill="#407BFF" />
    <g opacity="0.5">
      <path opacity="0.5" d="M117.636 40.2715L117.511 40.2427L108.601 44.955L108.562 45.104C108.035 46.4626 107.998 47.9624 108.457 49.3456L121.172 42.6205C120.304 41.4487 119.053 40.6177 117.636 40.2715Z" fill="#FAFAFA" />
      <path opacity="0.5" d="M121.633 43.3362L108.793 50.1285C109.018 50.5723 109.3 50.9842 109.634 51.3534L122.157 44.7244C122.043 44.2408 121.867 43.7741 121.633 43.3362Z" fill="#FAFAFA" />
    </g>
    <path d="M112.587 56.1089L111.069 55.7246C111.015 55.7119 110.965 55.6878 110.922 55.6541C110.878 55.6203 110.843 55.5776 110.817 55.5289C110.792 55.4802 110.777 55.4265 110.774 55.3716C110.771 55.3167 110.78 55.2618 110.8 55.2106C110.84 55.098 110.921 55.0046 111.027 54.9486C111.133 54.8927 111.256 54.8782 111.372 54.908L112.889 55.2922C112.943 55.305 112.993 55.329 113.037 55.3628C113.08 55.3965 113.116 55.4392 113.141 55.4879C113.167 55.5367 113.181 55.5903 113.184 55.6452C113.187 55.7001 113.179 55.755 113.158 55.8062C113.118 55.9188 113.037 56.0122 112.931 56.0682C112.825 56.1241 112.703 56.1387 112.587 56.1089Z" fill="#263238" />
    <path d="M112.631 55.4412C112.535 56.1089 112.396 56.7622 112.237 57.4155C112.079 58.0688 111.901 58.7077 111.709 59.337C111.517 59.9662 111.305 60.6147 111.084 61.2584C110.863 61.9021 110.863 62.5842 109.821 63.0117C109.651 63.0654 109.471 63.0789 109.296 63.0514C109.12 63.0239 108.953 62.9561 108.807 62.8532C108.758 62.8162 108.715 62.7726 108.678 62.7235C108.101 61.8877 108.634 61.3305 108.817 60.6868C108.999 60.0431 109.321 59.4378 109.6 58.8182C109.879 58.1985 110.162 57.5788 110.474 56.9688C110.786 56.3587 111.113 55.7486 111.488 55.153C111.587 55.0633 111.711 55.0046 111.843 54.9842C111.975 54.9637 112.11 54.9823 112.232 55.0377C112.492 55.1242 112.65 55.2923 112.631 55.4412Z" fill="#263238" />
    <path d="M105.68 64.7987L108.389 65.0389L107.841 60.8213C107.841 60.8213 105.761 60.8597 104.724 62.3056L105.68 64.7987Z" fill="#FFC3BD" />
    <path d="M111.953 64.0494L111.045 61.3209L107.822 60.8165L108.37 65.0389L111.953 64.0494Z" fill="#FFC3BD" />
    <path d="M81.7722 47.9044C80.3552 52.7897 87.796 59.5148 87.796 59.5148L94.4778 52.9722C94.4778 52.9722 92.273 50.2245 90.7262 48.4136C86.3885 43.365 82.9059 44.0182 81.7722 47.9044Z" fill="#407BFF" />
    <path opacity="0.2" d="M82.1461 162.154L81.9588 166.549H78.2744L78.4618 162.154H82.1461Z" fill="black" />
    <path opacity="0.2" d="M55.3619 159.848L58.9598 160.631L57.096 164.719L53.498 163.941L55.3619 159.848Z" fill="black" />
    <path d="M74.9839 31.2166C74.3018 34.8049 73.7205 36.8705 75.0463 39.1378C77.035 42.5484 81.8098 42.02 83.5487 38.7247C85.1147 35.7416 85.9506 30.3712 82.8618 28.0894C82.1808 27.5812 81.3809 27.2558 80.5385 27.1442C79.6961 27.0327 78.8391 27.1388 78.0493 27.4523C77.2594 27.7658 76.563 28.2764 76.0264 28.9353C75.4898 29.5943 75.1309 30.3796 74.9839 31.2166Z" fill="#FFC3BD" />
    <path d="M81.1817 34.3485C81.9311 34.0554 86.8932 27.729 76.1667 27.6522C71.4976 27.6186 72.0116 36.88 75.3165 38.7005C78.6214 40.5211 81.9743 36.7791 81.1817 34.3485Z" fill="#263238" />
    <path d="M75.0852 28.7715C81.1474 31.2165 87.1231 31.572 88.5114 28.2191C89.6066 25.5771 86.2681 27.5033 86.2681 27.5033C86.2681 27.5033 88.2232 24.6211 84.7693 24.5299C81.3155 24.4386 82.968 26.4513 82.968 26.4513C82.968 26.4513 72.5345 23.4539 75.0852 28.7715Z" fill="#263238" />
    <path d="M75.09 27.729C75.09 27.729 73.3271 27.6185 72.9092 29.1701C73.428 28.5696 74.0621 28.0653 74.4992 28.5312C75.1285 28.0461 75.09 27.729 75.09 27.729Z" fill="#263238" />
    <path d="M80.5616 33.8874C80.3844 34.6377 80.4569 35.4254 80.7682 36.1307C81.1909 37.053 82.0075 36.8224 82.4014 35.9866C82.7521 35.2372 82.9634 33.8538 82.2957 33.2053C81.628 32.5568 80.7922 32.9891 80.5616 33.8874Z" fill="#FFC3BD" />
    <path d="M80.7335 77.0384C80.7335 77.0384 76.5544 105.173 74.263 115.712C71.7507 127.246 58.9539 164.234 58.9539 164.234L52.3633 162.793C52.3633 162.793 61.7832 128.149 63.2195 116.726C64.7711 104.323 68.0231 77.0048 68.0231 77.0048L80.7335 77.0384Z" fill="#263238" />
    <path d="M60.5254 162.053L59.2957 164.455L51.8789 162.831L52.5082 160.602L60.5254 162.053Z" fill="#407BFF" />
    <path opacity="0.3" d="M78.092 87.0637C73.2595 88.0244 73.7687 106.024 74.3548 115.29C75.7478 108.742 77.7845 96.1377 79.1584 87.1934C78.8274 87.0322 78.452 86.9865 78.092 87.0637Z" fill="black" />
    <path d="M87.7191 77.0385C87.7191 77.0385 88.1994 104.563 87.8247 115.789C87.4068 127.467 83.3285 165.699 83.3285 165.699H77.0838C77.0838 165.699 76.8533 128.149 76.5794 116.702C76.2768 104.213 74.7637 77.0577 74.7637 77.0577L87.7191 77.0385Z" fill="#263238" />
    <path d="M84.5433 163.216C84.5721 163.216 84.1926 165.805 84.1926 165.805H76.5933L76.3867 163.494L84.5433 163.216Z" fill="#407BFF" />
  </svg>
);

export default Vector;
