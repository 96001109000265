import React from 'react';

import NewFeatureImg from '../../../../../_legacy/assets/img/new-features.svg';
import { WhatsappInfoCard } from './components/WhatsappInfoCard';
import { WhatsappQuotaSelect } from './components/WhatsappQuotaSelect';
import {
  WhatsappCardLabel,
  WhatsappContainer,
  WhatsappSwitch,
  WhatsappSwitchCard,
  NewFeatureFlag
} from './styles';

const cardLabel = 'Planos com Envio por WhatsApp';

export default function WhatsappCard({
  onChange, onSelectQuota, active, selectedQuota
}) {
  return (
    <>
      <WhatsappContainer>
        <WhatsappSwitchCard active={active}>
          <WhatsappSwitch checked={active} sx={{ m: 1 }} onChange={onChange} />
          <WhatsappCardLabel>{cardLabel}</WhatsappCardLabel>
          <NewFeatureFlag>
            <img src={NewFeatureImg} alt="new feature" />
          </NewFeatureFlag>
        </WhatsappSwitchCard>
        {
          active && (
            <>
              <WhatsappQuotaSelect onSelect={onSelectQuota} />
              { selectedQuota && <WhatsappInfoCard quota={selectedQuota} /> }
            </>
          )
        }
      </WhatsappContainer>
    </>
  );
}
