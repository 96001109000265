import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { PDFJS_VIEWER_URL } from '../../utils/constants';

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const FilePreview = ({ previewUrl }) => (
  <Iframe
    key={previewUrl}
    src={`${PDFJS_VIEWER_URL}?file=${encodeURIComponent(previewUrl)}#disableCreateObjectURL`}
    title="pdf"
  />
);

FilePreview.propTypes = {
  previewUrl: PropTypes.string.isRequired
};

export default FilePreview;
