import {
  Header as HeaderUI
} from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';

import logoHorizontalWhite from '../../../../assets/img/logo_horiz_white.svg';
import Banner from '../../../Trial/Banner';
import { Container } from './styles';

const Header = ({ hideBanner }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Container>
      {!hideBanner && <Banner />}

      {!isMobile && (
        <HeaderUI
          appBarPosition="static"
          appBarColorType="primary"
          toolbarType="regular"
          disableGutters={false}
        >
          <NavLink to="/">
            <img id="logo" src={logoHorizontalWhite} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
          </NavLink>
        </HeaderUI>
      )}
    </Container>
  );
};

Header.propTypes = {
  hideBanner: PropTypes.bool
};

Header.defaultProps = {
  hideBanner: false
};
export default Header;
