/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import { Button, Divider } from '@contraktor-tech/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaCheck as FaCheckIcon } from 'react-icons/fa';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';

import { getNextPaymentDueDate } from '../../../_utils/getNextPaymentDueDate';
import logoDark from '../../../../../assets/img/logo_letters_colorfull.svg';
import logoWhite from '../../../../../assets/img/logo_letters_white.svg';
import { useAuth } from '../../../../../containers/AuthContext/AuthContext';
import * as S from './styles';

function PlanComponent({
  plan, withFooter, planType, currentPlan
}) {
  const {
    features, planName
  } = plan;
  const [currentPlanInfo, setCurrentPlanInfo] = useState(false);
  const { subscription } = useAuth();

  const getCardColor = (planName) => (planName === 'Unlimited' ? '#0C0440' : '#ffffff');

  const getTextColor = (planName) => (planName === 'Unlimited' ? '#ffffff' : 'rgba(0, 0, 0, 0.6)');

  const getCardLogo = (planName) => (planName === 'Unlimited' ? logoWhite : logoDark);

  const getRenewDate = (option) => {
    const lastChargeDiff = moment().diff(moment(subscription.lastPaymentDate).format(), 'days');
    if (lastChargeDiff < 30 && !subscription.customerAsaas) return moment(subscription.nextPaymentDueDate).format('DD/MM/YYYY', true);
    if (option.name.includes('Anual')) return moment().add(1, 'year').format('DD/MM/YYYY', true);
    return moment().add(1, 'month').format('DD/MM/YYYY', true);
  };

  const optionCard = (option, planName) => (
    <div className="option-card">
      <div className="option-info-container">
        <div className="option-info-text">
          <span className="option-name" style={{ color: planName === 'Unlimited' ? '#ffffff' : 'rgba(0, 0, 0, 0.87)' }}>{option.name}</span>
          <span className="option-discount">{option.discount}</span>
          <span className="option-price-details" style={{ color: planName === 'Unlimited' ? '#ffffff' : 'rgba(0, 0, 0, 0.6)' }}>{option.priceDetails}</span>
          <span className="option-price-details" style={{ color: planName === 'Unlimited' ? '#ffffff' : 'rgba(0, 0, 0, 0.6)' }}>
            Renovação: {getRenewDate(option)}
          </span>
        </div>
      </div>
      <Button
        disabled={currentPlan.param === option.param && subscription.customerAsaas}
        className={planName === 'Unlimited' ? 'option-button-starter' : 'option-button-light'}
        label={currentPlan.param === option.param ? 'Manter plano Atual' : 'Escolher esse'}
        onClick={() => {
          window.location.href = `/update-payment-info?selectedPlan=${option.param}`;
        }}
      />
    </div>
  );

  const planOptions = (planName) => {
    const options = [
      {
        value: 'light',
        param: 'light_anual',
        name: 'Plano Light - Anual à vista',
        discount: 'GANHE 21% DE DESCONTO',
        priceDetails: 'R$26,91/mês ou R$322,92/ano'
      },
      {
        value: 'essential',
        param: 'essential_anual',
        name: 'Plano Essential - Anual à vista',
        discount: 'GANHE 21% DE DESCONTO',
        priceDetails: 'R$44,91/mês ou R$538,92/ano'
      },
      {
        value: 'unlimited',
        param: 'unlimited_anual',
        name: 'Plano Unlimited - Anual à vista',
        discount: 'GANHE 10% DE DESCONTO',
        priceDetails: 'R$134,90/mês ou R$1.618,80/ano'
      },
      {
        value: 'light',
        param: 'light_anual_installment',
        name: 'Plano Light - Anual parcelado',
        discount: 'GANHE 12% DE DESCONTO',
        priceDetails: 'R$29,90/mês ou R$358,80/ano'
      },
      {
        value: 'essential',
        param: 'essential_anual_installment',
        name: 'Plano Essential - Anual parcelado',
        discount: 'GANHE 12% DE DESCONTO',
        priceDetails: 'R$47,40/mês ou R$568,80/ano'
      },
      {
        value: 'unlimited',
        param: 'unlimited_anual_installment',
        name: 'Plano Unlimited - Anual parcelado',
        discount: 'GANHE 5% DE DESCONTO',
        priceDetails: 'R$142,41/mês ou R$1.708,92/ano'
      },
      {
        value: 'light',
        param: 'light',
        name: 'Plano Light - Mensal',
        discount: null,
        priceDetails: 'R$23,90/mês ou R$406,80/ano'
      },
      {
        value: 'essential',
        param: 'essential',
        name: 'Plano Essential - Mensal',
        discount: null,
        priceDetails: 'R$49,90/mês ou R$598,80/ano'
      },
      {
        value: 'unlimited',
        param: 'unlimited',
        name: 'Plano Unlimited - Mensal',
        discount: null,
        priceDetails: 'R$149,90/mês ou R$1.798,80/ano'
      }
    ];

    return options.filter((o) => o.value === planName.toLowerCase());
  };

  if (planType === 'business') {
    return (
      <S.Container style={{ backgroundColor: getCardColor(planName) }} elevation={4}>
        <div className="container-header">
          <div className="icon-container">
            <img src={getCardLogo(planName)} alt="contraktor logo" style={{ width: '50px' }} />
          </div>
          <div className="plan-info">
            <h6 className="plan-info-title">{planName}</h6>
          </div>
        </div>
        <Divider style={{ backgroundColor: '#E0E6ED' }} />
        <div className="description-container">
          <p className="description-title">Benefícios do plano:</p>
          <ul className="features-list">
            {features.map((item) => (
              <li className="feature-list-item" key={item}>
                <span className="feature-list-item-icon">
                  <FaCheckIcon style={{ color: '#346AF7' }} size="14" fontSize="14" />
                </span>
                <p style={{ color: getTextColor(planName) }} className="feature-list-item-text">{item}</p>
              </li>
            ))}
          </ul>
        </div>
        {
          currentPlan.planName === planName && (
            <>
              <Divider style={{ backgroundColor: '#E0E6ED' }} />
              <div className="current-plan-info-container" onClick={() => setCurrentPlanInfo(!currentPlanInfo)}>
                <span className="current-plan-info-text">Consultar meu plano atual</span>
                {
                  currentPlanInfo ? <MdOutlineKeyboardArrowUp className="current-plan-info-icon" /> : <MdOutlineKeyboardArrowDown className="current-plan-info-icon" />
                }
              </div>
            </>
          )
        }

        {withFooter && (
        <div className="container-footer">
          <S.ChoosePlanButton
            label="Ver mais detalhes"
            variant="outlined"
            onClick={() => window.open('https://contraktor.com.br/precos-e-planos/')}
          />
          <S.ChoosePlanButton
            label="Falar com vendas"
            onClick={() => window.open('https://contraktor.com.br/fale-com-vendas')}
            variant="contained"
          />
        </div>
        )}
      </S.Container>
    );
  }

  return (
    <S.Container style={{ backgroundColor: getCardColor(planName) }} elevation={4}>
      <div className="container-header">
        <div className="icon-container">
          <img src={getCardLogo(planName)} alt="contraktor logo" style={{ width: '50px' }} />
        </div>
        <div className="plan-info">
          <h6 style={{ color: getTextColor(planName) }} className="plan-info-title">Plano {planName}</h6>
        </div>
      </div>
      <Divider style={{ backgroundColor: '#E0E6ED' }} />
      <div className="description-container">

        <p style={{ color: getTextColor(planName) }} className="description-title">Benefícios do plano:</p>
        <ul className="features-list">
          {features.map((item) => (
            <li className="feature-list-item" key={item}>
              <span className="feature-list-item-icon">
                <FaCheckIcon style={{ color: '#346AF7' }} size="14" fontSize="14" />
              </span>
              <p style={{ color: getTextColor(planName) }} className="feature-list-item-text">{item}</p>
            </li>
          ))}
        </ul>
      </div>
      <Divider style={{ backgroundColor: '#E0E6ED' }} />
      <div className="plans-details">
        <div className="plans-details-title">
          <span>Assine o plano anual e ganhe desconto, confira as opções:</span>
        </div>
        {planOptions(planName).map((option) => optionCard(option, planName))}
      </div>
      {
          currentPlan.planName === planName && (
            <div>
              <Divider style={{ backgroundColor: '#E0E6ED' }} />
              <div className="current-plan-info-container" onClick={() => setCurrentPlanInfo(!currentPlanInfo)}>
                <span className="current-plan-info-text">Consultar meu plano atual</span>
                { currentPlanInfo ? <MdOutlineKeyboardArrowUp className="current-plan-info-icon" /> : <MdOutlineKeyboardArrowDown className="current-plan-info-icon" /> }
              </div>
              {
                currentPlanInfo && (
                  <div className="details-content">
                    <div className="price-details">
                      <span className="plan-name" style={{ color: planName === 'Unlimited' ? '#ffffff' : 'rgba(0, 0, 0, 0.87)' }}>Plano {planName}</span>
                      <span className="price-description" style={{ color: getTextColor(planName) }}>{currentPlan.priceDescription}</span>
                    </div>
                    <div className="divider" />
                    <div className="renew-dates">
                      <span style={{ color: getTextColor(planName) }}>Última renovação: { moment(subscription.lastPaymentDate).format('DD/MM/YYYY', true) }</span>
                      <span
                        style={{ color: getTextColor(planName) }}
                      >
                        Próxima renovação: {
                          getNextPaymentDueDate(
                            subscription.nextPaymentDueDate,
                            currentPlan.frequency
                          )
                        }
                      </span>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
    </S.Container>
  );
}

PlanComponent.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string),
    planName: PropTypes.string.isRequired
  }),
  withFooter: PropTypes.bool,
  planType: PropTypes.string,
  currentPlan: PropTypes.any.isRequired
};

PlanComponent.defaultProps = {
  plan: {},
  withFooter: false,
  planType: 'normal'
};

export default PlanComponent;
