/* eslint-disable react/forbid-prop-types */
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';

const OptionsMenuItem = ({
  danger,
  style,
  children,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Dropdown.Item
      style={{
        ...style,
        color: (danger) ? theme.errorColor : 'inherit'
      }}
      {...omit(props, ['innerRef'])}
    >
      {children}
    </Dropdown.Item>
  );
};

OptionsMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any)
};

OptionsMenuItem.defaultProps = {
  danger: false,
  style: {}
};

export default OptionsMenuItem;
