import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #346AF7;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  .choose-plan-container {
    text-align: center;
  }

  .choose-plan-message {
    font-size: 34px;
    color: ${({ theme }) => theme.backgroundColor};
    font-weight: 400;
  }

  .plans-container {
    display: flex;
    flex-direction: row;

    > .MuiPaper-root {
      margin: 0.5rem;
    }

    @media ${({ theme }) => theme.breakPoints.medium} {
      flex-direction: column;
      padding: 1rem;

      > .MuiPaper-root {
        margin: 1rem 0;
      }
    }
  }
`;

export const Header = styled.header`
  height: 64px;
  padding: 1rem;
  display: flex;
  align-items: center;

  .logo {
    max-width: 85%;
    max-width: 14rem;
    margin-left: 20px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 20px;
  }
`;
