import { Paper } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Container = styled(Paper)`
  width: 100%;
  margin: 1rem 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 16px 6px 16px 16px;

  border-bottom: 2px solid #E0E6ED;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  padding: 0 6px 0 16px;
  width: 176px;
  overflow: hidden;

  h2, p {
    width: 99%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.contraktorUi.palette.surface.black};
  }

  p {
    color: ${({ theme }) => theme.contraktorUi.palette.surface.mediumEmphasis};
  }
`;

export const ButtonLabel = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
`;

export const InputLabel = styled.div`
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 400;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;

  padding: 12px 16px;
`;
