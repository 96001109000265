import PropTypes from 'prop-types';
import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

import { easeOutExpo } from '../../utils/easings';
import { resetButton } from '../../utils/styles';

const Link = styled.button`
  ${resetButton}

  display: 'inline-block';

  color: ${(props) => (props.disabled ? props.theme.mutedTextColor : props.theme.linkColorHover)};
  font-weight: bold;

  &:hover,
  &:focus {
    color: ${(props) => (props.disabled ? props.theme.mutedTextColorHover : props.theme.linkColorHover)};
  }
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')}
`;

const TertiaryButton = styled.button`
  ${resetButton}

  display: 'inline-block';
  outline-style: none;

  color: ${(props) => props.theme.strongColor};
  font-weight: bold;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.linkColorHover};
  }
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')}
`;

const Circle = styled.button`
  ${resetButton}

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  padding: 0.5em;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.mutedTextColor};
  box-shadow: 0 0 3px 0 rgba(51, 51, 51, 0.1);
  border-radius: 100%;

  transition: background-color 200ms ${easeOutExpo};
  transition: color 200ms ${easeOutExpo};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.primaryColor};
    color:black;
  }
  ${(props) => (props.disabled ? 'cursor: not-allowed;' : '')}
`;

const StyledSemanticButton = styled(SemanticButton)`
  &.ui.button {
    ${(props) => (props.disabled ? 'cursor: not-allowed;' : '')}
    ${(props) => (props.unpadded ? 'padding: 1em;' : '')}
    ${(props) => (!props.borderless ? 'border-radius: 0;' : 'border-radius: .28571429em')}

    ${(props) => props.size === 'mini' && 'padding: .5em 1em;'};
  }
`;

const StyledLightGreyButton = styled(StyledSemanticButton)`
  &.ui.button {
    background-color: ${(props) => props.theme.lightGreyButton.background};
    color: ${(props) => props.theme.lightGreyButton.color};
    border: 1px solid ${(props) => props.theme.lightGreyButton.border};

    &:hover {
      background-color: ${(props) => props.theme.lightGreyButton.hover.background};
      color: ${(props) => props.theme.lightGreyButton.hover.color};
      border-color: ${(props) => props.theme.lightGreyButton.hover.border};
    }
  }
`;

const StyledGreyButton = styled(StyledSemanticButton)`
  &.ui.button {
    background-color: ${(props) => props.theme.greyButton.background};
    color: ${(props) => props.theme.greyButton.color};
    border: 1px solid ${(props) => props.theme.greyButton.border};

    &:hover {
      background-color: ${(props) => props.theme.greyButton.hover.background};
      color: ${(props) => props.theme.greyButton.hover.color};
      border-color: ${(props) => props.theme.greyButton.hover.border};
    }
  }
`;

const Button = ({
  link,
  circle,
  card,
  tertiary,
  unpadded,
  borderless,
  color,
  ...props
}) => {
  if (link) {
    return (
      <Link type="button" {...props} />
    );
  }

  if (tertiary) {
    return (
      <TertiaryButton type="button" tertiary={tertiary} {...props} />
    );
  }

  if (circle) {
    return (
      <Circle type="button" {...props} />
    );
  }

  if (color === 'lightGrey') {
    return (
      <StyledLightGreyButton
        unpadded={unpadded.toString()}
        borderless={borderless.toString()}
        {...props}
      />
    );
  }

  if (color === 'grey') {
    return (
      <StyledGreyButton
        unpadded={unpadded.toString()}
        borderless={borderless.toString()}
        {...props}
      />
    );
  }

  return (
    <StyledSemanticButton
      unpadded={unpadded.toString()}
      borderless={borderless.toString()}
      {...props}
    />
  );
};

Button.propTypes = {
  link: PropTypes.bool,
  circle: PropTypes.bool,
  card: PropTypes.bool,
  disabled: PropTypes.bool,
  tertiary: PropTypes.bool,
  unpadded: PropTypes.bool,
  borderless: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string
};

Button.defaultProps = {
  link: false,
  circle: false,
  card: false,
  disabled: false,
  tertiary: false,
  onClick: () => {},
  unpadded: false,
  borderless: false,
  color: 'primary',
  size: 'medium'
};

Button.Content = SemanticButton.Content;
Button.Group = SemanticButton.Group;
Button.Or = SemanticButton.Or;

export default Button;
