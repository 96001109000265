import isNil from 'lodash/isNil';
import styled from 'styled-components';

const getAvatarSize = (size, theme) => {
  if (Object.prototype.hasOwnProperty.call(theme.avatarSizes, size)) {
    return theme.avatarSizes[size];
  }

  return theme.avatarSizes.medium;
};

const getRealColor = (color, theme) => {
  const formattedColorValue = color.replace(/\D/g, '');
  const parsedColor = Number.parseInt(formattedColorValue, 10);
  if (!Number.isNaN(parsedColor)) {
    return theme.avatarColors[parsedColor % theme.avatarColors.length];
  }

  return color;
};

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  width: ${(props) => (props.responsive ? '100%' : getAvatarSize(props.size, props.theme))};
  height: ${(props) => (props.responsive ? props.calcSize : getAvatarSize(props.size, props.theme))};
  font-size: calc(${(props) => (props.responsive ? props.calcSize : getAvatarSize(props.size, props.theme))} / 2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: calc(${(props) => (props.responsive ? props.calcSize : getAvatarSize(props.size, props.theme))} / 8);
  background: ${(props) => (!isNil(props.color) ? getRealColor(props.color, props.theme) : props.theme.defaultAvatarBackground)};
  color: ${(props) => (!isNil(props.color) ? '#ffffff' : props.theme.defaultAvatarColor)};

  position: relative;

  &:after {
    content: '';
    display: ${(props) => (props.faded ? 'inline-block' : 'none')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    margin: ${(props) => (props.bordered ? '-0.1em' : 'none')};
    opacity: 0.7;
  }

  border: ${(props) => (props.bordered ? '0.1em solid #ffffff' : 'none')};
`;

export const DescriptionWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  > span {
    margin-left: 0.5em;
    font-weight: 500;
  }
`;
