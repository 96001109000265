import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 0.3fr;
    padding: 1rem 24px;
    width: calc(100vw - 25em);
    border-right: 1px solid ${theme.borderGrey};

    @media ${theme.breakPoints.medium} {
      width: 100%;
      border-right: 0;
    } 
  `}

`;
