import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { Wrapper } from './styles';

function Banner() {
  const { isTrial } = useAuth();

  if (!isTrial) return <></>;

  return (
    <Wrapper>
      <p>
        Essa é uma avaliação gratuita da Contraktor. Para disparos ilimitados e mais vantagens, {' '}
        <Link to="/plans">Contratar Agora</Link>
      </p>
    </Wrapper>
  );
}

export default Banner;
