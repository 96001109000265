import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { checkUserIsSigner } from '../../../utils/helpers';
import MobileHeader from '../../MobileHeader';
import History from '../History';
import Persons from '../Persons';
import { Wrapper, Tabs } from './styles';

const SidebarDocument = ({ confirmSign, setMobileSideBar, mobileSideBar }) => {
  const [tab, setTab] = useState('persons');
  const { signers, signature, status } = useSelector((state) => state.document);
  const { user } = useAuth();

  const canSign = checkUserIsSigner(signers, user);
  const currentSigner = signers && signers
    .find((signer) => signer.email === user.email);
  const readOnly = status !== 'canceled' && status !== 'signed' && signature !== null;

  return (
    <>
      <MobileHeader
        handleMenuChange={setMobileSideBar}
        open={mobileSideBar}
        canSign={canSign}
        signer={currentSigner && { name: currentSigner.name, email: currentSigner.email }}
        readOnly={readOnly}
        confirmSign={confirmSign}
      />
      <Wrapper mobileSidebar={mobileSideBar}>
        <Tabs>
          <Label
            as="a"
            onClick={() => setTab('persons')}
            size="large"
            color={tab === 'persons' ? 'blue' : null}
          >
            Assinantes
          </Label>
          <Label
            as="a"
            onClick={() => setTab('history')}
            size="large"
            color={tab === 'history' ? 'blue' : null}
          >
            Histórico
          </Label>
        </Tabs>
        {tab === 'persons' && <Persons />}
        {tab === 'history' && <History />}
      </Wrapper>
    </>
  );
};

SidebarDocument.propTypes = {
  confirmSign: PropTypes.func.isRequired,
  setMobileSideBar: PropTypes.func.isRequired,
  mobileSideBar: PropTypes.bool.isRequired
};

export default SidebarDocument;
