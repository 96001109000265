import styled from 'styled-components';

import Button from '../../Button';

export const Wrapper = styled.div`
  padding: 1em;
  width: 35.875em;

  > button {
    height: 30px;
    margin-top: 0.5em;
  }

  .ui.dropdown {
    width: 100%;
  }

  .ui.search {
    min-width: 14em;
  }

  .customDropdownFilter {
    margin-top: 0.5em;
  }

  > div {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      margin-top: 1.5em;
      margin: 0;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.5em;
    }

    > div {
      > button {
        min-height: 30px !important;
      }
    }
  }
`;

export const FilterLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.mutedTextColor};
  font-size: 0.975em;
  font-weight: 600;
  margin-bottom: ${(props) => (props.dateRange ? '0.5em' : '0')}
`;

export const DayPickerTrigger = styled(Button)`
  height: 2.71428571em !important;
  text-align: left !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 100%;

  &:hover {
    border-color: rgba(34, 36, 38, 0.35) !important;
    background-color: transparent !important;
  }
`;
