/* eslint-disable react/prop-types */
import { Icon } from '@contraktor-tech/ui';
import React from 'react';

import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import UserAvatar from '../../../Avatar/UserAvatar';
import { Container } from './styles';

const UserInfo = ({ withArrow = true }) => {
  const { user } = useAuth();
  return (
    <Container>
      <UserAvatar user={user} />
      <span className="user-info">{user.firstName}</span>
      {withArrow && (
        <Icon>keyboard_arrow_down</Icon>
      )}
    </Container>
  );
};

export default UserInfo;
