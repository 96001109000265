import CompletedAt from './CompletedAt';
import DaysLeft from './DaysLeft';
import InsertedAt from './InsertedAt';
import Number from './Number';
import Shares from './Shares';
import Status from './Status';
import Title from './Title';

export default {
  CompletedAt,
  InsertedAt,
  Number,
  Shares,
  Status,
  Title,
  DaysLeft
};
