/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import {
  removeDocumentFromList,
  updateCounter
} from '../../../redux/ducks/documents';
import api from '../../../utils/backend';
import Button from '../../Button';
import FilePreview from '../../FilePreview';
import OptionsMenu from '../../OptionsMenu';
import {
  Wrapper,
  HeaderRow,
  Description,
  Column,
  PreviewContainer,
  ActionsWrapper
} from './styles';

const TableItem = ({ columns, document }) => {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { counters } = useSelector((state) => state.documents);
  const {
    trialIsOver, isFreemium
  } = useAuth();
  const blockedTrashAndPreview = trialIsOver || isFreemium;

  async function handleSendToTrashAndRestore(id, param) {
    try {
      const { data } = await api.put(`/documents/${id}`, {
        document: { isTrashed: param }
      });

      dispatch(removeDocumentFromList(data.id));

      if (param) {
        toast.success('Documento enviado para lixeira.');
        dispatch(
          updateCounter({
            ...counters,
            waitSign: (counters.waitSign -= 1),
            trash: (counters.trash += 1)
          })
        );
      } else {
        toast.success('Documento restaurado.');
        dispatch(
          updateCounter({
            ...counters,
            trash: (counters.trash -= 1),
            waitSign: (counters.waitSign += 1)
          })
        );
      }
    } catch (error) {
      toast.error('Ops...Algo errado aconteceu!');
      throw new Error(error);
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`/documents/${id}`);

      dispatch(removeDocumentFromList(id));
      dispatch(updateCounter({ ...counters, trash: (counters.trash -= 1) }));
      setOpen(false);
      toast.success('Documento excluído.');
    } catch (error) {
      toast.error('Ops...Algo errado aconteceu!');
      throw new Error(error);
    }
  }

  function RenderDocumentDropdownItems() {
    if (pathname === '/documents') {
      return (
        <OptionsMenu.Item
          danger
          onClick={() => handleSendToTrashAndRestore(document.id, true)}
        >
          Mover para Lixeira
        </OptionsMenu.Item>
      );
    }
    return null;
  }

  function RenderTrashDropdownItems() {
    if (pathname === '/trash') {
      return (
        <>
          <OptionsMenu.Item
            onClick={() => handleSendToTrashAndRestore(document.id, false)}
          >
            Restaurar
          </OptionsMenu.Item>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            trigger={(
              <OptionsMenu.Item danger onClick={() => setOpen(true)}>
                Excluir Definitivamente
              </OptionsMenu.Item>
            )}
            size="small"
          >
            <Modal.Content>
              Tem certeza que deseja excluir definitivamente este documento?
            </Modal.Content>
            <Modal.Actions>
              <Button
                type="button"
                content="Manter contato na lixeira"
                onClick={() => setOpen(false)}
              />
              <Button
                type="submit"
                primary
                content="Excluir definitivamente"
                onClick={() => handleDelete(document.id)}
              />
            </Modal.Actions>
          </Modal>
        </>
      );
    }
    return null;
  }

  const renderTableItem = columns.map((col) => (
    <Column key={col.key} width={col.width} center={col.center}>
      {col.component && <col.component document={document} />}
    </Column>
  ));

  return (
    <Wrapper>
      <HeaderRow>
        <Description>
          <Link to={`/document/${document.id}`}>{renderTableItem}</Link>
        </Description>
        <ActionsWrapper>
          <OptionsMenu>
            <PreviewContainer
              trigger={<OptionsMenu.Item>Ver Detalhes</OptionsMenu.Item>}
              centered={false}
              size="large"
            >
              <Modal.Content>
                <FilePreview previewUrl={document.preview} />
              </Modal.Content>
            </PreviewContainer>
            {document.status !== 'canceled' && (
              <OptionsMenu.Item onClick={() => window.open(document.download)}>
                Baixar PDF
              </OptionsMenu.Item>
            )}
            {!blockedTrashAndPreview && (
            <>
              <RenderTrashDropdownItems />
              <RenderDocumentDropdownItems />
            </>
            )}
          </OptionsMenu>
        </ActionsWrapper>
      </HeaderRow>
    </Wrapper>
  );
};

TableItem.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.objectOf),
  document: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    title: PropTypes.string,
    download: PropTypes.string.isRequired,
    signature: PropTypes.shape({
      events: PropTypes.arrayOf(PropTypes.object),
      id: PropTypes.string,
      status: PropTypes.string
    }),
    preview: PropTypes.string.isRequired,
    status: PropTypes.string,
    inserted_at: PropTypes.string
  }).isRequired
};

TableItem.defaultProps = {
  columns: []
};

export default TableItem;
