import styled from 'styled-components';

export const PlansGrid = styled.div`
  width: fit-content;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  .grid-container {
    display: inline-flex;
    width: fit-content;
    height: 100%;
    box-sizing: content-box;
    gap: 32px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .stepper-mobile {
    display: none;
  }

  @media ${({ theme }) => theme.breakPoints.small} {
    .grid-container {
      display: none;
    }

    .stepper-mobile {
      display: block;

      > div .react-swipeable-view-container {
        width: 310px;
        gap: 20px;
        padding: 20px;

        div {
          width: fit-content!important;
        }
      }

      > .MuiMobileStepper-root {
        padding: 24px;
        justify-content: center;
        background: #ffffff;

        > .MuiMobileStepper-dots .MuiMobileStepper-dotActive {
          background-color: #0251DE!important;
        }

        > .MuiMobileStepper-dots .MuiMobileStepper-dot {
          background-color: #C7C6CA;
        }
      }
    }
  }  
`;
