import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  height: 80px;
  background-color: ${(props) => props.theme.backgroundColor};

  .cancel {
    margin-left: 1em;
    color: ${(props) => props.theme.fontColor};
  }
`;

const ButtonGroup = styled.div`
  width: 12em;
  display: flex;
  justify-content: space-between;

  > button {
    margin: 0 !important;
  }
`;

export { Wrapper, ButtonGroup };
