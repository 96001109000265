import React from 'react';
import { useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import emptyHistoryImage from '../../_new/assets/no_history_image.svg';
import { formatDateTime } from '../../../utils/localeUtils';
import { documentEventParser } from '../../../utils/parsers';
import * as S from './styles';

const History = () => {
  const { signature } = useSelector((state) => state.document);
  const { events } = signature;

  return events?.length > 0 ? (
    events.map((h, k) => (
      <S.Card key={Math.random()}>
        <S.Left>
          <S.Line />
          <Label size="mini" circular color="blue" />
          {k !== events.length - 1 && (<S.Line />)}
        </S.Left>
        <S.Right>
          <b>{h.signer && h.signer.name}</b> {documentEventParser(h.event)}
          <p>
            <small>{formatDateTime(h.insertedAt, true)}</small>
          </p>
        </S.Right>
      </S.Card>
    ))
  ) : (
    <S.HistoryContainer>
      <div style={{ display: 'table' }}>
        <S.HistoryImage>
          <img src={emptyHistoryImage} alt="Imagem de duas pessoas analisando gráficos" />
        </S.HistoryImage>
        <S.HistoryInfo>
          <S.HistoryTitle>Seu documento ainda não possuí histórico</S.HistoryTitle>
          <S.HistorySubTitle>
            Envie seu documento para assinatura para receber as informações
          </S.HistorySubTitle>
        </S.HistoryInfo>
      </div>
    </S.HistoryContainer>
  );
};

export default History;
