/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

function ProgressInfoItem({
  label, value, error, warning, disabled
}) {
  return (
    <S.Container disabled={disabled} warning={warning.toString()} error={error.toString()}>
      <>
        {label}
        <S.BorderLinearProgress variant="determinate" value={parseInt(value, 10)} disabled={disabled} />
      </>
    </S.Container>
  );
}

ProgressInfoItem.propTypes = {
  label: PropTypes.any.isRequired,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool
};

ProgressInfoItem.defaultProps = {
  error: false,
  warning: false,
  disabled: false
};

export default ProgressInfoItem;
