import moment from 'moment';

function formatDate(date, formatMethod) {
  return moment(date).format(formatMethod);
}

function convertToDefaultFormatAndMerge(data, formattedDate) {
  try {
    if (formattedDate && formattedDate.length > 0) {
      const momentDate = moment(formattedDate, 'DD-MM-YYYY');
      const values = { ...data, birthDate: formatDate(momentDate, 'YYYY-MM-DD') };

      return values;
    }
    return data;
  } catch (err) {
    throw new Error('Convert birthDate error');
  }
}

function formatDateTime(date, withTimezone) {
  if (date) {
    return withTimezone
      ? moment(date).utcOffset(-6).format('DD/MM/YYYY [às] HH:mm')
      : moment(date).format('DD/MM/YYYY [às] HH:mm');
  }
  return null;
}

function isBefore(currentDate, date) {
  if (currentDate && date) {
    const convertCurrentDate = moment(currentDate).toISOString();
    const before = moment(convertCurrentDate).isBefore(date);

    return before;
  }
  return null;
}

function getDaysBetweenDates(firstDate, secondDate) {
  if (firstDate && secondDate) {
    try {
      const firstDateConverted = moment(firstDate).toISOString();
      const secondDateConverted = moment(secondDate).toISOString();

      const diff = moment(secondDateConverted).diff(firstDateConverted, 'days');

      return diff < 0 ? 0 : diff;
    } catch (err) {
      throw new Error('Error on get days between dates');
    }
  }
  return null;
}

export {
  formatDate,
  formatDateTime,
  isBefore,
  convertToDefaultFormatAndMerge,
  getDaysBetweenDates
};
