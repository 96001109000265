import React from 'react';
import { useLocation } from 'react-router-dom';

import useQueryString from '../../../hooks/useQueryString';
import SignersListHeader from '../Header';

const only = ['page', 'search'];

const HeaderWrapper = () => {
  const location = useLocation();

  const tabs = [
    {
      name: 'Assinantes',
      href: '/signers',
      active: location.pathname === '/signers'
    }
  ];

  const { setQuery } = useQueryString({ only, clearFalsyValues: true });
  return (
    <SignersListHeader
      tabs={tabs}
      setQuery={setQuery}
    />
  );
};

export default HeaderWrapper;
