import styled from 'styled-components';

import Button from '../../Button';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardsList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem 1rem 0 1rem;
`;

export const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  opacity: 1;

  > span {
    color: ${({ theme }) => theme.fontColor};
    font-size: 14px;
    margin: 0.2rem 0 0.2rem 0;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > svg {
    opacity: 0.2;
  }

  > h4 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-left: 0.5em;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  > .ui.label {
    align-self: flex-end;
    height: 20px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primaryColor};
    border: 0;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: rgba(60, 60, 60, 0.5) 0px -11px 16px -20px;
  padding-top: 1rem;
  z-index: 1;
  background: rgb(250, 250, 251);
`;

export const CancelButton = styled(Button)`
  color: grey;
  padding: 1em 0 1rem 0;
`;

export const SignButton = styled(Button)`
  position: absolute;
  width: 300px;
  left: 33%;
  bottom: 1rem;
`;
