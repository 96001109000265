import { Menu, Button, Icon } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import requestSignaturesImg from '../../../../components/_new/assets/rafiki.svg';
import AddSignerModal from '../../../../components/_new/components/Signer/AddSignerModal/AddSignerModal';
import { SidebarContent, SidebarFooter } from '../../../../components/_new/Sidebar';
import SignerCard from '../../../../components/_new/SigneeCard';
import { useLocalStorage } from '../../../../components/utils/useLocalStorage';
import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import { useDocument } from '../../../../hooks/_new/useDocument';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import { toggleLoading } from '../../../../redux/ducks/global';
import { DocumentHTTP } from '../../../../services/api/adapters/documents';
import AddSignerButton from './components/AddSignerButton';
import * as S from './styles';

const SignersList = ({ onChangeSigners }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { Mixpanel } = useMixpanel();
  const { isFreemium, subscription: { features } } = useAuth();
  const { documentData } = useDocument();

  const [addSignerModalOpen, setAddSignerModalOpen] = useState(false);
  const [draftSigners, updateDraftSigners] = useLocalStorage('draftSigners', []);
  const [menu, setMenu] = useState({
    open: false,
    signee: null,
    index: null,
    anchorEl: null
  });

  const {
    signers, signature, download, status, id
  } = documentData.data;

  const hasWhatsapAccess = features && features.length && features.find((f) => f.name === 'whatsapp')?.enabled;
  const isCanceled = status === 'canceled';
  const isDraft = status === 'draft';
  const isSigned = status === 'signed';
  const signersList = isDraft ? draftSigners : signers;
  const readOnly = !['canceled', 'signed', 'draft'].includes(status) && signature !== null;
  const signeerCanResend = (menu.signee && !menu.signee.confirmedAt && !isCanceled) && !isDraft;
  const hasFooter = !isCanceled && !isDraft && signersList?.length > 0;

  useEffect(() => {
    onChangeSigners(draftSigners);
  }, [draftSigners, onChangeSigners]);

  const cancelSign = async () => {
    dispatch(toggleLoading(true));
    try {
      await DocumentHTTP.cancelSignatures(id);
      toast.success('Documento cancelado com sucesso.');
      history.push('/documents');
    } catch (error) {
      toast.error('Erro ao cancelar documento.');
      throw new Error(error);
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const resendDocumentNotification = async (whatsapp) => {
    dispatch(toggleLoading(true));
    try {
      const data = whatsapp ? { send_via_whatsapp: true } : null;
      await DocumentHTTP.resendDocument(id, menu.signee.id, data);
      toast.success('Solicitação de assinatura reenviada com sucesso');
    } catch (err) {
      toast.error('Erro ao reenviar solicitação de assinatura');
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const downloadFile = () => {
    window.open(download);
  };

  const handleOpenMenu = (event, signee, index) => {
    setMenu({
      open: true,
      signee,
      index,
      anchorEl: event.currentTarget
    });
  };

  const handleClose = () => {
    setMenu({
      open: false,
      signee: null,
      anchorEl: null
    });
  };

  const handleNewSignerClick = () => {
    Mixpanel.track('document_signer_button_cadastrar-assinante');

    setAddSignerModalOpen(true);
  };

  return (
    <>
      <SidebarContent hasFooter={hasFooter}>
        <S.Container fullHeight={!hasFooter}>
          {
            signersList?.length > 0
              ? (
                <>
                  {
                    signersList.map((s, index) => (
                      <SignerCard
                        documentCanceled={isCanceled}
                        documentStatus={status}
                        key={s.id}
                        signee={s}
                        isFreemium={isFreemium}
                        onClick={
                          (event, signee) => handleOpenMenu(
                            event,
                            signee,
                            index
                          )
                        }
                      />
                    ))
                  }
                  {
                    isDraft && (
                      <AddSignerButton
                        editMode
                        onClick={() => {
                          setAddSignerModalOpen(true);
                        }}
                      />
                    )
                  }
                </>
              )
              : (
                <S.RequestSignaturesContainer>
                  <div style={{ display: 'table' }}>
                    <S.RequestSignatureImage>
                      <img src={requestSignaturesImg} alt="Imagem de um papel sendo assinado por um lápis" />
                    </S.RequestSignatureImage>
                    <S.RequestSignatureInfo>
                      <S.RequestSignatureTitle>Solicitar assinatura</S.RequestSignatureTitle>
                      <S.RequestSignatureSubTitle>
                        Adicione assinantes no seu documento apenas com nome e email
                      </S.RequestSignatureSubTitle>
                    </S.RequestSignatureInfo>
                    <S.RequestSignatureButtonDiv>
                      <AddSignerButton
                        editMode={false}
                        onClick={handleNewSignerClick}
                      />
                    </S.RequestSignatureButtonDiv>
                  </div>
                </S.RequestSignaturesContainer>
              )
          }
        </S.Container>

        <Menu
          open={menu.open}
          anchorEl={menu.anchorEl}
          onClose={handleClose}
          options={[
            {
              title: 'Reenviar whatsapp',
              icon: 'whatsapp',
              alignIcon: 'left',
              onClick: () => resendDocumentNotification(true),
              disabled: !hasWhatsapAccess
            },
            {
              title: 'Reenviar assinatura',
              icon: 'send',
              alignIcon: 'left',
              onClick: () => resendDocumentNotification(),
              disabled: !signeerCanResend
            }
          ]}
        />
      </SidebarContent>
      {hasFooter && (
        <SidebarFooter>
          <Button
            disabled={isSigned ? false : !readOnly}
            onClick={isSigned ? downloadFile : cancelSign}
            label={(
              <>
                <Icon style={{ marginRight: 8 }}>close</Icon>
                {isSigned ? 'Baixar documento' : 'Cancelar assinatura'}
              </>
            )}
            disableElevation
          />
        </SidebarFooter>
      )}

      <AddSignerModal
        open={addSignerModalOpen}
        addedSigners={draftSigners}
        setSigners={updateDraftSigners}
        closeModal={() => {
          setAddSignerModalOpen(false);
        }}
      />
    </>
  );
};

SignersList.propTypes = {
  onChangeSigners: PropTypes.func.isRequired
};

export default SignersList;
