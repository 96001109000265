import last from 'lodash/last';

export function personTypeParser(personType) {
  switch (personType) {
    case 'pf':
      return 'Pessoa Física';
    case 'pj':
      return 'Pessoa Jurídica';
    default:
      return personType;
  }
}

export function documentColorByStatus(status) {
  switch (status) {
    case 'wait_sign':
      return {
        title: 'Ag. Assinatura',
        color: '#FD9827'
      };
    case 'signed':
      return {
        title: 'Vigente',
        color: '#21ba45'
      };
    case 'canceled':
      return {
        title: 'Cancelado',
        color: '#FF3509'
      };
    default:
      return {
        title: '',
        color: ''
      };
  }
}

export function getInitialsFromName(fullName) {
  const fragmentsOfName = fullName.trim().split(' ');
  const firstName = fragmentsOfName[0].trim();
  const lastName = last(fragmentsOfName).trim();

  if (!lastName) {
    return `${firstName[0].toUpperCase()}`;
  }

  if (!firstName) {
    return `${lastName[0].toUpperCase()}`;
  }

  if (!firstName && !lastName) {
    throw new Error('FullName Error');
  }

  return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
}

export function getFullNameFromUser(user) {
  const { firstName, lastName } = user;

  return `${firstName} ${lastName}`;
}

export function cpfCnpjParser(value) {
  return value && value.replace(/[^\d]+/g, '');
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

export function getPercentage(currentValue = 0, totalValue = 0, decimalPlaces = 2) {
  if (currentValue === 0 || currentValue === null) return '0';

  if (totalValue === 0 || !totalValue) throw Error('Total value is required');

  const result = ((100 * currentValue) / totalValue).toFixed(decimalPlaces);

  return result;
}

export function organizationPlanParser(statusPlan) {
  switch (statusPlan) {
    case 'canceled':
      return { title: 'Cancelado', color: 'red' };
    case 'active':
      return { title: 'Ativo', color: 'green' };
    case 'blocked':
      return { title: 'Bloqueado', color: 'yellow' };
    case 'inactive':
      return { title: 'Inativo', color: 'grey' };
    default:
      return statusPlan;
  }
}

export function documentEventParser(event) {
  switch (event) {
    case 'created':
      return 'criou o documento';
    case 'canceled':
      return 'Documento Cancelado';
    case 'updated':
      return 'atualizou o documento';
    case 'trashed':
      return 'enviou o documento para lixeira';
    case 'deleted':
      return 'deletou o documento';
    case 'signer_added':
      return 'adicionou um assinante ao documento';
    case 'signature_started':
      return 'Documento enviado para assinatura';
    case 'signature_canceled':
      return 'cancelou o documento';
    case 'signed':
      return 'assinou o documento';
    case 'completed':
      return 'Documento assinado por todos os assinantes.';
    default:
      return '';
  }
}

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const decript64 = (value) => decodeURIComponent(escape(window.atob(value)));
