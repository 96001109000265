/* eslint-disable object-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import moment from 'moment';
import React, { useEffect } from 'react';
import { HiUsers } from 'react-icons/hi2';
import { IoCloudOutline, IoLogoWhatsapp } from 'react-icons/io5';
import { MdUploadFile } from 'react-icons/md';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { useStorageDocument } from '../../../containers/StorageDocumentContext';
import { formatBytes, getPercentage } from '../../../utils/parsers';
import DispatchLimitRenewalModal from '../../DispatchLimitRenewalModal';
import ProgressInfoItem from './shared/ProgressInfoItem';
import {
  Container,
  ProgressInfo,
  ProgressInfoLabel,
  ProgressInfoSubtitle
} from './styles';

const StorageInfo = () => {
  const maxUsers = {
    Freemium: 1,
    Light: 6,
    Essential: 10
  };

  const maxDocuments = {
    Freemium: 5,
    Light: 15,
    Essential: 50
  };

  const maxStorage = {
    Light: 524288000,
    Essential: 1073741824,
    Trial: 2147483648,
    Freemium: 2147483648,
    Unlimited: 2147483648
  };

  const freePlans = ['Trial', 'Freemium'];
  const unlimitedDocumentPlans = ['Unlimited', 'Trial'];
  const unlimitedUserPlans = ['Unlimited'];

  const { subscription } = useAuth();
  const { dispatchesCountData, storageData } = useStorageDocument();
  const { dispatches } = dispatchesCountData;
  const { storage } = storageData;
  const warningDispatchesWarning = [maxDocuments[subscription.planName] - 2, maxDocuments[subscription.planName] - 1].includes(dispatches);
  const errorDispatchesWarning = dispatches === maxDocuments[subscription.planName];

  const [loading, setLoading] = React.useState(true);
  const [limitRenewModalOpen, setLimitRenewModalOpen] = React.useState(false);
  const [organizationLimits, setOrganizationLimits] = React.useState(null);
  const [disabledFields, setDisabledFields] = React.useState(null);
  const [usedPercentages, setUsedPercentages] = React.useState(null);
  const [storageLabelFormatted, setStorageLabelFormatted] = React.useState(null);

  const getUsedPercentages = (sub, orgLimits) => ({
    whatsapp: getPercentage(parseInt(sub.whatsappCount, 10), orgLimits.whatsapp),
    users: [...freePlans, ...unlimitedUserPlans].includes(sub.planName) ? 0 : getPercentage(parseInt(sub.usersCount, 10), orgLimits.users),
    storage: getPercentage(storage, orgLimits.storage),
    documents: unlimitedDocumentPlans.includes(sub.planName) ? 0 : getPercentage(dispatches, orgLimits.documents)
  });

  const getOrganizationLimits = (sub) => ({
    whatsapp: (sub.features && sub.features.find((f) => f.name === 'whatsapp')?.quota) || 0,
    users: maxUsers[sub.planName],
    storage: maxStorage[sub.planName],
    documents: maxDocuments[sub.planName]
  });

  const getDisabledFields = (sub) => ({
    whatsapp: sub.features && !sub.features.find((f) => f.name === 'whatsapp')?.enabled,
    users: [...freePlans, ...unlimitedUserPlans].includes(sub.planName),
    storage: false,
    documents: unlimitedDocumentPlans.includes(sub.planName)
  });

  useEffect(() => {
    setOrganizationLimits(getOrganizationLimits(subscription));
    setDisabledFields(getDisabledFields(subscription));
    // eslint-disable-next-line
  }, [subscription]);

  useEffect(() => {
    if (organizationLimits) {
      setStorageLabelFormatted(
        `${formatBytes(storage)} de ${formatBytes(maxStorage[subscription.planName])} (${getPercentage(storage, organizationLimits.storage, 0)}%)`
      );
      setUsedPercentages(getUsedPercentages(subscription, organizationLimits));
    }
    // eslint-disable-next-line
  }, [organizationLimits, dispatches, storage]);

  useEffect(() => {
    if (disabledFields && usedPercentages) setLoading(false);
    // eslint-disable-next-line
  }, [disabledFields, usedPercentages]);

  const storageInfoLabel = () => (
    <>
      <ProgressInfo className="progress-info-container">
        <IoCloudOutline />
        <ProgressInfoLabel>Armazenamento</ProgressInfoLabel>
      </ProgressInfo>
      <ProgressInfoSubtitle className="progress-info-subtitle">{storageLabelFormatted}</ProgressInfoSubtitle>
    </>
  );

  const documentsInfoLabel = () => (
    <>
      <ProgressInfo className="progress-info-container">
        <MdUploadFile />
        <ProgressInfoLabel>Documentos</ProgressInfoLabel>
      </ProgressInfo>
      <ProgressInfoSubtitle style={{ marginBottom: disabledFields.documents ? '16px' : '2.5px' }} className="progress-info-subtitle">
        {
          disabledFields.documents
            ? 'Envio ilimitado'
            : (
              <>
                <>{dispatches} de {maxDocuments[subscription.planName]} disparos/mês</>
                <br />
                <>Data renovação: {moment(subscription.nextPaymentDueDate).format('DD/MM/YYYY')}</>
              </>
            )
        }
      </ProgressInfoSubtitle>
    </>
  );

  const whatsappInfoLabel = () => (
    <>
      <ProgressInfo className="progress-info-container">
        <IoLogoWhatsapp />
        <ProgressInfoLabel>Envios Via Whatsapp</ProgressInfoLabel>
      </ProgressInfo>
      <ProgressInfoSubtitle className="progress-info-subtitle">{subscription.whatsappCount} de {organizationLimits.whatsapp} envios/mês</ProgressInfoSubtitle>
    </>
  );

  const usersInfoLabel = () => (
    <>
      <ProgressInfo className="progress-info-container">
        <HiUsers />
        <ProgressInfoLabel> Usuários</ProgressInfoLabel>
      </ProgressInfo>
      <ProgressInfoSubtitle className="progress-info-subtitle">
        {
            unlimitedUserPlans.includes(subscription.planName)
              ? 'Usuários ilimitados'
              : (
                <>
                  {subscription.usersCount} de {organizationLimits.users} usuários
                </>
              )
          }
      </ProgressInfoSubtitle>
    </>
  );

  useEffect(() => {
    const docLimitModalViewed = localStorage.getItem('docLimitModalViewed');
    const modalCondition = !errorDispatchesWarning
    && storage > 0
    && dispatches === 0
    && docLimitModalViewed !== 'true';

    setLimitRenewModalOpen(modalCondition);
  }, [dispatches, errorDispatchesWarning, storage]);

  return !loading && (
    <>
      <DispatchLimitRenewalModal
        open={limitRenewModalOpen}
        onClose={() => {
          localStorage.setItem('docLimitModalViewed', true);
          setLimitRenewModalOpen(false);
        }}
      />
      <Container>
        <ProgressInfoItem
          label={whatsappInfoLabel()}
          value={usedPercentages.whatsapp}
          disabled={disabledFields.whatsapp}
        />
        <ProgressInfoItem
          error={errorDispatchesWarning}
          warning={warningDispatchesWarning}
          label={documentsInfoLabel()}
          value={usedPercentages.documents}
          disabled={disabledFields.documents}
        />
        <ProgressInfoItem
          label={storageInfoLabel()}
          value={usedPercentages.storage}
          disabled={disabledFields.storage}
        />
        <ProgressInfoItem
          label={usersInfoLabel()}
          value={usedPercentages.users}
          disabled={disabledFields.users}
        />
      </Container>
    </>
  );
};

export default StorageInfo;
