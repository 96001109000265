export const whatsappQuotaPrices = {
  15: 7.50,
  30: 13.50,
  50: 20.00,
  80: 28.00,
  100: 35.00,
  150: 52.50,
  200: 70.00,
  500: 175.00,
  750: 262.50,
  1000: 350.00
};
