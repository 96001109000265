import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 4px 4px 8px 4px;
`;

export const InputLabel = styled.div`
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 400;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 1.8125rem 0 1rem 0;
`;

export const PaymentDetailsContainer = styled.div`
  width: 100%;
  display: flow-root;
`;

export const PaymentDetails = styled.div`
  float: right;
  display: grid;
  font-family: Poppins;
 
  .payment-details-title {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: right;
    color: rgba(0, 0, 0, 0.6);
  }

  .payment-details-value {
    width: 100%;
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0.25px;
    text-align: right;
    color: #297F2D;
  }
`;
