import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 7.5px 3px 20px 3px;

  .search-input {
    width: 100%;
    border: 1px solid #E5E5E5;

    > input {
      padding: 17px 13.51px 17px 13.51px;
    } 
  }
`;
