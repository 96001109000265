import Actions from './Actions';
import Document from './Document';
import Email from './Email';
import Name from './Name';
import Type from './Type';
import Whatsapp from './Whatsapp';

export default {
  Actions,
  Document,
  Email,
  Name,
  Type,
  Whatsapp
};
