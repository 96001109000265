import styled from 'styled-components';

export const Vector = styled.span`
  display: block;
  
  max-width: 190px;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  width: 100%;
  min-width: 250px;

  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export const Text = styled.p`
  width: 100%;
  margin: 8px 0 16px 0;

  font-size: 14px;
  text-align: center;
`;
