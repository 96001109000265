import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  justify-content: center;

  @media ${(props) => props.theme.breakPoints.medium} {
    flex-direction: column;
  }
`;

export const Header = styled.header`
  height: 64px;
  padding: 1rem;
  display: flex;
  align-items: center;

  > img {
    max-width: 85%;
    max-width: 14rem;
    margin-left: 0px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 5px;
  }
  
  .back-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;

  .info-title {
    margin-top: 25px;
    .page-title {
      font-size: 32px;
      line-height: 40px;
      font-family: 'Nunito', sans-serif !important;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }
  }

  .info-card {
    margin-top: 20px;
    width: 271px;
    height: 32px;
    border-radius: 16px;
    background: #e5f9e5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .secure-text {
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p,
    svg {
      color: #4caf50;
    }
    p {
      font-size: 1.15rem;
      font-weight: 600;
      font-family: 'Nunito', sans-serif;
      margin-left: 0.5rem;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      margin-top: 8px;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .info-title {
      margin-top: 0;
      .page-title {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }

  @media ${(props) => props.theme.breakPoints.medium} {
    padding: 2rem 1rem;
    .info-title {
      margin-top: 0;
    }
  }
`;
