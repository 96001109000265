import { Modal } from '@contraktor-tech/ui';
import styled from 'styled-components';

export const Container = styled(Modal)`
  & .MuiDialog-paper {
    max-width: 48rem;

    .MuiDialogContent-root {
      padding: 1.5rem;
    }
  }

  .modal-header {
    display: flex;
    flex-direction: column;
  }

  .modal-subtitle {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.surfaceMediumEmphasis};
  }

  .modal-title {
    font-family: 'Poppins';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0em;
    text-align: center;
  }

  .modal-content {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .content-text {
    font-family: 'Poppins';
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.0015em;
    text-align: center;

    p {
      margin: 1rem 0 1rem 0!important;
      font-weight: 400;

      bold {
        font-weight: 700;
      }
    }
  }

  .modal-footer {
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .left {
      box-shadow: none;
      background: #CAE6FF;
      color: #001E2F;
    }

    .left:hover {
      box-shadow: none;
      background: #AAD7FF;
      color: #001E2F;
    }
    
    .right {
      box-shadow: none;
      background: #0251DE;
      color: #ffffff;
    }
   
   > .MuiButton-root {
     height: 40px;
     width: 48%;

     > .MuiButton-label {
      font-family: 'Poppins';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      letter-spacing: 0rem;
      text-align: center;
     }
   }
  }


`;
