import styled, { css } from 'styled-components';

const Container = styled.div`
  #logo {
    height: 2rem;
  }

  ${({ theme }) => css`
    .MuiToolbar-root {
      justify-content: space-between;
      height: 68px;
      /* Must change when design system is updated */
      background-color: #0D0440;
    }

    .icon-button--help-icon, .menu-icon {
      font-size: 24px;
      color: ${theme.whiteColor};
    }

    .buy-button {
      height: 42px;
      background: ${theme.whiteColor};
      color: ${theme.primaryColor};

      padding: 16px !important;

      &:hover {
        background: ${theme.defaultLabelColor};
        color: ${theme.primaryColor};
      }      
    }
  `}
`;

const Menu = styled.ul`
  ${({ theme }) => css`

    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;


    > li {
      margin: 0 1.5em;

      > a {
        font-size: 16px;
        color: ${theme.defaultLabelColor};
        font-weight: 700;
        font-family: 'Nunito', sans-serif;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &.active {
          color: ${theme.whiteColor};
        }

      }
    }

    > li.help-dropdown {
      margin-right: 0;
    }

    > li.daysRemaining-wrapper {
      display: flex;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 14px;
        height: 42px;

        background: #48426a;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    @media ${theme.breakPoints.small} {
      display: none;
    }

  `}
`;

export { Container, Menu };
