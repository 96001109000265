import {
  Card, CardContent, styled as muiStyled, Typography
} from '@mui/material';
import styled from 'styled-components';

import themes from '../../../../_utils/themes';

export const StyledCard = muiStyled(Card)(({ planStyles }) => ({
  backgroundColor: planStyles.backgroundColor,
  width: '232px',
  border: planStyles.border,
  boxShadow: planStyles.boxShadow,
  borderRadius: '8px'
}));

export const StyledCardContent = muiStyled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  padding: '5px 16px'
});

export const PlanTag = styled.div`
  width: 232px;
  text-align: center;
  border-radius: 8px 8px 0px 0px;
  padding: 4px 0px;
  color: ${({ planStyles }) => (planStyles?.fontColorTag || themes.fonts.color.brown)};
  background-color: ${({ planStyles }) => (planStyles?.backgroundColorTag || themes.background.primaryOrange)};
  font-weight: ${(themes.fonts.weight.bold)};
  font-size: ${(themes.fonts.size.small)};
`;

export const CardTitle = muiStyled(Typography)(({ planStyles }) => ({
  color: planStyles.fontColor,
  fontFamily: planStyles.fontFamily,
  fontWeight: themes.fonts.weight.normal,
  fontSize: themes.fonts.size.large,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 230px;
  justify-content: space-around;
`;

export const DiscountInfo = muiStyled(Typography)(({ planStyles }) => ({
  color: planStyles.fontColor,
  fontFamily: planStyles.fontFamily,
  fontWeight: themes.fonts.weight.bolder,
  fontSize: themes.fonts.size.smaller,
  textTransform: 'uppercase'
}));

export const CardPrice = muiStyled(Typography)(({ planStyles }) => ({
  width: '100%',
  color: planStyles.fontColor,
  fontFamily: planStyles.fontFamily,
  fontWeight: themes.fonts.weight.bold,
  fontSize: themes.fonts.size.big,

  '.plan-frequency': {
    alignSelf: 'end',
    paddingBottom: '10px',
    fontFamily: themes.fonts.family.Poppins,
    fontSize: themes.fonts.size.large,
    color: planStyles.fontColor,
    fontWeight: themes.fonts.weight.thin
  }
}));

export const CashPayment = muiStyled(Typography)(({ planStyles }) => ({
  fontFamily: themes.fonts.family.Poppins,
  fontSize: themes.fonts.size.small,
  color: planStyles.fontColor,
  fontWeight: themes.fonts.weight.thin,
  letterSpacing: '0.25px'
}));

export const PlanDescription = muiStyled(Typography)(({ planStyles }) => ({
  fontFamily: themes.fonts.family.Poppins,
  fontSize: themes.fonts.size.small,
  color: planStyles.fontColor,
  fontWeight: themes.fonts.weight.normal
}));

export const BottomContent = styled.div`
  padding-top: 32px;
`;

export const Benefits = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: flex-start;

    margin: 8px 0;

    span {
      margin-top: 3px;
      display: flex;
      flex-shrink: 0;
      
      svg {
        width: 14px;
        height: 14px;

        color: ${({ planStyles }) => (planStyles.fontColor)};
        opacity: ${({ isFeature }) => (isFeature ? 1 : 0.3)};
      }
    }

    p {
      padding-left: 13px;

      color: ${({ planStyles }) => (planStyles.fontColor)};
      opacity: ${({ isFeature }) => (isFeature ? 1 : 0.3)};
      font-size: 14px;
      line-height: 16px;
      
      b {
        font-size: 14px;
      }
    }
  }
`;
