import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 60px);
  max-width: 480px;
  background: #fff;
  padding: 5rem 2.5rem;
  border-radius: 8px;

  .overlay {
    mix-blend-mode: multiply;
  }
  p.subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000;
  }

  p.caption,
  p.caption * {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  p.caption {
    color: #000;
    span.error {
      color: #e53935;
    }
  }

  button.ui.button {
    margin-top: 1rem;
  }
`;

export default Wrapper;
