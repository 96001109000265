import { IconButton, Icon } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import logoHorizontalWhite from '../../../../assets/img/logo_horiz_colorfull.svg';
import * as S from './styles';

const WhiteHeader = ({ backButtonText, backButtonOnClick }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (backButtonOnClick) {
      backButtonOnClick();
    } else {
      history.goBack();
    }
  };

  return (
    <S.HeaderWrapper
      appBarPosition="relative"
      appBarColorType="default"
      toolbarType="regular"
      disableGutters={false}
      elevation={0}
    >
      <S.BackButtonWrapper>
        <IconButton onClick={() => handleGoBack()}>
          <Icon>arrow_back_ios</Icon>
        </IconButton>

        {backButtonText && (
        <span className="back-button-text">
          {backButtonText}
        </span>
        )}
      </S.BackButtonWrapper>

      <NavLink to="/" className="white-header__logo-image">
        <img
          src={logoHorizontalWhite}
          alt="Imagem com as letras CK e o nome da plataforma Contraktor"
        />
      </NavLink>
    </S.HeaderWrapper>
  );
};

WhiteHeader.propTypes = {
  backButtonText: PropTypes.string,
  backButtonOnClick: PropTypes.func
};

WhiteHeader.defaultProps = {
  backButtonText: '',
  backButtonOnClick: null
};

export default WhiteHeader;
