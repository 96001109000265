import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime } from '../../../utils/localeUtils';

const InsertedAt = ({ document }) => (
  <>{formatDateTime(document.insertedAt, true)}</>
);

InsertedAt.propTypes = {
  document: PropTypes.shape({
    insertedAt: PropTypes.string
  }).isRequired
};

export default InsertedAt;
