import PropTypes from 'prop-types';
import React from 'react';

import { documentColorByStatus } from '../../utils/parsers';
import TruncateField from '../TruncateField';
import { Container } from './styles';

const DocumentStatusLabel = ({ status }) => {
  const statusTitle = documentColorByStatus(status).title;
  const statusColor = documentColorByStatus(status).color;

  return (
    <Container background={statusColor}>
      <TruncateField>
        <span className="status-circle" />
        <span className="status-title">{statusTitle}</span>
      </TruncateField>
    </Container>
  );
};

DocumentStatusLabel.propTypes = {
  status: PropTypes.string
};

DocumentStatusLabel.defaultProps = {
  status: ''
};

export default DocumentStatusLabel;
