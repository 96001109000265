import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useModal } from '../../components/_new/Modal';
import MainLayout from '../../components/MainLayout';
import {
  Wizard, SidebarHome
} from '../../components/Starter';
import { useAuth } from '../../containers/AuthContext/AuthContext';
import { useAmplitude } from '../../hooks/AmplitudeProvider';
import { Container } from './styles';

const StarterHomePage = () => {
  const { openModal } = useModal();
  const {
    hasQueryCKToken, subscription
  } = useAuth();
  const isTrial = subscription?.planName === 'Trial';
  const { Amplitude } = useAmplitude();

  useEffect(() => {
    Amplitude.logEvent('new_doc_screen');
    // eslint-disable-next-line
  }, []);

  const openWelcomeTrialModal = isTrial && hasQueryCKToken;

  useEffect(() => {
    if (openWelcomeTrialModal) openModal('welcomeTrial');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hasQueryCKToken) toast.success('Documento enviado com sucesso! 😉');
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout header options hideBanner>
      <Container>
        <Wizard />
      </Container>
      <SidebarHome />
    </MainLayout>
  );
};

export default StarterHomePage;
