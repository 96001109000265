import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { useChat } from '../../../hooks/ChatProvider';

// Criação do contexto
const ModalContext = createContext();

// Provedor do contexto
export const ModalProvider = ({ children }) => {
  const history = useHistory();
  const { openChat } = useChat();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [templateId, setTemplateId] = useState(null);

  const defaultModalProps = {
    open,
    navigate: (path) => history.push(path),
    closeModal: () => setOpen(false),
    openChat
  };

  const openModal = (tempId) => {
    setTemplateId(tempId);
    setOpen(true);
  };

  const closeModal = () => {
    setTemplateId(null);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      import(`${__dirname}/templates/${templateId}`).then((module) => setContent(module.default({ ...defaultModalProps })));
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <ModalContext.Provider value={{ open, openModal, closeModal }}>
      {open ? content : null}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.isRequired
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal deve ser usado dentro de um ModalProvider');
  }
  return context;
};
