/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import ModalPaymentHeader from '../../shared/components/ModalPaymentHeader/ModalPaymentHeader';
import * as S from './styles';

const ContactOrganizationOwnerModal = ({
  open,
  closeModal,
  handleOKClick,
  ownerInfo
}) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        size="small"
        style={{ maxWidth: '670px', borderRadius: '8px' }}
      >
        <ModalPaymentHeader mode="payment-limit-info" closeModal={closeModal} />
        <Modal.Content scrolling>
          <S.Text>
            <S.TextTitle>autenticação da conta</S.TextTitle>
            <S.TextBody>
              <p>
                Para continuar o processo de criação de cobrança entre em contato com <b>{`${ownerInfo.firstName}`}</b> (<a href={`mailto: ${ownerInfo.email}`}>{`${(ownerInfo.email)}`}</a>),
                solicitando que faça a validação dos dados em sua própria conta Contraktor. Esse processo só precisará ser feito uma vez e depois você poderá criar cobranças de forma independente e automática.
              </p>
            </S.TextBody>
          </S.Text>
        </Modal.Content>
        <S.Footer>
          <S.FooterActions>
            <Button
              className="action-button create-payment-button"
              onClick={() => {
                handleOKClick();
              }}
              label={(
                <S.ButtonText>
                  <span className="button-text-normal button-text-white">OK</span>
                </S.ButtonText>
              )}
            />
          </S.FooterActions>
        </S.Footer>
      </Modal>
    </>
  );
};

ContactOrganizationOwnerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOKClick: PropTypes.func.isRequired,
  ownerInfo: PropTypes.any.isRequired
};

export default ContactOrganizationOwnerModal;
