import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const Table = styled.table`
  width: 100%;

  &, thead, tbody, tfoot, tr, th, td {
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: center;
    text-align: left;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
  }

  th, td {
    padding: 0 1.5em;
  }

  thead {
    height: 3.5em;
    box-shadow: 0 3px 6px rgba(0,0,0,0.04), 0 3px 6px rgba(0,0,0,0.06);
    
    tr {
      th {
        background-color: #EFEFEF;
        line-height: 1.5;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      height: 3.5em;

      td{
        border-bottom: 1px solid #DEDEDE;
        cursor: pointer;
      }

      &:hover,
      &:focus {
        background-color: ${(props) => props.theme.primaryBackgroundColor};
        box-shadow: 0 1px 4px rgba(0,0,0,0.2), 0 0 20px rgba(0,0,0,0.1);
      }
    }
  }
`;
