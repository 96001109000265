/* eslint-disable react/forbid-prop-types */
/**
 * @attention
 * Please, do not remove file comments.
 * They will be use as a reference to refactor this code in a near future.
 */

/* eslint-disable max-len */
/* eslint-disable camelcase */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useLayoutEffect
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';

import { useModal } from '../../components/_new/Modal';
import RenewalAnnualPlanModal from '../../components/_new/RenewalAnnualPlanModal';
import apiAdapter from '../../components/_new/services/api/config';
import gatewayApi from '../../components/_new/services/gateway/config';
import Loader from '../../components/Loader';
import { useChat } from '../../hooks/ChatProvider';
import { useMixpanel } from '../../hooks/MixpanelProvider';
import { useSocket } from '../../hooks/useSocket';
import { useWootric } from '../../hooks/useWootric';
import { toggleLoading } from '../../redux/ducks/global';
import { updateStep, resetState } from '../../redux/ducks/signUpWizard';
import { resetStore } from '../../redux/ducks/starterWizard';
import api, { unserialize } from '../../utils/backend';
import { decript64 } from '../../utils/parsers';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const clearTokens = () => {
  delete api.defaults.headers.Authorization;
  delete gatewayApi.defaults.headers.authorization;
  delete apiAdapter.defaults.headers.Authorization;
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();
  const { subscribeChannel } = useSocket();
  const { chatIdentify, finishChatSession, getChatSubscriptionName } = useChat();
  const { initWootric } = useWootric();
  const { mixpanelIdentify } = useMixpanel();
  const [hasQueryCKToken, setHasQueryCKToken] = useState(false);
  // Starte of subscription state variables
  const [subscription, setSubscription] = useState(() => {
    const storagedUser = localStorage.getItem('contraktor:user');
    const storageData = JSON.parse(storagedUser);

    if (storageData?.subscription) return storageData.subscription;

    return null;
  });
  const [gatewaySocket, setGatewaySocket] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  // End of subscription state variables
  const [authData, setAuthData] = useState(() => {
    const storagedUser = localStorage.getItem('contraktor:user');
    const storageData = JSON.parse(storagedUser);

    if (storageData?.token) {
      api.defaults.headers.Authorization = `Bearer ${storageData.token}`;
      apiAdapter.defaults.headers.Authorization = `Bearer ${storageData.token}`;
      gatewayApi.defaults.headers.authorization = `Bearer ${storageData.token}`;
      return storageData;
    }

    return {};
  });

  // Start of modals data control
  const [renewalPlanModalOpen, setRenewalPlanModalOpen] = useState(false);
  const [renewalPlanMoreInfoOpen, setRenewalPlanMoreInfoOpen] = useState(false);
  const [renewalSuccessModalOpen, setRenewalSuccessModalOpen] = useState(false);
  const [renewalSuccessMoreInfoOpen, setRenewalSuccessMoreInfoOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isRenewalSuccessModalVisible, setIsRenewalSuccessModalVisible] = useState(true);
  const [renewalPlanTitle, setRenewalPlanTitle] = useState(null);
  const [renewalPlanContent, setRenewalPlanContent] = useState(null);
  const [topModalButtonLabel, setTopModalButtonLabel] = useState(null);
  const [bottomModalButtonLabel, setBottomModalButtonLabel] = useState(null);
  const [topButtonDisabled, setTopButtonDisabled] = useState(false);

  const renewalPlanMoreInfoTitle = 'Saiba mais detalhes sobre a renovação do seu plano que acontecerá breve!';
  const renewalPlanMoreInfoContents = [
    '1. Data de Renovação: A renovação do seu plano está agendada para ocorrer na mesma data da primeira contratação. Para mais detalhes acesse a página de Plano e Pagamento. Nessa data, o valor referente à renovação será automaticamente debitado do método de pagamento cadastrado em sua conta.',
    '2. Valor e Duração: O valor e a duração da renovação serão os mesmos do plano atual. Isso significa que você continuará a desfrutar dos benefícios e recursos do nosso serviço pelos próximos 12 meses.',
    '3. Cancelamento: Se  você não desejar renovar o plano, você tem a opção de cancelar a renovação antes da data prevista. Para fazer isso, basta acessar sua conta e seguir as instruções de cancelamento. Tenha em mente que o cancelamento precisa ser realizado antes da data de renovação mencionada anteriormente.',
    '4. Atendimento ao Cliente: Se você tiver alguma dúvida, preocupação ou precisar de suporte adicional, nossa equipe de atendimento ao cliente estará pronta para ajudá-lo(a).',
    'Por favor, esteja ciente de que nenhuma ação é necessária de sua parte se você estiver satisfeito(a) com a renovação automática do seu plano. Caso contrário, lembre-se de cancelar antes da data mencionada para evitar cobranças futuras.'
  ];
  const renewalSuccessModalTitle = 'Seu plano anual foi renovado com sucesso! 🎉';
  const renewalSuccessModalContent = 'Agradecemos por sua confiança e por escolher a ContraktorSign para atender aos seus objetivos. A renovação do seu plano garante que você possa aproveitar continuamente  todos os benefícios e recursos exclusivos pelos próximos 12 meses. Estamos entusiasmados em tê-lo(a) em nossa plataforma por mais um ano!';
  const renewalSuccessMoreInfoTitle = 'Saiba mais detalhes sobre a renovação do seu plano!';
  const renewalSuccessMoreInfoContent = [
    'Aqui estão algumas informações importantes sobre a renovação:',
    '1. Duração da Renovação: Seu plano agora está vigente por mais 1 (um) ano, a partir da data de renovação bem-sucedida.',
    '2. Valor: O valor da renovação permanece o mesmo do seu plano anterior, sem qualquer alteração.',
    '3. Suporte ao Cliente: Lembre-se de que nossa equipe de suporte ao cliente está sempre à disposição para ajudá-lo(a) com qualquer dúvida ou necessidade que possa surgir ao longo do seu período de assinatura.',
    'Parabéns pela renovação bem-sucedida do seu plano anual. Valorizamos sua presença aqui na ContraktorSign e estamos ansiosos para fazer parte da sua jornada nos próximos 12 meses.'
  ];

  const checkModalVisibility = () => {
    const isModalClosed = localStorage.getItem('modalClosed') === 'true';
    const isRenewalSuccessModalClosed = localStorage.getItem('renewalSuccessModalClosed') === 'true';

    setIsModalVisible(!isModalClosed);
    setIsRenewalSuccessModalVisible(!isRenewalSuccessModalClosed);
  };

  const handleTopButtonClick = () => {
    const paymentMethod = subscription?.billingType;

    if (paymentMethod !== 'CREDIT_CARD') {
      let planQuery = subscription?.planName?.toLowerCase();
      const cycle = subscription?.cycle;

      if (cycle === 'YEARLY') planQuery += '_anual';

      history.push(`/subscription/payment?selectedPlan=${planQuery}`);
    }

    setIsModalVisible(false);
    localStorage.setItem('modalClosed', 'true');
  };

  const handleBottomButtonClick = () => {
    const paymentMethod = subscription?.billingType;

    if (paymentMethod === 'CREDIT_CARD') {
      setRenewalPlanModalOpen(false);
      setRenewalPlanMoreInfoOpen(true);
    } else {
      setIsModalVisible(false);
      localStorage.setItem('modalClosed', 'true');
    }
  };

  const hasRenewed = (lastPaymentDate) => moment(lastPaymentDate).toDate() <= moment().toDate();

  const renewalPlanModal = (
    <RenewalAnnualPlanModal
      isOpen={renewalPlanModalOpen && isModalVisible && !!authData.user}
      title={renewalPlanTitle}
      content={renewalPlanContent}
      topButtonLabel={topModalButtonLabel}
      topButtonDisabled={topButtonDisabled}
      bottomButtonLabel={bottomModalButtonLabel}
      hasBottomButton
      topButtonClick={handleTopButtonClick}
      bottomButtonClick={handleBottomButtonClick}
    />
  );

  const renewalPlanMoreInfo = (
    <RenewalAnnualPlanModal
      isOpen={renewalPlanMoreInfoOpen && !!authData.user}
      title={renewalPlanMoreInfoTitle}
      content={renewalPlanMoreInfoContents}
      topButtonLabel="Ok, entendi"
      hasBottomButton={false}
      topButtonClick={() => {
        setRenewalPlanMoreInfoOpen(false);
        setRenewalPlanModalOpen(true);
      }}
    />
  );

  const renewalSuccessModal = (
    <RenewalAnnualPlanModal
      isOpen={renewalSuccessModalOpen && isRenewalSuccessModalVisible && !!authData.user}
      title={renewalSuccessModalTitle}
      content={renewalSuccessModalContent}
      topButtonLabel="Continuar aproveitando"
      bottomButtonLabel="Quero saber mais"
      hasBottomButton
      topButtonClick={() => {
        setIsRenewalSuccessModalVisible(false);
        localStorage.setItem('renewalSuccessModalClosed', 'true');
      }}
      bottomButtonClick={() => {
        setRenewalSuccessMoreInfoOpen(true);
        setRenewalSuccessModalOpen(false);
      }}
    />
  );

  const renewalSuccessMoreInfo = (
    <RenewalAnnualPlanModal
      isOpen={renewalSuccessMoreInfoOpen && isRenewalSuccessModalVisible && !!authData.user}
      title={renewalSuccessMoreInfoTitle}
      content={renewalSuccessMoreInfoContent}
      topButtonLabel="Ok, entendi"
      hasBottomButton={false}
      topButtonClick={() => {
        setRenewalSuccessMoreInfoOpen(false);
        setRenewalSuccessModalOpen(true);
      }}
    />
  );
  // End of modals data control

  // Unlimited of subscription data control
  useEffect(() => {
    if (subscription) {
      const shouldNotify = subscription.shouldNotificateRenewalDate;
      const modalRenewalInfoShowed = ![null, undefined].includes(localStorage.getItem('modalClosed'));
      const shouldShowRenewSuccess = modalRenewalInfoShowed && hasRenewed(subscription.lastPaymentDate) && isRenewalSuccessModalVisible;
      checkModalVisibility();
      setRenewalPlanModalOpen(!!shouldNotify);
      setRenewalSuccessModalOpen(shouldShowRenewSuccess);
    }
  // eslint-disable-next-line
  }, [subscription]);

  useEffect(() => {
    if (subscription) checkModalVisibility();
  }, [subscription]);

  useEffect(() => {
    if (subscription) setAuthData((prev) => ({ ...prev, subscription, loading: false }));
  }, [subscription]);

  useEffect(() => {
    if (subscription && authData?.user) {
      const paymentMethod = subscription.billingType;
      const { isOwner } = authData.user;
      const modalRenewalInfoShowed = ![null, undefined].includes(localStorage.getItem('modalClosed'));

      if (paymentMethod === 'CREDIT_CARD') {
        Promise.all([
          setRenewalPlanTitle('Seu plano será renovado em breve!'),
          setRenewalPlanContent('Informamos que o seu plano anual está prestes a ser renovado automaticamente. Queremos garantir que você esteja ciente dessa renovação para evitar surpresas indesejadas. O seu plano atual, será renovado no mesmo formato e condições. Para saber mais sobre a data de renovação acesse a página de Plano e Pagamento.'),
          setTopModalButtonLabel('Li e estou ciente'),
          setBottomModalButtonLabel('Quero saber mais'),
          setTopButtonDisabled(false)
        ]);
      } else {
        Promise.all([
          setRenewalPlanTitle('Não perca a renovação do seu plano: Atualize seu método de pagamento para uma renovação sem preocupações!'),
          setRenewalPlanContent([
            'Seu plano atual irá vencer em breve e pode ser renovado no mesmo formato e condições. Para saber mais sobre a data de renovação acesse a página de Plano e Pagamento.',
            'Para garantir uma renovação contínua e sem complicações do seu plano anual, indicamos que você atualize seu método de pagamento para cartão de crédito. Com o cartão de crédito cadastrado, você desfrutará dos benefícios da renovação automática, continuidade do serviço e conveniência no pagamento.',
            'Após a atualização, a data de cobrança do seu plano será ajustada para coincidir com a data de cadastro do cartão. Caso opte por não atualizar, continuaremos emitindo os boletos conforme antes.',
            !isOwner
              ? <b>*Lembrando que apenas o usuário responsável pela conta pode fazer alterações no método de pagamento.</b>
              : null
          ]),
          setTopModalButtonLabel('Quero atualizar'),
          setBottomModalButtonLabel('OK, entendi'),
          setTopButtonDisabled(!isOwner)
        ]);
      }

      const shouldShowRenwalModal = subscription.shouldNotificateRenewalDate;

      setRenewalPlanModalOpen(shouldShowRenwalModal);
      if (!shouldShowRenwalModal) setRenewalSuccessModalOpen(modalRenewalInfoShowed && hasRenewed(subscription.lastPaymentDate));
    }
    // eslint-disable-next-line
  }, [subscription]);
  // End of subscription data control

  // Start of gateway socket configuration
  const handleSubscriptionUpdate = useCallback((sub) => {
    const { suspended, suspensionReason, planName } = sub;

    const isSubscriptionOverdue = suspended && suspensionReason === 'PAYMENT_OVERDUE';
    switch (planName) {
      case 'Freemium':
        openModal('welcomeFreemium');
        break;
      case 'Trial':
        if (suspended) openModal('finishTrial');
        break;
      default:
        if (isSubscriptionOverdue) openModal('subscriptionSuspendedOverdue');
        break;
    }
  }, [openModal]);

  const subscriptionUpdatedEventHandler = useCallback(async () => {
    const orgSubscription = (await gatewayApi.get('/subscription')).data;

    setSubscription(orgSubscription);
    handleSubscriptionUpdate(orgSubscription);
  }, [setSubscription, handleSubscriptionUpdate]);

  const initGatewaySocket = useCallback(async (organizationId) => {
    try {
      const socket = io(process.env.REACT_APP_GATEWAY_API_URL, {
        reconnection: true,
        reconnectionDelay: 2000,
        reconnectionAttempts: 1,
        query: {
          organizationId
        }
      });

      socket.on('connect', () => {
        socket.on('subscription_updated', async () => subscriptionUpdatedEventHandler());
        socket.on('disconnect', () => setSocketConnected(false));

        setGatewaySocket(socket);
        setSocketConnected(true);
      });
    } catch (error) {
      console.error('Failed to connect:', error);
    }
    // eslint-disable-next-line
  }, [
    subscriptionUpdatedEventHandler,
    // connectionErrorHandler,
    setSocketConnected,
    setGatewaySocket
  ]);

  const disconnectGatewaySocket = useCallback(() => {
    if (socketConnected) gatewaySocket.disconnect();
  }, [socketConnected, gatewaySocket]); // eslint-disable-line
  // End of gateway socket configuration

  const initLibs = useCallback((authPayload, orgSubscription) => {
    const { owner, user, token } = authPayload;

    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') initWootric(user);
    mixpanelIdentify(user, true);
    chatIdentify({
      user_id: user.id,
      plan: getChatSubscriptionName(
        orgSubscription.planName,
        orgSubscription.cycle,
        orgSubscription.providerId
      ),
      token,
      user_name: `${user.firstName} ${user.lastName}`,
      user_email: user.email,
      user_origin: user.origin || 'n/d',
      user_inserted_at: user.insertedAt,
      user_phone: user.phone,
      owner_email: owner.ownerEmail,
      organization_logo: user.organization.logoUrl,
      organization_name: user.organization.name || 'organização sem nome',
      organization_id: user.organizationId,
      organization_segment: user.organization.operationSegment || 'n/d',
      organization_documents_sent_in_month: user.organization.documentsSentInMonth || 'n/d',
      has_whatsapp_access: orgSubscription.features?.some((f) => f.name === 'whatsapp'),
      whatsapp_enabled: orgSubscription.features?.some((f) => f.name === 'whatsapp' && f.enabled),
      whatsapp_quota: orgSubscription.features?.find((f) => f.name === 'whatsapp')?.quota || 'não tem acesso',
      employees_number: user.organization?.employeesNumber,
      suspended: orgSubscription.suspended,
      suspension_reason: orgSubscription.suspensionReason || 'n/d'
    });
  // eslint-disable-next-line
  }, [
    initWootric,
    mixpanelIdentify,
    chatIdentify
  ]);

  useEffect(() => {
    if (authData.token) initGatewaySocket(authData.user.organizationId);
  // eslint-disable-next-line
  }, []);

  const initSocketConnection = useCallback((authPayload) => {
    const socketInstance = subscribeChannel(`user:${authPayload.token}`, {});

    socketInstance
      .join()
      .receive('ok', () => {})
      .receive('error', () => {
        localStorage.removeItem('contraktor:user');
        clearTokens();
        history.push('/logout');
        socketInstance.leave();
      });

    socketInstance
      .push('get', {})
      .receive('ok', ({ main_info: mainInfoData, user: userData }) => {
        const unserializedData = unserialize(userData);
        setAuthData((prev) => ({
          ...prev, user: unserializedData, owner: mainInfoData
        }));
      });

    socketInstance
      .on('send_account_updated', async ({ user: userData }) => {
        const unserializedData = unserialize(userData);

        setAuthData((prev) => ({
          ...prev, user: unserializedData
        }));
      });
  // eslint-disable-next-line
  }, [subscribeChannel, setAuthData]);

  async function signIn(data) {
    const { email, password } = data;

    console.log('EMAIL DO USUÁRIO HAHA WHY SO SERIOUS', email);

    const response = await api.post('/auth/sign_in', {
      email,
      password
    });

    const { token, ...rest } = response.data;
    gatewayApi.defaults.headers.authorization = `Bearer ${token}`;

    const orgSubscription = (await gatewayApi.get('/subscription')).data;
    setSubscription(orgSubscription);
    initGatewaySocket(rest.organizationId);

    const authPayload = {
      user: rest,
      token,
      owner: rest.organization.owner
    };

    setAuthData(authPayload);
    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiAdapter.defaults.headers.Authorization = `Bearer ${token}`;

    initLibs(authPayload, orgSubscription);
    initSocketConnection(authPayload);
  }

  function signOut() {
    localStorage.removeItem('contraktor:user');
    localStorage.removeItem('contraktorModals:inactivedPlan');
    clearTokens();
    setAuthData({
      user: null,
      owner: null,
      token: null,
      loading: false,
      subscription: null
    });
    finishChatSession();
    disconnectGatewaySocket();
    dispatch(resetStore());
    dispatch(resetState());
    if (window.productFruits && window.productFruits.services) {
      window.productFruits.services.destroy();
    }
    window.location.reload();
  }

  const signUp = useCallback(async (data) => {
    const {
      firstName,
      lastName,
      password,
      email,
      phone,
      optIn, employeesNumber, gtm_campaign_url, gtm_referrer, grtoken
    } = data;

    const response = await api.post('/auth/sign_up', {
      first_name: firstName.replace(/\s{2,}/g, ' ').trim(),
      last_name: lastName.replace(/\s{2,}/g, ' ').trim(),
      email,
      password,
      gtm_campaign_url,
      gtm_referrer,
      password_confirmation: data.password,
      phone: phone.replace(/[)(_-]/g, '').length > 0 ? phone : null,
      opt_in: optIn,
      employees_number: employeesNumber,
      operation_segment: data.operationSegment,
      documents_sent_in_month: data.documentsSentInMonth,
      origin: 'LPST',
      grtoken
    });

    const { token, ...rest } = response.data;

    gatewayApi.defaults.headers.authorization = `Bearer ${token}`;
    const orgSubscription = (await gatewayApi.get('/subscription')).data;
    initGatewaySocket(rest.organizationId);
    setSubscription(orgSubscription);

    const authPayload = {
      user: {
        ...rest,
        organization: {
          ...rest.organization,
          employeesNumber
        }
      },
      token,
      owner: rest.organization.owner
    };

    setAuthData(authPayload);

    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiAdapter.defaults.headers.Authorization = `Bearer ${token}`;

    initLibs(authPayload, orgSubscription);
    initSocketConnection(authPayload);

    setTimeout(() => {
      openModal('welcomeTrial');
    }, 500);
  }, [
    openModal,
    initGatewaySocket,
    setSubscription,
    setAuthData,
    initSocketConnection,
    initLibs
  ]);

  const handleDoSignIn = async (data) => {
    try {
      await signIn(data);
      const redirectUrl = localStorage.getItem('redirectTo') || '/';

      history.push(redirectUrl);
      localStorage.removeItem('redirectTo');

      toast.success('Logado com sucesso!');
    } catch (err) {
      toast.error('Usuário ou senha incorretos.');
    }
  };

  useEffect(() => {
    localStorage.removeItem('contraktorAuth:user');

    api.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status && [401, 403].includes(error.response.status)) {
        history.push('/logout');
        dispatch(toggleLoading(false));
      }

      if (error.response && error.response.status && error.response.status === 402) {
        dispatch(updateStep({ step: 2, stepIndex: 2 }));
        dispatch(toggleLoading(false));
        history.push('/register/payment');
        toast.warn('Realize a ativação do plano para prosseguir com o uso.', {
          toastId: 'payment'
        });
      }
      return Promise.reject(error);
    });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authData.token && authData.user && authData.owner) {
      localStorage.setItem('contraktor:user', JSON.stringify({ ...authData, subscription }));
    }
  }, [authData, subscription]);

  useLayoutEffect(() => {
    if (query && query.get('ck')) {
      const agAuthData = JSON.parse(decript64(query.get('ck')));
      if (agAuthData.token && agAuthData.email && agAuthData.uniqueKey) {
        setHasQueryCKToken(true);
        handleDoSignIn({ email: agAuthData.email, password: decript64(agAuthData.uniqueKey) });
      }
    }
  // eslint-disable-next-line
  }, [query]);

  const isTrial = subscription?.planName === 'Trial';
  const isFreemium = subscription?.planName === 'Freemium';
  const latePayment = subscription?.suspended && subscription?.suspensionReason === 'PAYMENT_OVERDUE';
  const isUnlimitedPlan = subscription?.planName === 'Unlimited';
  const isLightPlan = subscription?.planName === 'Light';
  const isSuspended = subscription?.suspended;
  const maxUsers = {
    Light: 6,
    Essential: 10,
    Unlimited: 12
  };

  if (authData.loading) return <Loader loading />;

  return (
    <AuthContext.Provider
      value={{
        loading: authData.loading,
        signed: !!authData.token,
        hasQueryCKToken,
        setHasQueryCKToken,
        user: authData.user,
        owner: authData.owner,
        isOwner: authData.user ? authData.user.isOwner : false,
        orgUsersCount: authData.owner && authData.owner.org_users_count
          ? authData.owner.org_users_count : 0,
        isFreemium,
        isUnlimitedPlan,
        isLightPlan,
        isTrial,
        isPaidPlan: isUnlimitedPlan || isLightPlan,
        trialIsOver: isTrial && isSuspended,
        planActivated: !isSuspended,
        accountInactived: isSuspended,
        migrationActive: authData.user?.organization?.migration,
        latePayment,
        subscription,
        features: subscription?.features || [],
        currentPlan: {
          name: subscription?.planName || '',
          maxUsers: maxUsers[subscription?.planName]
        },
        signIn,
        signUp,
        setUser: (data) => setAuthData((prev) => ({ ...prev, user: data })),
        token: authData.token,
        signOut,
        setAuthData
      }}
    >
      {children}
      {renewalPlanModal}
      {renewalPlanMoreInfo}
      {renewalSuccessModal}
      {renewalSuccessMoreInfo}
    </AuthContext.Provider>
  );
};
export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastName: PropTypes.string,
    organization: PropTypes.shape({
      amountToPay: PropTypes.string,
      logoUrl: PropTypes.string,
      nextChargeAt: PropTypes.string,
      state: PropTypes.string,
      planName: PropTypes.string
    }),
    subscription: PropTypes.shape({
      value: PropTypes.string,
      suspended: PropTypes.bool,
      suspensionReason: PropTypes.string,
      nextPaymentDueDate: PropTypes.string,
      planName: PropTypes.string,
      insertedAt: PropTypes.string
    }),
    features: PropTypes.any,
    organizationId: PropTypes.string,
    token: PropTypes.string
  })
};

AuthProvider.defaultProps = {
  user: null
};

export default AuthContext;
