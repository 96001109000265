/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

import loadingIcon from '../../assets/loader-icon.png';
import * as S from './styles';

const LoaderModal = ({
  open,
  loadingMessage
}) => {
  useEffect(() => {}, []);

  if (!open) return null;

  return (
    <Modal
      open={open}
      size="small"
      style={{
        minHeight: '190px',
        maxWidth: '670px',
        borderRadius: '8px',
        backgroundColor: '#0C0440',
        paddingTop: '15px'
      }}
    >
      <S.Info>
        <S.LoaderModalContainer>
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '22.66px'
          }}
          >
            <img src={loadingIcon} alt="Ícone de carregamento" className="spin" />
          </div>
          <span className="loader-text">{loadingMessage}</span>
        </S.LoaderModalContainer>
      </S.Info>
    </Modal>
  );
};

LoaderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string.isRequired
};

export default LoaderModal;
