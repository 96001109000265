/* eslint-disable react/prop-types */
import React from 'react';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import DefaultHeader from './components/DefaultHeader';
import DefaultNotSignedHeader from './components/DefaultNotSignedHeader';
import WhiteHeader from './components/WhiteHeader';

const Header = ({ variant = 'default', ...rest }) => {
  const {
    signed
  } = useAuth();

  if (variant === 'white') return <WhiteHeader {...rest} />;

  if (!signed) return <DefaultNotSignedHeader {...rest} />;

  return <DefaultHeader {...rest} />;
};

export default Header;
