import styled from 'styled-components';

export const Wrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100%;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 42px;

  background-color: #346AF7;

  .documentPreview-header__back-button {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    z-index:; 6;

    display: flex;
    align-items: center;

    padding: 5px !important;

    cursor: pointer;
    color: #fff;
    background: transparent !important;
    border: 0 !important;

    & > svg {
      margin-right: 12px;
    }
  }

  .documentPreview-header__title {
    width: 100%;

    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .edit-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: calc(100% - 42px);
`;

export const SignButtonContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: auto;
  bottom: 16px;
  right: auto;

  > button { 
    width: 18.75rem;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;
