import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import React from 'react';

import FieldErrorMessage from '../components/FieldErrorMessage';
import { cpfCnpjParser } from './parsers';

export function checkUserIsSigner(signersList, currentUser) {
  const userSigner = signersList && signersList
    .find((signer) => signer.email === currentUser.email);

  return Boolean(userSigner) && !userSigner.confirmedAt;
}

export function validateCpfCnpj(value) {
  const parsedValue = cpfCnpjParser(value);

  if (parsedValue && parsedValue.length === 14) {
    return isValidCNPJ(parsedValue);
  }

  if (parsedValue && parsedValue.length === 11) {
    return isValidCPF(parsedValue);
  }

  return false;
}

export function validateCardholderName(value) {
  if (typeof value !== 'string') {
    return false;
  }

  if (value.length < 5) {
    return false;
  }

  if (value.indexOf(' ') < 1) {
    return false;
  }

  if (value.split(' ')[0].length < 2) {
    return false;
  }

  if (/^[\d\s-]*$/.test(value)) {
    return false;
  }

  if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~/]/.test(value)) {
    return false;
  }

  return true;
}

export function removeSpecialCharacteres(str) {
  return str && str.replace(/[^\d]+/g, '');
}

export function checkEmailAlreadyTaken(error, setError, component) {
  const { data } = error.response;

  return Object.entries(data.errors).forEach(([key, value]) => {
    value.forEach((message) => {
      if (key === 'email' && message === 'has already been taken') {
        setError(
          'email',
          {
            type: 'requestError',
            message: component
            || (
              <FieldErrorMessage>
                Email já cadastrado no sistema.
              </FieldErrorMessage>
            )
          }
        );
      }
    });
  });
}
