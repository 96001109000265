import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & .div-overflow {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const Content = styled.div`
  ${({ theme, orientation }) => css`
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: ${orientation};

    @media ${theme.breakPoints.medium} {
      overflow: unset;
    }
  `}
`;

export { Wrapper, Content };
