import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Label, Icon } from 'semantic-ui-react';

import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { toggleLoading } from '../../../redux/ducks/global';
import api from '../../../utils/backend';
import Button from '../../Button';
import {
  Wrapper,
  Card,
  CardsList,
  CardHeader,
  Footer,
  CancelButton,
  CardFooter
} from './styles';

const getColor = (status) => {
  switch (status) {
    case 'ASSINADO':
      return 'green';

    case 'PENDENTE':
      return 'yellow';

    default:
      return 'grey';
  }
};

const Persons = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { subscription } = useAuth();

  const {
    signers, signature, download, status, id
  } = useSelector(
    (state) => state.document
  );

  async function cancelSign() {
    dispatch(toggleLoading(true));
    try {
      await api.delete(`/documents/${id}/signatures`);

      push('/documents');
      toast.success('Documento cancelado com sucesso.');
    } catch (error) {
      toast.error('Erro ao cancelar documento.');
      throw new Error(error);
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  async function resendDocumentEmail(signerId) {
    dispatch(toggleLoading(true));
    try {
      await api.post(`/documents/${id}/signers/${signerId}/resent`);
      toast.success('Solicitação de assinatura reenviada com sucesso');
    } catch (err) {
      toast.error('Erro ao reenviar solicitação de assinatura');
    } finally {
      dispatch(toggleLoading(false));
    }
  }

  function downloadFile(downloadUrl) {
    window.open(downloadUrl);
  }

  const readOnly = status !== 'canceled' && status !== 'signed' && signature !== null;

  return (
    <Wrapper>
      <CardsList>
        {signers
          && signers.map((signer) => (
            <Card key={signer.id}>
              <CardHeader>
                <FaUser size={14} />
                <h4>{signer.name}</h4>
                {signer.confirmedAt ? (
                  <Label size="medium" color={getColor('ASSINADO')}>
                    Assinados
                  </Label>
                ) : (
                  <Label size="medium" color={getColor('PENDENTE')}>
                    Pendente
                  </Label>
                )}
              </CardHeader>
              <span>{signer.email}</span>
              <CardFooter>
                {!signer.confirmedAt && status !== 'canceled' && (
                  <Label
                    as="a"
                    size="medium"
                    basic
                    onClick={() => resendDocumentEmail(signer.id)}
                  >
                    <Icon name="mail" />
                    Reenviar
                  </Label>
                )}
              </CardFooter>
              {signer.confirmedAt && (
                <>
                  <span>IP: {signer.confirmedIpAddress}</span>
                  <span>Assinado em: {signer.confirmedAt}</span>
                </>
              )}
            </Card>
          ))}
      </CardsList>
      <Footer>
        {status === 'signed' && (
          <Button primary onClick={() => downloadFile(download)}>
            Baixar documento
          </Button>
        )}
        {readOnly
        && subscription.suspended
        && readOnly
        && status !== 'signed' && (
          <CancelButton link onClick={() => cancelSign()}>
            Cancelar assinaturas
          </CancelButton>
        )}
      </Footer>
    </Wrapper>
  );
};

export default Persons;
