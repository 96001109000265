import React from 'react';

import { useMixpanel } from '../../../../../../_legacy/hooks/MixpanelProvider';
import { TermsText } from './styles';

export default function TermsAndConditions() {
  const { Mixpanel } = useMixpanel();

  const trackPrivacyPolicyClick = () => {
    Mixpanel.track('checkout_button_politica-de-privacidade');
  };

  const trackTermsClick = () => {
    Mixpanel.track('checkout_button_termos-e-condiçoes');
  };

  return (
    <TermsText>
      Ao “Finalizar compra”, você aceita e concorda com nossa
      &nbsp;
      <a href="https://contraktor.com.br/politica-de-privacidade/" onClick={trackPrivacyPolicyClick}>
        Política de Privacidade
      </a> e
      &nbsp;
      <a
        href="https://contraktorsign.com.br/termos-de-uso/"
        onClick={trackTermsClick}
      >Termos e Condições
      </a>.
    </TermsText>
  );
}
