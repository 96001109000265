import { styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

import themes from '../../../../../_utils/themes';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FlexField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;

export const CustomMenuItem = muiStyled('div')({
  fontFamily: themes.fonts.family.Poppins,
  fontSize: themes.fonts.size.medium,
  fontWeight: themes.fonts.weight.thin,
  color: themes.fonts.color.neutral,

  b: {
    fontFamily: themes.fonts.family.Poppins,
    fontSize: themes.fonts.size.medium,
    fontWeight: themes.fonts.weight.bold,
    color: themes.fonts.color.neutral
  },

  small: {
    fontFamily: themes.fonts.family.Poppins,
    fontSize: themes.fonts.size.smaller,
    fontWeight: themes.fonts.weight.normal,
    color: themes.fonts.color.neutral
  }
});
