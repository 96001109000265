import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import FreeTrialModal from '../../../../_refactor/_components/FreeTrialModal';
import MobileHeader from '../../../components/MobileHeader';
import History from './History';
import Persons from './Persons';
import { Wrapper, Tabs } from './styles';

const Sidebar = ({
  mobileSideBar,
  setMobileSideBar,
  confirmSign,
  isMobile
}) => {
  const {
    confirmedAt, document, email, name
  } = useSelector((state) => state.documentByToken);

  const [tab, setTab] = useState('persons');
  const [isFreeTrialModalOpen, setFreeTrialModalOpen] = useState(false);

  const readOnly = document.status !== 'canceled' && document.status !== 'signed' && document.signature !== null;

  return (
    <>
      <MobileHeader
        handleMenuChange={setMobileSideBar}
        open={mobileSideBar}
        canSign={!confirmedAt}
        confirmSign={async () => {
          await confirmSign();
          setFreeTrialModalOpen(true);
        }}
        signer={{ name, email }}
        readOnly={readOnly}
      />
      <Wrapper mobileSidebar={mobileSideBar}>
        <Tabs>
          <Label
            as="a"
            onClick={() => setTab('persons')}
            size="large"
            color={tab === 'persons' ? 'blue' : null}
          >
            Assinantes
          </Label>

          <Label
            as="a"
            onClick={() => setTab('history')}
            size="large"
            color={tab === 'history' ? 'blue' : null}
          >
            Histórico
          </Label>
        </Tabs>
        {tab === 'persons' && <Persons />}
        {tab === 'history' && <History />}
        {!isMobile ? null : (
          <FreeTrialModal
            open={isFreeTrialModalOpen}
            onClose={() => setFreeTrialModalOpen(false)}
          />
        )}
      </Wrapper>
    </>
  );
};

Sidebar.propTypes = {
  setMobileSideBar: PropTypes.func.isRequired,
  mobileSideBar: PropTypes.bool.isRequired,
  confirmSign: PropTypes.func.isRequired
};

export default Sidebar;
