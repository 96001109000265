import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'semantic-ui-react';

const ErrorMessage = ({ message, children }) => (
  <Label basic color="red" pointing>
    {(children) ? React.Children.toArray(children) : message}
  </Label>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node
};

ErrorMessage.defaultProps = {
  children: null
};

export default ErrorMessage;
