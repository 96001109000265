import { Check } from '@mui/icons-material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

import themes from '../../../../../_utils/themes';

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 12px;
  align-items: center;
  border: 2px solid ${themes.pallete.primaryLight};
  border-radius: 8px;
  padding: 8px 12px 8px 16px;
  background-color: ${themes.pallete.white};
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const TextTitle = styled.span`
  font-size: ${themes.fonts.size.large};
  color: ${themes.fonts.color.darkBlue};
  font-family: ${themes.fonts.family.Poppins};
  font-weight: ${themes.fonts.weight.normal};
`;

export const TextSubtitle = styled.span`
  font-size: ${themes.fonts.size.smaller};
  color: ${themes.fonts.color.lightGrey};
  font-family: ${themes.fonts.family.Poppins};
  font-weight: ${themes.fonts.weight.thin};
`;

export const Icon = muiStyled(Check)({
  color: themes.pallete.primary
});
