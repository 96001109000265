import {
  Header as HeaderUI, IconButton, Icon, Button as ButtonUI
} from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useHistory } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

import logoHorizontalWhite from '../../../../assets/img/logo_horiz_white.svg';
import { useAuth } from '../../../../containers/AuthContext/AuthContext';
import { useMixpanel } from '../../../../hooks/MixpanelProvider';
import { getDaysBetweenDates } from '../../../../utils/localeUtils';
import HelpOnboardingVideo from '../../../HelpOnboardingVideo';
import ProfileMenu from '../../../ProfileMenu';
import Banner from '../../../Trial/Banner';
import MobileMenu from './components/MobileMenu';
import { Menu, Container } from './styles';

const menuItems = [
  { title: 'Inicio', path: '/' },
  { title: 'Documentos', path: '/documents' },
  { title: 'Pessoas', path: '/signers' }
];

const Header = ({ options, payment, hideBanner }) => {
  const history = useHistory();
  const { Mixpanel } = useMixpanel();
  const {
    signed, subscription
  } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { nextPaymentDueDate } = subscription || '';

  const isTrial = signed
    && subscription?.planName === 'Trial';

  const isPaymentPage = !options && payment;
  const daysRemaining = getDaysBetweenDates(Date.now(), nextPaymentDueDate);

  return (
    <Container>
      {!hideBanner && <Banner />}

      {isMobile && (
        <MobileMenu />
      )}

      {!isMobile && (
        <HeaderUI
          appBarPosition="static"
          appBarColorType="primary"
          toolbarType="regular"
          disableGutters={false}
        >
          <NavLink to="/">
            <img id="logo" src={logoHorizontalWhite} alt="Imagem com as letras CK e o nome da plataforma Contraktor" />
          </NavLink>
          {options && (
            <Menu>
              {menuItems.map((item) => (
                <li key={`${item.path}${item.title}`}>
                  <NavLink exact to={item.path}>
                    {item.title}
                  </NavLink>
                </li>
              ))}
              {isTrial && (
                <li className="daysRemaining-wrapper">
                  <span>{daysRemaining} dias restantes</span>
                  <ButtonUI
                    className="buy-button"
                    label="Contratar Agora"
                    onClick={() => {
                      Mixpanel.track('home_button_contratar-agora');
                      history.push('/plans');
                    }}
                  />
                </li>
              )}
              <li className="help-dropdown">
                <Popup
                  content="Ir para página de ajuda"
                  trigger={(
                    <div>
                      <HelpOnboardingVideo
                        trigger={(
                          <IconButton>
                            <Icon className="icon-button--help-icon">help_outline</Icon>
                          </IconButton>
                        )}
                      />
                    </div>
                  )}
                  position="left center"
                />
              </li>
              <ProfileMenu />
            </Menu>
          )}
          {isPaymentPage && (
            <Menu>
              <li>
                <NavLink exact to="/logout">
                  Sair
                </NavLink>
              </li>
            </Menu>
          )}
        </HeaderUI>
      )}
    </Container>
  );
};

Header.propTypes = {
  options: PropTypes.bool,
  payment: PropTypes.bool,
  hideBanner: PropTypes.bool
};

Header.defaultProps = {
  options: false,
  payment: false,
  hideBanner: false
};
export default Header;
