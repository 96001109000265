/* eslint-disable no-confusing-arrow */
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const Container = styled.div`
  > .progress-info-container {
    ${({ disabled }) => disabled ? 'color: #CAC4D0' : 'color: #45464F'};
  }

  > .progress-info-subtitle {
    ${({ disabled }) => disabled ? 'color: #CAC4D0' : 'color: #767680'};
  }
`;

export const BorderLinearProgress = muiStyled(LinearProgress)(({ disabled }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: disabled ? '#F2F0F4' : '#EFF0FF'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: disabled ? '#CAC4D0' : '#346AF7'
  }
}));
