// Action types

export const SET_REQUESTED = '@contraktor/recoverPassword/SET_REQUESTED';
export const TOGGLE_LOADING = '@contraktor/recoverPassword/TOGGLE_LOADING';
export const ERROR_RESPONSE = '@contraktor/recoverPassword/ERROR_RESPONSE';

// Reducer

export const INITIAL_STATE = {
  recoveryRequested: false,
  loading: false,
  error: ''
};

export default function recoveryPassword(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_REQUESTED:
      return {
        ...state, recoveryRequested: action.payload
      };
    case TOGGLE_LOADING:
      return {
        ...state, loading: action.payload
      };
    case ERROR_RESPONSE:
      return {
        ...state, error: action.payload, loading: false
      };
    default:
      return state;
  }
}

// Actions

export function setRequested(payload) {
  return {
    type: SET_REQUESTED,
    payload
  };
}

export function toggleLoading(payload) {
  return {
    type: TOGGLE_LOADING,
    payload
  };
}

export function errorResponse(payload) {
  return {
    type: ERROR_RESPONSE,
    payload
  };
}
