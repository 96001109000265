/* eslint-disable max-len */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import * as S from './styles';

const contentDataByPlan = {
  Light: {
    documentLimit: 15,
    planName: 'Light'
  },
  Freemium: {
    documentLimit: 5,
    planName: 'Free'
  }
};

const modalContent = (planName) => ({
  title: 'Novo mês, novos envios 🚀',
  description: (
    <span>
      <p>
        Oba! Agora,
        <bold> você tem novamente {contentDataByPlan[planName]?.documentLimit} envios de documentos </bold>
        disponíveis no Plano {contentDataByPlan[planName]?.planName} da ContraktorSign.
        O limite foi renovado, permitindo que você continue agilizando seus processos
        com facilidade.
      </p>

      <p>
        Aproveite para enviar os seus documentos importantes e para
        explorar todos os benefícios da assinatura digital.
      </p>

      <p>
        Se você deseja ter <bold>mais envios disponíveis</bold> e aproveitar outros recursos,
        lembre-se que temos planos que oferecem mais funcionalidades e benefícios exclusivos.
        Com envios ilimitados e vantagens adicionais, você pode elevar a
        sua experiência a um novo nível!
      </p>

      <p>
        Clique abaixo em <bold>“Explorar Mais Planos” </bold>
        para conhecer mais dos nossos planos pagos!
      </p>
    </span>
  )
});

function DispatchLimitRenewalModal({ open, onClose }) {
  const history = useHistory();
  const { subscription: { planName } } = useAuth();

  return (
    <S.Container
      open={open}
      onClose={onClose}
      fullWidth
    >
      <div className="modal-header">
        <p className="modal-title">{modalContent(planName).title}</p>
      </div>
      <div className="modal-content">
        <p className="content-text">{modalContent(planName).description}</p>
      </div>
      <div className="modal-footer">
        <Button
          className="left"
          onClick={() => {
            localStorage.setItem('docLimitModalViewed', true);
            history.push('/plans');
          }}
          fullWidth
          label="Explorar Mais Planos"
        />
        <Button className="right" onClick={onClose} fullWidth label="Enviar Documento Agora" />
      </div>
    </S.Container>
  );
}

DispatchLimitRenewalModal.defaultProps = {
  open: false,
  onClose: () => {}
};

DispatchLimitRenewalModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DispatchLimitRenewalModal;
