import { createGlobalStyle } from 'styled-components';
import 'react-day-picker/lib/style.css';

import theme from './theme';

export default createGlobalStyle`
  * {
    font-size: 16px;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${theme.backgroundColor};
    font-family: ${theme.fontFamily} !important;
    color: ${theme.fontColor};
    font-size: ${theme.fontSize};
    line-height: ${theme.lineHeight};

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  #root {
    height: 100%;
    min-height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fontFamily} !important;
    font-weight: 400;
    line-height: ${theme.headerLineHeight};
    color: ${theme.headerColor};
    margin: 0;
    padding: 0;
  }

  input {
    font-family: ${theme.fontFamily};
  }

  p {
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: ${theme.linkColor};
    text-decoration: none;
  }

  a:hover,
  a:focus {
    color: ${theme.linkColorHover};
    text-decoration: none;
  }

  .headline-6 {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .body2 {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .grey {
    color: #00000099;
  }


  /* Input */

  .ui.input.focus > input {
    border: 1px solid ${theme.errorColor} !important;
  }
  /* Dropdown */ 

  .ui.dropdown .menu { z-index: 201; }
  .ui.dropdown:not(.button) > .default.text,
  .ui.default.dropdown:not(.button) > .text {
    color: ${theme.mutedTextColor};
  }
  
  .ui.dropdown .menu > .item {
    font-size: 1em;
    color: ${theme.fontColor}
  }

  .ui.dropdown .menu > .item:hover {
    color: ${theme.fontColor}
  }

  .ui.inverted.popup {
    background-color: ${theme.tooltipBackgrounColor};
    color: ${theme.fontColor};
    font-weight: 500;
    .header {
      color: ${theme.fontColor};
    }
    .content{

    }
    &::before {
      background-color: ${theme.tooltipBackgrounColor} !important;
    }
  }

  .ui.inverted.top.popup:before,
  .ui.inverted.bottom.popup:before{
    background-color: ${theme.tooltipBackgrounColor};
  }

  .ui.dropdown.fat > .dropdown.icon {
    margin-top: -1px;
  }

  .ui.button, 
  button {
    font-family: ${theme.buttonFont} !important;
    font-size: 1em;
    font-weight: 600;
    padding: 1em 5em;

    &.compact {
      padding: 0.625em 1.875em 0.625em 1.875em;
    }

    &.circular {
      padding: 1em;
    }

    &.primary {
      background: ${theme.primaryButton.background} !important;
      color: ${theme.primaryButton.color};
      border: 1px solid ${theme.primaryButton.border};

      &:hover,
      &:focus {
        background: ${theme.primaryButton.hover.background};
        color: ${theme.primaryButton.hover.color};
        border-color: ${theme.primaryButton.hover.border};
      }

      &:active {
        background: ${theme.primaryButton.active.background};
        color: ${theme.primaryButton.active.color};
        border-color: ${theme.primaryButton.active.border};
      }

      &:disabled {
        background: ${theme.primaryButton.disabled.background};
        color: ${theme.primaryButton.disabled.color};
        border-color: ${theme.primaryButton.disabled.border};
      }
    }

    &.secondary {
      background: ${theme.secondaryButton.background};
      color: ${theme.secondaryButton.color};
      border: 1px solid ${theme.primaryButton.border};

      &:hover,
      &:focus {
        background: ${theme.secondaryButton.hover.background};
        color: ${theme.secondaryButton.hover.color};
        border-color: ${theme.primaryButton.hover.border};
      }

      &:active {
        background: ${theme.secondaryButton.active.background};
        color: ${theme.secondaryButton.active.color};
        border-color: ${theme.primaryButton.active.border};
      }

      &:disabled {
        background: ${theme.secondaryButton.disabled.background};
        color: ${theme.secondaryButton.disabled.color};
        border-color: ${theme.primaryButton.disabled.border};
      }
    }
  }

  /* ReactDayPicker */
  .DayPicker {
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: ${theme.primaryColor};
      border-radius: 0;
    }
  }
  .DayPicker:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: ${theme.secondaryBackgroundColor};
      border-radius: 0;
    }
  }

  /* MUI Contraktor-ui overrides Buttons */
  .MuiButton-root {
    padding: 16px 32px !important;

    font-family: ${theme.buttonFont} !important;

    &.MuiButton-contained {
      background-color: #346AF7;
      border-color: #346AF7;
      color: #fff;

      &:hover {
        background-color: #0b3dbe;
        border-color: #0b3dbe;
      }

      &.MuiButton-containedSecondary {
        background-color: rgba(52, 106, 247, 0.12);
        border-color: rgba(52, 106, 247, 0.12);
        color: #346AF7;

        &:hover {
          background-color: rgba(52, 106, 247, 0.20);
          border-color: rgba(52, 106, 247, 0.20);
        }
      }
    }
    &.MuiButton-outlined {}
    &.MuiButton-text {}
  }
`;
