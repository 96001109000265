import Check from '@mui/icons-material/Check';
import React from 'react';

import { CustomButton, StyledButtonGroup } from './styles';

export default function CustomButtonGroup({ options, selectedOption, setActiveOption }) {
  return (
    <StyledButtonGroup variant="outlined" aria-label="Basic button group">
      {
        options.map(({ label, value }) => {
          return (
            <CustomButton
              active={selectedOption === value}
              onClick={() => setActiveOption(value)}
            >
              {(selectedOption === value) && <Check style={{ marginRight: '5px' }} />}
              { label }
            </CustomButton>
          );
        })
      }
    </StyledButtonGroup>
  );
}
