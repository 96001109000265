import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Document = ({ signer }) => (
  <Wrapper>
    <ShortenedField clamp={1}>
      {signer.document}
    </ShortenedField>
  </Wrapper>
);

Document.propTypes = {
  signer: PropTypes.shape({
    document: PropTypes.string
  })
};

Document.defaultProps = {
  signer: { document: '' }
};

export default Document;
