/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.theme.starterWidth};
  min-height: ${(props) => props.theme.starterHeight};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.fontColor};
  box-shadow: 0 0 16px rgba(152, 152, 152, 0.2);
  background-color: ${(props) => props.theme.starterBackground};

  > h3 {
    font-weight: 500;
    margin: 1.5rem 0 0.5rem 0;
    color: ${(props) => props.theme.fontColor};
  }

  > h2.error {
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0;
    color: ${(props) => props.theme.errorColor};
  }

  > p {
    font-size: 14px;
    margin-bottom: 1em;
  }

  > img {
    width: 200px;
  }

  > h3.success {
    font-weight: 600;
    margin-top: 1.5rem;
    color: ${(props) => props.theme.darkGrey};
  }
`;

export { Wrapper };
