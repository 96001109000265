import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .content { 
    text-align: center;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  height: 30%;
  align-items: center;

  > button {
    margin: 1rem 0;
    width: 150px;
    height: 50px;
  }
`;
