import React, { useEffect } from 'react';

import MainLayout from '../../components/MainLayout';
import SignersList from '../../components/SignersList';
import HeaderWrapper from '../../components/SignersList/HeaderWrapper';
import { useAmplitude } from '../../hooks/AmplitudeProvider';
import { Container, Wrapper } from './styles';

const SignersPage = () => {
  const { Amplitude } = useAmplitude();

  useEffect(() => {
    Amplitude.logEvent('participants_screen');
  }, [Amplitude]);

  return (
    <MainLayout header options>
      <Container>
        <Wrapper>
          <HeaderWrapper />
          <SignersList />
        </Wrapper>
      </Container>
    </MainLayout>
  );
};

export default SignersPage;
