/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import { Select } from '@contraktor-tech/ui';
import currency from 'currency.js';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useMixpanel } from '../../../../../../hooks/MixpanelProvider';
import GridContainer from '../../../../components/GridContainer/index';
import { CustomTextField } from '../CustomFieldStyles/CustomTextField';
import DueDateInputCustom from '../CustomInputMasks/DueDateInputCustom';
import ValueInputCustom from '../CustomInputMasks/ValueInputCustom';
import * as S from './styles';

const PaymentInfo = ({
  updatePayment, setFormState, paymentPayload, mode
}) => {
  const { Mixpanel } = useMixpanel();
  const {
    control, setError, clearErrors, setValue, getValues, register
  } = useForm({
    defaultValues: mode === 'create' ? {
      value: null,
      due_date: null,
      type: null,
      frequency: 'MONTHLY',
      installment_count: '1',
      description: null
    } : {
      value: '',
      due_date: '',
      type: '',
      description: ''
    },
    mode: 'onChange'
  });

  const typeOptions = [
    {
      label: 'Boleto',
      value: 'BOLETO'
    },
    {
      label: 'PIX',
      value: 'PIX'
    },
    {
      label: 'Cartão de crédito',
      value: 'CREDIT_CARD'
    },
    {
      label: 'Todos',
      value: 'UNDEFINED'
    }
  ];

  // const frequencyOptions = [
  //   {
  //     label: 'Recorrência',
  //     value: ''
  //   },
  //   {
  //     label: 'Semanal',
  //     value: 'WEEKLY'
  //   },
  //   {
  //     label: 'Quinzenal',
  //     value: 'BIWEEKLY'
  //   },
  //   {
  //     label: 'Mensal',
  //     value: 'MONTHLY'
  //   },
  //   {
  //     label: 'Trimestral',
  //     value: 'QUARTERLY'
  //   },
  //   {
  //     label: 'Semestral',
  //     value: 'SEMIANNUALLY'
  //   },
  //   {
  //     label: 'Anual',
  //     value: 'YEARLY'
  //   }
  // ];

  const installmentOptions = () => {
    const maxInstallments = 12;
    const options = [
      {
        label: `À vista (R$ ${getValues('value') || 0.00})`,
        value: '1'
      }
    ];

    for (let i = 2; i <= maxInstallments; i += 1) {
      const totalValue = parseFloat(getValues('value').replace(',', '.'));

      options.push({
        label: `${i}x de (${currency(totalValue / i) || 0.00})`.replace('.', ','),
        value: `${i}`
      });
    }

    return options;
  };

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);
    const values = getValues();
    updatePayment({ ...paymentPayload, ...values });

    const date = moment(values.due_date, 'DD/MM/YYYY', true);
    const isValidDate = date.isValid() && (date.format() >= moment().format());
    const isValidValue = parseFloat(values?.value?.replace(',', '.')) >= 10.00;

    setFormState((!_.isEmpty(values?.value) && isValidValue)
      && (!_.isEmpty(values?.due_date) && isValidDate));
  };

  const validateRequiredField = (value, field) => {
    if (_.isEmpty(value)) setError(field, { message: 'Este campo é obrigatório' });
    else clearErrors(field);
  };

  const validateDate = (dueDate) => {
    const date = moment(dueDate, 'DD/MM/YYYY', true);
    const isValidDate = date.isValid() && (date.format() >= moment().format());

    if (!isValidDate) setError('due_date', { message: 'Insira uma data válida' });
    else clearErrors('due_date');
  };

  const validateValue = (event) => {
    const isValidValue = parseFloat(event.target.value?.replace(',', '.')) >= 10.00;

    if (!isValidValue) setError('value', { message: 'O valor não pode ser inferior a R$10,00' });
    else clearErrors('value');
  };

  // const handleFrequency = (value) => {
  //   const installment = value !== 'UNIQUE' && !_.isEmpty(value);
  //   if (installment) setValue('installment_count', '1');
  //   else setValue('installment_count', null);
  // };

  const isInstallment = () => (!_.isEmpty(getValues().frequency));

  const getPaymentDetails = () => {
    const installmentCount = paymentPayload.installment_count || '1';
    const paymentValue = paymentPayload.value?.replace(',', '.') || 0;
    const installmentValues = currency(parseFloat(paymentValue / parseInt(installmentCount, 10)));
    const uniquePaymentValue = currency(parseFloat(paymentPayload.value.replace(',', '.') || 0));

    if (mode === 'create') return `${installmentCount}x R$${installmentValues}`.replace('.', ',');
    return `R$${uniquePaymentValue}`.replace('.', ',');
  };

  const valueField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      fullWidth
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
        validateValue(event);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
        Mixpanel.track('document_billing_input_valor-da-cobrança');
      }}
      label={<S.InputLabel>Valor total da cobrança</S.InputLabel>}
      id="value"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: ValueInputCustom
      }}
    />
  );

  const dueDateField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      fullWidth={false}
      onChange={(event) => {
        handleChange(event);
        validateDate(event.target.value);
      }}
      onBlur={() => Mixpanel.track('document_billing_input_data-de-vencimento')}
      label={<S.InputLabel>Data de vencimento</S.InputLabel>}
      id="due_date"
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputComponent: DueDateInputCustom
      }}
    />
  );

  const typeField = (field, error) => (
    <Select
      {...field}
      disabled={paymentPayload.status !== 'PAYMENT_ON_HOLD' && mode === 'edit'}
      options={typeOptions}
      onChange={(event) => {
        handleChange(event);
        validateRequiredField(event.target.value, event.target.name);
      }}
      onBlur={(event) => {
        validateRequiredField(event.target.value, event.target.name);
        Mixpanel.track('document_billing_input_meio-de-recebimento');
      }}
      label={<S.InputLabel>Meio de recebimento</S.InputLabel>}
      id="type"
      error={!!error}
      helperText={error?.message}
    />
  );

  // const frequencyField = (field, error) => (
  //   <Select
  //     {...field}
  //     options={frequencyOptions}
  //     onChange={(event) => {
  //       handleFrequency(event.target.value);
  //       handleChange(event);
  //     }}
  //     label={<S.InputLabel>Recorrência</S.InputLabel>}
  //     id="frequency"
  //     error={!!error}
  //     helperText={error?.message}
  //   />
  // );

  const installmentCountField = (field, error) => (
    <Select
      {...field}
      options={installmentOptions()}
      disabled={!isInstallment()}
      onChange={(event) => {
        handleChange(event);
      }}
      onBlur={() => Mixpanel.track('document_billing_input_numero-de-parcelas')}
      label={<S.InputLabel>Número de parcelas</S.InputLabel>}
      id="installment_count"
      error={!!error}
      helperText={error?.message}
    />
  );

  const descriptionField = (field, error) => (
    <CustomTextField
      {...field}
      variant="outlined"
      fullWidth
      onChange={handleChange}
      onBlur={() => Mixpanel.track('document_billing_input_descrição')}
      label={<S.InputLabel>Descrição</S.InputLabel>}
      id="description"
      error={!!error}
      helperText={error?.message}
    />
  );

  useEffect(() => {}, []);

  return (
    <S.FormContainer>
      <GridContainer
        columns="1fr"
        width="auto"
        spacing="1.125"
        padding="0 0 1.125rem 0"
      >
        <Controller
          rules={{ required: true }}
          control={control}
          {...register('value', { value: paymentPayload.value || '' })}
          render={({ field, fieldState: { error } }) => valueField(field, error)}
        />
      </GridContainer>
      <GridContainer
        columns="2fr 2fr"
        width="auto"
        spacing="1.125"
        padding="0"
      >
        <Controller
          disabled={paymentPayload.status !== 'PAYMENT_ON_HOLD' && mode === 'edit'}
          rules={{ required: true }}
          control={control}
          {
            ...register(
              'type',
              {
                value: paymentPayload.type || '',
                disabled: paymentPayload.status !== 'PAYMENT_ON_HOLD' && mode === 'edit'
              }
            )}
          render={({ field, fieldState: { error } }) => typeField(field, error)}
        />
        <Controller
          rules={{ required: true }}
          control={control}
          {...register('due_date', { value: paymentPayload.due_date || '' })}
          render={({ field, fieldState: { error } }) => dueDateField(field, error)}
        />
      </GridContainer>
      <GridContainer
        columns="1fr"
        width="auto"
        spacing="1.125"
        padding="1.125rem 0 0 0"
      >
        {
          mode === 'create'
          && (
          <>
            {/* <Controller
              control={control}
              {...register(
                'frequency',
                {
                  value: paymentPayload.frequency || '',
                  required: true
                }
              )}
              render={({ field, fieldState: { error } }) => frequencyField(field, error)}
            /> */}
            <Controller
              control={control}
              disabled={!isInstallment()}
              {...register(
                'installment_count',
                {
                  value: paymentPayload.installment_count || '1',
                  required: isInstallment()
                }
              )}
              render={({ field, fieldState: { error } }) => installmentCountField(field, error)}
            />
          </>
          )
        }
        <Controller
          control={control}
          {...register('description', { value: paymentPayload.description || '' })}
          render={({ field, fieldState: { error } }) => descriptionField(field, error)}
        />
      </GridContainer>
      <S.Divider />
      <S.PaymentDetailsContainer>
        <S.PaymentDetails>
          <span className="payment-details-title">Resumo do pagamento:</span>
          <span className="payment-details-value">
            {getPaymentDetails()}
          </span>
        </S.PaymentDetails>
      </S.PaymentDetailsContainer>
    </S.FormContainer>
  );
};

PaymentInfo.propTypes = {
  updatePayment: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  paymentPayload: PropTypes.any.isRequired,
  mode: PropTypes.string.isRequired
};

export default PaymentInfo;
