import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';

import api from '../../../utils/backend';
import Button from '../../Button';
import FieldErrorMessage from '../../FieldErrorMessage';
import InputWithRef from '../../InputWithRef';
import { StyledForm } from './styles';

const ChangePasswordForm = ({ onCloseForm }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({ mode: 'blur' });

  const password = watch('password');
  const onSubmit = useCallback(async (data) => {
    try {
      setLoading(true);

      await api.put('/users/settings/update_password', { user: data });
      onCloseForm();
      toast.success('Senha alterada com sucesso!');
    } catch (err) {
      toast.error('Erro ao alterar senha');
      throw new Error('Error: on password change');
    } finally {
      setLoading(false);
    }
  }, [onCloseForm]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid columns={1}>
        <Grid.Column>
          <InputWithRef
            type="password"
            id="password"
            label="Alterar Senha"
            {...register('password', {
              minLength: { value: 8, message: <FieldErrorMessage message="Sua senha deve ter no mínimo 8 caracteres" /> }
            })}
          >
            {errors?.password && errors?.password.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column>
          <InputWithRef
            type="password"
            id="passwordConfirmation"
            label="Confirmar nova senha"
            {...register('passwordConfirmation', {
              validate: { isEqual: (value) => value === password },
              minLength: { value: 8, message: <FieldErrorMessage message="Sua senha deve ter no mínimo 8 caracteres" /> }
            })}
          >
            {errors?.passwordConfirmation && errors?.passwordConfirmation.type === 'isEqual' && (
              <FieldErrorMessage message="As senhas não conferem" />
            )}
            {errors?.passwordConfirmation && errors?.passwordConfirmation.message}
          </InputWithRef>
        </Grid.Column>
        <Grid.Column />
        <Grid.Column
          verticalAlign="middle"
          mobile={8}
          largeScreen={8}
          widescreen={8}
          textAlign="center"
        >
          <Button
            link
            fluid
            onClick={onCloseForm}
          >
            Voltar
          </Button>
        </Grid.Column>
        <Grid.Column
          mobile={8}
          largeScreen={8}
          widescreen={8}
          textAlign="center"
        >
          <Button
            primary
            fluid
            type="submit"
            loading={loading}
          >
            Salvar Informações
          </Button>
        </Grid.Column>
      </Grid>
    </StyledForm>
  );
};

ChangePasswordForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired
};

export default ChangePasswordForm;
