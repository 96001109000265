import { Input as StyledInput } from 'semantic-ui-react';
import styled from 'styled-components';

const Input = styled(StyledInput)`
  input:not([type]),
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='text'],
  input[type='file'],
  input[type='url'] {
    font-family: ${(props) => props.theme.fontFamily} !important;
    font-size: 1em !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: ${(props) => props.theme.headerColor} !important;
    border: 1px solid ${(props) => props.theme.greyBlue} !important;
  }
`;

export default Input;
