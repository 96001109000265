import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Email = ({ signer }) => (
  <Wrapper>
    <ShortenedField clamp={1}>
      {signer.email}
    </ShortenedField>
  </Wrapper>
);

Email.propTypes = {
  signer: PropTypes.shape({
    email: PropTypes.string
  }).isRequired
};

export default Email;
