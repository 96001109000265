import { Progress } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styles';

const Button = ({
  loading, disabled, color, type, children, ...rest
}) => (
  <S.Button
    type={type}
    color={color}
    disabled={disabled || loading}
    {...rest}
  >
    {loading ? (
      <Progress
        size={18}
        type="circular"
      />
    ) : (
      <>{children}</>
    )}
  </S.Button>
);

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  type: PropTypes.oneOf(['submit', 'button']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  type: 'button',
  color: 'primary'
};

export default Button;
