import { Socket } from 'phoenix';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  createContext,
  useContext
} from 'react';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const socket = React.useMemo(() => (
    new Socket(process.env.REACT_APP_SOCKET_URL, {
      heartbeatIntervalMs: 2000
    })
  ), []);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  function subscribeChannel(topic, opts) {
    const currentChannel = socket.channels.find((channel) => channel.topic === topic);

    if (currentChannel) {
      return currentChannel;
    }
    const channel = socket.channel(topic, opts);
    return channel;
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        subscribeChannel
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket() {
  const context = useContext(SocketContext);

  return context;
}

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SocketContext;
