import React from 'react';

import { Card, CardText } from './styles';

export default function InvoiceInfoCard() {
  return (
    <Card>
      <CardText>
        Agora, para você receber sua <b>nota fiscal</b>,
        preencha os campos abaixo e aproveite os
        benefícios da assinatura digital <span role="img" aria-label="icon">😉</span>
      </CardText>
    </Card>
  );
}
