import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundary from '../ErrorBoundary';
import GlobalLoader from '../GlobalLoader';
import Header from '../Header';
import { Wrapper, Content } from './styles';

const MainLayout = ({
  children,
  header,
  orientation,
  options,
  payment,
  hideBanner,
  headerVariant,
  backButtonText,
  backButtonOnClick
}) => (
  <Wrapper orientation={orientation}>
    <ErrorBoundary>
      {header && (
        <Header
          variant={headerVariant}
          options={options}
          hideBanner={hideBanner}
          payment={payment}
          backButtonText={backButtonText}
          backButtonOnClick={backButtonOnClick}
        />
      )}

      <GlobalLoader />
      <Content className="div-overflow">
        {children}
      </Content>
    </ErrorBoundary>
  </Wrapper>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  hideBanner: PropTypes.bool,
  orientation: PropTypes.string,
  options: PropTypes.bool,
  payment: PropTypes.bool,
  headerVariant: PropTypes.oneOf(['default', 'white']),
  backButtonText: PropTypes.string,
  backButtonOnClick: PropTypes.func
};

MainLayout.defaultProps = {
  header: false,
  hideBanner: true,
  orientation: 'column',
  options: false,
  payment: false,
  headerVariant: 'default',
  backButtonText: '',
  backButtonOnClick: null
};

export default MainLayout;
