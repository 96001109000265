/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment';

import 'moment/locale/pt-br';
import { formatDate as formatToString } from '../../../../utils/localeUtils';
import { DayPickerTrigger } from '../styles';

function SentRange({
  setFilterState,
  query,
  selectedDays,
  setSelectedDays,
  dayPickerShow,
  setDayPickerShow
}) {
  async function handleDayPick(day) {
    const range = DateUtils.addDayToRange(day, selectedDays);

    setSelectedDays(range);
  }

  useEffect(() => {
    setFilterState({
      ...query,
      page: 1,
      completedInitialDate:
        (selectedDays.from && formatToString(selectedDays.from, 'DD-MM-YYYY'))
           || undefined,
      completedEndDate:
        (selectedDays.to && selectedDays.from
          && formatToString(selectedDays.to, 'DD-MM-YYYY')) || undefined
    });
  }, [selectedDays, setFilterState, query]);

  return (
    <>
      <DayPickerTrigger
        color="lightGrey"
        unpadded
        fluid
        onClick={() => setDayPickerShow(!dayPickerShow)}
      >
        {selectedDays.from && `${formatToString(selectedDays.from, 'L')} - `}
        {selectedDays.to && `${formatToString(selectedDays.to, 'L')}`}
        {!selectedDays.to && selectedDays.from && 'Padrão'}
        {!selectedDays.from && 'Selecione a data'}
      </DayPickerTrigger>
      { dayPickerShow && (
        <DayPicker
          selectedDays={[selectedDays.from, { ...selectedDays }]}
          modifiers={{ start: selectedDays.from, end: selectedDays.to }}
          numberOfMonths={1}
          onDayClick={(day) => handleDayPick(day)}
          locale="pt-BR"
          localeUtils={MomentLocaleUtils}
          formatDate={formatDate}
          parseDate={parseDate}
          hideOnDayClick={false}
        />
      )}
    </>
  );
}

SentRange.propTypes = {
  setFilterState: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedDays: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedDays: PropTypes.func.isRequired,
  dayPickerShow: PropTypes.bool.isRequired,
  setDayPickerShow: PropTypes.func.isRequired
};

export default SentRange;
