/* eslint-disable import/no-extraneous-dependencies */
import { Button } from '@mui/material';
import Select from '@mui/material/Select';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const WhatsappQuotaContainer = styled.div`
  width: 436px;
  text-align: left;
  display: grid;
  gap: 8px;

  .form-control {
    width: 56.95%;
  }
`;

export const WhatsappQuotaSelectTitle = styled.span`
  color: #00174D;
  width: 100%;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.1px;
`;

export const WhatsappSelect = muiStyled(Select)({
  '& .MuiOutlinedInput-input': {
    display: 'flex!important'
  }
});

export const WhatsappSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContractButton = muiStyled(Button)({
  maxHeight: '53px',
  width: '39.41%',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  boxShadow: 'none'
});
