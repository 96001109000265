/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.theme.starterWidth};
  min-height: ${(props) => props.theme.starterHeight};
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.fontColor};
  box-shadow: 0 0 16px rgba(152, 152, 152, 0.2);

  > img {
    height: 100px;
  }

  > div {
    margin-left: 2em;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  > h3 {
    font-weight: 500;
    margin: 1.5rem 0 0.5rem 0;
    color: ${(props) => props.theme.fontColor};
  }

  > h3.error {
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0;
    color: ${(props) => props.theme.errorColor};
  }

  > p {
    font-size: 14px;
  }
`;

export { Wrapper };
