/* eslint-disable react/forbid-prop-types */
import { Button } from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import ckLogo from '../../../assets/img/logo_horiz_white_2.svg';
import * as S from './styles';

const RenewalAnnualPlanModal = ({
  isOpen,
  title,
  content,
  topButtonLabel,
  topButtonDisabled,
  bottomButtonLabel,
  hasBottomButton,
  topButtonClick,
  bottomButtonClick
}) => (
  <>
    {(isOpen && content) && (
    <S.ModalContainer>
      <S.ModalContent>
        <S.ModalHeader>
          <img src={ckLogo} alt="Header SVG" />
        </S.ModalHeader>
        <S.ModalMessage>
          <h3 className="title">{title}</h3>
          {typeof content === 'string' ? (
            <p className="text">{content}</p>
          ) : (
            content.map((paragraph) => (
              <p className="text">
                {paragraph}
              </p>
            ))
          )}
        </S.ModalMessage>
        <S.ModalButtons>
          <Button
            disabled={topButtonDisabled}
            label={topButtonLabel}
            className="button top-button"
            onClick={topButtonClick}
          />
          {
                hasBottomButton && (
                  <Button
                    label={bottomButtonLabel}
                    className="button bottom-button"
                    onClick={bottomButtonClick}
                  />
                )
              }
        </S.ModalButtons>
      </S.ModalContent>
    </S.ModalContainer>
    )}
  </>
);

RenewalAnnualPlanModal.defaultProps = {
  topButtonClick: null,
  bottomButtonLabel: null,
  bottomButtonClick: null,
  isOpen: false,
  topButtonDisabled: false
};

RenewalAnnualPlanModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  topButtonLabel: PropTypes.string.isRequired,
  topButtonDisabled: PropTypes.bool,
  bottomButtonLabel: PropTypes.string,
  hasBottomButton: PropTypes.bool.isRequired,
  topButtonClick: PropTypes.func,
  bottomButtonClick: PropTypes.func
};

export default RenewalAnnualPlanModal;
