/* eslint-disable quote-props */
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';

function serialize(data) {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (isArray(data)) {
    return map(data, (value, index) => serialize(value, index));
  }

  if (data._raw) { // eslint-disable-line no-underscore-dangle
    return omit(data, ['_raw']);
  }

  return transform(
    data,
    (result, value, key) => {
      // converts ids to integers when possible
      // only if key is 'id' or ends with 'Id'
      // PS: the nationalId is an exception, because it is not an actual foreign key
      if (
        typeof key === 'string'
        && (key === 'id' || key.match(/Id$/))
        && key !== 'nationalId'
      ) {
        // check if it is a string representing a number
        if (typeof value === 'string' && value.match(/^[0-9]*$/)) {
          result[snakeCase(key)] = parseInt(value, 10); // eslint-disable-line no-param-reassign
          return;
        }
      }

      result[snakeCase(key)] = serialize(value); // eslint-disable-line no-param-reassign
    },
    {}
  );
}

export function unserialize(data) {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (isArray(data)) {
    return map(data, (value) => unserialize(value));
  }

  if (data._raw) { // eslint-disable-line no-underscore-dangle
    return omit(data, ['_raw']);
  }

  return transform(
    data,
    (result, value, key) => {
      // converts ids to integers when possible
      // only if key is 'id' or ends with '_id'
      // PS: the national_id is an exception, because it is not an actual foreign key
      if (
        typeof key === 'string'
        && (key === 'id' || key.match(/Id$/))
        && key !== 'nationalId'
      ) {
        // eslint-disable-next-line no-param-reassign
        result[camelCase(key)] = value ? value.toString() : value;
        return;
      }

      result[camelCase(key)] = unserialize(value); // eslint-disable-line no-param-reassign
    },
    {}
  );
}

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  responseType: 'json',
  timeout: 80000,
  transformRequest: [
    (data) => {
      if (data instanceof FormData) {
        return data;
      }

      return JSON.stringify(serialize(data));
    }
  ],
  transformResponse: [
    (body) => {
      if (!body || typeof body !== 'object') {
        return body;
      }

      if (Object.prototype.hasOwnProperty.call(body, 'page_number')) {
        return {
          pagination: {
            totalPages: body.total_pages,
            pageSize: body.page_size,
            pageNumber: body.page_number,
            filteredResults: body.filtered_results
          },
          data: unserialize(body.data)
        };
      }

      if (Object.prototype.hasOwnProperty.call(body, 'data')) {
        return unserialize(body.data);
      }

      return unserialize(body);
    }
  ],
  validateStatus: (status) => status >= 200 && status < 300
});
