import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { setRequested } from '../../../redux/ducks/recoveryPassword';

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SuccessMessage = () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(setRequested(false)), [dispatch]);

  return (
    <Wrapper>
      <Message success>
        <Message.Header>
          Sucesso!
        </Message.Header>
        <p>
          O email para criar a nova senha foi enviado para a sua caixa de
          mensagens. Você já pode fechar esta janela.
        </p>
      </Message>
    </Wrapper>
  );
};

export default SuccessMessage;
