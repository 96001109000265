import React from 'react';

import SidebarBlockedCard from '../../_new/_freemium/SidebarBlockedCard';
import { useAuth } from '../../../containers/AuthContext/AuthContext';
import LastDocuments from '../LastDocuments';
import StorageInfo from '../StorageInfo';
import { Wrapper } from './styles';

const Sidebar = () => {
  const { trialIsOver, isFreemium } = useAuth();
  const sidebarBlocked = trialIsOver || isFreemium;

  return (
    <Wrapper>
      {sidebarBlocked ? <SidebarBlockedCard /> : (
        <>
          <LastDocuments />
          <StorageInfo />
        </>
      )}
    </Wrapper>
  );
};

export default Sidebar;
