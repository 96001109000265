import React from 'react';

const Vector = () => (
  <svg width="100%" height="100%" viewBox="0 0 375 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M375 245.55H0V245.738H375V245.55Z" fill="#EBEBEB" />
    <path d="M337.425 257.617H312.585V257.805H337.425V257.617Z" fill="#EBEBEB" />
    <path d="M303.143 257.617H250.035V257.805H303.143V257.617Z" fill="#EBEBEB" />
    <path d="M220.748 250.657H206.355V250.845H220.748V250.657Z" fill="#EBEBEB" />
    <path d="M65.0622 251.917H39.3447V252.105H65.0622V251.917Z" fill="#EBEBEB" />
    <path d="M118.5 251.917H71.7373V252.105H118.5V251.917Z" fill="#EBEBEB" />
    <path d="M168.855 255.083H141.247V255.27H168.855V255.083Z" fill="#EBEBEB" />
    <path d="M177.75 212.1H32.9322C31.7977 212.098 30.7104 211.646 29.9089 210.843C29.1074 210.04 28.6572 208.952 28.6572 207.818V4.245C28.6671 3.11702 29.1216 2.03848 29.9221 1.24366C30.7225 0.448844 31.8042 0.00193229 32.9322 0H177.75C178.886 0 179.975 0.451191 180.778 1.25432C181.581 2.05744 182.032 3.14671 182.032 4.2825V207.818C182.032 208.953 181.581 210.043 180.778 210.846C179.975 211.649 178.886 212.1 177.75 212.1ZM32.9322 0.150001C31.8475 0.151987 30.8078 0.584299 30.0415 1.35205C29.2751 2.11979 28.8447 3.16024 28.8447 4.245V207.818C28.8447 208.902 29.2751 209.943 30.0415 210.71C30.8078 211.478 31.8475 211.911 32.9322 211.912H177.75C178.835 211.911 179.876 211.478 180.643 210.711C181.411 209.943 181.843 208.903 181.845 207.818V4.245C181.843 3.15955 181.411 2.11912 180.643 1.35159C179.876 0.584055 178.835 0.151983 177.75 0.150001H32.9322Z" fill="#EBEBEB" />
    <path d="M339.982 212.1H195.157C194.022 212.098 192.934 211.646 192.132 210.844C191.329 210.041 190.877 208.953 190.875 207.818V4.245C190.887 3.11633 191.343 2.03781 192.145 1.24319C192.946 0.448581 194.029 0.00191122 195.157 0H339.982C341.109 0.00390337 342.189 0.451687 342.988 1.2463C343.787 2.0409 344.24 3.11831 344.25 4.245V207.818C344.25 208.951 343.801 210.038 343.001 210.84C342.201 211.643 341.116 212.096 339.982 212.1ZM195.157 0.150001C194.072 0.151983 193.032 0.584055 192.264 1.35159C191.497 2.11912 191.064 3.15955 191.062 4.245V207.818C191.064 208.903 191.497 209.943 192.264 210.711C193.032 211.478 194.072 211.911 195.157 211.912H339.982C341.068 211.911 342.108 211.478 342.876 210.711C343.643 209.943 344.076 208.903 344.077 207.818V4.245C344.076 3.15955 343.643 2.11912 342.876 1.35159C342.108 0.584055 341.068 0.151983 339.982 0.150001H195.157Z" fill="#EBEBEB" />
    <path d="M56.8803 103.665L158.655 103.665V15.4875L56.8803 15.4875L56.8803 103.665Z" fill="#E6E6E6" />
    <path d="M54.6078 103.665L156.383 103.665V15.4875L54.6078 15.4875L54.6078 103.665Z" fill="#F5F5F5" />
    <path d="M152.325 100.515V18.6375L58.6727 18.6375V100.515H152.325Z" fill="#FAFAFA" />
    <path d="M114.765 100.515L131.738 18.6375H96.2326L79.2676 100.515H114.765Z" fill="white" />
    <path d="M60.3828 100.523L60.3828 18.645H58.6728L58.6728 100.523H60.3828Z" fill="#E6E6E6" />
    <path d="M56.2871 85.8374L154.702 85.8374V81.6074L56.2871 81.6074V85.8374Z" fill="#F5F5F5" />
    <path d="M153.93 241.088H170.183V170.61H153.93V241.088Z" fill="#F0F0F0" />
    <path d="M47.7978 245.55L167.07 245.55V241.088H47.7978V245.55Z" fill="#F0F0F0" />
    <path d="M153.93 170.603H44.6699V241.08H153.93V170.603Z" fill="#F5F5F5" />
    <path d="M147.547 179.28H51.0596V202.395H147.547V179.28Z" fill="#F0F0F0" />
    <path d="M147.547 209.295H51.0596V232.41H147.547V209.295Z" fill="#F0F0F0" />
    <path d="M73.2973 192.42H125.31C125.729 192.418 126.13 192.251 126.427 191.954C126.723 191.658 126.89 191.257 126.892 190.838C126.89 190.418 126.723 190.016 126.427 189.718C126.131 189.421 125.73 189.251 125.31 189.248H73.2973C72.8776 189.251 72.4762 189.421 72.1801 189.718C71.8839 190.016 71.7168 190.418 71.7148 190.838C71.7168 191.257 71.8842 191.658 72.1805 191.954C72.4769 192.251 72.8782 192.418 73.2973 192.42Z" fill="#F5F5F5" />
    <path d="M73.2973 222.435H125.31C125.729 222.433 126.13 222.266 126.427 221.969C126.723 221.673 126.89 221.272 126.892 220.852C126.892 220.432 126.726 220.029 126.429 219.731C126.133 219.433 125.73 219.264 125.31 219.262H73.2973C72.877 219.264 72.4745 219.433 72.1779 219.731C71.8813 220.029 71.7148 220.432 71.7148 220.852C71.7168 221.272 71.8842 221.673 72.1805 221.969C72.4769 222.266 72.8782 222.433 73.2973 222.435Z" fill="#F5F5F5" />
    <path d="M323.715 61.5749C332.97 59.0249 336.135 42.2324 312.75 35.3774C317.91 52.2674 307.582 65.9999 323.715 61.5749Z" fill="#EBEBEB" />
    <path d="M325.762 68.865C333.06 60.9825 326.16 43.1475 299.88 50.4375C314.745 63.4425 313.05 82.6125 325.762 68.865Z" fill="#E0E0E0" />
    <path d="M318.563 70.89C312.458 64.845 321.203 43.8075 341.363 53.5575C329.94 64.5 329.25 81.4275 318.563 70.89Z" fill="#FAFAFA" />
    <path d="M312.473 82.6651H330.24L331.583 70.6426H311.138L312.473 82.6651Z" fill="#E6E6E6" />
    <path d="M310.935 72.7949H331.785C332.137 72.7949 332.475 72.655 332.724 72.4061C332.973 72.1571 333.112 71.8195 333.112 71.4674C333.112 71.1153 332.973 70.7777 332.724 70.5287C332.475 70.2798 332.137 70.1399 331.785 70.1399H310.935C310.583 70.1399 310.245 70.2798 309.996 70.5287C309.747 70.7777 309.607 71.1153 309.607 71.4674C309.607 71.8195 309.747 72.1571 309.996 72.4061C310.245 72.655 310.583 72.7949 310.935 72.7949Z" fill="#E6E6E6" />
    <path d="M162.983 225.188V204.825H179.94V184.47H196.905V164.108H213.863V143.745H230.828V123.39H247.785V103.028H264.75V82.665H281.715H375V245.55H162.983H146.018V225.188H162.983Z" fill="#FAFAFA" />
    <path d="M256.267 225.188V204.825H273.232V184.47H290.19V164.108H307.155V143.745H324.112V123.39H341.077V103.028H358.035V82.665H375V103.028V245.55H256.267H239.31V225.188H256.267Z" fill="#E0E0E0" />
    <path d="M239.311 225.188H145.021V231.293H239.311V225.188Z" fill="#EBEBEB" />
    <path d="M256.275 204.825H161.985V210.93H256.275V204.825Z" fill="#EBEBEB" />
    <path d="M273.232 184.47H178.942V190.575H273.232V184.47Z" fill="#EBEBEB" />
    <path d="M290.197 164.107H195.907V170.212H290.197V164.107Z" fill="#EBEBEB" />
    <path d="M307.155 143.745H212.865V149.85H307.155V143.745Z" fill="#EBEBEB" />
    <path d="M324.12 123.39H229.83V129.495H324.12V123.39Z" fill="#EBEBEB" />
    <path d="M341.077 103.028H246.787V109.133H341.077V103.028Z" fill="#EBEBEB" />
    <path d="M358.043 82.665H263.753V88.77H358.043V82.665Z" fill="#EBEBEB" />
    <path d="M187.5 279.42C267.811 279.42 332.917 275.619 332.917 270.93C332.917 266.241 267.811 262.44 187.5 262.44C107.188 262.44 42.082 266.241 42.082 270.93C42.082 275.619 107.188 279.42 187.5 279.42Z" fill="#F5F5F5" />
    <path d="M251.026 77.7226C250.891 79.9726 250.628 82.0351 250.336 84.1726C250.043 86.3101 249.646 88.4026 249.196 90.5026C248.319 94.7528 247.051 98.913 245.408 102.93L244.726 104.43L244.561 104.805L244.501 105L244.381 105.24C244.152 105.711 243.862 106.15 243.518 106.545C243.109 107.005 242.622 107.389 242.078 107.678C241.545 107.957 240.966 108.14 240.368 108.218C239.451 108.316 238.523 108.219 237.646 107.933C236.403 107.508 235.23 106.902 234.166 106.133C233.235 105.476 232.346 104.762 231.503 103.995C228.347 101.068 225.506 97.8178 223.028 94.2976L225.511 92.1901C227.011 93.5776 228.511 94.9801 230.011 96.3076C231.502 97.6348 233.059 98.8864 234.676 100.058C235.437 100.602 236.228 101.103 237.046 101.558C237.705 101.957 238.417 102.263 239.161 102.465C239.416 102.518 239.596 102.465 239.513 102.465C239.336 102.485 239.171 102.561 239.041 102.683C238.996 102.683 238.996 102.683 239.041 102.683L239.281 102.06L239.806 100.695C241.107 96.937 242.122 93.0861 242.843 89.1751C243.241 87.2101 243.518 85.2076 243.818 83.2201C244.118 81.2326 244.328 79.1851 244.501 77.2876L251.026 77.7226Z" fill="#FFC3BD" />
    <path d="M246.481 69.4651C243.683 70.1776 241.726 81.6826 241.726 81.6826L249.428 86.9326C249.428 86.9326 256.816 75.3301 254.678 72.2551C252.428 69.0376 250.561 68.4301 246.481 69.4651Z" fill="#407BFF" />
    <path d="M246.481 69.4651C243.683 70.1776 241.726 81.6826 241.726 81.6826L249.428 86.9326C249.428 86.9326 256.816 75.3301 254.678 72.2551C252.428 69.0376 250.561 68.4301 246.481 69.4651Z" fill="#407BFF" />
    <path opacity="0.4" d="M246.481 69.4651C243.683 70.1776 241.726 81.6826 241.726 81.6826L249.428 86.9326C249.428 86.9326 256.816 75.3301 254.678 72.2551C252.428 69.0376 250.561 68.4301 246.481 69.4651Z" fill="black" />
    <path opacity="0.3" d="M246.728 72.0376L244.583 83.6251L248.911 86.5651L246.728 72.0376Z" fill="black" />
    <path d="M225.638 91.7177L222 85.9277L219.923 92.5052C219.923 92.5052 222.923 95.6552 225.645 94.9052L225.638 91.7177Z" fill="#FFC3BD" />
    <path d="M216.6 84.0151L215.16 89.6101L219.893 92.5051L221.97 85.9276L216.6 84.0151Z" fill="#FFC3BD" />
    <path d="M261.601 263.063H254.821L254.363 247.358H261.151L261.601 263.063Z" fill="#FFC3BD" />
    <path d="M321.443 231.563L316.276 235.958L304.448 225.398L309.616 221.01L321.443 231.563Z" fill="#FFC3BD" />
    <path d="M314.858 235.433L319.875 229.688C319.961 229.589 320.08 229.524 320.209 229.506C320.339 229.488 320.471 229.518 320.58 229.59L325.688 232.898C325.814 232.985 325.92 233.098 325.999 233.23C326.078 233.361 326.129 233.508 326.147 233.66C326.165 233.812 326.151 233.967 326.106 234.113C326.06 234.26 325.984 234.395 325.883 234.51C324.098 236.483 323.138 237.345 320.933 239.865C319.583 241.418 317.76 243.833 315.885 245.97C314.01 248.108 311.79 246.383 312.383 245.318C315.015 240.563 314.385 238.898 314.43 236.558C314.437 236.144 314.588 235.746 314.858 235.433Z" fill="#263238" />
    <path d="M254.588 262.283H262.208C262.34 262.281 262.467 262.328 262.566 262.413C262.665 262.499 262.73 262.618 262.748 262.748L263.618 268.748C263.643 268.906 263.632 269.068 263.586 269.222C263.541 269.376 263.462 269.518 263.355 269.637C263.248 269.757 263.116 269.852 262.969 269.914C262.821 269.977 262.661 270.006 262.501 270C259.846 269.955 258.563 269.798 255.211 269.798C253.148 269.798 248.888 270.015 246.038 270.015C243.188 270.015 243.038 267.195 244.231 266.948C249.541 265.8 251.633 264.225 253.426 262.718C253.748 262.437 254.161 262.283 254.588 262.283Z" fill="#263238" />
    <path opacity="0.2" d="M254.37 247.365L254.603 255.458H261.383L261.15 247.365H254.37Z" fill="black" />
    <path opacity="0.2" d="M309.623 221.01L304.448 225.398L310.553 230.843L315.721 226.448L309.623 221.01Z" fill="black" />
    <path d="M246.48 69.4652C246.48 69.4652 243.248 70.6052 249.72 110.4H277.26C276.803 99.1877 276.788 92.2727 282.143 69.2702C278.287 68.4753 274.38 67.959 270.45 67.7252C266.285 67.4285 262.105 67.4285 257.94 67.7252C252.57 68.2502 246.48 69.4652 246.48 69.4652Z" fill="#407BFF" />
    <path opacity="0.4" d="M246.48 69.4652C246.48 69.4652 243.248 70.6052 249.72 110.4H277.26C276.803 99.1877 276.788 92.2727 282.143 69.2702C278.287 68.4753 274.38 67.959 270.45 67.7252C266.285 67.4285 262.105 67.4285 257.94 67.7252C252.57 68.2502 246.48 69.4652 246.48 69.4652Z" fill="black" />
    <path d="M270.451 67.7252C266.286 67.4285 262.105 67.4285 257.941 67.7252C257.266 67.7852 256.576 67.8602 255.893 67.9427C255.611 68.369 255.43 68.8544 255.365 69.3616C255.3 69.8687 255.352 70.384 255.518 70.8677C256.396 73.0502 259.561 73.3052 261.871 73.3052C270.398 73.3052 271.838 68.3252 271.898 68.1152L271.981 67.8152C271.501 67.7852 270.961 67.7477 270.451 67.7252Z" fill="white" />
    <path d="M269.251 53.6477C268.456 57.6677 267.676 65.0402 270.488 67.7252C270.488 67.7252 269.386 71.8127 261.908 71.8127C253.658 71.8127 257.978 67.7327 257.978 67.7327C262.478 66.6602 262.351 63.3302 261.571 60.2327L269.251 53.6477Z" fill="#FFC3BD" />
    <path opacity="0.2" d="M266.078 56.3176L261.533 60.1951C261.729 60.9322 261.859 61.6851 261.923 62.4451C263.633 62.1976 265.988 60.3151 266.176 58.5226C266.281 57.788 266.248 57.0401 266.078 56.3176Z" fill="black" />
    <path d="M264.533 31.7627C263.835 29.4002 263.25 25.6802 267.45 24.0002C265.808 21.9902 265.41 18.2102 267.45 15.9677C269.49 13.7252 272.55 14.5502 272.55 14.5502C272.55 14.5502 273.45 9.06774 280.118 10.0502C286.785 11.0327 283.815 15.4202 283.815 15.4202C283.815 15.4202 292.913 13.6202 293.52 22.1702C294.128 30.7202 288.908 29.6702 288.908 29.6702C288.908 29.6702 292.965 33.0752 290.715 36.8177C288.465 40.5602 283.268 39.2702 283.268 39.2702C283.268 39.2702 280.823 45.8852 275.063 42.1202C269.303 38.3552 264.533 31.7627 264.533 31.7627Z" fill="#263238" />
    <path d="M251.025 36.7502C248.775 39.6602 249.765 42.9527 251.775 44.2502C256.613 47.3402 263.61 34.3802 261.788 32.8277C259.965 31.2752 253.275 33.8327 251.025 36.7502Z" fill="#263238" />
    <path d="M271.343 29.82C268.163 28.5375 264.938 29.88 263.933 31.815C261.48 36.5325 272.618 45.03 275.843 39.135C277.755 35.6325 274.62 31.14 271.343 29.82Z" fill="#407BFF" />
    <path d="M270.001 44.2501C270.233 51.0001 270.473 53.8201 267.421 57.5776C262.838 63.2251 254.521 62.0401 252.286 55.5151C250.276 49.6426 250.238 39.6301 256.531 36.3451C257.914 35.6134 259.461 35.2475 261.026 35.282C262.59 35.3166 264.12 35.7504 265.469 36.5425C266.819 37.3345 267.944 38.4584 268.737 39.8074C269.53 41.1564 269.965 42.6857 270.001 44.2501Z" fill="#FFC3BD" />
    <path d="M268.988 47.7825C265.988 46.665 265.238 43.1625 265.718 41.7825C264.218 41.7225 260.94 41.1825 259.718 39.3225C257.378 40.8225 252.435 42.3225 251.903 39.825C251.37 37.3275 258.825 30.9075 264.465 30.4125C271.17 29.8275 277.785 36.4125 276.083 42.72C274.38 49.0275 268.988 47.7825 268.988 47.7825Z" fill="#263238" />
    <path d="M249.75 110.4C249.75 110.4 244.403 162.638 244.845 183.323C245.31 204.833 253.193 253.89 253.193 253.89H262.358C262.358 253.89 260.91 206.085 261.72 184.965C262.605 161.933 269.918 110.4 269.918 110.4H249.75Z" fill="#263238" />
    <path d="M263.123 254.22H252.368L251.573 250.793L263.626 250.373L263.123 254.22Z" fill="#407BFF" />
    <path opacity="0.2" d="M261.601 123.398C254.851 137.003 260.243 161.828 262.636 171.068C263.858 157.193 265.928 140.235 267.541 127.785C266.521 120.75 264.751 117.12 261.601 123.398Z" fill="black" />
    <path d="M256.995 110.4C256.995 110.4 263.235 164.707 267.945 182.145C273.54 202.845 308.288 230.707 308.288 230.707L315.338 224.707C315.338 224.707 290.153 194.437 285.87 183.532C276.743 160.283 284.558 125.573 277.26 110.385L256.995 110.4Z" fill="#263238" />
    <path d="M316.463 224.4L307.718 231.825L304.448 229.868L314.341 220.665L316.463 224.4Z" fill="#407BFF" />
    <path d="M260.018 47.0701C260.071 47.6176 259.823 48.0826 259.471 48.1201C259.118 48.1576 258.781 47.7376 258.721 47.1976C258.661 46.6576 258.916 46.1776 259.276 46.1476C259.636 46.1176 259.966 46.5001 260.018 47.0701Z" fill="#263238" />
    <path d="M253.868 47.6626C253.921 48.2026 253.673 48.6751 253.313 48.7126C252.953 48.7501 252.631 48.3301 252.563 47.7826C252.496 47.2351 252.758 46.7701 253.111 46.7401C253.463 46.7101 253.816 47.1151 253.868 47.6626Z" fill="#263238" />
    <path d="M253.283 46.755L251.94 46.5C251.94 46.5 252.75 47.46 253.283 46.755Z" fill="#263238" />
    <path d="M255.578 48.4426C255.213 49.7303 254.673 50.9618 253.973 52.1026C254.27 52.2738 254.601 52.3785 254.943 52.4095C255.285 52.4406 255.629 52.3973 255.953 52.2826L255.578 48.4426Z" fill="#ED847E" />
    <path d="M258.975 54.0751C258.678 54.1564 258.375 54.2116 258.068 54.2401C258.047 54.2421 258.026 54.2401 258.006 54.234C257.986 54.228 257.968 54.218 257.952 54.2048C257.936 54.1915 257.923 54.1752 257.913 54.1568C257.903 54.1384 257.897 54.1183 257.895 54.0976C257.891 54.0549 257.904 54.0123 257.93 53.9787C257.957 53.9451 257.995 53.9232 258.038 53.9176C258.717 53.8676 259.374 53.6591 259.958 53.3087C260.542 52.9582 261.035 52.4758 261.398 51.9001C261.406 51.8802 261.419 51.8624 261.435 51.848C261.451 51.8335 261.47 51.8227 261.491 51.8163C261.512 51.8099 261.534 51.808 261.555 51.8108C261.576 51.8136 261.597 51.8211 261.615 51.8326C261.652 51.8532 261.68 51.8873 261.693 51.9278C261.705 51.9683 261.702 52.0121 261.683 52.0501C261.078 53.0513 260.107 53.7777 258.975 54.0751Z" fill="#263238" />
    <path d="M271.576 48.9076C271.411 50.1983 270.743 51.3713 269.716 52.1701C268.343 53.2201 267.023 52.1701 266.866 50.5276C266.731 49.0276 267.271 46.7776 268.906 46.3426C269.262 46.27 269.631 46.285 269.981 46.3864C270.331 46.4878 270.651 46.6725 270.913 46.9248C271.176 47.177 271.373 47.4893 271.489 47.8346C271.604 48.18 271.634 48.5482 271.576 48.9076Z" fill="#FFC3BD" />
    <path d="M260.678 43.2152C260.636 43.2257 260.593 43.2279 260.55 43.2214C260.508 43.215 260.467 43.2001 260.43 43.1777C260.09 42.9607 259.704 42.8272 259.302 42.788C258.901 42.7489 258.496 42.8054 258.12 42.9527C258.042 42.9873 257.954 42.9911 257.873 42.9633C257.792 42.9355 257.725 42.8781 257.685 42.8027C257.649 42.726 257.644 42.6382 257.672 42.5581C257.701 42.4781 257.759 42.4124 257.835 42.3752C258.307 42.1801 258.82 42.1015 259.329 42.1458C259.838 42.1902 260.329 42.3563 260.76 42.6302C260.796 42.6517 260.827 42.6801 260.851 42.7137C260.876 42.7472 260.894 42.7853 260.903 42.8258C260.913 42.8662 260.915 42.9082 260.908 42.9493C260.902 42.9904 260.887 43.0298 260.865 43.0652C260.824 43.1369 260.757 43.1905 260.678 43.2152Z" fill="#263238" />
    <path d="M250.853 45.0827C250.776 45.1121 250.69 45.1121 250.613 45.0827C250.54 45.0402 250.486 44.9715 250.463 44.8906C250.439 44.8097 250.447 44.7228 250.485 44.6477C250.714 44.1912 251.05 43.7967 251.464 43.4977C251.878 43.1987 252.358 43.004 252.863 42.9302C252.905 42.9261 252.947 42.9305 252.987 42.943C253.028 42.9556 253.065 42.9762 253.097 43.0034C253.129 43.0307 253.156 43.0642 253.175 43.1019C253.194 43.1395 253.205 43.1806 253.208 43.2227C253.214 43.3071 253.187 43.3906 253.132 43.4551C253.077 43.5196 253 43.5601 252.915 43.5677C252.517 43.6346 252.141 43.7945 251.816 44.0343C251.492 44.2741 251.229 44.587 251.048 44.9477C251.027 44.9826 250.998 45.0126 250.965 45.0359C250.931 45.0591 250.893 45.0751 250.853 45.0827Z" fill="#263238" />
    <path d="M284.956 76.5825C286.485 80.3236 287.805 84.147 288.908 88.035C290.078 91.9569 290.911 95.9715 291.398 100.035C291.526 101.07 291.563 102.158 291.608 103.23V103.838C291.608 104.055 291.571 104.28 291.541 104.475C291.478 104.85 291.393 105.221 291.286 105.585C291.09 106.209 290.839 106.814 290.536 107.393C289.496 109.315 288.221 111.102 286.741 112.71C284.003 115.717 280.99 118.462 277.741 120.908L275.573 118.5C278.071 115.583 280.591 112.59 282.728 109.5C283.761 108.098 284.641 106.589 285.353 105C285.475 104.692 285.576 104.377 285.653 104.055C285.674 103.946 285.686 103.836 285.691 103.725C285.695 103.693 285.695 103.66 285.691 103.628V103.523C285.593 102.638 285.526 101.753 285.361 100.845C284.715 97.1401 283.816 93.4839 282.668 89.9025C281.521 86.2725 280.238 82.59 278.918 79.08L284.956 76.5825Z" fill="#FFC3BD" />
    <path d="M282.143 69.2701C284.903 70.1101 288.218 79.6126 288.218 79.6126L277.5 87.2626C277.5 87.2626 273.593 79.3351 274.755 75.7651C276 72.0526 279 68.3101 282.143 69.2701Z" fill="#407BFF" />
    <path opacity="0.4" d="M282.143 69.2701C284.903 70.1101 288.218 79.6126 288.218 79.6126L277.5 87.2626C277.5 87.2626 273.593 79.3351 274.755 75.7651C276 72.0526 279 68.3101 282.143 69.2701Z" fill="black" />
    <path d="M313.006 238.703C312.867 238.699 312.731 238.664 312.608 238.6C312.484 238.537 312.377 238.446 312.293 238.335C312.225 238.265 312.177 238.178 312.153 238.083C312.13 237.988 312.131 237.889 312.158 237.795C312.413 236.97 314.768 236.295 315.038 236.22C315.068 236.209 315.1 236.209 315.13 236.218C315.16 236.228 315.185 236.247 315.203 236.273C315.22 236.298 315.229 236.328 315.229 236.359C315.229 236.389 315.22 236.419 315.203 236.445C314.783 237.248 313.951 238.598 313.096 238.695L313.006 238.703ZM314.746 236.64C313.816 236.94 312.601 237.458 312.496 237.893C312.485 237.934 312.485 237.977 312.497 238.018C312.509 238.059 312.532 238.096 312.563 238.125C312.623 238.211 312.705 238.28 312.799 238.325C312.894 238.369 312.999 238.388 313.103 238.38C313.538 238.328 314.146 237.683 314.746 236.64Z" fill="#407BFF" />
    <path d="M314.003 236.67C313.301 236.742 312.599 236.543 312.038 236.115C311.96 236.016 311.917 235.893 311.917 235.766C311.917 235.64 311.96 235.517 312.038 235.418C312.105 235.314 312.194 235.226 312.299 235.161C312.404 235.096 312.523 235.056 312.646 235.043C313.621 234.923 315.098 236.198 315.158 236.25C315.18 236.268 315.197 236.291 315.206 236.318C315.215 236.344 315.217 236.373 315.211 236.4C315.208 236.429 315.196 236.457 315.177 236.48C315.159 236.502 315.134 236.519 315.106 236.528C314.747 236.63 314.376 236.678 314.003 236.67ZM312.811 235.358H312.706C312.629 235.366 312.555 235.392 312.489 235.432C312.423 235.472 312.366 235.526 312.323 235.59C312.211 235.763 312.248 235.853 312.323 235.913C312.616 236.295 313.951 236.438 314.761 236.288C314.206 235.808 313.533 235.486 312.811 235.358Z" fill="#407BFF" />
    <path d="M252.038 263.468C251.459 263.52 250.879 263.372 250.396 263.048C250.292 262.953 250.212 262.837 250.162 262.707C250.111 262.577 250.091 262.437 250.103 262.298C250.109 262.209 250.138 262.123 250.186 262.048C250.235 261.973 250.302 261.912 250.381 261.87C251.213 261.413 253.913 262.793 254.221 262.958C254.249 262.974 254.272 262.998 254.287 263.027C254.302 263.057 254.307 263.09 254.303 263.123C254.299 263.156 254.284 263.187 254.261 263.211C254.238 263.235 254.208 263.251 254.176 263.258C253.471 263.393 252.756 263.463 252.038 263.468ZM250.831 262.065C250.727 262.058 250.624 262.079 250.531 262.125C250.498 262.141 250.472 262.165 250.453 262.196C250.434 262.227 250.425 262.262 250.426 262.298C250.413 262.391 250.424 262.487 250.457 262.576C250.489 262.664 250.543 262.744 250.613 262.808C251.026 263.183 252.113 263.243 253.613 262.995C252.745 262.529 251.804 262.214 250.831 262.065Z" fill="#407BFF" />
    <path d="M254.146 263.25H254.078C253.268 262.883 251.671 261.42 251.828 260.655C251.828 260.475 251.986 260.243 252.428 260.198C252.588 260.181 252.75 260.197 252.903 260.245C253.056 260.292 253.199 260.371 253.321 260.475C254.168 261.188 254.326 263.01 254.333 263.093C254.335 263.12 254.33 263.148 254.319 263.173C254.307 263.198 254.289 263.219 254.266 263.235C254.248 263.246 254.228 263.253 254.207 263.256C254.187 263.259 254.166 263.257 254.146 263.25ZM252.526 260.498H252.436C252.136 260.498 252.121 260.648 252.113 260.685C252.038 261.143 253.126 262.298 253.951 262.793C253.883 262.022 253.58 261.291 253.081 260.7C252.929 260.561 252.732 260.481 252.526 260.475V260.498Z" fill="#407BFF" />
    <path d="M277.674 108.75L278.379 111.218C278.476 111.405 278.251 111.6 277.929 111.6H249.586C249.339 111.6 249.129 111.48 249.114 111.323L248.866 108.863C248.866 108.69 249.061 108.548 249.339 108.548H277.231C277.316 108.54 277.402 108.555 277.479 108.591C277.557 108.626 277.624 108.681 277.674 108.75Z" fill="#407BFF" />
    <path opacity="0.3" d="M277.674 108.75L278.379 111.218C278.476 111.405 278.251 111.6 277.929 111.6H249.586C249.339 111.6 249.129 111.48 249.114 111.323L248.866 108.863C248.866 108.69 249.061 108.548 249.339 108.548H277.231C277.316 108.54 277.402 108.555 277.479 108.591C277.557 108.626 277.624 108.681 277.674 108.75Z" fill="white" />
    <path d="M273.931 111.9H274.681C274.823 111.9 274.936 111.825 274.928 111.728L274.576 108.525C274.576 108.435 274.441 108.353 274.291 108.353H273.541C273.391 108.353 273.278 108.435 273.293 108.525L273.638 111.728C273.653 111.825 273.751 111.9 273.931 111.9Z" fill="#263238" />
    <path d="M251.686 111.9H252.436C252.586 111.9 252.698 111.825 252.691 111.728L252.338 108.525C252.338 108.435 252.196 108.353 252.053 108.353H251.303C251.153 108.353 251.041 108.435 251.048 108.525L251.401 111.728C251.408 111.825 251.536 111.9 251.686 111.9Z" fill="#263238" />
    <path d="M262.808 111.9H263.558C263.708 111.9 263.821 111.825 263.813 111.728L263.461 108.525C263.461 108.435 263.326 108.353 263.176 108.353H262.426C262.276 108.353 262.163 108.435 262.178 108.525L262.501 111.75C262.501 111.825 262.658 111.9 262.808 111.9Z" fill="#263238" />
    <path d="M259.44 46.1627L258.098 45.9077C258.098 45.9077 258.863 46.8677 259.44 46.1627Z" fill="#263238" />
    <path opacity="0.2" d="M234.615 141.668C221.909 146.604 208.024 147.662 194.716 144.707C181.408 141.752 169.275 134.918 159.852 125.068C150.428 115.218 144.137 102.794 141.774 89.3691C139.411 75.9436 141.082 62.119 146.576 49.6432C152.069 37.1674 161.139 26.6009 172.638 19.2797C184.137 11.9585 197.549 8.21144 211.177 8.51241C224.806 8.81339 238.039 13.1488 249.204 20.9705C260.368 28.7922 268.963 39.7489 273.9 52.4551C277.18 60.8922 278.765 69.8928 278.566 78.9428C278.367 87.9927 276.386 96.9148 272.738 105.199C269.09 113.484 263.846 120.968 257.304 127.226C250.763 133.483 243.053 138.39 234.615 141.668Z" fill="#407BFF" />
    <path opacity="0.1" d="M222.143 9.63767L168.083 132.413C167.25 131.783 166.425 131.13 165.615 130.47C160.39 126.148 155.84 121.069 152.115 115.403L198.81 9.35267C206.546 8.10667 214.439 8.20308 222.143 9.63767Z" fill="white" />
    <path opacity="0.1" d="M260.573 30.9377L209.746 146.37C199.188 146.381 188.77 143.964 179.296 139.305L234.796 13.2527C244.628 17.098 253.447 23.1482 260.573 30.9377Z" fill="white" />
    <path d="M152.911 30.9901C162.095 19.7679 174.401 11.5212 188.272 7.29273C202.143 3.06427 216.956 3.04401 230.839 7.23453C244.721 11.425 257.05 19.6381 266.265 30.8351C275.479 42.0322 281.167 55.7103 282.608 70.1398C284.049 84.5693 281.179 99.1021 274.361 111.9C267.543 124.699 257.082 135.188 244.303 142.041C231.523 148.895 216.998 151.805 202.565 150.403C188.132 149.002 174.438 143.352 163.216 134.168C148.168 121.851 138.63 104.062 136.697 84.7128C134.765 65.3639 140.597 46.0396 152.911 30.9901ZM261.751 120.06C270.181 109.755 275.366 97.1814 276.651 83.9294C277.936 70.6773 275.262 57.3419 268.968 45.6094C262.674 33.8768 253.042 24.2741 241.291 18.0154C229.539 11.7568 216.196 9.12324 202.948 10.4478C189.699 11.7724 177.142 16.9957 166.862 25.4571C156.582 33.9186 149.042 45.2382 145.196 57.9846C141.349 70.731 141.369 84.3318 145.252 97.0672C149.135 109.803 156.707 121.101 167.011 129.533C173.853 135.132 181.731 139.329 190.196 141.883C198.66 144.438 207.545 145.299 216.343 144.42C225.14 143.54 233.678 140.936 241.47 136.757C249.261 132.577 256.152 126.903 261.751 120.06Z" fill="#407BFF" />
    <path d="M268.223 119.805C275.446 124.118 282.316 128.858 289.066 133.748C295.816 138.638 302.393 143.73 308.866 148.958C315.338 154.185 321.691 159.563 327.818 165.21C330.888 168.03 333.888 170.925 336.818 173.895C339.758 176.895 342.646 179.895 345.368 183.15C345.997 183.904 346.344 184.853 346.349 185.835C346.354 186.816 346.017 187.769 345.395 188.529C344.774 189.289 343.907 189.808 342.944 189.998C341.981 190.188 340.982 190.037 340.118 189.57C336.368 187.545 332.858 185.31 329.356 183.023C325.853 180.735 322.433 178.35 319.058 175.898C312.308 171.008 305.776 165.855 299.378 160.538C292.981 155.22 286.681 149.783 280.553 144.128C274.426 138.473 268.418 132.683 262.763 126.458C262.057 125.688 261.652 124.689 261.623 123.645C261.593 122.6 261.941 121.58 262.603 120.772C263.265 119.964 264.196 119.421 265.225 119.243C266.255 119.066 267.314 119.265 268.208 119.805H268.223Z" fill="#407BFF" />
    <path d="M276.293 117.75L269.048 118.853L274.23 123.397C274.23 123.397 278.325 121.898 278.73 119.123L276.293 117.75Z" fill="#FFC3BD" />
    <path d="M265.133 123.015L269.685 126.585L275.243 122.955L269.048 118.868L265.133 123.015Z" fill="#FFC3BD" />
    <path d="M230.88 130.913C231.542 129.106 232.735 127.542 234.302 126.427C235.87 125.312 237.739 124.698 239.662 124.665H299.7C300.737 124.626 301.771 124.816 302.727 125.22C303.683 125.624 304.539 126.233 305.234 127.004C305.929 127.775 306.447 128.689 306.75 129.682C307.054 130.675 307.135 131.722 306.99 132.75L291.48 262.883C291.151 265.098 290.044 267.124 288.358 268.598C286.672 270.073 284.517 270.899 282.277 270.93H91.7774C90.7434 270.969 89.7135 270.782 88.7599 270.38C87.8063 269.979 86.9524 269.373 86.2581 268.606C85.5639 267.839 85.0462 266.929 84.7416 265.94C84.4369 264.951 84.3527 263.907 84.4949 262.883L97.4999 153.548C97.829 151.332 98.9355 149.306 100.621 147.832C102.307 146.357 104.463 145.531 106.702 145.5H210.367C214.859 145.424 219.223 143.989 222.884 141.385C226.546 138.782 229.333 135.131 230.88 130.913Z" fill="#407BFF" />
    <path d="M87.9229 264.937H286.98L298.32 131.002H99.2703L87.9229 264.937Z" fill="#F0F0F0" />
    <path d="M87.9229 264.937H286.98L295.185 131.002H99.2703L87.9229 264.937Z" fill="#E0E0E0" />
    <path d="M87.9229 264.937H286.98L293.82 126.75H94.7703L87.9229 264.937Z" fill="#F0F0F0" />
    <path d="M87.9229 264.937H286.98L289.688 126.75H94.7703L87.9229 264.937Z" fill="#E0E0E0" />
    <path d="M87.9229 264.937H286.98L288.165 123H89.1153L87.9229 264.937Z" fill="#F0F0F0" />
    <path d="M87.9225 264.938H286.98L282.893 125.685H85.125L87.9225 264.938Z" fill="#E0E0E0" />
    <path d="M87.923 264.937H286.981L281.071 126.75H82.0205L87.923 264.937Z" fill="#F0F0F0" />
    <path d="M87.9226 264.937H286.98L277.568 128.835H78.5176L87.9226 264.937Z" fill="#E0E0E0" />
    <path d="M87.9226 264.938H286.98L276.068 129.998H77.0176L87.9226 264.938Z" fill="#F0F0F0" />
    <path d="M187.605 139.365C187.616 138.495 187.802 137.636 188.152 136.839C188.502 136.042 189.009 135.324 189.642 134.727C190.276 134.13 191.023 133.667 191.839 133.364C192.655 133.062 193.523 132.927 194.393 132.967H254.43C256.755 133.057 258.984 133.915 260.767 135.408C262.551 136.902 263.788 138.945 264.285 141.217L288.008 262.717C288.893 267.27 285.923 270.967 281.37 270.967H90.8702C86.3102 270.967 81.8702 267.27 81.0077 262.717L61.4402 162.555C60.5552 158.002 63.5252 154.305 68.0777 154.305H171.75C180.75 154.305 187.298 148.11 187.605 139.365Z" fill="#407BFF" />
    <path opacity="0.2" d="M187.605 139.365C187.616 138.495 187.802 137.636 188.152 136.839C188.502 136.042 189.009 135.324 189.642 134.727C190.276 134.13 191.023 133.667 191.839 133.364C192.655 133.062 193.523 132.927 194.393 132.967H254.43C256.755 133.057 258.984 133.915 260.767 135.408C262.551 136.902 263.788 138.945 264.285 141.217L288.008 262.717C288.893 267.27 285.923 270.967 281.37 270.967H90.8702C86.3102 270.967 81.8702 267.27 81.0077 262.717L61.4402 162.555C60.5552 158.002 63.5252 154.305 68.0777 154.305H171.75C180.75 154.305 187.298 148.11 187.605 139.365Z" fill="black" />
  </svg>
);

export default Vector;
