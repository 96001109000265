import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Number = ({
  document
}) => (
  <ShortenedField clamp={2}>
    {document.number}
  </ShortenedField>
);

Number.propTypes = {
  document: PropTypes.shape({
    number: PropTypes.string
  }).isRequired
};

export default Number;
