import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div`
  width: 32rem;
  height: auto;
  background-color: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    width: 18rem;
    height: auto;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 5.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0D0440;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  img {
    width: 11.39175rem;
    height: 3.375rem;
    flex-shrink: 0;
  }
`;

export const ModalMessage = styled.div`
  padding: 0 1rem;
  margin-top: 1.5rem;
  text-align: center;
  
  h3, p {
    font-family: Poppins!important;
    font-style: normal;
    color: #0D0440;
  }

  .title {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.75rem;
  }

  .text, .text p {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01563rem;
  }

  .text, .text b {
    font-size: 0.875rem;
  }
`;

export const ModalButtons = styled.div`
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;    
  width: 100%;
  margin-top: 1.5rem;

  .button {
    padding: 0.62rem 1.5rem!important;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    box-shadow: none;
    
    > span {
      font-size: 0.875rem;
      font-family: Poppins!important;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }

  .button:hover {
    box-shadow: none;
  }

  .top-button {
    margin-bottom: 1rem;
    background: #0251DE!important;
    color: #ffffff!important;
  }

  .bottom-button {
    margin-bottom: 1rem;
    background: #ffffff!important;
    color: #0251DE!important;
  }
`;
