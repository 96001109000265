import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import {
  Tabs, Tab, ActionButton, HeaderActions
} from '../../../DocumentsList/Header/styles';
import SearchInput from '../../../SearchInput';
import Popover from '../../Popover';
import FiltersBlockedCard from '../FiltersBlockedCard';
import SearchBlockedModal from '../SearchBlockedModal';
import TrashBlockedCard from '../TrashBlockedCard';
import * as S from './styles';

const DocumentsHeader = () => {
  const history = useHistory();
  const { counters } = useSelector((state) => state.documents);

  const [openSearchModal, setOpenSearchModal] = useState(false);

  return (
    <>
      <Tabs>
        <NavLink
          exact
          to="#"
        >
          <Tab active>
            Documentos ({counters.waitSign})
          </Tab>
        </NavLink>

        <Popover
          trigger={(
            <Tab>
              Lixeira ({counters.trash})
            </Tab>
        )}
          direction="left"
        >
          <TrashBlockedCard />
        </Popover>

        <ActionButton
          unpadded
          primary
          onClick={() => history.push('/')}
        >
          Novo Documento
        </ActionButton>
      </Tabs>
      <HeaderActions>
        <S.SearchButton type="button" onClick={() => setOpenSearchModal(true)}>
          <SearchInput
            onSearch={() => setOpenSearchModal(true)}
          />
        </S.SearchButton>

        <Popover
          trigger={(
            <Dropdown
              text="Filtrar"
              icon="filter"
              labeled
              button
              closeOnEscape={false}
              closeOnBlur={false}
              className="icon"
            />
        )}
          direction="right"
        >
          <FiltersBlockedCard />
        </Popover>
      </HeaderActions>

      <SearchBlockedModal open={openSearchModal} onClose={() => setOpenSearchModal(false)} />
    </>
  );
};

export default DocumentsHeader;
