/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import React, {
  createContext, useCallback, useState, useLayoutEffect
} from 'react';
import { useParams } from 'react-router-dom';

import { DocumentHTTP } from '../../services/api/adapters/documents';

export const DocumentContext = createContext({});

export function DocumentProvider({ children }) {
  const { id } = useParams();

  const [documentData, setDocumentData] = useState({
    signatureLoading: false,
    loading: true,
    data: null
  });

  const getDocument = useCallback(async (documentId) => {
    if (!documentData.loading) {
      setDocumentData((prev) => ({
        ...prev,
        loading: true
      }));
    }

    const { data } = await DocumentHTTP.getById(documentId);

    setDocumentData({
      loading: false,
      data
    });
  }, [documentData]);

  const sendSignature = useCallback(async () => {
    try {
      setDocumentData((prev) => ({
        ...prev,
        signatureLoading: true
      }));

      const { data } = await DocumentHTTP.sendSignature(id);

      setDocumentData({
        loading: false,
        signatureLoading: false,
        data
      });
    } catch {
      setDocumentData((prev) => ({
        ...prev,
        signatureLoading: false
      }));
      throw new Error();
    }
  }, [id]);

  useLayoutEffect(() => {
    if (id) getDocument(id);
  // eslint-disable-next-line
  }, [id]);

  return (
    <DocumentContext.Provider
      value={{
        getDocument,
        sendSignature,
        documentData
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
}

DocumentProvider.propTypes = {
  children: PropTypes.node.isRequired
};
