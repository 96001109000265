/* eslint-disable import/prefer-default-export */
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.theme.starterWidth};
  min-height: ${(props) => props.theme.starterHeight};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.fontColor};
  box-shadow: 0 0 16px rgba(152, 152, 152, 0.2);
  background-color: ${(props) => props.theme.starterBackground};

  &:focus {
    outline: none;
  }

  > i {
    opacity: 0.25;
  }

  > h3 {
    font-weight: 600;
    margin-top: 0.5em;
  }

  > p.or {
    margin: 0.5em 0 1em 0;
    font-size: 14px;
  }

  > p.footer {
    font-size: 14px;
    margin-top: 1em;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ warning }) => (warning ? '0.5em' : '1em')};

  > .ui.input, .ui.dropdown {
    width: 320px;
    margin-right: 1em;
  }

  > .ui.dropdown {
    background: #fff;
    border: 1px solid #E0E6ED !important;

    > .dropdown.icon {
      display: none;
    }
  }

  > button {
    color: ${(props) => props.theme.fontColor};
    width: 40px;
  }
`;

const StyledForm = styled(Form)`
  padding: 0 1.4em 1em 1.4em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div > small {
    font-weight: 600;
    color: ${({ theme }) => theme.errorColor};
    margin-bottom: 0.5em;
  }
`;

export const AddPersonWrapper = styled.span`
  margin-bottom: 0.5rem;

  > button {
    display: flex;
    align-items: center;
    cursor: ${({ off }) => (off ? 'not-allowed' : 'pointer')};
    
    > svg {
      margin-right: 0.3rem;
    }
  }
`;

export { Wrapper, StyledForm, FormGroup };
