import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setRequested, toggleLoading, errorResponse } from '../../redux/ducks/recoveryPassword';
import api from '../../utils/backend';
import Button from '../Button';
import FieldErrorMessage from '../FieldErrorMessage';
import InputWithRef from '../InputWithRef';
import { StyledForm, Title, Description } from './styles';

const RecoveryPasswordForm = ({ token }) => {
  const dispatch = useDispatch();
  const {
    register, handleSubmit, formState: { errors }, watch
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data) => {
    const { password, passwordConfirmation } = data;
    dispatch(toggleLoading(true));
    await api.post('/auth/reset_password', {
      reset_token: token,
      password,
      passwordConfirmation
    }).then(() => {
      dispatch(toggleLoading(false));
      dispatch(setRequested(true));
    }).catch((error) => {
      dispatch(errorResponse(error.response));
    });
  };

  const password = watch('password');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Title>
        Redefinir Senha
      </Title>
      <Description>
        Informe uma nova senha para ter acesso ao sistema novamente:
      </Description>

      <InputWithRef
        type="password"
        id="password"
        label="Alterar Senha"
        {...register('password', {
          minLength: { value: 8, message: <FieldErrorMessage message="Sua senha deve ter no mínimo 8 caracteres" /> }
        })}
      >
        {errors?.password && errors?.password.message}
      </InputWithRef>

      <InputWithRef
        type="password"
        id="passwordConfirmation"
        label="Confirmar nova senha"
        {...register('passwordConfirmation', {
          validate: { isEqual: (value) => value === password },
          minLength: { value: 8, message: <FieldErrorMessage message="Sua senha deve ter no mínimo 8 caracteres" /> }
        })}
      >
        {errors?.passwordConfirmation && errors?.passwordConfirmation.type === 'isEqual' && (
          <FieldErrorMessage message="As senhas não conferem" />
        )}
        {errors?.passwordConfirmation && errors?.passwordConfirmation.message}
      </InputWithRef>

      <Button
        type="submit"
        primary
        fluid
      >
        Recuperar Senha
      </Button>
    </StyledForm>
  );
};

RecoveryPasswordForm.propTypes = {
  token: PropTypes.string.isRequired
};

export default RecoveryPasswordForm;
