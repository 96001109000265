/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 1em;
  background: #e5f9e5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  * {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
  }
  a {
    font-weight: bold;
    font-feature-settings: 'kern' off;
    color: #297F2D;
  }
`;

export { Wrapper };
