import PropTypes from 'prop-types';
import React from 'react';
import DotDotDot from 'react-dotdotdot';
import styled from 'styled-components';

const ShortenedField = styled(DotDotDot)`
  word-break: break-all;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Name = ({ signer }) => (
  <Wrapper>
    <ShortenedField clamp={1}>
      {signer.name}
    </ShortenedField>
  </Wrapper>
);

Name.propTypes = {
  signer: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
};

export default Name;
