import styled from 'styled-components';

const getAvatarSize = (size, theme) => {
  if (Object.prototype.hasOwnProperty.call(theme.avatarSizes, size)) {
    return theme.avatarSizes[size];
  }

  return theme.avatarSizes.medium;
};

export const StackList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 0;
  display: inline-flex;
`;

export const StackItem = styled.li`
  display: inline-block;
  margin: 0;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 1em;
  transition: transform .1s linear;
  margin-left: calc(${(props) => getAvatarSize(props.size, props.theme)} / 3 * -1);

  &:hover {
    z-index: 1;
    transform: scale(1.15);
  }

  color: ${(props) => props.theme.headerColor};

  &:first-child{
    margin: 0;
  }
`;

export const CollapsedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const CollapsedItem = styled.li`
`;
