// Action types

export const SET_DOCUMENT_BY_TOKEN = '@contraktor/document/SET_DOCUMENT_BY_TOKEN';

// Reducer

export const INITIAL_STATE = {
  id: null,
  confirmedAt: null,
  confirmedIpAddress: null,
  confirmedUserAgent: null,
  document: {},
  email: '',
  name: ''
};

export default function documentByToken(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DOCUMENT_BY_TOKEN:
      return action.payload;

    default:
      return state;
  }
}

// Actions

export function setDocumentByToken(payload) {
  return {
    type: SET_DOCUMENT_BY_TOKEN,
    payload
  };
}
