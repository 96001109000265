import amplitude from 'amplitude-js';
import PropTypes from 'prop-types';
import React, { useContext, createContext, useCallback } from 'react';

const instance = amplitude.getInstance();
instance.init(process.env.REACT_APP_AMPLITUDE_TOKEN);

const AmplitudeContext = createContext({});

const AmplitudeProvider = ({ children }) => {
  const identifyPeople = useCallback((user, fromLogin = true) => {
    instance.setUserId(user.id);

    if (fromLogin) {
      instance.setUserProperties({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        user_id: user.id,
        // status: user.subscription.suspended,
        organization_id: user.organizationId,
        organization_name: user.organization.name,
        last_login: new Date().toISOString()
      });
    } else {
      instance.setUserProperties({
        first_name: user.name,
        email: user.email,
        last_access: new Date().toISOString()
      });
    }
  }, []);

  return (
    <AmplitudeContext.Provider value={{
      amplitudeIdentify: identifyPeople, Amplitude: instance
    }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};

function useAmplitude() {
  const context = useContext(AmplitudeContext);

  if (!context) {
    throw new Error('useAmplitude must be used within an AmplitudeProvider');
  }

  return context;
}

AmplitudeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AmplitudeProvider, useAmplitude };
