// Semantic UI theme
import 'semantic-ui-css/semantic.min.css';

import { ContraktorProvider } from '@contraktor-tech/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './_legacy/App';
import configureStore from './_legacy/redux';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ContraktorProvider theme="default">
        <App />
      </ContraktorProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
