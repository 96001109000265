import PropTypes from 'prop-types';
import React from 'react';

import { useChoosePlan } from '../../../containers/NewPaymentPage/contexts/ChoosePlanContext';
import Button from '../../Button';
import DialogModal from '../../DialogModal';

const SuccessIcon = () => (
  <svg width="95" height="102" viewBox="0 0 95 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.4861 22.9248H68.2861C69.3061 22.9248 69.9861 23.6115 69.9861 24.6415C69.9861 25.6715 69.3061 26.3581 68.2861 26.3581H44.4861C43.4661 26.3581 42.7861 25.6715 42.7861 24.6415C42.7861 23.6115 43.6361 22.9248 44.4861 22.9248Z" fill="#908F8F" />
    <path d="M44.4861 31.5083H68.2861C69.3061 31.5083 69.9861 32.195 69.9861 33.225C69.9861 34.255 69.3061 34.9416 68.2861 34.9416H44.4861C43.4661 34.9416 42.7861 34.255 42.7861 33.225C42.7861 32.195 43.6361 31.5083 44.4861 31.5083Z" fill="#908F8F" />
    <path d="M10.4861 45.2412H35.9861C37.0061 45.2412 37.6861 45.9279 37.6861 46.9579C37.6861 47.9879 37.0061 48.6745 35.9861 48.6745H10.4861C9.46613 48.6745 8.78613 47.9879 8.78613 46.9579C8.78613 45.9279 9.63613 45.2412 10.4861 45.2412Z" fill="#908F8F" />
    <path d="M10.4861 53.8247H29.1861C30.2061 53.8247 30.8861 54.5114 30.8861 55.5414C30.8861 56.5714 30.2061 57.258 29.1861 57.258H10.4861C9.46613 57.258 8.78613 56.5714 8.78613 55.5414C8.78613 54.5114 9.63613 53.8247 10.4861 53.8247Z" fill="#908F8F" />
    <path d="M35.9861 17.7749H10.4861C9.46613 17.7749 8.78613 18.6332 8.78613 19.4916V36.6582C8.78613 37.6882 9.63613 38.3749 10.4861 38.3749H35.9861C37.0061 38.3749 37.6861 37.5166 37.6861 36.6582V19.4916C37.6861 18.4616 37.0061 17.7749 35.9861 17.7749ZM34.2861 34.9416H12.1861V21.2082H34.2861V34.9416Z" fill="#908F8F" />
    <path d="M78.4861 12.6249H83.7561L83.4161 10.5649C83.0761 8.16152 81.8861 5.75819 80.1861 4.04152C77.9761 1.80986 74.9161 0.436523 71.8561 0.436523H12.3561C5.72605 0.436523 0.456055 5.75819 0.456055 12.4532V98.2865H78.3161C78.1461 97.7715 76.4461 97.0849 76.1061 96.3982C76.9561 96.7415 77.8061 97.7715 78.6561 97.5999L78.4861 12.6249ZM71.6861 4.04152C75.0861 4.04152 78.1461 6.10152 79.5061 9.19152H23.5761C23.0661 7.30319 22.0461 5.58652 20.5161 4.21319C20.5161 4.21319 20.5161 4.21319 20.3461 4.04152H71.6861ZM3.68605 12.6249C3.68605 9.19152 5.72605 6.10152 8.95605 4.72819C12.1861 3.35486 15.7561 4.21319 18.1361 6.61652C19.4961 7.81819 20.3461 9.36319 20.6861 11.2515L20.8561 12.6249H75.0861V95.0249H3.68605V12.6249Z" fill="#908F8F" />
    <path d="M10.4861 62.4082H29.1861C30.2061 62.4082 30.8861 63.0949 30.8861 64.1249C30.8861 65.1549 30.2061 65.8415 29.1861 65.8415H10.4861C9.46613 65.8415 8.78613 65.1549 8.78613 64.1249C8.78613 63.0949 9.63613 62.4082 10.4861 62.4082Z" fill="#908F8F" />
    <path d="M69.4759 100.175C82.1508 100.175 92.4259 89.7989 92.4259 76.9997C92.4259 64.2005 82.1508 53.8247 69.4759 53.8247C56.8009 53.8247 46.5259 64.2005 46.5259 76.9997C46.5259 89.7989 56.8009 100.175 69.4759 100.175Z" fill="white" />
    <path d="M58.8789 75.6748L66.8269 83.6229L80.0738 70.376" stroke="#00CB00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M69.4762 55.5412C57.7401 55.5412 48.2262 65.1485 48.2262 76.9996C48.2262 88.8507 57.7401 98.4579 69.4762 98.4579C81.2122 98.4579 90.7262 88.8507 90.7262 76.9996C90.7262 65.1485 81.2122 55.5412 69.4762 55.5412ZM44.8262 76.9996C44.8262 63.2523 55.8624 52.1079 69.4762 52.1079C83.09 52.1079 94.1262 63.2523 94.1262 76.9996C94.1262 90.7469 83.09 101.891 69.4762 101.891C55.8624 101.891 44.8262 90.7469 44.8262 76.9996Z" fill="#908F8F" />
  </svg>
);

const ModalActivedPlan = ({ open, onClose }) => {
  const { currentPlanInfo } = useChoosePlan();

  return (
    <DialogModal
      open={open}
      title="Plano ativado com sucesso!"
      message={`Agora é só aproveitar todos os recursos do ${currentPlanInfo.name}`}
      icon={<SuccessIcon />}
      confirmButton={<Button primary onClick={() => onClose()}>Ok, entendi</Button>}
    />
  );
};

ModalActivedPlan.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalActivedPlan;
