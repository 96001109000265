import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
`;

export const Flag = styled.span`
  position: sticky;
  z-index: 4;
  height: fit-content;

  img {
    position: absolute;
    margin-left: -120px;
    margin-top: -30px;
  }

  @media ${({ theme }) => theme.breakPoints.small} {
    display: none;
  }  
`;
