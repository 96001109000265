import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 32px;
  padding: 20px 16px;
  width: 100%;
  max-width: 375px;

  background: rgba(52, 107, 247, 0.08);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
`;

export const VectorWrapper = styled.span`
  display: block;

  margin-bottom: 12px;
  width: 100%;
  max-width: 220px;
`;

export const Title = styled.h2`
  margin: 12px auto;

  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;

export const Text = styled.p`
  margin-bottom: 16px;
  text-align: center;
`;
