/* eslint-disable no-param-reassign */
import {
  Avatar, Icon, IconButton
} from '@contraktor-tech/ui';
import PropTypes from 'prop-types';
import React from 'react';

import SigneeStatusLabel from '../SigneeStatusLabel';
import * as S from './styles';

const SigneeCard = ({
  signee, onClick, documentCanceled, documentStatus, isFreemium
}) => {
  const {
    name,
    email,
    id,
    whatsapp
  } = signee;

  const status = signee.confirmedAt ? 'signed' : 'pending';

  const showFooter = documentStatus !== 'draft' && !isFreemium;

  return (
    <S.Container variant="outlined">
      <S.Header>
        <Avatar letters={name} randomHash={id} />
        <S.HeaderInfo>
          <h2>{name}</h2>
          <p>{email}</p>
          <p>{whatsapp}</p>
        </S.HeaderInfo>
        {showFooter && (
          <IconButton onClick={(event) => onClick(event, signee)}>
            <Icon>more_horiz</Icon>
          </IconButton>
        )}
      </S.Header>
      {showFooter && (
        <>
          <S.Footer>
            <SigneeStatusLabel status={documentCanceled ? 'canceled' : status} />
          </S.Footer>
        </>
      )}
    </S.Container>
  );
};

SigneeCard.propTypes = {
  signee: PropTypes.shape({
    confirmedAt: PropTypes.string.isRequired,
    confirmedIpAddress: PropTypes.string.isRequired,
    confirmedUserAgent: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sentAt: PropTypes.string.isRequired,
    signatureType: PropTypes.string,
    whatsapp: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  documentCanceled: PropTypes.string.isRequired,
  documentStatus: PropTypes.string.isRequired,
  isFreemium: PropTypes.bool.isRequired
};

export default SigneeCard;
