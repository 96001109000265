import { useContext } from 'react';

import { DocumentContext } from '../../contexts/DocumentContenxt';

export function useDocument() {
  const context = useContext(DocumentContext);

  if (!context) {
    throw new Error('useDocument must be used within an DocumentProvider');
  }

  return context;
}
