import styled from 'styled-components';

export const Wrapper = styled.div`
  border-color: ${(props) => props.theme.separatorBorderColor};
  border-style: solid;
  border-top-width: 0;
  width: 100%;
  border-right-width: 0;
  border-bottom: 1px solid #DEDEDE;
  border-left-width: 0;
  position: relative;

  .overlay {
    position: absolute;
    right: 0;
    top: 1px;
    bottom: 1px;
  }

  &:hover,
  &:focus,
  &.focus-within {
    background-color: ${(props) => props.theme.primaryBackgroundColor};
    box-shadow: 0 1px 4px rgba(0,0,0,0.2), 0 0 20px rgba(0,0,0,0.1);
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.5em;
  margin-left: 1.5em;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Description = styled.div`
  width: calc(100% - 1em);
  display: flex;

  > * {
    color: ${(props) => props.theme.fontColor};
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;

    &:hover,
    &:focus,
    &.focus-within {
      color: ${(props) => props.theme.fontColor};

      .highlight {
        color: ${(props) => props.theme.linkColorHover};
      }
    }
  }
`;

export const Column = styled.div`
  width: ${(props) => props.width || 'auto'};
  flex-grow: 1;
  flex-shrink: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  line-height: 1.5;
`;
