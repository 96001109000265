import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdUploadFile } from 'react-icons/md';

import { Disclaimer } from '../../_shared/styles';
import { useAuth } from '../../../_legacy/containers/AuthContext/AuthContext';
import Button from '../Button';
import {
  ActionsContainer,
  Container,
  PreviewImage,
  TextWrapper, UploadInfo, UploadTitle, Wrapper
} from './styles';

export default function Dropzone({ onDrop, onDelete, image }) {
  const { subscription: { planName } } = useAuth();
  const [preview, setPreview] = useState();

  const infos = [
    `Tamanho de imagem ideal: ${316} x ${48} pixels`,
    'Somente arquivos PNG, JPG e JPEG.',
    `Máximo de ${20}MB.`
  ];

  const hasImage = !!preview || !!image;
  const hasLogoAccess = ['Trial', 'Essential', 'Unlimited'].includes(planName);

  const removeImage = useCallback(() => {
    URL.revokeObjectURL(preview);
    setPreview(null);
    onDelete();
  }, [preview]); // eslint-disable-line

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      onDrop(acceptedFiles);
    },
    accept: 'image/*',
    maxFiles: 1
  });

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]); // eslint-disable-line

  return (
    <Container>
      <Wrapper style={{ cursor: hasLogoAccess ? 'pointer' : 'not-allowed' }} {...getRootProps()}>
        <input disabled={!hasLogoAccess} {...getInputProps()} accept=".png,.jpg,.jpeg" />
        {
          hasImage
            ? <PreviewImage src={image || preview} alt="uploaded-image" />
            : (
              <TextWrapper>
                <UploadTitle bottomGap="20px">
                  Faça upload da imagem do seu logotipo aqui
                  &nbsp;<MdUploadFile className="upload-icon" />
                </UploadTitle>
                {
                  infos.map((i) => <UploadInfo>{i}</UploadInfo>)
                }
              </TextWrapper>
            )
        }
      </Wrapper>
      <Disclaimer>
        *Na ausência de um logotipo personalizado,
        o logotipo da Contraktorsign será utilizado por padrão.
      </Disclaimer>
      <Disclaimer>
        **Tamanho de imagem ideal: 316 x 48 pixels
        &nbsp;| Somente arquivos PNG, JPG e JPEG.
        &nbsp;| Menos de 20MB.
      </Disclaimer>
      {
        hasLogoAccess && (
          <ActionsContainer>
            <Button
              variant="contained"
              theme="danger"
              label="Remover Imagem"
              onClick={() => removeImage(preview)}
            />
            <Button
              variant="contained"
              theme="secondary"
              label="Alterar Imagem"
              {...getRootProps()}
            />
          </ActionsContainer>
        )
      }
    </Container>
  );
}
