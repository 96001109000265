/* eslint-disable max-len */
import { Button } from '@contraktor-tech/ui';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import signatureSvg from '../../../assets/img/signature-icon.svg';
import { useAuth } from '../../../containers/AuthContext/AuthContext';
import { useAmplitude } from '../../../hooks/AmplitudeProvider';
import { useMixpanel } from '../../../hooks/MixpanelProvider';
import { updateFile, updateStep } from '../../../redux/ducks/starterWizard';
import { Wrapper } from './styles';

const DEFAULT_BUTTON_LABEL = 'Selecionar documento';

const DragzoneComponent = ({
  // eslint-disable-next-line react/prop-types
  canUpload = true, getRootProps = () => {}, getInputProps = () => {},
  // eslint-disable-next-line react/prop-types
  error, message, onClick = () => {}, maxUploadSize = 10000000
}) => (
  <Wrapper {...(canUpload ? getRootProps() : {})} accept=".doc,.docx,.pdf">
    {canUpload && <input type="file" {...getInputProps()} multiple={false} />}
    <img src={signatureSvg} alt="signature-icon" />
    <Button
      disabled={!canUpload}
      className="upload-button"
      label={DEFAULT_BUTTON_LABEL}
      type="submit"
      onClick={onClick}
    />
    <p className="footer-info">Ou arraste um documento aqui</p>
    <p className="footer-info">No máximo <b>{maxUploadSize / 1000000}MB</b>, no formato <b>.pdf</b>, <b>.doc</b> ou <b>.docx</b>.</p>
    {error && <p className="error">{message}</p>}
  </Wrapper>
);

const UploadArea = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    trialIsOver, isFreemium
  } = useAuth();
  const { Amplitude } = useAmplitude();
  const { Mixpanel } = useMixpanel();
  const maxUploadSize = isFreemium ? 5000000 : 20000000;
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const checkMimeType = (type) => {
    if (
      type === 'application/pdf'
      || type === 'application/msword'
      || type
        === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return true;
    }
    return false;
  };

  const checkFileSize = useCallback((bytes) => {
    if (bytes > maxUploadSize) {
      return false;
    }

    return true;
  }, [maxUploadSize]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setError(false);

      const file = acceptedFiles[0];

      const typeAccepted = checkMimeType(file.type);
      const sizeAccepted = checkFileSize(file.size);

      if (typeAccepted && sizeAccepted) {
        Mixpanel.track('home_import_button_selecionar-documento', { document_name: file.name, document_type: file.type, document_size: file.size });
        dispatch(updateFile(file));
        Amplitude.logEvent('new_doc_import_attempt');
        return dispatch(updateStep({ step: 2, stepIndex: 2 }));
      }

      if (!typeAccepted) {
        setMessage('Somente arquivos PDF, doc ou docx são aceitos.');
      } else if (!sizeAccepted) {
        setMessage('Tamanho do arquivo é maior de 20MB.');
      }

      return setError(true);
    },
    [dispatch, Amplitude, checkFileSize, Mixpanel]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadComponentDefaultProps = {
    error,
    message,
    maxUploadSize
  };

  if (trialIsOver) {
    return (
      <DragzoneComponent
        canUpload={false}
        onClick={() => {
          toast.warn('Seu período trial acabou');
          Mixpanel.track('home_import_button_selecionar-documento--blocked');
          history.push('/plans');
        }}
        {...uploadComponentDefaultProps}
      />
    );
  }

  return (
    <DragzoneComponent
      canUpload
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      {...uploadComponentDefaultProps}
    />
  );
};

export default UploadArea;
