export default {
  pallete: {
    primary: '#0251DE',
    primaryLight: '#DBE1FF',
    primaryGrey: '#605D62',
    successLight: '#8DF8B7',
    white: '#ffffff',
    secondaryLight: '#CAE6FF'
  },
  buttons: {
    bg: {
      primary: '#0251DE',
      secondary: '#CAE6FF',
      secondaryDark: '#001E2F',
      danger: '#FFDAD6',
      disabled: '#bfbfbf',
      white: '#ffffff'
    },
    label: {
      primary: '#ffffff',
      secondary: '#001E2F',
      secondaryDark: '#ffffff',
      danger: '#BA1A1A',
      disabled: '#ffffff',
      white: '#0251DE'
    },
    border: {
      secondaryLightGrey: '#767680'
    }
  },
  background: {
    light: '#ffffff',
    lightGrey: '#FEFBFF',
    primaryBlue: '#0251DE',
    darkBlue: '#00174D',
    lightBlue: '#DBE1FF',
    neutralGrey: '#E2E1EC',
    secoundBlue: '#22DCD9',
    primaryOrange: '#FFB95E'
  },
  fonts: {
    family: {
      Poppins: 'Poppins'
    },
    weight: {
      thin: '400',
      normal: '500',
      bold: '600',
      bolder: '700'
    },
    color: {
      primary: '#0251DE',
      secondaryDark: '#001E2F',
      white: '#ffffff',
      darkBlue: '#00174D',
      darkGrey: '#45464F',
      lightGrey: '#767680',
      darkGreen: '#00391F',
      neutralGrey: '#5E5E62',
      brown: '#472A00',
      secundaryBlue: '#346AF7',
      neutral: '#1B1B1F'
    },
    size: {
      min: '10px',
      smaller: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      larger: '22px',
      big: '33px',
      extraLarge: '54px'
    },
    letterSpacing: {
      small: '0.5px'
    }
  }
};
