/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper, Column } from './styles';

const TableHeader = ({ columns }) => (
  <Wrapper>
    {columns.map((col) => (
      <Column
        key={col.title}
        width={col.width}
        center={col.center}
      >
        {col.title}
      </Column>
    ))}
  </Wrapper>
);

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableHeader;
