import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import theme from '../../utils/theme';
import IconAlert from '../IconAlert';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  img {
    margin-bottom: 1em;
  }
`;

const Title = styled.header`
  color: ${theme.tooltipBackgrounColor};
  font-size: 4rem;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 0.75em;
`;

const Content = styled.div`
  color: ${theme.defaultAvatarColor};

  p {
    margin: 0;
    padding: 0;
  }
`;

const IconWrapper = styled.div`
  font-size: 17em;
  color: #E0E6ED;
`;

const defaultErrorChildren = (
  <div>
    <p>Algo inesperado aconteceu e sentimos por isso :(</p>
    <p>Você pode tentar atualizar a página ou contatar o suporte.</p>
  </div>
);

const defaultIcon = (
  <IconAlert />
);

const ErrorPage = ({ header, icon, children }) => (
  <Wrapper>
    <Grid>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4} widescreen={4}>
          <IconWrapper>
            {icon}
          </IconWrapper>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={8} largeScreen={8} widescreen={8}>
          <Title>{header}</Title>
          <Content>
            {React.Children.toArray(children)}
          </Content>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Wrapper>
);

ErrorPage.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  children: PropTypes.node,
  icon: PropTypes.node

};

ErrorPage.defaultProps = {
  header: 'Ops...',
  children: defaultErrorChildren,
  icon: defaultIcon
};

export default ErrorPage;
