import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../Loader';

const GlobalLoader = () => {
  const { loading } = useSelector((state) => state.global);

  return (
    <Loader loading={loading} />
  );
};

export default GlobalLoader;
