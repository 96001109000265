import axios from 'axios';

import { GATEWAY_URL } from '../../../../utils/constants';

const gatewayApi = axios.create({
  baseURL: GATEWAY_URL(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  timeout: 30000
});

export default gatewayApi;
