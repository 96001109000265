/* eslint-disable camelcase */
import gatewayApi from '../../../components/_new/services/gateway/config';
import { removeSpecialCharacteres } from '../../../utils/helpers';
import { cpfCnpjParser } from '../../../utils/parsers';

export const subscribe = async (data) => {
  const {
    voucher,
    planName,
    frequency,
    isInstallment,
    clientSuspensionReason
  } = data;
  const endpoint = isInstallment ? '/installment' : '';

  const payload = {
    code: voucher,
    plan: planName,
    clientSuspensionReason,
    additionalFeatures: JSON.parse(localStorage.getItem('additionalFeatures')) || null
  };

  if (isInstallment) Object.assign(payload, { installments: 12 });

  const params = {
    frequency
  };

  return gatewayApi
    .post(`/subscription/subscribe${endpoint}`, payload, { params })
    .then((res) => {
      localStorage.removeItem('additionalFeatures');
      return res;
    });
};

export const registerCard = async (data) => {
  const {
    cardNumber,
    cardName,
    cardDueDate,
    cardCvv,
    cupom,
    cep,
    document,
    street_number,
    ...formData
  } = data;

  const parsedDocument = cpfCnpjParser(document);
  const parsedPostCode = removeSpecialCharacteres(cep);

  const cardData = {
    cardNumber: cardNumber.replace(/\s/g, ''),
    holderName: cardName,
    ccv: cardCvv,
    expirationMonth: cardDueDate.split('/')[0],
    expirationYear: cardDueDate.split('/')[1],
    ...formData
  };

  const payload = {
    ...cardData,
    number: street_number,
    postCode: parsedPostCode,
    document: parsedDocument
  };

  return gatewayApi
    .post('/creditCard', payload);
};

export const getSubscription = async () => gatewayApi
  .get('/subscription');

export const validateVoucher = async (data) => gatewayApi
  .post('/voucher', data);

export const subscribeFeature = async (data) => gatewayApi
  .post('/organizationFeature', data);
