import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ModalBase from '../_new/ModalBase';
import gatewayApi from '../_new/services/gateway/config';
import { useAuth } from '../../containers/AuthContext/AuthContext';
// import { useAmplitude } from '../../hooks/AmplitudeProvider';
import useQueryString from '../../hooks/useQueryString';
import ChangePlanModal from '../../pages/Plans/ChangePlanModal';
import ModalActivedPlan from '../Starter/ModalActivedPlan';
import ModalCancelationConfirmed from '../Starter/ModalCancelationConfirmed/ModalCancelationConfirmed';
import ModalCancelPlan from '../Starter/ModalCancelPlan';
import PaymentForm from './PaymentForm';
import PlanInfo from './shared/PlanInfo';
import {
  Wrapper, FormContainer, Container, Button as ButtonUI
} from './styles';

const PaymentSettings = () => {
  const { query } = useQueryString({ only: ['r'], clearFalsyValues: true });
  const updateCreditCard = (new URLSearchParams(window.location.search.split('?')[1])).get('updateCreditCard');

  const {
    isOwner,
    owner,
    isPaidPlan,
    isTrial,
    planActivated,
    subscription: {
      value
    }
  } = useAuth();

  // const { Amplitude } = useAmplitude();
  const history = useHistory();
  const [openForm, setOpenForm] = useState(false);
  const [openActivationForm, setOpenActivationForm] = useState(false);
  const [openActivedModal, setOpenActivedModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [newPrice, setNewPrice] = useState(null);
  const [clientSuspensionReason, setClientSuspensionReason] = useState(null);
  const [modalCancelationConfirmed, setModalCancelationConfirmed] = useState(false);

  useEffect(() => {
    if (updateCreditCard) setOpenForm(true);
  }, []); //eslint-disable-line

  const handleClickMelhorarPlano = () => history.push('/plans');

  const handleDoCancelation = async () => {
    try {
      await gatewayApi.delete('/subscription/unsubscribe', { params: { clientSuspensionReason } });
    } catch (err) {
      const errorMsg = err.response?.data?.message || 'Erro ao cancelar o plano';

      toast.error(errorMsg);
    } finally {
      setOpenChangePlanModal(false);
      setModalCancelationConfirmed(true);
    }
  };

  const renderOwnerArea = () => {
    if (isTrial) {
      return (
        <ButtonUI
          label="Contratar plano"
          onClick={() => history.push('/plans')}
          variant="contained"
          disableElevation
        />
      );
    }

    if (isOwner) {
      return (
        <Container>
          {!isTrial && !openActivationForm && (
            <ButtonUI
              label="Mudar plano"
              onClick={() => handleClickMelhorarPlano()}
              variant="contained"
              style={{
                backgroundColor: '#0251DE'
              }}
              disableElevation
            />
          )}

          {isPaidPlan && planActivated && (
            <>
              <ButtonUI
                label="Alterar cartão"
                onClick={() => setOpenForm(!openForm)}
                variant="contained"
                color="secondary"
                style={{
                  backgroundColor: '#CAE6FF',
                  color: '#001E2F'
                }}
                disableElevation
              />
            </>
          )}

          {isPaidPlan && !planActivated && (
            <>
              {!openActivationForm ? (
                <ButtonUI
                  variant="contained"
                  label="Reativar plano"
                  type="submit"
                  onClick={() => history.push('/update-payment-info')}
                  disableElevation
                />
              ) : (
                <div className="reactivate-container">
                  <h2 className="reactivate-text">
                    Preencha os dados do seu cartão para reativar o plano
                    automaticamente:
                  </h2>
                  <div style={{ maxWidth: 440 }}>
                    <PaymentForm
                      onCloseForm={() => {
                        setOpenActivationForm(!openActivationForm);
                      }}
                      formColumns={1}
                      confirmButtonLabel={`Pagar R$${newPrice}`}
                      doPayment
                      onPaymentSuccess={() => setOpenActivedModal(true)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </Container>
      );
    }

    return (
      <Container>
        <p><b>Responsável pela conta:</b> {owner.owner_name} ({owner.owner_email})</p>
      </Container>
    );
  };

  useEffect(() => {
    const emptyVals = [undefined, null, ''];
    if (!emptyVals.includes(clientSuspensionReason)) {
      handleDoCancelation();
    }
    // eslint-disable-next-line
  }, [clientSuspensionReason]);

  useEffect(() => {
    if (query && query.r) setOpenActivationForm(true);
  }, [query]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setNewPrice(value);
    }

    return () => { mounted = false; };
  }, [value]);

  if (openForm) {
    return (
      <>
        <h2>Insira seus dados para atualizar o cartão:</h2>
        <FormContainer>
          <PaymentForm onCloseForm={() => setOpenForm(!openForm)} />
        </FormContainer>
      </>
    );
  }

  return (
    <Wrapper>
      <PlanInfo />
      <ModalActivedPlan
        open={openActivedModal}
        onClose={() => setOpenActivedModal(false)}
      />
      <ModalCancelPlan
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        handleConfirm={() => {
          setOpenCancelModal(false);
          setOpenChangePlanModal(true);
        }}
      />
      <ModalCancelationConfirmed
        open={modalCancelationConfirmed}
        onClose={() => setModalCancelationConfirmed(false)}
      />
      <ModalBase
        open={openChangePlanModal}
        onClose={() => {}}
        hiddenCloseButton
      >
        <ChangePlanModal
          onSubmit={() => handleDoCancelation()}
          onCancel={() => {
            setOpenChangePlanModal(false);
            setOpenCancelModal(true);
          }}
          setClientSuspensionReason={(val) => setClientSuspensionReason(val)}
        />
      </ModalBase>
      {renderOwnerArea()}
    </Wrapper>
  );
};

export default PaymentSettings;
