import PropTypes from 'prop-types';
import React from 'react';

import { Field } from './styles';

const TruncateField = ({ children, clampValue }) => (
  <Field clamp={clampValue}>
    {children}
  </Field>
);

TruncateField.propTypes = {
  children: PropTypes.node,
  clampValue: PropTypes.number
};

TruncateField.defaultProps = {
  clampValue: 2,
  children: null
};

export default TruncateField;
