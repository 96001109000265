import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';

import { useAuth } from '../../containers/AuthContext/AuthContext';
import { emailRegex } from '../../utils/parsers';
import Button from '../Button';
import FieldErrorMessage from '../FieldErrorMessage';
import InputWithRef from '../InputWithRef';
import { Form, TermsOfUse, ForgotPassword } from './styles';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const history = useHistory();
  const {
    handleSubmit, register, formState: { errors }, getValues
  } = useForm();

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        email: getValues('email'),
        password: getValues('password')
      };
      await signIn(data);

      const redirectUrl = localStorage.getItem('redirectTo') || '/';

      history.push(redirectUrl);
      localStorage.removeItem('redirectTo');

      toast.success('Logado com sucesso!');
    } catch (err) {
      toast.error('Usuário ou senha incorretos.');
    } finally {
      setLoading(false);
    }
  }, [history, signIn, getValues]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Grid columns={1}>
          <Grid.Column>
            <InputWithRef
              type="email"
              id="email"
              onChange={(e) => {
                if (e.target.value) {
                  e.target.value = e.target.value.toLowerCase().trim();
                }
              }}
              label="Email"
              {...register('email', {
                required: (<FieldErrorMessage message="Campo Obrigatório" />),
                pattern: {
                  value: emailRegex,
                  message: (
                    <FieldErrorMessage message="O e-mail inserido deve ser válido." />
                  )
                }
              })}
            >
              {errors?.email && errors?.email.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column>
            <InputWithRef
              type="password"
              id="password"
              label="Senha"
              {...register('password', {
                required: (<FieldErrorMessage message="Campo Obrigatório" />),
                minLength: { value: 8, message: <FieldErrorMessage message="Sua senha deve ter no mínimo 8 caracteres" /> }
              })}
            >
              {errors?.password && errors?.password.message}
            </InputWithRef>
          </Grid.Column>
          <Grid.Column>
            <ForgotPassword>
              <Link to="/register">Criar nova conta</Link>
              <Link to="/recover_password">Esqueceu sua senha?</Link>
            </ForgotPassword>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Button primary type="submit" loading={loading}>
              Entrar
            </Button>
          </Grid.Column>
          <Grid.Column>
            <TermsOfUse>
              Ao ingressar na plataforma você concorda com os
              <a href="https://contraktorsign.com.br/termos-de-uso/" target="_blank" rel="noopener noreferrer">
                termos de uso
              </a>
            </TermsOfUse>
          </Grid.Column>
        </Grid>
      </div>
    </Form>
  );
};

export default LoginForm;
