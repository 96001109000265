import React from 'react';

import themes from '../../_utils/themes';
import { CustomButton } from './styles';

export default function Button({
  label, onClick, theme, disabled, loading, styles
}) {
  return (
    <CustomButton
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: themes.buttons.bg[theme],
        color: themes.buttons.label[theme],
        ...styles
      }}
    >
      {!loading && label}
    </CustomButton>
  );
}
